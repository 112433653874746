<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-unpick_license_plate" *ngIf="!fields.unpick_license_plate.hidden && !fields.unpick_license_plate.removed" 
                            class="field-container standard {{fields.unpick_license_plate.invalid ? 'invalid' : ''}} {{fields.unpick_license_plate.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.unpick_license_plate.styles.style"
                            [ngClass]="fields.unpick_license_plate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.unpick_license_plate.label + (fields.unpick_license_plate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.unpick_license_plate.label}}<span *ngIf="fields.unpick_license_plate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="unpick_license_plate"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.unpick_license_plate.control.styles.style"
                                      [ngClass]="fields.unpick_license_plate.control.styles.classes"
                                      [matTooltip]="fields.unpick_license_plate.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.unpick_license_plate.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.unpick_license_plate.invalid">
                          <ng-container *ngFor="let error of fields.unpick_license_plate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_create_manual_allocation" *ngIf="!fields.is_create_manual_allocation.hidden && !fields.is_create_manual_allocation.removed" 
                            class="field-container standard {{fields.is_create_manual_allocation.invalid ? 'invalid' : ''}} {{fields.is_create_manual_allocation.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.is_create_manual_allocation.styles.style"
                            [ngClass]="fields.is_create_manual_allocation.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.is_create_manual_allocation.label + (fields.is_create_manual_allocation.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.is_create_manual_allocation.label}}<span *ngIf="fields.is_create_manual_allocation.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="is_create_manual_allocation"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.is_create_manual_allocation.control.styles.style"
                                      [ngClass]="fields.is_create_manual_allocation.control.styles.classes"
                                      [matTooltip]="fields.is_create_manual_allocation.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.is_create_manual_allocation.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.is_create_manual_allocation.invalid">
                          <ng-container *ngFor="let error of fields.is_create_manual_allocation.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-packaged_amount" *ngIf="!fields.packaged_amount.hidden && !fields.packaged_amount.removed" 
                            class="field-container standard {{fields.packaged_amount.invalid ? 'invalid' : ''}} {{fields.packaged_amount.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.packaged_amount.styles.style"
                            [ngClass]="fields.packaged_amount.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.packaged_amount.label + (fields.packaged_amount.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.packaged_amount.label}}<span *ngIf="fields.packaged_amount.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.packaged_amount.control.styles.style"
                              [ngClass]="fields.packaged_amount.control.styles.classes"
                              [matTooltip]="fields.packaged_amount.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.packaged_amount.control.text }}</div>
                        <ng-container *ngIf="fields.packaged_amount.invalid">
                          <ng-container *ngFor="let error of fields.packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-unpick_packaged_amount" *ngIf="!fields.unpick_packaged_amount.hidden && !fields.unpick_packaged_amount.removed" 
                            class="field-container standard {{fields.unpick_packaged_amount.invalid ? 'invalid' : ''}} {{fields.unpick_packaged_amount.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.unpick_packaged_amount.styles.style"
                            [ngClass]="fields.unpick_packaged_amount.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.unpick_packaged_amount.label + (fields.unpick_packaged_amount.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.unpick_packaged_amount.label}}<span *ngIf="fields.unpick_packaged_amount.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="unpick_packaged_amount"
                                matInput
                                numberBox
                                [format]="fields.unpick_packaged_amount.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.unpick_packaged_amount.control.readOnly || fields.unpick_packaged_amount.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.unpick_packaged_amount.control.placeholder}}"
                                [ngStyle]="fields.unpick_packaged_amount.control.styles.style"
                                [ngClass]="fields.unpick_packaged_amount.control.styles.classes"
                                [matTooltip]="fields.unpick_packaged_amount.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.unpick_packaged_amount.invalid">
                          <ng-container *ngFor="let error of fields.unpick_packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-location" *ngIf="!fields.location.hidden && !fields.location.removed" 
                            class="field-container standard {{fields.location.invalid ? 'invalid' : ''}} {{fields.location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.location.styles.style"
                            [ngClass]="fields.location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.location.label + (fields.location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.location.label}}<span *ngIf="fields.location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.location.control.type"
                            formControlName="location"
                            (displayTextChange)="fields.location.control.displayText=$event"
                            [placeholder]="fields.location.control.placeholder"
                            [styles]="fields.location.control.styles"
                            [tooltip]="fields.location.control.tooltip"
                          [warehouseId]="$fields_location_selector_inParams_warehouseId"
                          [typeId]="$fields_location_selector_inParams_typeId"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.location.invalid">
                          <ng-container *ngFor="let error of fields.location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-licenseplate"
                     *ngIf="!fieldsets.licenseplate.hidden && !fieldsets.licenseplate.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.licenseplate.collapsible }">
                    <div *ngIf="!fieldsets.licenseplate.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.licenseplate.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.licenseplate.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.licenseplate.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.licenseplate.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.licenseplate.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.licenseplate.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-new_licenseplate_checkbox" *ngIf="!fields.new_licenseplate_checkbox.hidden && !fields.new_licenseplate_checkbox.removed" 
                            class="field-container standard {{fields.new_licenseplate_checkbox.invalid ? 'invalid' : ''}} {{fields.new_licenseplate_checkbox.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.new_licenseplate_checkbox.styles.style"
                            [ngClass]="fields.new_licenseplate_checkbox.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.new_licenseplate_checkbox.label + (fields.new_licenseplate_checkbox.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.new_licenseplate_checkbox.label}}<span *ngIf="fields.new_licenseplate_checkbox.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="new_licenseplate_checkbox"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.new_licenseplate_checkbox.control.styles.style"
                                      [ngClass]="fields.new_licenseplate_checkbox.control.styles.classes"
                                      [matTooltip]="fields.new_licenseplate_checkbox.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.new_licenseplate_checkbox.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.new_licenseplate_checkbox.invalid">
                          <ng-container *ngFor="let error of fields.new_licenseplate_checkbox.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-licenseplate" *ngIf="!fields.licenseplate.hidden && !fields.licenseplate.removed" 
                            class="field-container standard {{fields.licenseplate.invalid ? 'invalid' : ''}} {{fields.licenseplate.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.licenseplate.styles.style"
                            [ngClass]="fields.licenseplate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.licenseplate.label + (fields.licenseplate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.licenseplate.label}}<span *ngIf="fields.licenseplate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Inventory-licenseplates_dd_single 
                            data-cy="selector"
                            [type]="fields.licenseplate.control.type"
                            formControlName="licenseplate"
                            (displayTextChange)="fields.licenseplate.control.displayText=$event"
                            [placeholder]="fields.licenseplate.control.placeholder"
                            [styles]="fields.licenseplate.control.styles"
                            [tooltip]="fields.licenseplate.control.tooltip"
                          [locationId]="$fields_licenseplate_selector_inParams_locationId"
                          [typeId]="$fields_licenseplate_selector_inParams_typeId"
                          [archived]="$fields_licenseplate_selector_inParams_archived"
                        >
                        </Inventory-licenseplates_dd_single>
                        <ng-container *ngIf="fields.licenseplate.invalid">
                          <ng-container *ngFor="let error of fields.licenseplate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-new_licenseplate" *ngIf="!fields.new_licenseplate.hidden && !fields.new_licenseplate.removed" 
                            class="field-container standard {{fields.new_licenseplate.invalid ? 'invalid' : ''}} {{fields.new_licenseplate.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.new_licenseplate.styles.style"
                            [ngClass]="fields.new_licenseplate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.new_licenseplate.label + (fields.new_licenseplate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.new_licenseplate.label}}<span *ngIf="fields.new_licenseplate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="new_licenseplate"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.new_licenseplate.control.readOnly || fields.new_licenseplate.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.new_licenseplate.control.placeholder}}"
                                [ngStyle]="fields.new_licenseplate.control.styles.style"
                                [ngClass]="fields.new_licenseplate.control.styles.classes"
                                [matTooltip]="fields.new_licenseplate.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.new_licenseplate.invalid">
                          <ng-container *ngFor="let error of fields.new_licenseplate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-misc"
                     *ngIf="!fieldsets.misc.hidden && !fieldsets.misc.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.misc.collapsible }">
                    <div *ngIf="!fieldsets.misc.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.misc.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.misc.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.misc.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.misc.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.misc.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.misc.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden && !fields.notes.removed" 
                            class="field-container double {{fields.notes.invalid ? 'invalid' : ''}} {{fields.notes.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.notes.control.readOnly || fields.notes.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>