import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { AsnOrders_licenseplates_by_order_dd_singleComponent } from './AsnOrders.licenseplates_by_order_dd_single.component'
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { FootPrintManager_lots_dd_singleComponent } from './FootPrintManager.lots_dd_single.component'
import { FootPrintManager_vendorlots_dd_singleComponent } from './FootPrintManager.vendorlots_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'


interface IFootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentEntity {
  Id?: number, ExpectedInventoryAmount?: number, ExpectedPackagedAmount?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, ShipmentLineId?: number, StatusId?: number, Material?: { Id?: number, ControllerTypeId?: number, LookupCode?: string, PackagingLookups?: { PackagingId?: number }[] }, VendorLot?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string, ShortName?: string }, Status?: { Name?: string }, ActualTargetLicensePlate?: { Id?: number, Archived?: boolean, LookupCode?: string, LicensePlateContents?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[] }}

interface IFootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentInParams {
  orderId: number, readOnly?: boolean}


class FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent;
  entity: IFootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentEntity;

  vars: { licenseplateId?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    licenseplate_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    material_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    lot_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    vendorlot_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    packaging_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    packaged_amount_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    licenseplate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['licenseplate_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      ),
    material: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['material_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      ),
    lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['lot_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      ),
    vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['vendorlot_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['packaging_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      ),
    packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['packaged_amount_edit'] as DatexFormControl, null, false, false, '0', '', null)
      ),
  }

  get $fields_licenseplate_selector_inParams_orderId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.orderId;
    
    return expr;
  }
  get $fields_licenseplate_selector_inParams_shipmentId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.vars.shipmentId;
    
    return expr;
  }
  get $fields_licenseplate_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.vars.warehouseId;
    
    return expr;
  }
  get $fields_licenseplate_selector_inParams_defaultLocationId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.vars.defaultReceivingLocationId;
    
    return expr;
  }
  get $fields_material_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity?.ProjectId ?? $grid.vars.projectId;
    
    return expr;
  }
  get $fields_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }
  get $fields_lot_selector_inParams_vendorLotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.vendorlot?.editControl?.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.lot?.editControl?.value;
    
    return expr;
  }
  get $fields_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
    this.formGroup
      .controls['material_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_changed();
      });
    this.formGroup
      .controls['lot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lot_changed();
      });
    this.formGroup
      .controls['vendorlot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendorlot_changed();
      });
    this.formGroup
      .controls['packaging_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_packaging_changed();
      });
  }

  async $initializeExisting(grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent, entity: IFootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent, entity?: IFootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      licenseplateId:  null ,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.WarehouseTransfers.ds_warehouse_transfer_inbound_order_receiving_tasks_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.licenseplate.displayControl as TextModel).text = $row.entity?.ActualTargetLicensePlate?.LookupCode;
    (this.cells.licenseplate.editControl as SelectBoxModel).reset($row.entity?.ActualTargetLicensePlate?.Id);
    (this.cells.material.displayControl as TextModel).text = $row.entity?.Material?.LookupCode;
    (this.cells.material.editControl as SelectBoxModel).reset($row.entity?.Material?.Id);
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.lot.editControl as SelectBoxModel).reset($row.entity?.Lot?.Id);
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity?.VendorLot?.LookupCode;
    (this.cells.vendorlot.editControl as SelectBoxModel).reset($row.entity?.VendorLot?.Id);
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.ExpectedPackagedPack?.ShortName;
    (this.cells.packaging.editControl as SelectBoxModel).reset($row.entity?.ExpectedPackagedPack?.Id);
    (this.cells.packaged_amount.displayControl as TextModel).text = $row.entity?.ExpectedPackagedAmount?.toString();
    (this.cells.packaged_amount.editControl as NumberBoxModel).reset($row.entity?.ExpectedPackagedAmount);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      /*** Validations ***/
      // Check that all required fields are populated
      if (!$utils.isAllDefined(
          $row.cells.licenseplate.editControl.value,
          $row.cells.lot.editControl.value,
          $row.cells.packaging.editControl.value,
          $row.cells.packaged_amount.editControl.value
      )) {
          throw new Error('Please enter data for all required fields.');
      }
  
      // Packaged Amount
      if ($row.cells.packaged_amount.editControl.value <= 0) {
          throw new Error('Order line quantity must be greater than 0.');
      }
  
      // Serial Numbers
      let material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $row.cells.material.editControl.value })).result;
      let adjustedPackagedAmount = $row.cells.packaged_amount.editControl.value;
  
      if (material[0].ControllerTypeId === 3 || material[0].ControllerTypeId === 4 || !material[0].IsFixedWeight || !material[0].IsFixedVolume || !material[0].IsFixedLength) {
          if (adjustedPackagedAmount > 0) { // Create SerialNumbers
              let dialogResults = await $shell.FootPrintManager.openserialnumbers_creation_gridDialog({ materialId: material[0].Id, packagingId: $row.cells.packaging.editControl.value, packagedAmount: adjustedPackagedAmount });
  
              let serialnumbersResult = dialogResults.serialNumbers;
              let confirmResult = dialogResults.confirm;
  
              if (confirmResult === true && $utils.isDefined(serialnumbersResult) && serialnumbersResult.length === adjustedPackagedAmount) {
                  var serialNumbersToCreate = serialnumbersResult;
              }
              else {
                  throw Error('No serial numbers were provided.');
              }
          }
      }
  
      // Create receiving task
      $row.entity.Id = (await $flows.WarehouseTransfers.set_target_inbound_receiving_task_flow({
          license_plate_id: $row.cells.licenseplate.editControl.value,
          lot_id: $row.cells.lot.editControl.value,
          packaging_id: $row.cells.packaging.editControl.value,
          order_id: $grid.inParams.orderId,
          material_id: $row.cells.material.editControl.value,
          vendor_lot_id: $row.cells.vendorlot.editControl.value,
          packaged_amount: $row.cells.packaged_amount.editControl.value,
          existing_task_id: null,
          serial_numbers_to_create: serialNumbersToCreate
      })).task_id;
  
      await $row.refresh();
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      $shell.FootPrintManager.openErrorDialog('Error', targetError.message);
      throw Error(targetError.message); // To prevent display mode
  }
  
  /****************************************** 
   * FUNCTIONS
  ******************************************/
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.packaged_amount.editControl.value <= 0) {
      $shell.FootPrintManager.openErrorDialog('Save', 'Order line quantity must be greater than 0.');
      throw new Error('Order line quantity must be greater than 0.'); // to prevent displayMode 
  }
  
  
  try {
      if ($row.cells.licenseplate.editControl.value !== $row.entity.ActualTargetLicensePlate.Id) {
          // Update task to new license plate
          await $flows.Utilities.crud_update_flow({
              entitySet: 'Tasks',
              id: $row.entity.Id,
              entity: {
                  ActualTargetLicensePlateId: $row.cells.licenseplate.editControl.value,
                  ExpectedTargetLicensePlateId: $row.cells.licenseplate.editControl.value
              }
          });
          // Update SerialNumbers to new license plate
          let serialnumbers = (await $datasources.SerialNumbers.ds_find_serialnumbers.get({ licenseplateId: $row.entity.ActualTargetLicensePlate.Id })).result;
  
          let serialnumberTasks = (await $datasources.WarehouseTransfers.ds_get_tasks_by_serialnumberIds.get({ serialnumberIds: serialnumbers.map(sn => sn.Id) })).result;
  
          for (let task of serialnumberTasks) {
              await $flows.Utilities.crud_update_flow({
                  entitySet: 'Tasks',
                  id: task.Id,
                  entity: {
                      ActualTargetLicensePlateId: $row.cells.licenseplate.editControl.value,
                      ActualSourceLicensePlateId: $row.cells.licenseplate.editControl.value
                  }
              });
          }
  
          for (let serialnumber of serialnumbers) {
              await $flows.Utilities.crud_update_flow({
                  entitySet: 'SerialNumbers',
                  id: serialnumber.Id,
                  entity: {
                      LicensePlateId: $row.cells.licenseplate.editControl.value
                  }
              });
          }
      }
  
      // Serial Numbers
      let material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $row.cells.material.editControl.value })).result;
      let adjustedPackagedAmount = $row.cells.packaged_amount.editControl.value - $row.entity.ExpectedPackagedAmount;
  
      if (material[0].ControllerTypeId === 3 || material[0].ControllerTypeId === 4 || !material[0].IsFixedWeight || !material[0].IsFixedVolume || !material[0].IsFixedLength) {
          if (adjustedPackagedAmount > 0) { // Create SerialNumbers
              let dialogResults = await $shell.FootPrintManager.openserialnumbers_creation_gridDialog({ materialId: material[0].Id, packagingId: $row.cells.packaging.editControl.value, packagedAmount: adjustedPackagedAmount });
  
              let serialnumbersResult = dialogResults.serialNumbers;
              let confirmResult = dialogResults.confirm;
  
              if (confirmResult === true && $utils.isDefined(serialnumbersResult) && serialnumbersResult.length === adjustedPackagedAmount) {
                  var serialNumbersToCreate = serialnumbersResult;
              }
              else {
                  throw Error('No serial numbers were provided.');
              }
          }
          else if (adjustedPackagedAmount < 0) {
              let dialogResults = await $shell.FootPrintManager.openserialnumbers_selection_gridDialog({
                  lotId: $row.cells.lot.editControl.value,
                  licenseplateId: $row.cells.licenseplate.editControl.value,
                  packagingId: $row.cells.packaging.editControl.value,
                  packagedAmountRequest: adjustedPackagedAmount * -1
              });
  
              let serialnumberIds = dialogResults.SerialNumberIds;
              let confirmResult = dialogResults.confirm;
  
              if (confirmResult === true && $utils.isDefined(serialnumberIds)) {
                  var serialNumbersToDelete = serialnumberIds;
              }
              else {
                  throw Error('No serial numbers were provided.');
              }
          }
          else {
              //throw new Error('Error creating serial numbers.')
          }
      }
  
      await $flows.WarehouseTransfers.set_target_inbound_receiving_task_flow({
          license_plate_id: $row.cells.licenseplate.editControl.value,
          lot_id: $row.cells.lot.editControl.value,
          packaging_id: $row.cells.packaging.editControl.value,
          order_id: $grid.inParams.orderId,
          material_id: $row.cells.material.editControl.value,
          vendor_lot_id: $row.cells.vendorlot.editControl.value,
          packaged_amount: $row.cells.packaged_amount.editControl.value,
          existing_task_id: $row.entity.Id,
          serial_number_ids_to_delete: serialNumbersToDelete,
          serial_numbers_to_create: serialNumbersToCreate
      });
  
      // Update Grid
      if ($row.cells.packaged_amount.editControl.value > 0) {
          $row.refresh();
      }
      else {
          $grid.rows.splice($grid.rows.indexOf($row), 1);
      }
  }
  catch (ex) {
      let message = $utils.isDefined(ex.error?.error?.message) ? ex.error.error.message : ex.message;
      $shell.FootPrintManager.openErrorDialog('Error', message);
      throw Error(message); // To prevent display mode
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.entity?.StatusId === 2 || $row.entity?.StatusId === 3) {
      $row.cells.licenseplate.editControl.readOnly = true;
      $row.cells.material.editControl.readOnly = true;
      $row.cells.vendorlot.editControl.readOnly = true;
      $row.cells.lot.editControl.readOnly = true;
      $row.cells.packaging.editControl.readOnly = true;
      $row.cells.packaged_amount.editControl.readOnly = true;
  }
  else {
      if ($utils.isDefined($row.entity.Id)) {
          // $row.cells.licenseplate.editControl.readOnly = true;
      }
      else {
          $row.cells.licenseplate.editControl.readOnly = false;
      }
  
      if ($row.entity?.Material?.ControllerTypeId === 1 || $row.entity?.Material?.ControllerTypeId === 3) {
          $row.cells.vendorlot.editControl.readOnly = true;
          $row.cells.lot.editControl.readOnly = true;
      }
      else {
          $row.cells.vendorlot.editControl.readOnly = false;
          $row.cells.lot.editControl.readOnly = false;
      }
  
      if ($row.entity?.Material?.PackagingLookups?.length === 1) {
          $row.cells.packaging.editControl.readOnly = true;
      }
      else {
          $row.cells.packaging.editControl.readOnly = false;
      }
  }
  }
  on_material_changed(event = null) {
    return this.on_material_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changedInternal(
    $row: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var materialId = $row.cells.material.editControl.value;
  
  
  if ($row.cells.material.editControl.isChanged) {
      // Clear dependent fields
      $row.cells.lot.editControl.value = null;
      $row.cells.vendorlot.editControl.value = null;
      $row.cells.packaging.editControl.value = null;
      unlockCell($row.cells.lot);
      unlockCell($row.cells.vendorlot);
      unlockCell($row.cells.packaging);
  
      if ($utils.isDefined($row.cells.material.editControl.value)) {
          // Get material
          let material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: materialId })).result[0];
          if (!$utils.isDefined(material)) { return; }
  
          // Automatically set Lot if Non-lotted material
          if ((material.ControllerTypeId === 1 || material.ControllerTypeId === 3)) {
              let globalLot = (await $datasources.Lots.ds_get_lots_by_materialId.get({ materialId: material.Id })).result.filter(l => l.TypeId === 1)[0];
              if ($utils.isDefined(globalLot)) {
                  $row.cells.vendorlot.editControl.value = globalLot.VendorLotId;
                  lockCell($row.cells.vendorlot);
                  $row.cells.lot.editControl.value = globalLot.Id;
                  lockCell($row.cells.lot);
              }
          }
  
          // Default the packaging
          let packagings = (await $datasources.Materials.ds_get_material_packagings_by_materialId.get({ materialId: material.Id })).result;
  
          if ($utils.isDefined(packagings)) {
  
              let defaultPackaging = packagings.find(p => p.IsBasePackaging);
              $row.cells.packaging.editControl.value = defaultPackaging.PackagingId;
  
              if (packagings.length === 1) { lockCell($row.cells.packaging); }
  
              // Default the decimal formatting
              const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
                  materialId: materialId,
                  packagingId: defaultPackaging.PackagingId
              }));
  
              if ($utils.isDefined(decimalFormat)) {
                  $row.cells.packaged_amount.editControl.format = decimalFormat.format;
              }
  
          }
  
      }
  }
  
  /************************************************* 
   * FUNCTIONS
  *************************************************/
  function lockCell(cell: any) {
      cell.editControl.readOnly = true;
      cell.editControl.styles.setStyle('background', 'transparent');
  }
  
  function unlockCell(cell: any) {
      cell.editControl.readOnly = false;
      cell.editControl.styles.resetStyle();
  }
  }
  on_packaging_changed(event = null) {
    return this.on_packaging_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_packaging_changedInternal(
    $row: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var materialId = $row.cells.material.editControl.value;
  var packagingId = $row.cells.packaging.editControl.value;
  
  
  // Default the decimal formatting
  const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
      materialId: materialId,
      packagingId: packagingId
  }));
  
  if ($utils.isDefined(decimalFormat)) {
      $row.cells.packaged_amount.editControl.format = decimalFormat.format;
  
  }
  
  
  
  }
  on_lot_changed(event = null) {
    return this.on_lot_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changedInternal(
    $row: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.lot.editControl.isChanged) {
      if ($utils.isDefined($row.cells.lot.editControl.value)) {
          let vendorlot = (await $datasources.Lots.ds_find_vendorlots.get({lotId: $row.cells.lot.editControl.value})).result[0];
          if ($utils.isDefined(vendorlot)) {
              $row.cells.vendorlot.editControl.value = vendorlot.Id;
          }
      }
  }
  }
  on_vendorlot_changed(event = null) {
    return this.on_vendorlot_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_changedInternal(
    $row: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.vendorlot.editControl.isChanged) {
      $row.cells.lot.editControl.value = null;
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => AsnOrders_licenseplates_by_order_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => FootPrintManager_lots_dd_singleComponent),
    forwardRef(() => FootPrintManager_vendorlots_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
  ],
  selector: 'FootPrintManager-warehouse_transfer_inbound_order_receiving_tasks_grid',
  templateUrl: './FootPrintManager.warehouse_transfer_inbound_order_receiving_tasks_grid.component.html'
})
export class FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('headersWidth', null);

  fullTextSearch: string;

  inParams: IFootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentInParams = { orderId: null, readOnly: null };


  //#region Variables
  vars: { projectId?: number, shipmentId?: number, warehouseId?: number, defaultReceivingLocationId?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'licenseplate', 'License plate', false, false, null, false),       material: new GridHeaderModel(new HeaderStyles(null, null), 'material', 'Material', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'vendorlot', 'Vendor lot', false, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', false, false, null, false),       packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'packaged_amount', 'Packaged amount', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentRowModel[] = [];

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('readOnly') set $inParams_readOnly(value: any) {
    this.inParams['readOnly'] = value;
  }
  get $inParams_readOnly(): any {
    return this.inParams['readOnly'] ;
  }

  topToolbar = {
      receive_tasks: new ToolModel(new ButtonModel('receive_tasks', new ButtonStyles(null, null), false, false, false, 'Receive', 'icon-datex-Receive', null)
    , false),
      cancel_tasks: new ToolModel(new ButtonModel('cancel_tasks', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Receiving tasks';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 10;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      licenseplateId:  null ,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
      const data = await this.datasources.WarehouseTransfers.ds_warehouse_transfer_inbound_order_receiving_tasks_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentEntity) {
    const row = new FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let order = (await $datasources.WarehouseTransfers.ds_get_order_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  let shipments = order.ShipmentOrderLookups.map(sol => sol.Shipment);
  
  if (!$utils.isDefined(shipments)) {
      throw new Error(`No shipments exist for order '${order.LookupCode}'.`);
  }
  
  $grid.vars.projectId = order.ProjectId;
  $grid.vars.shipmentId = shipments[0].Id;
  $grid.vars.warehouseId = shipments[0].ActualWarehouseId ?? shipments[0].ExpectedWarehouseId;
  
  let warehouse = (await $datasources.Locations.ds_get_warehouse_by_warehouseId.get({ warehouseId: $grid.vars.warehouseId })).result;
  
  $grid.vars.defaultReceivingLocationId = warehouse.DefaultReceivingLocationId;
  
  // Lock down grid
  if (true || (order.OrderStatusId !== 1 && order.OrderStatusId !== 2 && shipments[0].StatusId !== 1 && shipments[0].StatusId !== 2 && shipments[0].StatusId !== 4)) {
      $grid.canAdd = false;
      $grid.canEdit = false;
  }
  
  if ($grid.inParams.readOnly ?? false) {
      $grid.topToolbar.cancel_tasks.hidden = true;
      $grid.topToolbar.receive_tasks.hidden = true;
  }
  }
  on_delete_tasks_clicked(event = null) {
    return this.on_delete_tasks_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_tasks_clickedInternal(
    $grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'warehouse_transfer_inbound_order_receiving_tasks_grid.on_delete_tasks_clicked');
  let confirmDelete = await $shell.FootPrintManager.openConfirmationDialog('Delete tasks', 'Are you sure you want to delete the selected tasks?', 'Confirm', 'Cancel');
  
  if ($utils.isDefined(confirmDelete) && confirmDelete) {
      for (let row of $grid.selectedRows) {
          if (row.entity.StatusId !== 1) {
  
              await $flows.WarehouseTransfers.set_target_inbound_receiving_task_flow({
                  license_plate_id: row.entity.ActualTargetLicensePlate.Id,
                  lot_id: row.entity.Lot.Id,
                  packaging_id: row.entity.ExpectedPackagedPack.Id,
                  order_id: $grid.inParams.orderId,
                  material_id: row.entity.Material.Id,
                  vendor_lot_id: row.entity.VendorLot.Id,
                  packaged_amount: 0,
                  existing_task_id: row.entity.Id
              });
          }
      }
  
      $grid.refresh();
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.receive_tasks.control.readOnly = false;
  $grid.topToolbar.cancel_tasks.control.readOnly = false;
  
  // No rows selected
  if ($grid.selectedRows.length === 0) {
      $grid.topToolbar.receive_tasks.control.readOnly = true;
      $grid.topToolbar.cancel_tasks.control.readOnly = true;
  }
  else {
  
      // Has completed tasks
      if ($grid.selectedRows.find(r => r.entity.StatusId === 2)) {
          $grid.topToolbar.receive_tasks.control.readOnly = true;
          $grid.topToolbar.cancel_tasks.control.readOnly = true;
      }
  
      // Has cancelled tasks
      if ($grid.selectedRows.find(r => r.entity.StatusId === 3)) {
          $grid.topToolbar.receive_tasks.control.readOnly = true;
          $grid.topToolbar.cancel_tasks.control.readOnly = true;
      }
  }
  }
  on_receive_tasks_clicked(event = null) {
    return this.on_receive_tasks_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_receive_tasks_clickedInternal(
    $grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (await $shell.FootPrintManager.openConfirmationDialog('Complete receiving tasks', 'Are you sure you want to complete the selected receiving tasks?', 'Confirm', 'Cancel')) {
      for (let row of $grid.selectedRows) {
          if (row.entity.StatusId === 1 || row.entity.StatusId === 4) {
              await $flows.WarehouseTransfers.auto_receive_target_inbound_task_flow({ taskId: row.entity.Id });
          }
      }
  
      await $shell.FootPrintManager.openInfoDialog('Successfully complete receiving tasks', 'Successfully completed the selected receiving tasks.');
      await $grid.refresh();
  
  }
  }
  on_cancel_tasks_clicked(event = null) {
    return this.on_cancel_tasks_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_tasks_clickedInternal(
    $grid: FootPrintManager_warehouse_transfer_inbound_order_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let confirmDelete = await $shell.FootPrintManager.openConfirmationDialog('Cancel tasks', 'Are you sure you want to cancel the selected tasks?', 'Confirm', 'Cancel');
  
  if ($utils.isDefined(confirmDelete) && confirmDelete) {
      for (let row of $grid.selectedRows) {
          if (row.entity.StatusId !== 2 && row.entity.StatusId !== 3) {
  
              await $flows.Utilities.crud_update_flow({
                  entitySet: 'Tasks',
                  id: row.entity.Id,
                  entity: {
                      StatusId: 3,
                      CancelledDateTime: $utils.date.now()
                  }
              });
          }
      }
  
      $grid.refresh();
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
