<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="one_call_buy_shipment" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_one_call_buy_shipment_button_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="retrieve_tracker_status" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_retrieve_tracker_status_button_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-easypost_key" *ngIf="!fields.easypost_key.hidden && !fields.easypost_key.removed" 
                            class="field-container standard {{fields.easypost_key.invalid ? 'invalid' : ''}} {{fields.easypost_key.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_key.styles.style"
                            [ngClass]="fields.easypost_key.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_key.label + (fields.easypost_key.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_key.label}}<span *ngIf="fields.easypost_key.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="easypost_key"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.easypost_key.control.readOnly || fields.easypost_key.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.easypost_key.control.placeholder}}"
                                [ngStyle]="fields.easypost_key.control.styles.style"
                                [ngClass]="fields.easypost_key.control.styles.classes"
                                [matTooltip]="fields.easypost_key.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.easypost_key.invalid">
                          <ng-container *ngFor="let error of fields.easypost_key.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier_account" *ngIf="!fields.carrier_account.hidden && !fields.carrier_account.removed" 
                            class="field-container standard {{fields.carrier_account.invalid ? 'invalid' : ''}} {{fields.carrier_account.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.carrier_account.styles.style"
                            [ngClass]="fields.carrier_account.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.carrier_account.label + (fields.carrier_account.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.carrier_account.label}}<span *ngIf="fields.carrier_account.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="carrier_account"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.carrier_account.control.readOnly || fields.carrier_account.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.carrier_account.control.placeholder}}"
                                [ngStyle]="fields.carrier_account.control.styles.style"
                                [ngClass]="fields.carrier_account.control.styles.classes"
                                [matTooltip]="fields.carrier_account.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.carrier_account.invalid">
                          <ng-container *ngFor="let error of fields.carrier_account.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier_service" *ngIf="!fields.carrier_service.hidden && !fields.carrier_service.removed" 
                            class="field-container standard {{fields.carrier_service.invalid ? 'invalid' : ''}} {{fields.carrier_service.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.carrier_service.styles.style"
                            [ngClass]="fields.carrier_service.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.carrier_service.label + (fields.carrier_service.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.carrier_service.label}}<span *ngIf="fields.carrier_service.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="carrier_service"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.carrier_service.control.readOnly || fields.carrier_service.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.carrier_service.control.placeholder}}"
                                [ngStyle]="fields.carrier_service.control.styles.style"
                                [ngClass]="fields.carrier_service.control.styles.classes"
                                [matTooltip]="fields.carrier_service.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.carrier_service.invalid">
                          <ng-container *ngFor="let error of fields.carrier_service.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-label_size" *ngIf="!fields.label_size.hidden && !fields.label_size.removed" 
                            class="field-container standard {{fields.label_size.invalid ? 'invalid' : ''}} {{fields.label_size.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.label_size.styles.style"
                            [ngClass]="fields.label_size.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.label_size.label + (fields.label_size.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.label_size.label}}<span *ngIf="fields.label_size.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="label_size"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.label_size.control.readOnly || fields.label_size.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.label_size.control.placeholder}}"
                                [ngStyle]="fields.label_size.control.styles.style"
                                [ngClass]="fields.label_size.control.styles.classes"
                                [matTooltip]="fields.label_size.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.label_size.invalid">
                          <ng-container *ngFor="let error of fields.label_size.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-label_format" *ngIf="!fields.label_format.hidden && !fields.label_format.removed" 
                            class="field-container standard {{fields.label_format.invalid ? 'invalid' : ''}} {{fields.label_format.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.label_format.styles.style"
                            [ngClass]="fields.label_format.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.label_format.label + (fields.label_format.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.label_format.label}}<span *ngIf="fields.label_format.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="label_format"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.label_format.control.readOnly || fields.label_format.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.label_format.control.placeholder}}"
                                [ngStyle]="fields.label_format.control.styles.style"
                                [ngClass]="fields.label_format.control.styles.classes"
                                [matTooltip]="fields.label_format.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.label_format.invalid">
                          <ng-container *ngFor="let error of fields.label_format.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-print_custom_one" *ngIf="!fields.print_custom_one.hidden && !fields.print_custom_one.removed" 
                            class="field-container standard {{fields.print_custom_one.invalid ? 'invalid' : ''}} {{fields.print_custom_one.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.print_custom_one.styles.style"
                            [ngClass]="fields.print_custom_one.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.print_custom_one.label + (fields.print_custom_one.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.print_custom_one.label}}<span *ngIf="fields.print_custom_one.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="print_custom_one"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.print_custom_one.control.readOnly || fields.print_custom_one.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.print_custom_one.control.placeholder}}"
                                [ngStyle]="fields.print_custom_one.control.styles.style"
                                [ngClass]="fields.print_custom_one.control.styles.classes"
                                [matTooltip]="fields.print_custom_one.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.print_custom_one.invalid">
                          <ng-container *ngFor="let error of fields.print_custom_one.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-contents_explanation" *ngIf="!fields.contents_explanation.hidden && !fields.contents_explanation.removed" 
                            class="field-container double {{fields.contents_explanation.invalid ? 'invalid' : ''}} {{fields.contents_explanation.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.contents_explanation.styles.style"
                            [ngClass]="fields.contents_explanation.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.contents_explanation.label + (fields.contents_explanation.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.contents_explanation.label}}<span *ngIf="fields.contents_explanation.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="contents_explanation"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.contents_explanation.control.readOnly || fields.contents_explanation.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.contents_explanation.control.placeholder}}"
                                [ngStyle]="fields.contents_explanation.control.styles.style"
                                [ngClass]="fields.contents_explanation.control.styles.classes"
                                [matTooltip]="fields.contents_explanation.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.contents_explanation.invalid">
                          <ng-container *ngFor="let error of fields.contents_explanation.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden && !fieldsets.newGroup2.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup2.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-tracker_status" *ngIf="!fields.tracker_status.hidden && !fields.tracker_status.removed" 
                            class="field-container standard {{fields.tracker_status.invalid ? 'invalid' : ''}} {{fields.tracker_status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.tracker_status.styles.style"
                            [ngClass]="fields.tracker_status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.tracker_status.label + (fields.tracker_status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.tracker_status.label}}<span *ngIf="fields.tracker_status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.tracker_status.control.styles.style"
                              [ngClass]="fields.tracker_status.control.styles.classes"
                              [matTooltip]="fields.tracker_status.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.tracker_status.control.text }}</div>
                        <ng-container *ngIf="fields.tracker_status.invalid">
                          <ng-container *ngFor="let error of fields.tracker_status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_tracking_code" *ngIf="!fields.easypost_tracking_code.hidden && !fields.easypost_tracking_code.removed" 
                            class="field-container standard {{fields.easypost_tracking_code.invalid ? 'invalid' : ''}} {{fields.easypost_tracking_code.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_tracking_code.styles.style"
                            [ngClass]="fields.easypost_tracking_code.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_tracking_code.label + (fields.easypost_tracking_code.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_tracking_code.label}}<span *ngIf="fields.easypost_tracking_code.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_tracking_code.control.styles.style"
                              [ngClass]="fields.easypost_tracking_code.control.styles.classes"
                              [matTooltip]="fields.easypost_tracking_code.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_tracking_code.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_tracking_code.invalid">
                          <ng-container *ngFor="let error of fields.easypost_tracking_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_trackerId" *ngIf="!fields.easypost_trackerId.hidden && !fields.easypost_trackerId.removed" 
                            class="field-container full {{fields.easypost_trackerId.invalid ? 'invalid' : ''}} {{fields.easypost_trackerId.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_trackerId.styles.style"
                            [ngClass]="fields.easypost_trackerId.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_trackerId.label + (fields.easypost_trackerId.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_trackerId.label}}<span *ngIf="fields.easypost_trackerId.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_trackerId.control.styles.style"
                              [ngClass]="fields.easypost_trackerId.control.styles.classes"
                              [matTooltip]="fields.easypost_trackerId.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_trackerId.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_trackerId.invalid">
                          <ng-container *ngFor="let error of fields.easypost_trackerId.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_shipmentId" *ngIf="!fields.easypost_shipmentId.hidden && !fields.easypost_shipmentId.removed" 
                            class="field-container full {{fields.easypost_shipmentId.invalid ? 'invalid' : ''}} {{fields.easypost_shipmentId.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_shipmentId.styles.style"
                            [ngClass]="fields.easypost_shipmentId.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_shipmentId.label + (fields.easypost_shipmentId.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_shipmentId.label}}<span *ngIf="fields.easypost_shipmentId.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_shipmentId.control.styles.style"
                              [ngClass]="fields.easypost_shipmentId.control.styles.classes"
                              [matTooltip]="fields.easypost_shipmentId.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_shipmentId.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_shipmentId.invalid">
                          <ng-container *ngFor="let error of fields.easypost_shipmentId.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_parcel_weight" *ngIf="!fields.easypost_parcel_weight.hidden && !fields.easypost_parcel_weight.removed" 
                            class="field-container standard {{fields.easypost_parcel_weight.invalid ? 'invalid' : ''}} {{fields.easypost_parcel_weight.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_parcel_weight.styles.style"
                            [ngClass]="fields.easypost_parcel_weight.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_parcel_weight.label + (fields.easypost_parcel_weight.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_parcel_weight.label}}<span *ngIf="fields.easypost_parcel_weight.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_parcel_weight.control.styles.style"
                              [ngClass]="fields.easypost_parcel_weight.control.styles.classes"
                              [matTooltip]="fields.easypost_parcel_weight.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_parcel_weight.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_parcel_weight.invalid">
                          <ng-container *ngFor="let error of fields.easypost_parcel_weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_parcel_length" *ngIf="!fields.easypost_parcel_length.hidden && !fields.easypost_parcel_length.removed" 
                            class="field-container standard {{fields.easypost_parcel_length.invalid ? 'invalid' : ''}} {{fields.easypost_parcel_length.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_parcel_length.styles.style"
                            [ngClass]="fields.easypost_parcel_length.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_parcel_length.label + (fields.easypost_parcel_length.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_parcel_length.label}}<span *ngIf="fields.easypost_parcel_length.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_parcel_length.control.styles.style"
                              [ngClass]="fields.easypost_parcel_length.control.styles.classes"
                              [matTooltip]="fields.easypost_parcel_length.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_parcel_length.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_parcel_length.invalid">
                          <ng-container *ngFor="let error of fields.easypost_parcel_length.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_parcel_width" *ngIf="!fields.easypost_parcel_width.hidden && !fields.easypost_parcel_width.removed" 
                            class="field-container standard {{fields.easypost_parcel_width.invalid ? 'invalid' : ''}} {{fields.easypost_parcel_width.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_parcel_width.styles.style"
                            [ngClass]="fields.easypost_parcel_width.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_parcel_width.label + (fields.easypost_parcel_width.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_parcel_width.label}}<span *ngIf="fields.easypost_parcel_width.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_parcel_width.control.styles.style"
                              [ngClass]="fields.easypost_parcel_width.control.styles.classes"
                              [matTooltip]="fields.easypost_parcel_width.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_parcel_width.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_parcel_width.invalid">
                          <ng-container *ngFor="let error of fields.easypost_parcel_width.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_parcel_height" *ngIf="!fields.easypost_parcel_height.hidden && !fields.easypost_parcel_height.removed" 
                            class="field-container standard {{fields.easypost_parcel_height.invalid ? 'invalid' : ''}} {{fields.easypost_parcel_height.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_parcel_height.styles.style"
                            [ngClass]="fields.easypost_parcel_height.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_parcel_height.label + (fields.easypost_parcel_height.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_parcel_height.label}}<span *ngIf="fields.easypost_parcel_height.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_parcel_height.control.styles.style"
                              [ngClass]="fields.easypost_parcel_height.control.styles.classes"
                              [matTooltip]="fields.easypost_parcel_height.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_parcel_height.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_parcel_height.invalid">
                          <ng-container *ngFor="let error of fields.easypost_parcel_height.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_shipment_creation_date" *ngIf="!fields.easypost_shipment_creation_date.hidden && !fields.easypost_shipment_creation_date.removed" 
                            class="field-container standard {{fields.easypost_shipment_creation_date.invalid ? 'invalid' : ''}} {{fields.easypost_shipment_creation_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_shipment_creation_date.styles.style"
                            [ngClass]="fields.easypost_shipment_creation_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_shipment_creation_date.label + (fields.easypost_shipment_creation_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_shipment_creation_date.label}}<span *ngIf="fields.easypost_shipment_creation_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_shipment_creation_date.control.styles.style"
                              [ngClass]="fields.easypost_shipment_creation_date.control.styles.classes"
                              [matTooltip]="fields.easypost_shipment_creation_date.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_shipment_creation_date.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_shipment_creation_date.invalid">
                          <ng-container *ngFor="let error of fields.easypost_shipment_creation_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_selected_rate" *ngIf="!fields.easypost_selected_rate.hidden && !fields.easypost_selected_rate.removed" 
                            class="field-container standard {{fields.easypost_selected_rate.invalid ? 'invalid' : ''}} {{fields.easypost_selected_rate.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_selected_rate.styles.style"
                            [ngClass]="fields.easypost_selected_rate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_selected_rate.label + (fields.easypost_selected_rate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_selected_rate.label}}<span *ngIf="fields.easypost_selected_rate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_selected_rate.control.styles.style"
                              [ngClass]="fields.easypost_selected_rate.control.styles.classes"
                              [matTooltip]="fields.easypost_selected_rate.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_selected_rate.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_selected_rate.invalid">
                          <ng-container *ngFor="let error of fields.easypost_selected_rate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_selected_rate_delivery_date" *ngIf="!fields.easypost_selected_rate_delivery_date.hidden && !fields.easypost_selected_rate_delivery_date.removed" 
                            class="field-container standard {{fields.easypost_selected_rate_delivery_date.invalid ? 'invalid' : ''}} {{fields.easypost_selected_rate_delivery_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_selected_rate_delivery_date.styles.style"
                            [ngClass]="fields.easypost_selected_rate_delivery_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_selected_rate_delivery_date.label + (fields.easypost_selected_rate_delivery_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_selected_rate_delivery_date.label}}<span *ngIf="fields.easypost_selected_rate_delivery_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_selected_rate_delivery_date.control.styles.style"
                              [ngClass]="fields.easypost_selected_rate_delivery_date.control.styles.classes"
                              [matTooltip]="fields.easypost_selected_rate_delivery_date.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_selected_rate_delivery_date.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_selected_rate_delivery_date.invalid">
                          <ng-container *ngFor="let error of fields.easypost_selected_rate_delivery_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-open_postage_label_url" *ngIf="!fields.open_postage_label_url.hidden && !fields.open_postage_label_url.removed" 
                            class="field-container full {{fields.open_postage_label_url.invalid ? 'invalid' : ''}} {{fields.open_postage_label_url.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.open_postage_label_url.styles.style"
                            [ngClass]="fields.open_postage_label_url.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.open_postage_label_url.label + (fields.open_postage_label_url.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.open_postage_label_url.label}}<span *ngIf="fields.open_postage_label_url.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.open_postage_label_url.control.readOnly || fields.open_postage_label_url.control.disabled"
                                [ngStyle]="fields.open_postage_label_url.control.styles.style"
                                [ngClass]="fields.open_postage_label_url.control.styles.classes"
                                [matTooltip]="fields.open_postage_label_url.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_easypost_postage_label_url_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.open_postage_label_url.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.open_postage_label_url.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.open_postage_label_url.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.open_postage_label_url.invalid">
                          <ng-container *ngFor="let error of fields.open_postage_label_url.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_postage_label_url" *ngIf="!fields.easypost_postage_label_url.hidden && !fields.easypost_postage_label_url.removed" 
                            class="field-container full {{fields.easypost_postage_label_url.invalid ? 'invalid' : ''}} {{fields.easypost_postage_label_url.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_postage_label_url.styles.style"
                            [ngClass]="fields.easypost_postage_label_url.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_postage_label_url.label + (fields.easypost_postage_label_url.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_postage_label_url.label}}<span *ngIf="fields.easypost_postage_label_url.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_postage_label_url.control.styles.style"
                              [ngClass]="fields.easypost_postage_label_url.control.styles.classes"
                              [matTooltip]="fields.easypost_postage_label_url.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_postage_label_url.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_postage_label_url.invalid">
                          <ng-container *ngFor="let error of fields.easypost_postage_label_url.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-open_tracker_public_url" *ngIf="!fields.open_tracker_public_url.hidden && !fields.open_tracker_public_url.removed" 
                            class="field-container standard {{fields.open_tracker_public_url.invalid ? 'invalid' : ''}} {{fields.open_tracker_public_url.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.open_tracker_public_url.styles.style"
                            [ngClass]="fields.open_tracker_public_url.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.open_tracker_public_url.label + (fields.open_tracker_public_url.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.open_tracker_public_url.label}}<span *ngIf="fields.open_tracker_public_url.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.open_tracker_public_url.control.readOnly || fields.open_tracker_public_url.control.disabled"
                                [ngStyle]="fields.open_tracker_public_url.control.styles.style"
                                [ngClass]="fields.open_tracker_public_url.control.styles.classes"
                                [matTooltip]="fields.open_tracker_public_url.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_easypost_tracker_public_url_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.open_tracker_public_url.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.open_tracker_public_url.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.open_tracker_public_url.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.open_tracker_public_url.invalid">
                          <ng-container *ngFor="let error of fields.open_tracker_public_url.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_tracker_public_url" *ngIf="!fields.easypost_tracker_public_url.hidden && !fields.easypost_tracker_public_url.removed" 
                            class="field-container full {{fields.easypost_tracker_public_url.invalid ? 'invalid' : ''}} {{fields.easypost_tracker_public_url.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_tracker_public_url.styles.style"
                            [ngClass]="fields.easypost_tracker_public_url.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_tracker_public_url.label + (fields.easypost_tracker_public_url.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_tracker_public_url.label}}<span *ngIf="fields.easypost_tracker_public_url.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_tracker_public_url.control.styles.style"
                              [ngClass]="fields.easypost_tracker_public_url.control.styles.classes"
                              [matTooltip]="fields.easypost_tracker_public_url.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_tracker_public_url.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_tracker_public_url.invalid">
                          <ng-container *ngFor="let error of fields.easypost_tracker_public_url.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-open_commercial_invoice_url" *ngIf="!fields.open_commercial_invoice_url.hidden && !fields.open_commercial_invoice_url.removed" 
                            class="field-container standard {{fields.open_commercial_invoice_url.invalid ? 'invalid' : ''}} {{fields.open_commercial_invoice_url.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.open_commercial_invoice_url.styles.style"
                            [ngClass]="fields.open_commercial_invoice_url.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.open_commercial_invoice_url.label + (fields.open_commercial_invoice_url.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.open_commercial_invoice_url.label}}<span *ngIf="fields.open_commercial_invoice_url.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.open_commercial_invoice_url.control.readOnly || fields.open_commercial_invoice_url.control.disabled"
                                [ngStyle]="fields.open_commercial_invoice_url.control.styles.style"
                                [ngClass]="fields.open_commercial_invoice_url.control.styles.classes"
                                [matTooltip]="fields.open_commercial_invoice_url.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_easypost_commercial_invoice_url_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.open_commercial_invoice_url.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.open_commercial_invoice_url.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.open_commercial_invoice_url.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.open_commercial_invoice_url.invalid">
                          <ng-container *ngFor="let error of fields.open_commercial_invoice_url.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-easypost_commercial_invoice_url" *ngIf="!fields.easypost_commercial_invoice_url.hidden && !fields.easypost_commercial_invoice_url.removed" 
                            class="field-container full {{fields.easypost_commercial_invoice_url.invalid ? 'invalid' : ''}} {{fields.easypost_commercial_invoice_url.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.easypost_commercial_invoice_url.styles.style"
                            [ngClass]="fields.easypost_commercial_invoice_url.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.easypost_commercial_invoice_url.label + (fields.easypost_commercial_invoice_url.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.easypost_commercial_invoice_url.label}}<span *ngIf="fields.easypost_commercial_invoice_url.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.easypost_commercial_invoice_url.control.styles.style"
                              [ngClass]="fields.easypost_commercial_invoice_url.control.styles.classes"
                              [matTooltip]="fields.easypost_commercial_invoice_url.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.easypost_commercial_invoice_url.control.text }}</div>
                        <ng-container *ngIf="fields.easypost_commercial_invoice_url.invalid">
                          <ng-container *ngFor="let error of fields.easypost_commercial_invoice_url.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-output_details" *ngIf="!fields.output_details.hidden && !fields.output_details.removed" 
                            class="field-container full {{fields.output_details.invalid ? 'invalid' : ''}} {{fields.output_details.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.output_details.styles.style"
                            [ngClass]="fields.output_details.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.output_details.label + (fields.output_details.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.output_details.label}}<span *ngIf="fields.output_details.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.output_details.control.styles.style"
                              [ngClass]="fields.output_details.control.styles.classes"
                              [matTooltip]="fields.output_details.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.output_details.control.text }}</div>
                        <ng-container *ngIf="fields.output_details.invalid">
                          <ng-container *ngFor="let error of fields.output_details.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>