import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manifesting_ShellService } from './Manifesting.shell.service';
import { Manifesting_OperationService } from './Manifesting.operation.service';
import { Manifesting_DatasourceService } from './Manifesting.datasource.index';
import { Manifesting_FlowService } from './Manifesting.flow.index';
import { Manifesting_ReportService } from './Manifesting.report.index';
import { Manifesting_LocalizationService } from './Manifesting.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manifesting.frontend.types'
import { $frontendTypes as $types} from './Manifesting.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Manifesting_easypost_manifesting_shipments_gridComponent } from './Manifesting.easypost_manifesting_shipments_grid.component';
import { Manifesting_shiptheory_manifesting_shipments_gridComponent } from './Manifesting.shiptheory_manifesting_shipments_grid.component';
import { PrintNode_printers_dd_singleComponent } from './PrintNode.printers_dd_single.component'
import { Manifesting_label_size_dd_singleComponent } from './Manifesting.label_size_dd_single.component'
import { Manifesting_containers_by_type_dd_singleComponent } from './Manifesting.containers_by_type_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manifesting_easypost_manifesting_shipments_gridComponent),
    forwardRef(() => Manifesting_shiptheory_manifesting_shipments_gridComponent),
    forwardRef(() => PrintNode_printers_dd_singleComponent),
    forwardRef(() => Manifesting_label_size_dd_singleComponent),
    forwardRef(() => Manifesting_containers_by_type_dd_singleComponent),
  ],
  selector: 'Manifesting-manifesting_hub',
  templateUrl: './Manifesting.manifesting_hub.component.html'
})
export class Manifesting_manifesting_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { shipmentIds: number[] } = { shipmentIds: [] };
  //#region Inputs
  @Input('shipmentIds') set $inParams_shipmentIds(v: number[]) {
    this.inParams.shipmentIds = v;
  }
  get $inParams_shipmentIds(): number[] {
    return this.inParams.shipmentIds;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { shipments?: number[], printerId?: number, length?: number, width?: number, height?: number, weight?: number, utilizeShipmentContainer?: boolean, labelSize?: number, contentsExplanation?: string, containerCount?: number } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    printer_packinglist: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    printer: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    label_size: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    utilize_shipment_containers: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    container_types: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    container_count: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    length: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    width: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    height: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    weight: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    contents_explanation: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
    printer_packinglist: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['printer_packinglist'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Printer for packing list', false, false),
    printer: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['printer'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Printer for label', false, false),
    label_size: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['label_size'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Label size', false, false),
    utilize_shipment_containers: new FieldModel(new CheckBoxModel(this.formGroup.controls['utilize_shipment_containers'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Utilize shipment containers', false, false),
    container_types: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['container_types'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Container types', true, false),
    container_count: new FieldModel(new NumberBoxModel(this.formGroup.controls['container_count'] as DatexFormControl, null, false, false, '0', '', null)
, new ControlContainerStyles(null, null), 'Container count', false, false),
    length: new FieldModel(new NumberBoxModel(this.formGroup.controls['length'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Length', true, false),
    width: new FieldModel(new NumberBoxModel(this.formGroup.controls['width'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Width', true, false),
    height: new FieldModel(new NumberBoxModel(this.formGroup.controls['height'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Height', true, false),
    weight: new FieldModel(new NumberBoxModel(this.formGroup.controls['weight'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Weight', true, false),
    contents_explanation: new FieldModel(new TextBoxModel(this.formGroup.controls['contents_explanation'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Label contents explanation', false, false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('Printing', false, true, true, false),
  newGroup2: new FieldsetModel('Container details', false, true, false, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      shipments_easypost: new TabItemModel(
        this.rootTabGroup, 
        'Shipments EasyPost', 
        false,
        ),
      shipments_shiptheory: new TabItemModel(
        this.rootTabGroup, 
        'Shipments ShipTheory', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    cacheValueFor_$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_shipmentIds: number[];
    get $tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_shipmentIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.shipments;
      
      if(!isEqual(this.cacheValueFor_$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_shipmentIds, expr)) {
        this.cacheValueFor_$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_shipmentIds = expr;
      }
      return this.cacheValueFor_$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_shipmentIds;
    }
  
    get $tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_printerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.printerId;
      
      return expr;
    }
  
    get $tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_length(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.length;
      
      return expr;
    }
  
    get $tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_width(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.width;
      
      return expr;
    }
  
    get $tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_height(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.height;
      
      return expr;
    }
  
    get $tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_weight(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.weight;
      
      return expr;
    }
  
    get $tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_utilizeShipmentContainer(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.utilizeShipmentContainer;
      
      return expr;
    }
  
    get $tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_labelSize(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.labelSize;
      
      return expr;
    }
  
    get $tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_contentsExplanation(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.contentsExplanation;
      
      return expr;
    }
  
    get $tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_containerCount(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.containerCount;
      
      return expr;
    }
  
    get $tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_printerIdPackinglist(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.printer_packinglist.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_shipmentIds: number[];
    get $tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_shipmentIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.shipments;
      
      if(!isEqual(this.cacheValueFor_$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_shipmentIds, expr)) {
        this.cacheValueFor_$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_shipmentIds = expr;
      }
      return this.cacheValueFor_$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_shipmentIds;
    }
  
    get $tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_printerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.printerId;
      
      return expr;
    }
  
    get $tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_length(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.length;
      
      return expr;
    }
  
    get $tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_width(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.width;
      
      return expr;
    }
  
    get $tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_height(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.height;
      
      return expr;
    }
  
    get $tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_weight(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.weight;
      
      return expr;
    }
  
    get $tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_utilizeShipmentContainer(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.utilizeShipmentContainer;
      
      return expr;
    }
  
    get $tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_labelSize(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.labelSize;
      
      return expr;
    }
  
    get $tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_contentsExplanation(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.contentsExplanation;
      
      return expr;
    }
  
    get $tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_containerCount(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.containerCount;
      
      return expr;
    }
  
    get $tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_printerIdPackinglist(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.printer_packinglist.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_shipments_easypost', { read: Manifesting_easypost_manifesting_shipments_gridComponent }) $tabs_shipments_easypost: Manifesting_easypost_manifesting_shipments_gridComponent;
      @ViewChild('$tabs_shipments_shiptheory', { read: Manifesting_shiptheory_manifesting_shipments_gridComponent }) $tabs_shipments_shiptheory: Manifesting_shiptheory_manifesting_shipments_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_printer_packinglist_selector_inParams_letterOnly(): boolean {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = true;
    
    return expr;
  }



  get $fields_printer_selector_inParams_letterOnly(): boolean {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = false;
    
    return expr;
  }



  get $fields_container_types_selector_inParams_containerTypeCategoriesId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 3;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Manifesting`;
  }


  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: Manifesting_ShellService,
  private datasources: Manifesting_DatasourceService,
  private flows: Manifesting_FlowService,
  private reports: Manifesting_ReportService,
  private localization: Manifesting_LocalizationService,
  private operations: Manifesting_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.shipments_easypost,
      this.tabs.shipments_shiptheory,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.shipmentIds)) {
        this.$missingRequiredInParams.push('shipmentIds');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Manifesting Hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.container_count.control as NumberBoxModel).reset(1);
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['printer_packinglist']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_selection();
      });
    this.formGroup
      .controls['printer']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_printer_selection();
      });
    this.formGroup
      .controls['label_size']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_selection();
      });
    this.formGroup
      .controls['utilize_shipment_containers']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_utilize_shipment_container_clicked();
      });
    this.formGroup
      .controls['container_types']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_container_type_selection();
      });
    this.formGroup
      .controls['container_count']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_selection();
      });
    this.formGroup
      .controls['length']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_selection();
      });
    this.formGroup
      .controls['width']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_selection();
      });
    this.formGroup
      .controls['height']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_selection();
      });
    this.formGroup
      .controls['weight']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_selection();
      });
    this.formGroup
      .controls['contents_explanation']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_selection();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_shipments_easypost') {
      if (!isNil(this.$tabs_shipments_easypost) && !this.tabs.shipments_easypost.hidden) {
        this.$tabs_shipments_easypost.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_shipments_shiptheory') {
      if (!isNil(this.$tabs_shipments_shiptheory) && !this.tabs.shipments_shiptheory.hidden) {
        this.$tabs_shipments_shiptheory.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: Manifesting_manifesting_hubComponent,
  
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
  
  
  $hub.title = `Manifesting`
  
  
  $hub.vars.shipments = $hub.inParams.shipmentIds;
  
  
  
  $hub.vars.containerCount = 1
  
  
  const shipments = (await $datasources.Manifesting.ds_get_shipment_by_shipmentId.get({ shipmentId: $hub.inParams.shipmentIds[0] })).result
  
  if ($utils.isDefined(shipments)) {
  
      // DHL default to 4x7
      if (shipments[0].Carrier?.Name === 'DHL') {
          $hub.filters.label_size.control.value = 2
      }
      // Else default to 4x6
      else {
          $hub.filters.label_size.control.value = 1
      }
  }
  }
  on_utilize_shipment_container_clicked(event = null) {
    return this.on_utilize_shipment_container_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_utilize_shipment_container_clickedInternal(
    $hub: Manifesting_manifesting_hubComponent,
  
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($hub.filters.utilize_shipment_containers) && $hub.filters.utilize_shipment_containers.control.value === true) {
  
      // Clear data
      $hub.filters.container_types.control.value = null;
      $hub.filters.length.control.value = null;
      $hub.filters.width.control.value = null;
      $hub.filters.height.control.value = null;
      $hub.filters.weight.control.value = null;
  
      $hub.vars.length = null;
      $hub.vars.width = null;
      $hub.vars.height = null;
      $hub.vars.weight = null;
      $hub.vars.containerCount = null;
  
      // Set fields to read only
      $hub.filters.container_types.hidden = true;
      $hub.filters.container_count.hidden = true;
      $hub.filters.length.hidden = true;
      $hub.filters.width.hidden = true;
      $hub.filters.height.hidden = true;
      $hub.filters.weight.hidden = true;
  
      $hub.vars.utilizeShipmentContainer = true;
  
  
  }
  else {
  
      $hub.vars.utilizeShipmentContainer = false;
      $hub.filters.container_types.hidden = false;
  
      $hub.filters.container_count.hidden = false;
      $hub.vars.containerCount = 1;
      $hub.filters.container_count.control.value = 1;
      
      $hub.filters.length.hidden = false;
      $hub.filters.width.hidden = false;
      $hub.filters.height.hidden = false;
      $hub.filters.weight.hidden = false;
  
  }
  }
  on_filter_selection(event = null) {
    return this.on_filter_selectionInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_filter_selectionInternal(
    $hub: Manifesting_manifesting_hubComponent,
  
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($hub.filters.label_size.control.value)) {
      $hub.vars.labelSize = $hub.filters.label_size.control.value;
  }
  else {
      $hub.vars.labelSize = null;
  }
  
  if ($utils.isDefined($hub.filters.contents_explanation.control.value)) {
      $hub.vars.contentsExplanation = $hub.filters.contents_explanation.control.value;
  }
  else {
      $hub.vars.contentsExplanation = null;
  }
  
  
  if ($utils.isDefined($hub.filters.length.control.value)) {
      $hub.vars.length = $hub.filters.length.control.value;
  }
  else {
      $hub.vars.length = null;
  }
  
  if ($utils.isDefined($hub.filters.width.control.value)) {
      $hub.vars.width = $hub.filters.width.control.value;
  }
  else {
      $hub.vars.width = null;
  }
  
  
  if ($utils.isDefined($hub.filters.height.control.value)) {
      $hub.vars.height = $hub.filters.height.control.value;
  }
  else {
      $hub.vars.height = null;
  }
  
  
  if ($utils.isDefined($hub.filters.weight.control.value)) {
      $hub.vars.weight = $hub.filters.weight.control.value;
  }
  else {
      $hub.vars.weight = null;
  }
  
  
  if ($utils.isDefined($hub.filters.container_count.control.value)) {
      $hub.vars.containerCount = $hub.filters.container_count.control.value;
  }
  else {
      $hub.vars.containerCount = null;
  }
  }
  on_container_type_selection(event = null) {
    return this.on_container_type_selectionInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_container_type_selectionInternal(
    $hub: Manifesting_manifesting_hubComponent,
  
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
  
  const containerTypes = (await $datasources.Manifesting.ds_get_container_type_by_containerTypeId.get({
      containerTypeId: $hub.filters.container_types.control.value
  })).result;
  if (containerTypes.length > 0) {
  
      var length = containerTypes[0].Length;
      if ($utils.isDefined(length)) {
          $hub.filters.length.control.value = length;
      }
      else {
          $hub.filters.length.control.value = null;
      }
  
      var width = containerTypes[0].Width;
      if ($utils.isDefined(width)) {
          $hub.filters.width.control.value = width;
      }
      else {
          $hub.filters.width.control.value = null;
      }
  
      var height = containerTypes[0].Height;
      if ($utils.isDefined(height)) {
          $hub.filters.height.control.value = height;
      }
      else {
          $hub.filters.height.control.value = null;
      }
  
      var weight = containerTypes[0].Weight;
      if ($utils.isDefined(weight)) {
          $hub.filters.weight.control.value = weight;
      }
      else {
          $hub.filters.weight.control.value = null;
      }
  
      $hub.vars.length = ($utils.isAllDefined(length)) ? length : null;
      $hub.vars.width = ($utils.isAllDefined(width)) ? width : null;
      $hub.vars.height = ($utils.isAllDefined(height)) ? height : null;
      $hub.vars.weight = ($utils.isAllDefined(weight)) ? weight : null;
   
  
  }
  else {
      $hub.vars.length = null;
      $hub.vars.width = null;
      $hub.vars.height = null;
      $hub.vars.weight = null;
      $hub.filters.length.control.value = null;
      $hub.filters.width.control.value = null;
      $hub.filters.height.control.value = null;
      $hub.filters.weight.control.value = null;
  
  }
  
  
  }
  on_printer_selection(event = null) {
    return this.on_printer_selectionInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_printer_selectionInternal(
    $hub: Manifesting_manifesting_hubComponent,
  
    $shell: Manifesting_ShellService,
    $datasources: Manifesting_DatasourceService,
    $flows: Manifesting_FlowService,
    $reports: Manifesting_ReportService,
    $settings: SettingsValuesService,
    $operations: Manifesting_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manifesting_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($hub.filters.printer.control.value)) {
      $hub.vars.printerId = $hub.filters.printer.control.value;
  } else {
      $hub.vars.printerId = null;
  }
  
  }
  //#endregion private flows
}
