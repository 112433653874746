import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootPrintManager_asn_order_licenseplates_gridComponent } from './FootPrintManager.asn_order_licenseplates_grid.component';
import { FootPrintManager_asn_order_receiving_tasks_gridComponent } from './FootPrintManager.asn_order_receiving_tasks_grid.component';
import { FootPrintManager_asn_orderlines_gridComponent } from './FootPrintManager.asn_orderlines_grid.component';
import { FootPrintManager_accessorial_tasks_gridComponent } from './FootPrintManager.accessorial_tasks_grid.component';
import { FootPrintManager_billing_records_gridComponent } from './FootPrintManager.billing_records_grid.component';
import { FootPrintManager_orderaddresses_gridComponent } from './FootPrintManager.orderaddresses_grid.component';
import { FootPrintManager_asn_order_tasks_gridComponent } from './FootPrintManager.asn_order_tasks_grid.component';
import { AsnOrders_orderline_total_amount_widgetComponent } from './AsnOrders.orderline_total_amount_widget.component';
import { AsnOrders_orderline_total_received_widgetComponent } from './AsnOrders.orderline_total_received_widget.component';
import { AsnOrders_orderline_total_gross_received_widgetComponent } from './AsnOrders.orderline_total_gross_received_widget.component';
import { AsnOrders_orderclasses_dd_singleComponent } from './AsnOrders.orderclasses_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Carriers_carriers_ownerscarriers_dd_singleComponent } from './Carriers.carriers_ownerscarriers_dd_single.component'
import { SalesOrders_shipment_payment_term_dd_singleComponent } from './SalesOrders.shipment_payment_term_dd_single.component'

type EntityType = { 
    Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Project?: { OwnerId?: number }, OrderClass?: { Name?: string }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, CarrierId?: number, ContainerIdentifier?: string, ConveyanceId?: number, ConveyenceReference?: string, ExpectedDate?: string, Notes?: string, PaymentTermId?: number, PickupDate?: string, SealId?: string, TrailerId?: string } }[], OrderLines?: { LineNumber?: number }[], Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_asn_order_licenseplates_gridComponent),
    forwardRef(() => FootPrintManager_asn_order_receiving_tasks_gridComponent),
    forwardRef(() => FootPrintManager_asn_orderlines_gridComponent),
    forwardRef(() => FootPrintManager_accessorial_tasks_gridComponent),
    forwardRef(() => FootPrintManager_billing_records_gridComponent),
    forwardRef(() => FootPrintManager_orderaddresses_gridComponent),
    forwardRef(() => FootPrintManager_asn_order_tasks_gridComponent),
    forwardRef(() => AsnOrders_orderline_total_amount_widgetComponent),
    forwardRef(() => AsnOrders_orderline_total_received_widgetComponent),
    forwardRef(() => AsnOrders_orderline_total_gross_received_widgetComponent),
    forwardRef(() => AsnOrders_orderclasses_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Carriers_carriers_ownerscarriers_dd_singleComponent),
    forwardRef(() => SalesOrders_shipment_payment_term_dd_singleComponent),
  ],
  selector: 'FootPrintApiManager-asn_order_editor',
  templateUrl: './FootPrintApiManager.asn_order_editor.component.html'
})
export class FootPrintApiManager_asn_order_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { order_id: number } = { order_id: null };
  //#region Inputs
  @Input('order_id') set $inParams_order_id(v: number) {
    this.inParams.order_id = v;
  }
  get $inParams_order_id(): number {
    return this.inParams.order_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { billing_address_id?: number, has_completed_receiving_tasks?: boolean, has_planned_receiving_tasks?: boolean, can_unreceive?: boolean, shipment_id?: number, is_edi?: boolean } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    order_class: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    vendor_reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    trailer_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    seal_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    requested_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    pickup_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    payment_term: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shipment_carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shipment_notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, false, false, 'Process', 'icon-ic_fluent_arrow_clockwise_20_regular', null)
    , false),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, false, false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      separator16: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      validate: new ToolModel(new ButtonModel('validate', new ButtonStyles(null, null), false, false, false, 'Validate', 'icon-ic_fluent_document_checkmark_20_regular', null)
    , false),
      auto_receive: new ToolModel(new ButtonModel('auto_receive', new ButtonStyles(null, null), false, false, false, 'Auto receive', 'icon-ic_fluent_flash_flow_20_regular', null)
    , false),
      unreceive: new ToolModel(new ButtonModel('unreceive', new ButtonStyles(null, null), false, false, false, 'Unreceive all', 'icon-ic_fluent_screen_cut_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      revert: new ToolModel(new ButtonModel('revert', new ButtonStyles(null, null), false, false, false, 'Revert', 'icon-ic_fluent_square_hint_arrow_back_20_regular', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), true, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      appointment: new ToolModel(new ButtonModel('appointment', new ButtonStyles(null, null), false, false, false, 'Appointment', 'icon-ic_fluent_calendar_20_regular', null)
    , false),
      separator4: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    , false),
      print: new ToolModel(new SplitButtonModel<{ print_receiving_report: ButtonModel, unloading_tally_report: ButtonModel, print_asn_labels: ButtonModel }>(
        'print',
        new ButtonStyles(null, null),
        false,
        false,
        ' ',
        'icon-ic_fluent_print_20_regular',
        null,
        [
          new ButtonModel('print_receiving_report', new ButtonStyles(null, null), false, false, false, 'Receiving report', ''),
          new ButtonModel('unloading_tally_report', new ButtonStyles(null, null), false, false, false, 'Unloading tally', ''),
          new ButtonModel('print_asn_labels', new ButtonStyles(null, null), false, false, false, 'License plate labels', '')
        ])
    , false),
      discussions: new ToolModel(new ButtonModel('discussions', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_chat_20_regular', null)
    , false),
      separator5: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      options: new ToolModel(new SplitButtonModel<{ email_requests: ButtonModel, pallet_transactions: ButtonModel, surveys: ButtonModel, temperature_readings: ButtonModel, check_out: ButtonModel, convert_edi: ButtonModel }>(
        'options',
        new ButtonStyles(null, null),
        false,
        false,
        ' ',
        'icon-ic_fluent_more_horizontal_20_regular',
        null,
        [
          new ButtonModel('email_requests', new ButtonStyles(null, null), false, false, false, 'Email requests', 'icon-ic_fluent_mail_arrow_up_20_regular'),
          new ButtonModel('pallet_transactions', new ButtonStyles(null, null), false, false, false, 'Pallet transactions', 'icon-ic_fluent_arrow_swap_20_regular'),
          new ButtonModel('surveys', new ButtonStyles(null, null), false, false, false, 'Surveys', 'icon-ic_fluent_shifts_availability_20_regular'),
          new ButtonModel('temperature_readings', new ButtonStyles(null, null), false, false, false, 'Temperature readings', 'icon-ic_fluent_temperature_20_regular'),
          new ButtonModel('check_out', new ButtonStyles(null, null), false, false, false, 'Driver check out', 'icon-ic_fluent_signature_20_regular'),
          new ButtonModel('convert_edi', new ButtonStyles(null, null), false, false, false, 'Convert to EDI order', 'icon-ic_fluent_convert_range_20_regular')
        ])
    , false)
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Order code', true, false),
    order_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_class'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Order class', true, false),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', true, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', true, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
    order_status: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Order status', false, false),
    ship_from_selection: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, false, false, 'Ship From', 'icon-ic_fluent_video_person_20_regular', null)
, new ControlContainerStyles(null, null), '', false, false),
    ship_from_clear: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, false, false, 'Clear', 'icon-ic_fluent_dismiss_circle_20_regular', null)
, new ControlContainerStyles(null, null), '', false, false),
    ship_from: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    vendor_reference: new FieldModel(new TextBoxModel(this.formGroup.controls['vendor_reference'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Vendor reference', false, false),
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Carrier', false, false),
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Owner reference', false, false),
    trailer_number: new FieldModel(new TextBoxModel(this.formGroup.controls['trailer_number'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Trailer number', false, false),
    seal_number: new FieldModel(new TextBoxModel(this.formGroup.controls['seal_number'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Seal number', false, false),
    expected_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expected_date'] as DatexFormControl, null, false, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'Expected date', false, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, 'Order notes', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
    custom_fields: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, false, false, 'Custom fields', 'icon-ic_fluent_pen_20_regular', null)
, new ControlContainerStyles(null, null), '', false, false),
    requested_date: new FieldModel(new DateBoxModel(this.formGroup.controls['requested_date'] as DatexFormControl, null, false, false, 'MM/DD/YYYY', 'datetime', null)
, new ControlContainerStyles(null, null), 'Requested delivery date', false, false),
    pickup_date: new FieldModel(new DateBoxModel(this.formGroup.controls['pickup_date'] as DatexFormControl, null, false, false, 'MM/DD/YYYY', 'datetime', null)
, new ControlContainerStyles(null, null), 'Pickup date', false, false),
    payment_term: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['payment_term'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Payment term', false, false),
    shipment_carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['shipment_carrier'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Shipment carrier', false, false),
    shipment_notes: new FieldModel(new TextBoxModel(this.formGroup.controls['shipment_notes'] as DatexFormControl, null, false, false, 'Shipment notes', null)
, new ControlContainerStyles(null, null), 'Shipment notes', false, false),
    integration_information: new FieldModel(new ButtonModel('', new ButtonStyles(['link'], null), false, false, false, 'Integration information', '', null)
, new ControlContainerStyles(null, null), 'Integration information', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Order', false, true, true, false),
  newGroup2: new FieldsetModel('Details', false, true, false, false),
  additional_details: new FieldsetModel('Additional Details', false, true, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      licenseplates: new TabGroupModel(),
      billing: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      licenseplates: new TabItemModel(
        this.rootTabGroup, 
        'License plates', 
        false,
        true,
        {
          grouped: new TabItemModel(
          this.subTabGroups.licenseplates, 
          'Grouped', 
          false
          ),
          ungrouped: new TabItemModel(
          this.subTabGroups.licenseplates, 
          'Ungrouped', 
          false
          ),
        }
        ),
      orderlines: new TabItemModel(
        this.rootTabGroup, 
        'Order lines', 
        false,
        ),
      billing: new TabItemModel(
        this.rootTabGroup, 
        'Billing', 
        false,
        true,
        {
          accessorial_tasks: new TabItemModel(
          this.subTabGroups.billing, 
          'Accessorials', 
          false
          ),
          billing_records: new TabItemModel(
          this.subTabGroups.billing, 
          'Billing Records', 
          false
          ),
        }
        ),
      contacts: new TabItemModel(
        this.rootTabGroup, 
        'Contacts', 
        false,
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_licenseplates_grouped_asn_order_licenseplates_grid_inParams_order_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_licenseplates_grouped_asn_order_licenseplates_grid_inParams_order_status_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_licenseplates_grouped_asn_order_licenseplates_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_licenseplates_grouped_asn_order_licenseplates_grid_inParams_can_unreceive(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = !$editor.toolbar.unreceive.hidden;
      
      return expr;
    }
  
    get $tabs_licenseplates_ungrouped_asn_order_receiving_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_licenseplates_ungrouped_asn_order_receiving_tasks_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_orderlines_asn_orderlines_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.PreferredWarehouseId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_orderIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.entity.Id];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds;
    }
  
    get $tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.entity.ShipmentOrderLookups[0]?.ShipmentId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds;
    }
  
    get $tabs_contacts_orderaddresses_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_tasks_asn_order_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_tasks_asn_order_tasks_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
        @ViewChild('$tabs_licenseplates_grouped', { read: FootPrintManager_asn_order_licenseplates_gridComponent }) $tabs_licenseplates_grouped: FootPrintManager_asn_order_licenseplates_gridComponent;
        @ViewChild('$tabs_licenseplates_ungrouped', { read: FootPrintManager_asn_order_receiving_tasks_gridComponent }) $tabs_licenseplates_ungrouped: FootPrintManager_asn_order_receiving_tasks_gridComponent;
      @ViewChild('$tabs_orderlines', { read: FootPrintManager_asn_orderlines_gridComponent }) $tabs_orderlines: FootPrintManager_asn_orderlines_gridComponent;
        @ViewChild('$tabs_billing_accessorial_tasks', { read: FootPrintManager_accessorial_tasks_gridComponent }) $tabs_billing_accessorial_tasks: FootPrintManager_accessorial_tasks_gridComponent;
        @ViewChild('$tabs_billing_billing_records', { read: FootPrintManager_billing_records_gridComponent }) $tabs_billing_billing_records: FootPrintManager_billing_records_gridComponent;
      @ViewChild('$tabs_contacts', { read: FootPrintManager_orderaddresses_gridComponent }) $tabs_contacts: FootPrintManager_orderaddresses_gridComponent;
      @ViewChild('$tabs_tasks', { read: FootPrintManager_asn_order_tasks_gridComponent }) $tabs_tasks: FootPrintManager_asn_order_tasks_gridComponent;
    //#endregion tabs children
    widgets = {
      orderline_total_amount_widget: new WidgetModel(false),
      orderline_total_received_widget: new WidgetModel(false),
      orderline_total_gross_received_widget: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_orderline_total_amount_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $widgets_orderline_total_received_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $widgets_orderline_total_gross_received_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_orderline_total_amount_widget', { read:  AsnOrders_orderline_total_amount_widgetComponent }) $widgets_orderline_total_amount_widget: AsnOrders_orderline_total_amount_widgetComponent;
      @ViewChild('$widgets_orderline_total_received_widget', { read:  AsnOrders_orderline_total_received_widgetComponent }) $widgets_orderline_total_received_widget: AsnOrders_orderline_total_received_widgetComponent;
      @ViewChild('$widgets_orderline_total_gross_received_widget', { read:  AsnOrders_orderline_total_gross_received_widgetComponent }) $widgets_orderline_total_gross_received_widget: AsnOrders_orderline_total_gross_received_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  get $fields_carrier_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.entity.Project.OwnerId;
    
    return expr;
  }

  get $fields_payment_term_selector_inParams_shipment_payment_term_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.entity.ShipmentOrderLookups[0]?.Shipment?.PaymentTermId;
    
    return expr;
  }

  get $fields_shipment_carrier_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.entity.Project.OwnerId;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    order_class: this.fields.order_class.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    vendor_reference: this.fields.vendor_reference.control.valueChanges
    ,
    carrier: this.fields.carrier.control.valueChanges
    ,
    reference: this.fields.reference.control.valueChanges
    ,
    trailer_number: this.fields.trailer_number.control.valueChanges
    ,
    seal_number: this.fields.seal_number.control.valueChanges
    ,
    expected_date: this.fields.expected_date.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    requested_date: this.fields.requested_date.control.valueChanges
    ,
    pickup_date: this.fields.pickup_date.control.valueChanges
    ,
    payment_term: this.fields.payment_term.control.valueChanges
    ,
    shipment_carrier: this.fields.shipment_carrier.control.valueChanges
    ,
    shipment_notes: this.fields.shipment_notes.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.licenseplates,
      this.tabs.orderlines,
      this.tabs.billing,
      this.tabs.contacts,
      this.tabs.tasks,
    ]; 
    this.subTabGroups.licenseplates.tabs = [
        this.tabs.licenseplates.tabs.grouped,
        this.tabs.licenseplates.tabs.ungrouped,
    ];    
    this.subTabGroups.billing.tabs = [
        this.tabs.billing.tabs.accessorial_tasks,
        this.tabs.billing.tabs.billing_records,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.order_id)) {
        this.$missingRequiredInParams.push('order_id');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit ASN Order';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      orderId:  $editor.inParams.order_id 
    };

    const data = await this.datasources.AsnOrders.ds_asn_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.order_class.control as SelectBoxModel).reset($editor.entity.OrderClassId);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity.PreferredWarehouseId);
    (this.fields.order_status.control as TextModel).text = $editor.entity.Status.Name;
    (this.fields.vendor_reference.control as TextBoxModel).reset($editor.entity.VendorReference);
    (this.fields.carrier.control as SelectBoxModel).reset($editor.entity.PreferredCarrierId);
    (this.fields.reference.control as TextBoxModel).reset($editor.entity.OwnerReference);
    (this.fields.trailer_number.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.TrailerId);
    (this.fields.seal_number.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.SealId);
    (this.fields.expected_date.control as DateBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.ExpectedDate);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);
    (this.fields.requested_date.control as DateBoxModel).reset($editor.entity.RequestedDeliveryDate);
    (this.fields.pickup_date.control as DateBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.PickupDate?.toString());
    (this.fields.payment_term.control as SelectBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.PaymentTermId);
    (this.fields.shipment_carrier.control as SelectBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.CarrierId);
    (this.fields.shipment_notes.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_orderline_total_amount_widget') {
      if (!isNil(this.$widgets_orderline_total_amount_widget) && !this.widgets.orderline_total_amount_widget.hidden && !this.widgets.orderline_total_amount_widget.removed) {
        this.$widgets_orderline_total_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_orderline_total_received_widget') {
      if (!isNil(this.$widgets_orderline_total_received_widget) && !this.widgets.orderline_total_received_widget.hidden && !this.widgets.orderline_total_received_widget.removed) {
        this.$widgets_orderline_total_received_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_orderline_total_gross_received_widget') {
      if (!isNil(this.$widgets_orderline_total_gross_received_widget) && !this.widgets.orderline_total_gross_received_widget.hidden && !this.widgets.orderline_total_gross_received_widget.removed) {
        this.$widgets_orderline_total_gross_received_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_licenseplates_grouped') {
        if (!isNil(this.$tabs_licenseplates_grouped) && !this.tabs.licenseplates.tabs.grouped.hidden && !this.tabs.licenseplates.tabs.grouped.removed) {
          this.$tabs_licenseplates_grouped.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_licenseplates_ungrouped') {
        if (!isNil(this.$tabs_licenseplates_ungrouped) && !this.tabs.licenseplates.tabs.ungrouped.hidden && !this.tabs.licenseplates.tabs.ungrouped.removed) {
          this.$tabs_licenseplates_ungrouped.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_orderlines') {
        if (!isNil(this.$tabs_orderlines) && !this.tabs.orderlines.hidden && !this.tabs.orderlines.removed) {
          this.$tabs_orderlines.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_billing_accessorial_tasks') {
        if (!isNil(this.$tabs_billing_accessorial_tasks) && !this.tabs.billing.tabs.accessorial_tasks.hidden && !this.tabs.billing.tabs.accessorial_tasks.removed) {
          this.$tabs_billing_accessorial_tasks.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_billing_billing_records') {
        if (!isNil(this.$tabs_billing_billing_records) && !this.tabs.billing.tabs.billing_records.hidden && !this.tabs.billing.tabs.billing_records.removed) {
          this.$tabs_billing_billing_records.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_contacts') {
        if (!isNil(this.$tabs_contacts) && !this.tabs.contacts.hidden && !this.tabs.contacts.removed) {
          this.$tabs_contacts.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_tasks') {
        if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden && !this.tabs.tasks.removed) {
          this.$tabs_tasks.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .order_class
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .vendor_reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .carrier
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .trailer_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .seal_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .expected_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .requested_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .pickup_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .payment_term
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .shipment_carrier
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .shipment_notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.AsnOrders.is_asn_order_deletable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.has_completed_receiving_tasks
      }
  });
  
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be deleted', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue})}`);
      return;
  } 
  else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Delete Order', `Are you sure you want to delete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              const orderLookup = $editor.entity.LookupCode;
              const result = await $flows.AsnOrders.delete_asn_order_flow({ orderId: $editor.entity.Id });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be deleted', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue})}`);
                  return;
              }
              else {
                  const title = `Order ${orderLookup} deleted`
                  $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
                  $editor.close();
              }
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Order delete error', 'An error occurred during the deletion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.AsnOrders.is_asn_order_cancelable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.has_completed_receiving_tasks
      }
  });
  
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be cancelled', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Cancel order', `Are you sure you want to cancel order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              const result = await $flows.AsnOrders.cancel_asn_order_flow({ orderId: $editor.entity.Id });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be cancelled', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
                  return;
              }
              else {
  
                  const title = `Order ${$editor.entity.LookupCode} cancelled`;
                  $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
                  await $editor.refresh();
              }
  
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Cancel order error', 'An error occurred during the cancelling of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.AsnOrders.is_asn_order_completable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.has_completed_receiving_tasks
      }
  });
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be completed', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
      return;
  } else {
  
      try {
  
          var confirmation = false;
          // Lock down complete button to handle potential double clicks
          $editor.toolbar.complete.control.readOnly = true;
  
          $editor.toolbar.auto_receive.control.readOnly = true;
          $editor.toolbar.unreceive.control.readOnly = true;
  
          // Change icon to a rolling progress
          $editor.toolbar.complete.control.icon = "icon datex-default-spinner";
  
  
          // Check if the order contains pending receiving tasks
          const plannedTasks = (await $datasources.AsnOrders.ds_get_planned_receiving_tasks_by_orderId_shipmentId_top1.get({
              orderId: $editor.entity.Id,
              shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId
          })).result;
  
          if ($utils.isDefined(plannedTasks)) {
  
              //  Prompt for asn order transfer outstanding options
              var userConfirmed = false;
              var ignoreConfirmed = false;
              var cancelCompletion = false;
  
              const dialogResult = await $shell.FootPrintManager.openasn_order_transfer_outstanding_options_formDialog('modal');
              userConfirmed = dialogResult.confirm;
              ignoreConfirmed = dialogResult.ignore;
              cancelCompletion = dialogResult.cancelCompletion;
              const notes = dialogResult.notes;
              const createNewOrder = dialogResult.createNewOrder;
              const copyUdfs = dialogResult.copyUdfs;
  
              if (userConfirmed) {
                  var openNewOrder = false;
  
  
                  $editor.toolbar.complete.control.label = 'Transferring Outstanding';
  
                  const result = await $flows.AsnOrders.transfer_outstanding_asn_order_flow({
                      orderId: $editor.entity.Id,
                      shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId,
                      notes: notes,
                      createNewOrder: createNewOrder,
                      copyUdfs: copyUdfs
                  });
                  const reasons = result.reasons;
  
                  var newOrderId = result.newOrderId;
                  if ($utils.isDefined(newOrderId)) {
                      openNewOrder = true;
                      confirmation = true;
                  }
  
                  if ($utils.isDefined(reasons)) {
                      await $shell.FootPrintManager.openErrorDialog('Transfer outstanding cannot be completed.', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
  
                      // Reset buttons
                      $editor.toolbar.complete.control.styles.resetStyle();
                      $editor.toolbar.complete.control.icon = "icon ms-Icon ms-Icon--AcceptMedium";
                      $editor.toolbar.complete.control.label = 'Complete';
                      $editor.toolbar.complete.control.readOnly = false;
  
                      $editor.toolbar.auto_receive.control.readOnly = false;
                      $editor.toolbar.unreceive.control.readOnly = false;
  
                      confirmation = false;
  
                      return;
                  }
              }
  
              if (!userConfirmed) {
                  if (cancelCompletion) {
  
                      // Reset buttons
                      $editor.toolbar.complete.control.styles.resetStyle();
                      $editor.toolbar.complete.control.icon = "icon ms-Icon ms-Icon--AcceptMedium";
                      $editor.toolbar.complete.control.label = 'Complete';
                      $editor.toolbar.complete.control.readOnly = false;
  
                      $editor.toolbar.auto_receive.control.readOnly = false;
                      $editor.toolbar.unreceive.control.readOnly = false;
  
                      confirmation = false;
  
                      return;
  
                  }
                  else if (ignoreConfirmed) {
                      // Exit and move on below to complete the order
                      confirmation = true;
                  }
                  else {
                      // All options are false
  
                      // Reset buttons
                      $editor.toolbar.complete.control.styles.resetStyle();
                      $editor.toolbar.complete.control.icon = "icon ms-Icon ms-Icon--AcceptMedium";
                      $editor.toolbar.complete.control.label = 'Complete';
                      $editor.toolbar.complete.control.readOnly = false;
  
                      $editor.toolbar.auto_receive.control.readOnly = false;
                      $editor.toolbar.unreceive.control.readOnly = false;
  
                      confirmation = false;
  
                      return;
                  }
              }
          }
          else {
              const confirmComplete = await $shell.FootPrintManager.openConfirmationDialog('Complete Order', `Are you sure you want to complete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
              if (confirmComplete) {
                  confirmation = true;
              }
              else {
  
                  // Reset buttons
                  $editor.toolbar.complete.control.styles.resetStyle();
                  $editor.toolbar.complete.control.icon = "icon ms-Icon ms-Icon--AcceptMedium";
                  $editor.toolbar.complete.control.label = 'Complete';
                  $editor.toolbar.complete.control.readOnly = false;
  
                  $editor.toolbar.auto_receive.control.readOnly = false;
                  $editor.toolbar.unreceive.control.readOnly = false;
  
                  return;
              }
          }
  
          if (confirmation) {
              $editor.toolbar.complete.control.label = 'Completing';
  
              const result = await $flows.AsnOrders.complete_asn_order_flow({ orderId: $editor.entity.Id });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be completed', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
  
                  // Reset buttons
                  $editor.toolbar.complete.control.styles.resetStyle();
                  $editor.toolbar.complete.control.icon = "icon ms-Icon ms-Icon--AcceptMedium";
                  $editor.toolbar.complete.control.label = 'Complete';
                  $editor.toolbar.complete.control.readOnly = false;
  
                  $editor.toolbar.auto_receive.control.readOnly = false;
                  $editor.toolbar.unreceive.control.readOnly = false;
  
                  return;
              }
              else {
  
                  // Hide buttons on completion
                  $editor.toolbar.complete.hidden = true;
                  $editor.toolbar.auto_receive.hidden = true;
                  $editor.toolbar.unreceive.hidden = true;
  
                  let title = `Order ${$editor.entity.LookupCode} completed`;
                  $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
                  await $editor.refresh();
              
  
  
  
                  // If the open new order boolean is true and we succesfully created a new orderId
                  if (openNewOrder && $utils.isDefined(newOrderId)) {
                      $shell.FootPrintManager.openasn_order_editor({ order_id: newOrderId })
                  }
              }
          }
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.FootPrintManager.openErrorDialog('Order complete error', 'An error occurred during the completion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  /**** MAKE ALL DATASOURCE CALLS ****/
  const discussionCheck = (await $operations.Discussions.Enable_Discussions_Feature.isAuthorized())
  
  // Get receiving tasks
  const tasksPromise = ($datasources.AsnOrders.ds_find_tasks.get({ orderId: $editor.entity.Id, operationCodeId: 2 }));
  
  // Get dock appointments
  const appointmentsPromise = ($datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.order_id
  }));
  
  // Get discussions
  let discussionsPromise: Promise<{
      result?: {
          id?: string;
          entity_name?: string;
          entity_id?: string;
          reference?: string;
          discussion?: string;
          created_by?: string;
          created_on?: string;
          read_on?: string;
          alerted_on?: string;
          application_id?: string;
      }[];
      totalCount?: number;
  }>;
  
  if (discussionCheck) {
      discussionsPromise = ($datasources.Discussions.ds_storage_discussions.getList({
          entity_name: 'Order',
          entity_id: $editor.inParams.order_id.toString()
      }));
  }
  
  // Order addresses
  const addressPromise = $flows.Addresses.get_address_single_string_by_orderId({ orderId: $editor.entity.Id });
  
  // Get eligible actions
  const failProcessPromise = $flows.AsnOrders.is_asn_order_processable_flow({ orderId: $editor.entity.Id })
  const failReceivePromise = $flows.AsnOrders.is_asn_order_auto_receivable_flow({ orderId: $editor.entity.Id })
  const failCompletePromise = $flows.AsnOrders.is_asn_order_completable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.has_completed_receiving_tasks
      }
  })
  const failCancelPromise = $flows.AsnOrders.is_asn_order_cancelable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.has_completed_receiving_tasks
      }
  })
  const failRevertPromise = $flows.AsnOrders.is_asn_order_revertable_flow({
      orderId: $editor.entity.Id,
      useValidation: false,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.has_completed_receiving_tasks
      }
  });
  const failUnreceivePromise = $flows.AsnOrders.is_asn_order_unreceivable_flow({
      orderId: $editor.entity.Id,
      shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
  });
  const failDeletePromise = $flows.AsnOrders.is_asn_order_deletable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.has_completed_receiving_tasks
      }
  });
  const failValidatePromise = $flows.AsnOrders.is_asn_order_validatable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.has_completed_receiving_tasks
      }
  })
  
  // Get all responses
  const [tasksResponse, appointmentsResponse, discussionsResponse, addressResponse, failProcessResponse, failReceiveResponse, failCompleteResponse, failCancelResponse, failRevertResponse, failUnreceiveResponse, failDeleteResponse, failValidateResponse] = 
      await Promise.all([tasksPromise, appointmentsPromise, discussionsPromise, addressPromise, failProcessPromise, failReceivePromise, failCompletePromise, failCancelPromise, failRevertPromise, failUnreceivePromise, failDeletePromise, failValidatePromise]);
  
  const tasks = tasksResponse.result;
  const appointments = appointmentsResponse.result;
  const discussions = discussionsResponse?.result;
  const orderAddress = addressResponse;
  
  const failProcessReasons = failProcessResponse.reasons;
  const failReceiveReasons = failReceiveResponse.reasons; 
  const failCompleteReasons = failCompleteResponse.reasons; 
  const failCancelReasons = failCancelResponse.reasons;
  const failRevertReasons = failRevertResponse.reasons;
  const failUnreceiveReasons = failUnreceiveResponse.reasons;
  const failDeleteReasons = failDeleteResponse.reasons;
  const failValidateReasons = failValidateResponse.reasons;
  
  /*** USE DATA ***/
  // Set hasCompletedReceivingTasks
  $editor.vars.has_completed_receiving_tasks = tasks.some(t => t.StatusId === 2);
  $editor.vars.has_planned_receiving_tasks = tasks.some(t => t.StatusId === 4);
  
  // Shipment created if not found in the on_init flow
  
  
  // Set editor title
  $editor.title = `ASN Order ${$editor.entity.LookupCode}`;
  
  let shipFrom = '';
  
  
  if ($utils.isDefined(orderAddress)) {
      shipFrom = orderAddress.address;
  
  }
  // Only display ship from if it is not an auto generated account
  if ($utils.isDefined(shipFrom)) {
      if (!shipFrom.startsWith('ord-')) {
  
          $editor.fields.ship_from.hidden = false;
          $editor.fields.ship_from.control.text = shipFrom;
          $editor.fields.ship_from_clear.hidden = false;
  
      } else {
          $editor.fields.ship_from.hidden = true;
          $editor.fields.ship_from.control.text = '';
          $editor.fields.ship_from_clear.hidden = true;
      }
  }
  else {
      $editor.fields.ship_from.hidden = true;
      $editor.fields.ship_from.control.text = '';
      $editor.fields.ship_from_clear.hidden = true;
  }
  
  // Apply appointment text logic
  if ($utils.isDefined(appointments)) {
      $editor.toolbar.appointment.control.label = (await $flows.DockAppointments.get_formatted_dock_appointment({
          dock_appointments: [{ id: appointments[0]?.DockAppointmentId}],
      })).dock_appointments[0]?.formatted_value;
  
  } else {
      // Reset the appointment label back to original
      $editor.toolbar.appointment.control.styles.resetStyle();
      $editor.toolbar.appointment.control.label = 'Appointment'
  }
  // Discussions feature
  if (discussionCheck) {
      if ($utils.isDefined(discussions)) {
  
          let containsUnread = false;
          let containsDiscussion = false;
  
          for (let discussion of discussions) {
              let read_on = discussion.read_on
              let discussionData = discussion.discussion
  
              if (!$utils.isDefined(read_on) && discussion.id != '0') {
                  containsUnread = true;
              }
              if ($utils.isDefined(discussionData) && discussion.id != '0') {
                  containsDiscussion = true;
              }
          }
          if (containsDiscussion) {
              if (containsUnread) {
                  $editor.toolbar.discussions.control.icon = "icon icon-ic_fluent_chat_multiple_20_regular";
                  $editor.toolbar.discussions.control.styles.setDestructiveClass();
              }
              else {
                  $editor.toolbar.discussions.control.icon = "icon icon-ic_fluent_chat_20_regular";
                  $editor.toolbar.discussions.control.styles.setDestructiveClass();
              }
          }
          else {
              $editor.toolbar.discussions.control.icon = "icon icon-ic_fluent_chat_empty_20_regular";
              $editor.toolbar.discussions.control.styles.setCreationClass();
  
          }
      }
  }
  else {
      $editor.toolbar.discussions.control.readOnly = true;
  }
  
  
  // Fetch shipments
  if ($editor.entity.ShipmentOrderLookups.length > 1) {
      // close
      await $shell.FootPrintManager.openErrorDialog('Invalid order', 'This order has more than one shipment. Editor will close');
      $editor.close();
      return;
  }
  
  else {
  
      $editor.fields.warehouse.control.readOnly = false;
      $editor.fields.warehouse.required = false;
  
      if ($editor.entity.ShipmentOrderLookups.length === 1) {
          $editor.vars.shipment_id = $editor.entity.ShipmentOrderLookups[0].ShipmentId;
          $editor.fields.warehouse.required = true;
      }
  
      const noWarehouse = $utils.isDefined($editor.fields.warehouse.control.value) === false;
  
      // Enable/Disable Components
      // Process
      if ($utils.isDefined(failProcessReasons)) {
          disableComponent($editor.toolbar.process);
      }
      else {
          enableComponent($editor.toolbar.process);
      }
  
      // Auto Receive
      if ($utils.isDefined(failReceiveReasons)) {
          disableComponent($editor.toolbar.auto_receive);
      }
      else {
          enableComponent($editor.toolbar.auto_receive);
      }
  
      // Complete
      if ($utils.isDefined(failCompleteReasons)) {
          disableComponent($editor.toolbar.complete);
      }
      else {
          enableComponent($editor.toolbar.complete);
      }
  
      // Cancel
      if ($utils.isDefined(failCancelReasons)) {
          disableComponent($editor.toolbar.cancel);
      }
      else {
          enableComponent($editor.toolbar.cancel);
      }
  
      // Revert
      if ($utils.isDefined(failRevertReasons)) {
          disableComponent($editor.toolbar.revert);
      }
      else {
          enableComponent($editor.toolbar.revert);
      }
  
      // Unreceive All
      $editor.vars.can_unreceive = !$utils.isDefined(failUnreceiveReasons);
  
      if ($editor.vars.can_unreceive) {
          enableComponent($editor.toolbar.unreceive);
      }
      else {
          disableComponent($editor.toolbar.unreceive);
      }
  
      // Delete
      if ($utils.isDefined(failDeleteReasons)) {
          disableComponent($editor.toolbar.on_delete);
      }
      else {
          enableComponent($editor.toolbar.on_delete);
      }
  
      // Validate
      if ($utils.isDefined(failValidateReasons)) {
          disableComponent($editor.toolbar.validate);
      }
      else {
          enableComponent($editor.toolbar.validate);
      }
  
  
      // Check status
      if ($editor.entity.OrderStatusId === 1) { // Created
          // Enable components
  
  
          enableComponent($editor.fields.lookupcode);
          enableComponent($editor.fields.order_class);
          enableComponent($editor.fields.vendor_reference);
          enableComponent($editor.fields.carrier);
          enableComponent($editor.fields.expected_date);
  
          disableComponent($editor.widgets.orderline_total_gross_received_widget);
          disableComponent($editor.widgets.orderline_total_received_widget);
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
          $editor.fields.owner.control.readOnly = hasOrderLines;
          $editor.fields.project.control.readOnly = hasOrderLines;
  
  
  
      }
      else if ($editor.entity.OrderStatusId === 2048) { // ApprovalRequired
          // Enable components
  
  
          enableComponent($editor.fields.lookupcode);
          enableComponent($editor.fields.order_class);
          enableComponent($editor.fields.vendor_reference);
          enableComponent($editor.fields.carrier);
          enableComponent($editor.fields.expected_date);
  
          disableComponent($editor.widgets.orderline_total_gross_received_widget);
          disableComponent($editor.widgets.orderline_total_received_widget);
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
          $editor.fields.owner.control.readOnly = hasOrderLines;
          $editor.fields.project.control.readOnly = hasOrderLines;
  
  
      }
      else if ($editor.entity.OrderStatusId === 4096) { // Rejected
          // Enable components
  
  
          enableComponent($editor.fields.lookupcode);
          enableComponent($editor.fields.order_class);
          enableComponent($editor.fields.vendor_reference);
          enableComponent($editor.fields.carrier);
          enableComponent($editor.fields.expected_date);
  
  
          disableComponent($editor.widgets.orderline_total_gross_received_widget);
          disableComponent($editor.widgets.orderline_total_received_widget);
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
          $editor.fields.owner.control.readOnly = hasOrderLines;
          $editor.fields.project.control.readOnly = hasOrderLines;
  
      }
      else if ($editor.entity.OrderStatusId === 2) {
          // processing status
  
  
          $editor.fields.lookupcode.control.readOnly = false;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = false;
          $editor.fields.carrier.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = false;
          $editor.widgets.orderline_total_received_widget.hidden = false;
  
          // Set custom status
          let order_state = await $flows.AsnOrders.get_asn_order_state({
              orderId: $editor.entity.Id,
              shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId
          });
  
          if ($utils.isDefined(order_state.state)) {
              switch (order_state.state) {
                  case 2: {
                      $editor.fields.order_status.control.text = 'Ready to receive'
                      break;
                  }
                  case 3: {
                      $editor.fields.order_status.control.text = 'Receiving in progress'
                      break;
                  }
                  case 4: {
                      $editor.fields.order_status.control.text = 'Ready to complete'
                      break;
                  }
  
              }
  
          }
  
      }
      else {
  
  
          $editor.fields.lookupcode.control.readOnly = true;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = true;
          $editor.fields.carrier.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.expected_date.control.readOnly = true;
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = false;
          $editor.widgets.orderline_total_received_widget.hidden = false;
      }
  }
  
  // Call common flow to apply the role based permissions
  await $editor.apply_operations();
  
  //Integration information
  
  let integration_info = (await $flows.FootPrintManager.get_integration_info_by_order_id({order_id: $editor.inParams.order_id}));
  
  if ($utils.isDefined(integration_info?.Source)) {
      $editor.fields.integration_information.hidden = false;
      $editor.fields.integration_information.control.label = integration_info.Source;
  }
  
  /******************************************
   * FUNCTIONS
  *******************************************/
  function disableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = true;
      }
      component.hidden = true;
  }
  function enableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = false;
      }
      component.hidden = false;
  
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.vars.can_unreceive = false;
  $editor.fields.integration_information.hidden = true;
  
  // Set Date/Time Formats
  $editor.fields.expected_date.control.format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  // Create shipment if none found
  const shipments = (await $datasources.AsnOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.inParams.order_id })).result;
  if (!$utils.isDefined(shipments)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.AsnOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.order_id })).result;
  
      if ($utils.isAllDefined(order, order.PreferredWarehouseId)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.order_id,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "CarrierId": order.PreferredCarrierId,
                  "ExpectedWarehouseId": order.PreferredWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 1
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
          $editor.vars.shipment_id = nextShipmentId;
      }
  }
  
  // Set warehouse on order if blank and shipment contains expected warehouse
  const order = (await $datasources.AsnOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.order_id })).result;
  if (!$utils.isDefined(order.PreferredWarehouseId) && $utils.isDefined(shipments[0]?.Shipment?.ExpectedWarehouseId)) {
  
      let payload: any = {};
  
      payload.PreferredWarehouseId = shipments[0]?.Shipment?.ExpectedWarehouseId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.inParams.order_id, entity: payload });
  
  }
  
  $editor.vars.is_edi = (await $flows.FootPrintManager.is_edi_order_flow({ order_id: $editor.inParams.order_id }))?.result ?? false;
  
  if (!$editor.vars.is_edi) {
      $editor.fieldsets.additional_details.hidden = true;
  } else {
      $editor.fieldsets.additional_details.hidden = false;
      $editor.toolbar.options.control.buttons.convert_edi.readOnly = true;
  }
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.project.control.value = null;
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.order_class.control.value,
      $editor.fields.project.control.value
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  
  var shipmentId = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
  
  
  let payload: any = {};
  if ($editor.fields.project.control.isChanged) {
      payload.ProjectId = $editor.fields.project.control.value;
  }
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  if ($editor.fields.order_class.control.isChanged) {
      payload.OrderClassId = $editor.fields.order_class.control.value;
  }
  if ($editor.fields.reference.control.isChanged) {
      payload.OwnerReference = $editor.fields.reference.control.value;
  }
  if ($editor.fields.vendor_reference.control.isChanged) {
      payload.VendorReference = $editor.fields.vendor_reference.control.value;
  }
  if ($editor.fields.carrier.control.isChanged) {
      payload.PreferredCarrierId = $editor.fields.carrier.control.value;
  }
  if ($editor.fields.warehouse.control.isChanged) {
      if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
          throw new Error('Please select a warehouse')
      }
      else {
          payload.PreferredWarehouseId = $editor.fields.warehouse.control.value;
      }
  }
  if ($editor.fields.requested_date.control.isChanged) {
      payload.RequestedDeliveryDate = $editor.fields.requested_date.control.value;
  }
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  
  
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  // Create the shipment if it does not exist only if the order has a preferred warehouse of if the preferred warehouse is selected in the editor
  if (!$utils.isDefined(shipmentId)) {
      if ($utils.isAllDefined($editor.entity.PreferredWarehouseId) || $utils.isAllDefined($editor.fields.warehouse.control.value)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.entity.Id,
              "Shipment": {
                  "LookupCode": $editor.entity.LookupCode,
                  "CarrierId": $editor.entity.PreferredCarrierId,
                  "ExpectedWarehouseId": $utils.isDefined($editor.fields.warehouse.control.value) ? $editor.fields.warehouse.control.value : $editor.entity.PreferredWarehouseId,
                  "ExpectedDate": $editor.entity.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 1,
                  "PickUpDate": $editor.fields.pickup_date.control.value,
                  "PaymentTermId": $editor.fields.payment_term.control.value,
                  "Notes": $editor.fields.notes.control.value
              }
          }
          let response = await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
          shipmentId = response.result.Id;
      }
  }
  
  if ($utils.isDefined(shipmentId)) {
  
      payload = {};
  
      if ($editor.fields.lookupcode.control.isChanged) {
          payload.LookupCode = $editor.fields.lookupcode.control.value;
      }
  
      if ($editor.fields.shipment_carrier.control.isChanged) {
          payload.CarrierId = $editor.fields.shipment_carrier.control.value;
      }
      if ($editor.fields.carrier.control.isChanged && !$utils.isDefined($editor.fields.shipment_carrier.control.value)) {
          payload.CarrierId = $editor.fields.carrier.control.value;
      }
  
      if ($editor.fields.warehouse.control.isChanged) {
          if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
              throw new Error('Please select a warehouse')
          }
          else {
              payload.ExpectedWarehouseId = $editor.fields.warehouse.control.value;
          }
      }
      if ($editor.fields.expected_date.control.isChanged) {
          payload.ExpectedDate = $editor.fields.expected_date.control.value;
      }
      if ($editor.fields.trailer_number.control.isChanged) {
          payload.TrailerId = $editor.fields.trailer_number.control.value;
      }
      if ($editor.fields.seal_number.control.isChanged) {
          payload.SealId = $editor.fields.seal_number.control.value;
      }
      if ($editor.fields.notes.control.isChanged && !$utils.isDefined($editor.fields.shipment_notes.control.value)) {
          payload.Notes = $editor.fields.notes.control.value;
      }
      if ($editor.fields.shipment_notes.control.isChanged) {
          payload.Notes = $utils.isDefined($editor.fields.shipment_notes.control.value) ? $editor.fields.shipment_notes.control.value : $editor.fields.notes.control.value;
      }
      if ($editor.fields.pickup_date.control.isChanged) {
          payload.PickupDate = $editor.fields.pickup_date.control.value;
      }
      if ($editor.fields.payment_term.control.isChanged) {
          payload.PaymentTermId = $editor.fields.payment_term.control.value;
      }
      await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: shipmentId, entity: payload });
  }
  
  
  
  await $editor.refresh();
  }
  on_ship_from_clicked(event = null) {
    return this.on_ship_from_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_from_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dialogResult = await $shell.FootPrintManager.openaccounts_gridDialog({
      ownerId: $editor.entity.Project.OwnerId,
      typeIds: [1, 3, 4, 5], // Billing, Operations, Residential, Commerical
      accountTypeId: 1, // Vendor
      allowSelection: true
  });
  
  const userConfirmed = dialogResult.confirm;
  const accountId = dialogResult.accountId;
  const addressId = dialogResult.addressId;
  
  if (userConfirmed && $utils.isAllDefined(accountId, addressId)) {
      let payload: any = {};
  
      payload.AccountId = accountId;
      payload.BillingAddresId = addressId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  }
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_revert_clicked(event = null) {
    return this.on_revert_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_revert_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.AsnOrders.is_asn_order_revertable_flow({
      orderId: $editor.entity.Id,
      useValidation: false,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.has_completed_receiving_tasks
      }
  });
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be reverted', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Revert Order', `Are you sure you want to bring order ${$editor.entity.LookupCode} back to its original created status?`, 'Yes', 'No');
      if (confirm) {
          try {
              // Lock down buttons
              $editor.toolbar.revert.control.readOnly = true;
  
              const result = await $flows.AsnOrders.revert_asn_order_flow({
                  orderId: $editor.entity.Id,
                  useValidation: false
              });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be reverted', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
                  $editor.toolbar.revert.control.readOnly = false;
                  return;
              }
              else {
                  const title = `Order ${$editor.entity.LookupCode} reverted`
                  $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
                  await $editor.refresh();
                  
                  $editor.toolbar.revert.hidden = true;
                  $editor.toolbar.revert.control.readOnly = true;
                  $editor.toolbar.validate.hidden = false;
                  $editor.toolbar.validate.control.readOnly = false;
              }
          } catch (error) {
              $editor.toolbar.revert.control.readOnly = false;
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Order revert error', 'An error occurred while reverting the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_auto_receive_clicked(event = null) {
    return this.on_auto_receive_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_receive_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Validate order can be auto received
  let reasons = (await $flows.AsnOrders.is_asn_order_auto_receivable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.Id,
          hasPlannedReceivingTasks: $editor.vars.has_planned_receiving_tasks
      }
  })).reasons;
  
  
  if ($utils.isDefined(reasons)) {
      $shell.FootPrintManager.openErrorDialog('Order cannot be Auto Received', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
      return;
  }
  
  // Open Confirmation Form
  const result = (await $shell.FootPrintManager.openauto_receive_and_putaway_formDialog({ orderId: $editor.entity.Id }));
  const isConfirm = result.isConfirm;
  const isReceived = result.isReceived;
  const isPutaway = result.isPutaway
  const reason = result.reason;
  
  if (isConfirm) {
      if (isReceived) {
          var title = `Order ${$editor.entity.LookupCode} auto received`;
          if (isPutaway && !$utils.isDefined(reason)) {
              title = title + ' and Putaway';
              $editor.vars.has_completed_receiving_tasks = true;
              await $editor.refresh();
              $shell.FootPrintManager.openToaster(title,'', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
  
          }
          else if (isPutaway && $utils.isDefined(reason)) {
              title = `Order ${$editor.entity.LookupCode} could not be auto received ${reason}`
              await $editor.refresh();
              $shell.FootPrintManager.openToaster(title, '', EToasterType.Error, { closeButton: true, timeOut: 7000, positionClass: EToasterPosition.bottomRight });
  
          }
          else {
              $editor.vars.has_completed_receiving_tasks = true;
              await $editor.refresh();
              $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
  
          }
      }
      else {
          const title = `Order ${$editor.entity.LookupCode} could not be auto received ${reason}`
          await $editor.refresh();
          $shell.FootPrintManager.openToaster(title, '', EToasterType.Error, { closeButton: true, timeOut: 7000, positionClass: EToasterPosition.bottomRight });
  
      }
  }
  
  
  
  }
  on_validate_clicked(event = null) {
    return this.on_validate_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_validate_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.toolbar.validate.control.readOnly = true;
  
  // Check whether order can be validated
  let reasons = (await $flows.AsnOrders.is_asn_order_validatable_flow({
      orderId: $editor.entity.Id,
      optimalInputs: {
          statusId: $editor.entity.OrderStatusId,
          hasCompletedReceivingTasks: $editor.vars.has_completed_receiving_tasks
      }
  })).reasons;
  
  
  if ($utils.isDefined(reasons)) {
      $shell.FootPrintManager.openErrorDialog('Order cannot be Validated', `Order ${reasons.join(', ').replace(/, ([^,]*)$/, ', and $1')}`);
      return;
  }
  
  // Validate
  let result = (await $flows.AsnOrders.validate_order_flow({ orderId: $editor.entity.Id })).reason;
  await $editor.refresh();
  
  
  if ($utils.isDefined(result)) {
      $shell.FootPrintManager.openErrorDialog('Validation Error', result);
      $editor.toolbar.validate.control.readOnly = false;
  }
  else {
      $editor.toolbar.revert.hidden = false;
      $editor.toolbar.revert.control.readOnly = false;
      $editor.toolbar.validate.hidden = true;
      $editor.toolbar.validate.control.readOnly = true;
  }
  
  await $editor.on_data_loaded();
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.order_id
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
          dockAppointmentId: dockAppointments[0].DockAppointmentId
      })
  
  } else {
  
      // Check if the selected order is in a rejected status and throw an error.
      if ($editor.entity.OrderStatusId == 4096) {
  
          throw new Error('Selected order is currently in a rejected status, a dock appointment cannot be created against rejected orders.')
      }
  
      const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
         
          warehouseId: [$editor.entity.PreferredWarehouseId],
          lookupcode: nextAppointmentId.toString(),
          scheduledArrival: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $editor.entity.RequestedDeliveryDate : $utils.date.now(),
          scheduledDeparture: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $utils.date.add(1, 'hour', $editor.entity.RequestedDeliveryDate) : $utils.date.add(1, 'hour', $utils.date.now()),
          typeId: 1,
          ownerId:  $editor.entity.Project.OwnerId,
          projectId: $editor.entity.ProjectId,
          carrierId: $editor.entity.PreferredCarrierId,
          orderId: $editor.inParams.order_id
      });
  
  }
  
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_print_receiving_report_clicked(event = null) {
    return this.on_print_receiving_report_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_receiving_report_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.AsnOrders.openreceiving_reportDialog({ orderId: $editor.entity.Id });
  }
  on_unreceive_clicked(event = null) {
    return this.on_unreceive_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unreceive_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.AsnOrders.is_asn_order_unreceivable_flow({
      orderId: $editor.entity.Id,
      shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
  });
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be unreceived', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
      return;
  } else {
  
      const dialogResult = await $shell.FootPrintManager.openunreceiving_all_options_formDialog({
          title: 'Unreceive and bring back entire order to a planned state?'
      });
      var userConfirmed = dialogResult.confirm;
      var reasonCodeId = dialogResult.reasonCodeId;
  
      if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
          return;
      }
  
      if (userConfirmed) {
          try {
  
              const result = await $flows.AsnOrders.unreceive_asn_order_flow({
                  orderId: $editor.entity.Id,
                  shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
                  reasonCodeId: reasonCodeId
              });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be fully unreceived', (await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue);
                  await $editor.refresh();
                  await $editor.tabs.licenseplates.activate();
                  return;
              }
  
              else {
  
                  const title = `Order ${$editor.entity.LookupCode} unreceived`;
                  $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
                  await $editor.refresh();
                  await $editor.tabs.licenseplates.activate();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Unreceive order error', 'An error occurred during unreceiving of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
              await $editor.refresh();
              await $editor.tabs.licenseplates.activate();
          }
      }
  }
  }
  on_ship_from_cleared(event = null) {
    return this.on_ship_from_clearedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_from_clearedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let payload: any = {};
  
  payload.AccountId = null;
  payload.BillingAddresId = null;
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  
  await $editor.refresh();
  
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.AsnOrders.is_asn_order_processable_flow({
      orderId: $editor.entity.Id
  });
  
  
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be processed', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}`);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Process order', `Are you sure you want to process order ${$editor.entity.LookupCode}?`, 'Confirm', 'Cancel');
      if (confirm) {
          try {
  
              const result = await $flows.AsnOrders.process_asn_order_flow({ orderId: $editor.entity.Id });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be processed', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}`);
                  return;
              }
              else {
                  const title = `Order ${$editor.entity.LookupCode} processed`;
                  $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
                  await $editor.refresh();
              }
          } catch (error) {
  
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Error processing order', 'An error occurred while processing the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  //Disable entire form
  if (await $operations.FootPrintManager.Disable_AsnOrders_OrderEdits.isAuthorized()) {
      // Toolbar
      $editor.toolbar.appointment.control.readOnly = true;
      $editor.toolbar.attachments.control.readOnly = true;
      $editor.toolbar.auto_receive.control.readOnly = true;
      $editor.toolbar.cancel.control.readOnly = true;
      $editor.toolbar.complete.control.readOnly = true;
      $editor.toolbar.on_delete.control.readOnly = true;
      $editor.toolbar.process.control.readOnly = true;
      $editor.toolbar.revert.control.readOnly = true;
      $editor.toolbar.options.control.buttons.surveys.readOnly = true;
      $editor.toolbar.options.control.buttons.pallet_transactions.readOnly = true;
      $editor.toolbar.options.control.buttons.temperature_readings.readOnly = true;
      $editor.toolbar.unreceive.control.readOnly = true;
      $editor.toolbar.validate.control.readOnly = true;
  
      //Fields
      $editor.fields.carrier.control.readOnly = true;
      $editor.fields.expected_date.control.readOnly = true;
      $editor.fields.lookupcode.control.readOnly = true;
      $editor.fields.notes.control.readOnly = true;
      $editor.fields.order_class.control.readOnly = true;
      $editor.fields.owner.control.readOnly = true;
      $editor.fields.project.control.readOnly = true;
      $editor.fields.reference.control.readOnly = true;
      $editor.fields.seal_number.control.readOnly = true;
      $editor.fields.ship_from_selection.control.readOnly = true;
      $editor.fields.trailer_number.control.readOnly = true;
      $editor.fields.vendor_reference.control.readOnly = true;
      $editor.fields.warehouse.control.readOnly = true;
  
  
  };
  
  
  //Get the temperature capture operation
  if (await $operations.FootPrintManager.Disable_Temperatures_Entry.isAuthorized()) {
      $editor.toolbar.options.control.buttons.temperature_readings.readOnly = true;
  };
  
  
  //Get the disable asn auto receive operation
  if (await $operations.FootPrintManager.Disable_AsnOrders_AutoReceiving.isAuthorized()) {
      $editor.toolbar.auto_receive.hidden = true;
  };
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.FootPrintManager.openorders_attachments_hubDialog({orderId: $editor.entity.Id, shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId});
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($editor.entity.ShipmentOrderLookups)) {
      await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
          entities: [{ type: 'Shipment', ids: [$editor.entity.ShipmentOrderLookups[0].ShipmentId] }],
          operationContextTypes: null,
          warehouseIds: null
      }, 'modal');
  }
  
  
  }
  on_temperature_readings(event = null) {
    return this.on_temperature_readingsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_temperature_readingsInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  $shell.FootPrintManager.opentemperature_readings_gridDialog({
      orderId: $editor.entity.Id
  });
  }
  on_custom_fields_clicked(event = null) {
    return this.on_custom_fields_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_custom_fields_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_user_defined_field_values_gridDialog({ entityType: 'Order', entityKeys: [{name: 'Id', value: $editor.inParams.order_id}]}, 'modal');
  }
  on_print_license_plate_labels_clicked(event = null) {
    return this.on_print_license_plate_labels_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_license_plate_labels_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'asn_order_editor.on_print_license_plate_labels_clicked');
  await $shell.FootPrintManager.openprint_license_plate_labels_formDialog({ orderId: $editor.entity.Id, licensePlateId: null});
  }
  on_unloading_tally_clicked(event = null) {
    return this.on_unloading_tally_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unloading_tally_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.PurchaseOrders.openinbound_unloading_tally_reportDialog({ orderId: $editor.entity.Id });
  }
  on_discussions_clicked(event = null) {
    return this.on_discussions_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_discussions_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  await $shell.Discussions.opendiscussions_listDialog({
      entity_name: 'Order',
      entity_id: $editor.inParams.order_id.toString()
  },
  'flyout')
  
  await $editor.refresh();
  }
  on_email_requests_clicked(event = null) {
    return this.on_email_requests_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_email_requests_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.Notifications.openemail_requests_gridDialog({
      entityType: 'Order',
      entityId: $editor.entity.Id
  }, 'modal');
  }
  on_integration_clicked(event = null) {
    return this.on_integration_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_integration_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/29/2024
  
  await $shell.FootPrintManager.openintegration_info_by_order_idDialog({ order_id: $editor.inParams.order_id }, "flyout");
  }
  on_print_labels_clicked(event = null) {
    return this.on_print_labels_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_labels_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.FootPrintManager.openprint_order_labels_formDialog({ orderId: $editor.entity.Id ,context:"asn"});
  }
  on_pallet_transactions_clicked(event = null) {
    return this.on_pallet_transactions_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_pallet_transactions_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/25/2024
  
  if ($utils.isDefined($editor.vars.shipment_id)) {
  
      await $shell.FootPrintManager.openpallet_transactions_shipment_gridDialog({
          project_id: $editor.entity.ProjectId,
          shipment_id: $editor.vars.shipment_id,
          order_lookup_code: $editor.entity.LookupCode
      }, "flyout", EModalSize.Large)
  
  } else {
  
      await $shell.FootPrintManager.openInfoDialog("Missing shipment", "A shipment Id could not be found for this order.")
  }
  }
  on_driver_checkout_clicked(event = null) {
    return this.on_driver_checkout_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_driver_checkout_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'asn_order_editor.on_driver_checkout_clicked');
  $shell.FootPrintManager.opendriver_check_out_hub({
      orderId: $editor.inParams.order_id
  });
  }
  on_convert_to_edi_clicked(event = null) {
    return this.on_convert_to_edi_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_convert_to_edi_clickedInternal(
    $editor: FootPrintApiManager_asn_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  const confirmEdi = await $shell.FootPrintManager.openConfirmationDialog('Convert to EDI Order', `Are you sure you want to convert order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  if (confirmEdi) {
      (await $flows.FootPrintManager.convert_order_to_edi({ order_id: $editor.inParams.order_id }))
  
      $editor.on_init();
      $editor.refresh();
  
  
  }
  
  
  
  }
  //#endregion private flows
  
}
