<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden && !filtersets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden && !filters.owner.removed" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}} {{filters.owner.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.owner.label + (filters.owner.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.owner.label}}<span *ngIf="filters.owner.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Owners-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                  [tooltip]="filters.owner.control.tooltip"
                                [statusId]="$fields_owner_selector_inParams_statusId"
                              >
                              </Owners-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden && !filters.project.removed" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}} {{filters.project.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.project.label + (filters.project.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.project.label}}<span *ngIf="filters.project.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Owners-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                  [tooltip]="filters.project.control.tooltip"
                                [statusId]="$fields_project_selector_inParams_statusId"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </Owners-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouses" *ngIf="!filters.warehouses.hidden && !filters.warehouses.removed" 
                                  class="field-container standard {{filters.warehouses.invalid ? 'invalid' : ''}} {{filters.warehouses.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.warehouses.styles.style"
                                  [ngClass]="filters.warehouses.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouses.label + (filters.warehouses.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouses.label}}<span *ngIf="filters.warehouses.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <ExcelOrderImport-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouses.control.type"
                                  formControlName="warehouses"
                                  (displayTextChange)="filters.warehouses.control.displayText=$event"
                                  [placeholder]="filters.warehouses.control.placeholder"
                                  [styles]="filters.warehouses.control.styles"
                                  [tooltip]="filters.warehouses.control.tooltip"
                              >
                              </ExcelOrderImport-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouses.invalid">
                                <ng-container *ngFor="let error of filters.warehouses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-shipment_statuses" *ngIf="!filters.shipment_statuses.hidden && !filters.shipment_statuses.removed" 
                                  class="field-container standard {{filters.shipment_statuses.invalid ? 'invalid' : ''}} {{filters.shipment_statuses.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.shipment_statuses.styles.style"
                                  [ngClass]="filters.shipment_statuses.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.shipment_statuses.label + (filters.shipment_statuses.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.shipment_statuses.label}}<span *ngIf="filters.shipment_statuses.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <ShippingContainers-shipment_statuses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.shipment_statuses.control.type"
                                  formControlName="shipment_statuses"
                                  (displayTextChange)="filters.shipment_statuses.control.displayText=$event"
                                  [placeholder]="filters.shipment_statuses.control.placeholder"
                                  [styles]="filters.shipment_statuses.control.styles"
                                  [tooltip]="filters.shipment_statuses.control.tooltip"
                              >
                              </ShippingContainers-shipment_statuses_dd_multi>
                              <ng-container *ngIf="filters.shipment_statuses.invalid">
                                <ng-container *ngFor="let error of filters.shipment_statuses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-order_type" *ngIf="!filters.order_type.hidden && !filters.order_type.removed" 
                                  class="field-container standard {{filters.order_type.invalid ? 'invalid' : ''}} {{filters.order_type.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.order_type.styles.style"
                                  [ngClass]="filters.order_type.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.order_type.label + (filters.order_type.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.order_type.label}}<span *ngIf="filters.order_type.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <ShippingContainers-order_types_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.order_type.control.type"
                                  formControlName="order_type"
                                  (displayTextChange)="filters.order_type.control.displayText=$event"
                                  [placeholder]="filters.order_type.control.placeholder"
                                  [styles]="filters.order_type.control.styles"
                                  [tooltip]="filters.order_type.control.tooltip"
                              >
                              </ShippingContainers-order_types_multi>
                              <ng-container *ngIf="filters.order_type.invalid">
                                <ng-container *ngFor="let error of filters.order_type.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.shipping_containers.hasTabToShow" class="tab" data-cy="tab-shipping_containers">
              <h2 [className]="tabs.shipping_containers.active? 'active': ''" (click)="tabs.shipping_containers.activate()">{{tabs.shipping_containers.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-shipping_containers_grid *ngIf="tabs.shipping_containers.active"
              #$tabs_shipping_containers
              [ownerIds]="$tabs_shipping_containers_shipping_containers_grid_inParams_ownerIds"
              [projectIds]="$tabs_shipping_containers_shipping_containers_grid_inParams_projectIds"
              [warehouseIds]="$tabs_shipping_containers_shipping_containers_grid_inParams_warehouseIds"
              [orderTypeIds]="$tabs_shipping_containers_shipping_containers_grid_inParams_orderTypeIds"
              [shipmentStatuses]="$tabs_shipping_containers_shipping_containers_grid_inParams_shipmentStatuses"
              ($refreshEvent)="refresh(false, false, '$tabs_shipping_containers')">
              </FootPrintManager-shipping_containers_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>