<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="process" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_process_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator1" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="appointment" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_appointment_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator2" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="print" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_print_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="attachments" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_attachments_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupCode" *ngIf="!fields.lookupCode.hidden && !fields.lookupCode.removed" 
                            class="field-container standard {{fields.lookupCode.invalid ? 'invalid' : ''}} {{fields.lookupCode.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lookupCode.styles.style"
                            [ngClass]="fields.lookupCode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lookupCode.label + (fields.lookupCode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lookupCode.label}}<span *ngIf="fields.lookupCode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lookupCode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.lookupCode.control.readOnly || fields.lookupCode.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupCode.control.placeholder}}"
                                [ngStyle]="fields.lookupCode.control.styles.style"
                                [ngClass]="fields.lookupCode.control.styles.classes"
                                [matTooltip]="fields.lookupCode.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lookupCode.invalid">
                          <ng-container *ngFor="let error of fields.lookupCode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-container_type" *ngIf="!fields.container_type.hidden && !fields.container_type.removed" 
                            class="field-container standard {{fields.container_type.invalid ? 'invalid' : ''}} {{fields.container_type.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.container_type.styles.style"
                            [ngClass]="fields.container_type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.container_type.label + (fields.container_type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.container_type.label}}<span *ngIf="fields.container_type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <LoadContainers-container_types_single 
                            data-cy="selector"
                            [type]="fields.container_type.control.type"
                            formControlName="container_type"
                            (displayTextChange)="fields.container_type.control.displayText=$event"
                            [placeholder]="fields.container_type.control.placeholder"
                            [styles]="fields.container_type.control.styles"
                            [tooltip]="fields.container_type.control.tooltip"
                        >
                        </LoadContainers-container_types_single>
                        <ng-container *ngIf="fields.container_type.invalid">
                          <ng-container *ngFor="let error of fields.container_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden && !fields.status.removed" 
                            class="field-container standard {{fields.status.invalid ? 'invalid' : ''}} {{fields.status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.status.label + (fields.status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.status.label}}<span *ngIf="fields.status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <LoadContainers-load_container_statuses_single 
                            data-cy="selector"
                            [type]="fields.status.control.type"
                            formControlName="status"
                            (displayTextChange)="fields.status.control.displayText=$event"
                            [placeholder]="fields.status.control.placeholder"
                            [styles]="fields.status.control.styles"
                            [tooltip]="fields.status.control.tooltip"
                        >
                        </LoadContainers-load_container_statuses_single>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-container_size" *ngIf="!fields.container_size.hidden && !fields.container_size.removed" 
                            class="field-container standard {{fields.container_size.invalid ? 'invalid' : ''}} {{fields.container_size.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.container_size.styles.style"
                            [ngClass]="fields.container_size.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.container_size.label + (fields.container_size.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.container_size.label}}<span *ngIf="fields.container_size.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="container_size"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.container_size.control.readOnly || fields.container_size.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.container_size.control.placeholder}}"
                                [ngStyle]="fields.container_size.control.styles.style"
                                [ngClass]="fields.container_size.control.styles.classes"
                                [matTooltip]="fields.container_size.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.container_size.invalid">
                          <ng-container *ngFor="let error of fields.container_size.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden && !fields.warehouse.removed" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}} {{fields.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                            [tooltip]="fields.warehouse.control.tooltip"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-yard_location" *ngIf="!fields.yard_location.hidden && !fields.yard_location.removed" 
                            class="field-container standard {{fields.yard_location.invalid ? 'invalid' : ''}} {{fields.yard_location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.yard_location.styles.style"
                            [ngClass]="fields.yard_location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.yard_location.label + (fields.yard_location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.yard_location.label}}<span *ngIf="fields.yard_location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.yard_location.control.type"
                            formControlName="yard_location"
                            (displayTextChange)="fields.yard_location.control.displayText=$event"
                            [placeholder]="fields.yard_location.control.placeholder"
                            [styles]="fields.yard_location.control.styles"
                            [tooltip]="fields.yard_location.control.tooltip"
                          [warehouseId]="$fields_yard_location_selector_inParams_warehouseId"
                          [typeId]="$fields_yard_location_selector_inParams_typeId"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.yard_location.invalid">
                          <ng-container *ngFor="let error of fields.yard_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_type" *ngIf="!fields.order_type.hidden && !fields.order_type.removed" 
                            class="field-container standard {{fields.order_type.invalid ? 'invalid' : ''}} {{fields.order_type.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.order_type.styles.style"
                            [ngClass]="fields.order_type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order_type.label + (fields.order_type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order_type.label}}<span *ngIf="fields.order_type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <LoadContainers-order_types_single 
                            data-cy="selector"
                            [type]="fields.order_type.control.type"
                            formControlName="order_type"
                            (displayTextChange)="fields.order_type.control.displayText=$event"
                            [placeholder]="fields.order_type.control.placeholder"
                            [styles]="fields.order_type.control.styles"
                            [tooltip]="fields.order_type.control.tooltip"
                        >
                        </LoadContainers-order_types_single>
                        <ng-container *ngIf="fields.order_type.invalid">
                          <ng-container *ngFor="let error of fields.order_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden && !fieldsets.newGroup2.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup2.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-carrier" *ngIf="!fields.carrier.hidden && !fields.carrier.removed" 
                            class="field-container standard {{fields.carrier.invalid ? 'invalid' : ''}} {{fields.carrier.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.carrier.styles.style"
                            [ngClass]="fields.carrier.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.carrier.label + (fields.carrier.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.carrier.label}}<span *ngIf="fields.carrier.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <LoadContainers-carriers_single 
                            data-cy="selector"
                            [type]="fields.carrier.control.type"
                            formControlName="carrier"
                            (displayTextChange)="fields.carrier.control.displayText=$event"
                            [placeholder]="fields.carrier.control.placeholder"
                            [styles]="fields.carrier.control.styles"
                            [tooltip]="fields.carrier.control.tooltip"
                        >
                        </LoadContainers-carriers_single>
                        <ng-container *ngIf="fields.carrier.invalid">
                          <ng-container *ngFor="let error of fields.carrier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier_service" *ngIf="!fields.carrier_service.hidden && !fields.carrier_service.removed" 
                            class="field-container standard {{fields.carrier_service.invalid ? 'invalid' : ''}} {{fields.carrier_service.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.carrier_service.styles.style"
                            [ngClass]="fields.carrier_service.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.carrier_service.label + (fields.carrier_service.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.carrier_service.label}}<span *ngIf="fields.carrier_service.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <LoadContainers-carrier_service_types_single 
                            data-cy="selector"
                            [type]="fields.carrier_service.control.type"
                            formControlName="carrier_service"
                            (displayTextChange)="fields.carrier_service.control.displayText=$event"
                            [placeholder]="fields.carrier_service.control.placeholder"
                            [styles]="fields.carrier_service.control.styles"
                            [tooltip]="fields.carrier_service.control.tooltip"
                          [carrierId]="$fields_carrier_service_selector_inParams_carrierId"
                        >
                        </LoadContainers-carrier_service_types_single>
                        <ng-container *ngIf="fields.carrier_service.invalid">
                          <ng-container *ngFor="let error of fields.carrier_service.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-seal_id" *ngIf="!fields.seal_id.hidden && !fields.seal_id.removed" 
                            class="field-container standard {{fields.seal_id.invalid ? 'invalid' : ''}} {{fields.seal_id.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.seal_id.styles.style"
                            [ngClass]="fields.seal_id.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.seal_id.label + (fields.seal_id.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.seal_id.label}}<span *ngIf="fields.seal_id.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="seal_id"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.seal_id.control.readOnly || fields.seal_id.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.seal_id.control.placeholder}}"
                                [ngStyle]="fields.seal_id.control.styles.style"
                                [ngClass]="fields.seal_id.control.styles.classes"
                                [matTooltip]="fields.seal_id.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.seal_id.invalid">
                          <ng-container *ngFor="let error of fields.seal_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-trailer_number" *ngIf="!fields.trailer_number.hidden && !fields.trailer_number.removed" 
                            class="field-container standard {{fields.trailer_number.invalid ? 'invalid' : ''}} {{fields.trailer_number.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.trailer_number.styles.style"
                            [ngClass]="fields.trailer_number.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.trailer_number.label + (fields.trailer_number.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.trailer_number.label}}<span *ngIf="fields.trailer_number.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="trailer_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.trailer_number.control.readOnly || fields.trailer_number.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.trailer_number.control.placeholder}}"
                                [ngStyle]="fields.trailer_number.control.styles.style"
                                [ngClass]="fields.trailer_number.control.styles.classes"
                                [matTooltip]="fields.trailer_number.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.trailer_number.invalid">
                          <ng-container *ngFor="let error of fields.trailer_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-priority" *ngIf="!fields.priority.hidden && !fields.priority.removed" 
                            class="field-container standard {{fields.priority.invalid ? 'invalid' : ''}} {{fields.priority.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.priority.styles.style"
                            [ngClass]="fields.priority.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.priority.label + (fields.priority.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.priority.label}}<span *ngIf="fields.priority.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <LoadContainers-shipment_priorities_single 
                            data-cy="selector"
                            [type]="fields.priority.control.type"
                            formControlName="priority"
                            (displayTextChange)="fields.priority.control.displayText=$event"
                            [placeholder]="fields.priority.control.placeholder"
                            [styles]="fields.priority.control.styles"
                            [tooltip]="fields.priority.control.tooltip"
                        >
                        </LoadContainers-shipment_priorities_single>
                        <ng-container *ngIf="fields.priority.invalid">
                          <ng-container *ngFor="let error of fields.priority.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden && !fields.notes.removed" 
                            class="field-container double {{fields.notes.invalid ? 'invalid' : ''}} {{fields.notes.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.notes.control.readOnly || fields.notes.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup3"
                     *ngIf="!fieldsets.newGroup3.hidden && !fieldsets.newGroup3.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup3.collapsible }">
                    <div *ngIf="!fieldsets.newGroup3.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup3.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup3.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup3.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup3.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup3.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup3.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-available_date" *ngIf="!fields.available_date.hidden && !fields.available_date.removed" 
                            class="field-container standard {{fields.available_date.invalid ? 'invalid' : ''}} {{fields.available_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.available_date.styles.style"
                            [ngClass]="fields.available_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.available_date.label + (fields.available_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.available_date.label}}<span *ngIf="fields.available_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="available_date"
                            [format]="fields.available_date.control.format"
                            [mode]="fields.available_date.control.mode"
                            [ngStyle]="fields.available_date.control.styles.style"
                            [ngClass]="fields.available_date.control.styles.classes"
                            [tooltip]="fields.available_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.available_date.invalid">
                          <ng-container *ngFor="let error of fields.available_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-callout_date" *ngIf="!fields.callout_date.hidden && !fields.callout_date.removed" 
                            class="field-container standard {{fields.callout_date.invalid ? 'invalid' : ''}} {{fields.callout_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.callout_date.styles.style"
                            [ngClass]="fields.callout_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.callout_date.label + (fields.callout_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.callout_date.label}}<span *ngIf="fields.callout_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="callout_date"
                            [format]="fields.callout_date.control.format"
                            [mode]="fields.callout_date.control.mode"
                            [ngStyle]="fields.callout_date.control.styles.style"
                            [ngClass]="fields.callout_date.control.styles.classes"
                            [tooltip]="fields.callout_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.callout_date.invalid">
                          <ng-container *ngFor="let error of fields.callout_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-checkout_date" *ngIf="!fields.checkout_date.hidden && !fields.checkout_date.removed" 
                            class="field-container standard {{fields.checkout_date.invalid ? 'invalid' : ''}} {{fields.checkout_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.checkout_date.styles.style"
                            [ngClass]="fields.checkout_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.checkout_date.label + (fields.checkout_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.checkout_date.label}}<span *ngIf="fields.checkout_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="checkout_date"
                            [format]="fields.checkout_date.control.format"
                            [mode]="fields.checkout_date.control.mode"
                            [ngStyle]="fields.checkout_date.control.styles.style"
                            [ngClass]="fields.checkout_date.control.styles.classes"
                            [tooltip]="fields.checkout_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.checkout_date.invalid">
                          <ng-container *ngFor="let error of fields.checkout_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-inyard_date" *ngIf="!fields.inyard_date.hidden && !fields.inyard_date.removed" 
                            class="field-container standard {{fields.inyard_date.invalid ? 'invalid' : ''}} {{fields.inyard_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.inyard_date.styles.style"
                            [ngClass]="fields.inyard_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.inyard_date.label + (fields.inyard_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.inyard_date.label}}<span *ngIf="fields.inyard_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="inyard_date"
                            [format]="fields.inyard_date.control.format"
                            [mode]="fields.inyard_date.control.mode"
                            [ngStyle]="fields.inyard_date.control.styles.style"
                            [ngClass]="fields.inyard_date.control.styles.classes"
                            [tooltip]="fields.inyard_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.inyard_date.invalid">
                          <ng-container *ngFor="let error of fields.inyard_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lastonsite_date" *ngIf="!fields.lastonsite_date.hidden && !fields.lastonsite_date.removed" 
                            class="field-container standard {{fields.lastonsite_date.invalid ? 'invalid' : ''}} {{fields.lastonsite_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lastonsite_date.styles.style"
                            [ngClass]="fields.lastonsite_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lastonsite_date.label + (fields.lastonsite_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lastonsite_date.label}}<span *ngIf="fields.lastonsite_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="lastonsite_date"
                            [format]="fields.lastonsite_date.control.format"
                            [mode]="fields.lastonsite_date.control.mode"
                            [ngStyle]="fields.lastonsite_date.control.styles.style"
                            [ngClass]="fields.lastonsite_date.control.styles.classes"
                            [tooltip]="fields.lastonsite_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.lastonsite_date.invalid">
                          <ng-container *ngFor="let error of fields.lastonsite_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lastpier_date" *ngIf="!fields.lastpier_date.hidden && !fields.lastpier_date.removed" 
                            class="field-container standard {{fields.lastpier_date.invalid ? 'invalid' : ''}} {{fields.lastpier_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lastpier_date.styles.style"
                            [ngClass]="fields.lastpier_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lastpier_date.label + (fields.lastpier_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lastpier_date.label}}<span *ngIf="fields.lastpier_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="lastpier_date"
                            [format]="fields.lastpier_date.control.format"
                            [mode]="fields.lastpier_date.control.mode"
                            [ngStyle]="fields.lastpier_date.control.styles.style"
                            [ngClass]="fields.lastpier_date.control.styles.classes"
                            [tooltip]="fields.lastpier_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.lastpier_date.invalid">
                          <ng-container *ngFor="let error of fields.lastpier_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-created_date" *ngIf="!fields.created_date.hidden && !fields.created_date.removed" 
                            class="field-container standard {{fields.created_date.invalid ? 'invalid' : ''}} {{fields.created_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.created_date.styles.style"
                            [ngClass]="fields.created_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.created_date.label + (fields.created_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.created_date.label}}<span *ngIf="fields.created_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="created_date"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.created_date.control.readOnly || fields.created_date.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.created_date.control.placeholder}}"
                                [ngStyle]="fields.created_date.control.styles.style"
                                [ngClass]="fields.created_date.control.styles.classes"
                                [matTooltip]="fields.created_date.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.created_date.invalid">
                          <ng-container *ngFor="let error of fields.created_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-modified_date" *ngIf="!fields.modified_date.hidden && !fields.modified_date.removed" 
                            class="field-container standard {{fields.modified_date.invalid ? 'invalid' : ''}} {{fields.modified_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.modified_date.styles.style"
                            [ngClass]="fields.modified_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.modified_date.label + (fields.modified_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.modified_date.label}}<span *ngIf="fields.modified_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="modified_date"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.modified_date.control.readOnly || fields.modified_date.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.modified_date.control.placeholder}}"
                                [ngStyle]="fields.modified_date.control.styles.style"
                                [ngClass]="fields.modified_date.control.styles.classes"
                                [matTooltip]="fields.modified_date.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.modified_date.invalid">
                          <ng-container *ngFor="let error of fields.modified_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.orders.hasTabToShow" class="tab" data-cy="tab-orders">
              <h2 [className]="tabs.orders.active? 'active': ''" (click)="tabs.orders.activate()">{{tabs.orders.title}}</h2>
            </div>
            <div *ngIf="tabs.temperatures.hasTabToShow" class="tab" data-cy="tab-temperatures">
              <h2 [className]="tabs.temperatures.active? 'active': ''" (click)="tabs.temperatures.activate()">{{tabs.temperatures.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-load_container_orders_grid *ngIf="tabs.orders.active"
              #$tabs_orders
              [loadContainerId]="$tabs_orders_load_container_orders_grid_inParams_loadContainerId"
              [edit]="$tabs_orders_load_container_orders_grid_inParams_edit"
              [warehouseId]="$tabs_orders_load_container_orders_grid_inParams_warehouseId"
              [orderTypeId]="$tabs_orders_load_container_orders_grid_inParams_orderTypeId"
              [status_id]="$tabs_orders_load_container_orders_grid_inParams_status_id"
              ($refreshEvent)="refresh(false, false, '$tabs_orders')">
              </FootPrintManager-load_container_orders_grid>
              <FootPrintManager-temperature_readings_grid *ngIf="tabs.temperatures.active"
              #$tabs_temperatures
              [loadContainerId]="$tabs_temperatures_temperature_readings_grid_inParams_loadContainerId"
              ($refreshEvent)="refresh(false, false, '$tabs_temperatures')">
              </FootPrintManager-temperature_readings_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>