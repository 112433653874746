import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootPrintManager_material_catalogs_by_project_gridComponent } from './FootPrintManager.material_catalogs_by_project_grid.component';
import { Owners_owner_project_statuses_dd_singleComponent } from './Owners.owner_project_statuses_dd_single.component'

type EntityType = { 
    Id?: number, IsDefault?: boolean, LookupCode?: string, Name?: string, Notes?: string, StatusId?: number}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_material_catalogs_by_project_gridComponent),
    forwardRef(() => Owners_owner_project_statuses_dd_singleComponent),
  ],
  selector: 'FootPrintManager-project_editor',
  templateUrl: './FootPrintManager.project_editor.component.html'
})
export class FootPrintManager_project_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { projectId: number } = { projectId: null };
  //#region Inputs
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    material_catalog_subscription: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false)
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Project code', false, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Name', false, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
    material_catalog_subscription: new FieldModel(new CheckBoxModel(this.formGroup.controls['material_catalog_subscription'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Subscribe to material catalogs', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      material_catalogs: new TabItemModel(
        this.rootTabGroup, 
        'Material catalogs', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_material_catalogs_material_catalogs_by_project_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.projectId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_material_catalogs', { read: FootPrintManager_material_catalogs_by_project_gridComponent }) $tabs_material_catalogs: FootPrintManager_material_catalogs_by_project_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    name: this.fields.name.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    material_catalog_subscription: this.fields.material_catalog_subscription.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.material_catalogs,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.projectId)) {
        this.$missingRequiredInParams.push('projectId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Project';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      projectId:  $editor.inParams.projectId 
    };

    const data = await this.datasources.Owners.ds_project_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.status.control as SelectBoxModel).reset($editor.entity.StatusId);
    (this.fields.name.control as TextBoxModel).reset($editor.entity.Name);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_material_catalogs') {
        if (!isNil(this.$tabs_material_catalogs) && !this.tabs.material_catalogs.hidden && !this.tabs.material_catalogs.removed) {
          this.$tabs_material_catalogs.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .material_catalog_subscription
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_catalog_subscription_changed();
      });
  }

  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_project_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  $editor.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: FootPrintManager_project_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.toolbar.confirm.control.readOnly = true;
  
  
  if ($editor.fields.material_catalog_subscription.control.value) {
  
      let versionCheck = await $flows.Utilities.check_footprint_version({ minimumVersion: '24.10.25' });
  
      if (versionCheck.meetsMinimumVersion) {
          // @ts-ignore
          const material_catalog = (await $datasources.Catalogs.ds_get_material_catalogs_by_project_id_top1.get({ project_id: $editor.inParams.projectId })).result;
          if (!$utils.isDefined(material_catalog)) {
              $editor.toolbar.confirm.control.readOnly = false;
              throw new Error('Please manually add one or more material catalogs first or uncheck the Subscribe to material catalogs button.');
  
          }
  
      }
      
  
  }
  
  
  // Update project
  let payload: any = {};
  
  
  if ($editor.fields.lookupcode.control.isChanged) {
  
      // Get ownerId
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({ projectId: $editor.inParams.projectId })).result;
      if ($utils.isDefined(project)) {
          var ownerId = project[0].OwnerId;
      } else {
          $editor.toolbar.confirm.control.readOnly = false;
          $shell.Owners.openErrorDialog('Project Edit Error', 'Unable to extract owner from the given project.');
          return;
      }
  
      // Check for project duplicates
      const projects = (await $datasources.Owners.ds_get_project_by_lookupcode_and_ownerId.get({
          lookupcode: $editor.fields.lookupcode.control.value,
          ownerId: ownerId
      })).result;
      if ($utils.isDefined(projects)) {
          $editor.toolbar.confirm.control.readOnly = false;
          $shell.Owners.openErrorDialog('Project Edit Error', 'Project lookupcode already exists.');
          return;
  
      } else {
          payload.LookupCode = $editor.fields.lookupcode.control.value;
      }
  }
  
  
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  
  if ($editor.fields.name.control.isChanged) {
      payload.Name = $editor.fields.name.control.value;
  }
  
  if ($editor.fields.status.control.isChanged) {
      payload.StatusId = $editor.fields.status.control.value;
  }
  
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Projects', id: $editor.entity.Id, entity: payload });
      $editor.outParams.confirm = true;
      $editor.close();
  }
  
  catch (error) {
      $editor.toolbar.confirm.control.readOnly = false;
      $shell.Utilities.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_project_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  
  
  let versionCheck = await $flows.Utilities.check_footprint_version({ minimumVersion: '24.10.25' });
  
  if (versionCheck.meetsMinimumVersion) {
  
      // @ts-ignore 
      const material_catalog = (await $datasources.Catalogs.ds_get_material_catalogs_by_project_id_top1.get({ project_id: $editor.inParams.projectId })).result;
      if ($utils.isDefined(material_catalog)) {
          $editor.fields.material_catalog_subscription.control.value = true;
          $editor.fields.material_catalog_subscription.control.readOnly = true;
          $editor.tabs.material_catalogs.hidden = false;
      }
  
      else {
          $editor.fields.material_catalog_subscription.control.value = false;
          $editor.tabs.material_catalogs.hidden = true;
      }
  
  }
  
  else {
  
      $editor.fields.material_catalog_subscription.hidden = true;
      $editor.tabs.material_catalogs.hidden = true;
  }
  }
  on_material_catalog_subscription_changed(event = null) {
    return this.on_material_catalog_subscription_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_catalog_subscription_changedInternal(
    $editor: FootPrintManager_project_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($editor.fields.material_catalog_subscription.control.value) {
  
      $editor.tabs.material_catalogs.hidden = false;
  }
  else {
  
      let versionCheck = await $flows.Utilities.check_footprint_version({ minimumVersion: '24.10.25' });
  
      if (versionCheck.meetsMinimumVersion) {
  
          // @ts-ignore 
          const material_catalog = (await $datasources.Catalogs.ds_get_material_catalogs_by_project_id_top1.get({ project_id: $editor.inParams.projectId })).result;
          if ($utils.isDefined(material_catalog)) {
  
              $editor.refresh();
              throw new Error('Please manually un-sunbscribe to each catalog that is currently associated to this project before removing this flag.');
  
          }
  
          else {
              $editor.tabs.material_catalogs.hidden = true;
          }
  
      }
      else {
          $editor.tabs.material_catalogs.hidden = true;
      }
  
  
  }
  }
  //#endregion private flows
  
}
