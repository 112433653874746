<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="process" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_process_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="complete" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_complete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="auto_pick" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_auto_pick_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="auto_load" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_auto_load_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator1" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="appointment" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_appointment_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator2" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="revert" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_revert_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="on_delete" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_delete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator3" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="attachments" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_attachments_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="print" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div *ngIf="!tool.control.buttons.load_slip.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.load_slip.readOnly || tool.control.buttons.load_slip.disabled"
                            [ngStyle]="tool.control.buttons.load_slip.styles.style"
                            [ngClass]="tool.control.buttons.load_slip.styles.classes"
                            [matTooltip]="tool.control.buttons.load_slip.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_load_slip($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.load_slip.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.load_slip.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.load_slip.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.load_diagram.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.load_diagram.readOnly || tool.control.buttons.load_diagram.disabled"
                            [ngStyle]="tool.control.buttons.load_diagram.styles.style"
                            [ngClass]="tool.control.buttons.load_diagram.styles.classes"
                            [matTooltip]="tool.control.buttons.load_diagram.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_load_diagram_print($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.load_diagram.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.load_diagram.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.load_diagram.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator4" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="options" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div *ngIf="!tool.control.buttons.email_requests.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.email_requests.readOnly || tool.control.buttons.email_requests.disabled"
                            [ngStyle]="tool.control.buttons.email_requests.styles.style"
                            [ngClass]="tool.control.buttons.email_requests.styles.classes"
                            [matTooltip]="tool.control.buttons.email_requests.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_email_requests_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.email_requests.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.email_requests.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.email_requests.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.discussions.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.discussions.readOnly || tool.control.buttons.discussions.disabled"
                            [ngStyle]="tool.control.buttons.discussions.styles.style"
                            [ngClass]="tool.control.buttons.discussions.styles.classes"
                            [matTooltip]="tool.control.buttons.discussions.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_discussions_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.discussions.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.discussions.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.discussions.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.driver_check_out.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.driver_check_out.readOnly || tool.control.buttons.driver_check_out.disabled"
                            [ngStyle]="tool.control.buttons.driver_check_out.styles.style"
                            [ngClass]="tool.control.buttons.driver_check_out.styles.classes"
                            [matTooltip]="tool.control.buttons.driver_check_out.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_driver_check_out_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.driver_check_out.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.driver_check_out.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.driver_check_out.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.pallet_transactions.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.pallet_transactions.readOnly || tool.control.buttons.pallet_transactions.disabled"
                            [ngStyle]="tool.control.buttons.pallet_transactions.styles.style"
                            [ngClass]="tool.control.buttons.pallet_transactions.styles.classes"
                            [matTooltip]="tool.control.buttons.pallet_transactions.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_pallet_transactions_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.pallet_transactions.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.pallet_transactions.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.pallet_transactions.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.surveys.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.surveys.readOnly || tool.control.buttons.surveys.disabled"
                            [ngStyle]="tool.control.buttons.surveys.styles.style"
                            [ngClass]="tool.control.buttons.surveys.styles.classes"
                            [matTooltip]="tool.control.buttons.surveys.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_surveys_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.surveys.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.surveys.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.surveys.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.temperature_readings.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.temperature_readings.readOnly || tool.control.buttons.temperature_readings.disabled"
                            [ngStyle]="tool.control.buttons.temperature_readings.styles.style"
                            [ngClass]="tool.control.buttons.temperature_readings.styles.classes"
                            [matTooltip]="tool.control.buttons.temperature_readings.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_temperature_readings($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.temperature_readings.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.temperature_readings.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.temperature_readings.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.copy_order.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.copy_order.readOnly || tool.control.buttons.copy_order.disabled"
                            [ngStyle]="tool.control.buttons.copy_order.styles.style"
                            [ngClass]="tool.control.buttons.copy_order.styles.classes"
                            [matTooltip]="tool.control.buttons.copy_order.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_copy_order_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.copy_order.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.copy_order.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.copy_order.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator5" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden && !fields.lookupcode.removed" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}} {{fields.lookupcode.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lookupcode.label + (fields.lookupcode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.lookupcode.control.readOnly || fields.lookupcode.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"
                                [matTooltip]="fields.lookupcode.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_class" *ngIf="!fields.order_class.hidden && !fields.order_class.removed" 
                            class="field-container standard {{fields.order_class.invalid ? 'invalid' : ''}} {{fields.order_class.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.order_class.styles.style"
                            [ngClass]="fields.order_class.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order_class.label + (fields.order_class.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order_class.label}}<span *ngIf="fields.order_class.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <SalesOrders-orderclasses_dd_single 
                            data-cy="selector"
                            [type]="fields.order_class.control.type"
                            formControlName="order_class"
                            (displayTextChange)="fields.order_class.control.displayText=$event"
                            [placeholder]="fields.order_class.control.placeholder"
                            [styles]="fields.order_class.control.styles"
                            [tooltip]="fields.order_class.control.tooltip"
                        >
                        </SalesOrders-orderclasses_dd_single>
                        <ng-container *ngIf="fields.order_class.invalid">
                          <ng-container *ngFor="let error of fields.order_class.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden && !fields.owner.removed" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}} {{fields.owner.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.owner.label + (fields.owner.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.owner.label}}<span *ngIf="fields.owner.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <ExcelMaterialImport-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                            [tooltip]="fields.owner.control.tooltip"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </ExcelMaterialImport-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden && !fields.project.removed" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}} {{fields.project.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <ExcelMaterialImport-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                            [tooltip]="fields.project.control.tooltip"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </ExcelMaterialImport-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden && !fields.warehouse.removed" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}} {{fields.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                            [tooltip]="fields.warehouse.control.tooltip"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_status" *ngIf="!fields.order_status.hidden && !fields.order_status.removed" 
                            class="field-container standard {{fields.order_status.invalid ? 'invalid' : ''}} {{fields.order_status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.order_status.styles.style"
                            [ngClass]="fields.order_status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order_status.label + (fields.order_status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order_status.label}}<span *ngIf="fields.order_status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.order_status.control.styles.style"
                              [ngClass]="fields.order_status.control.styles.classes"
                              [matTooltip]="fields.order_status.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.order_status.control.text }}</div>
                        <ng-container *ngIf="fields.order_status.invalid">
                          <ng-container *ngFor="let error of fields.order_status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_state" *ngIf="!fields.order_state.hidden && !fields.order_state.removed" 
                            class="field-container standard {{fields.order_state.invalid ? 'invalid' : ''}} {{fields.order_state.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.order_state.styles.style"
                            [ngClass]="fields.order_state.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order_state.label + (fields.order_state.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order_state.label}}<span *ngIf="fields.order_state.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.order_state.control.styles.style"
                              [ngClass]="fields.order_state.control.styles.classes"
                              [matTooltip]="fields.order_state.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.order_state.control.text }}</div>
                        <ng-container *ngIf="fields.order_state.invalid">
                          <ng-container *ngFor="let error of fields.order_state.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-created_by" *ngIf="!fields.created_by.hidden && !fields.created_by.removed" 
                            class="field-container standard {{fields.created_by.invalid ? 'invalid' : ''}} {{fields.created_by.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.created_by.styles.style"
                            [ngClass]="fields.created_by.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.created_by.label + (fields.created_by.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.created_by.label}}<span *ngIf="fields.created_by.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.created_by.control.styles.style"
                              [ngClass]="fields.created_by.control.styles.classes"
                              [matTooltip]="fields.created_by.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.created_by.control.text }}</div>
                        <ng-container *ngIf="fields.created_by.invalid">
                          <ng-container *ngFor="let error of fields.created_by.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-integration_information" *ngIf="!fields.integration_information.hidden && !fields.integration_information.removed" 
                            class="field-container full {{fields.integration_information.invalid ? 'invalid' : ''}} {{fields.integration_information.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.integration_information.styles.style"
                            [ngClass]="fields.integration_information.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.integration_information.label + (fields.integration_information.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.integration_information.label}}<span *ngIf="fields.integration_information.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.integration_information.control.readOnly || fields.integration_information.control.disabled"
                                [ngStyle]="fields.integration_information.control.styles.style"
                                [ngClass]="fields.integration_information.control.styles.classes"
                                [matTooltip]="fields.integration_information.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_integration_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.integration_information.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.integration_information.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.integration_information.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.integration_information.invalid">
                          <ng-container *ngFor="let error of fields.integration_information.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden && !fieldsets.newGroup2.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup2.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-ship_to_selection" *ngIf="!fields.ship_to_selection.hidden && !fields.ship_to_selection.removed" 
                            class="field-container standard {{fields.ship_to_selection.invalid ? 'invalid' : ''}} {{fields.ship_to_selection.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.ship_to_selection.styles.style"
                            [ngClass]="fields.ship_to_selection.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.ship_to_selection.label + (fields.ship_to_selection.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.ship_to_selection.label}}<span *ngIf="fields.ship_to_selection.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.ship_to_selection.control.readOnly || fields.ship_to_selection.control.disabled"
                                [ngStyle]="fields.ship_to_selection.control.styles.style"
                                [ngClass]="fields.ship_to_selection.control.styles.classes"
                                [matTooltip]="fields.ship_to_selection.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_ship_to_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.ship_to_selection.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.ship_to_selection.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.ship_to_selection.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.ship_to_selection.invalid">
                          <ng-container *ngFor="let error of fields.ship_to_selection.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_to_clear" *ngIf="!fields.ship_to_clear.hidden && !fields.ship_to_clear.removed" 
                            class="field-container standard {{fields.ship_to_clear.invalid ? 'invalid' : ''}} {{fields.ship_to_clear.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.ship_to_clear.styles.style"
                            [ngClass]="fields.ship_to_clear.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.ship_to_clear.label + (fields.ship_to_clear.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.ship_to_clear.label}}<span *ngIf="fields.ship_to_clear.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.ship_to_clear.control.readOnly || fields.ship_to_clear.control.disabled"
                                [ngStyle]="fields.ship_to_clear.control.styles.style"
                                [ngClass]="fields.ship_to_clear.control.styles.classes"
                                [matTooltip]="fields.ship_to_clear.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_ship_to_cleared($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.ship_to_clear.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.ship_to_clear.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.ship_to_clear.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.ship_to_clear.invalid">
                          <ng-container *ngFor="let error of fields.ship_to_clear.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_to" *ngIf="!fields.ship_to.hidden && !fields.ship_to.removed" 
                            class="field-container double {{fields.ship_to.invalid ? 'invalid' : ''}} {{fields.ship_to.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.ship_to.styles.style"
                            [ngClass]="fields.ship_to.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.ship_to.label + (fields.ship_to.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.ship_to.label}}<span *ngIf="fields.ship_to.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.ship_to.control.styles.style"
                              [ngClass]="fields.ship_to.control.styles.classes"
                              [matTooltip]="fields.ship_to.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.ship_to.control.text }}</div>
                        <ng-container *ngIf="fields.ship_to.invalid">
                          <ng-container *ngFor="let error of fields.ship_to.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_reference" *ngIf="!fields.vendor_reference.hidden && !fields.vendor_reference.removed" 
                            class="field-container standard {{fields.vendor_reference.invalid ? 'invalid' : ''}} {{fields.vendor_reference.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.vendor_reference.styles.style"
                            [ngClass]="fields.vendor_reference.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vendor_reference.label + (fields.vendor_reference.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vendor_reference.label}}<span *ngIf="fields.vendor_reference.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="vendor_reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.vendor_reference.control.readOnly || fields.vendor_reference.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.vendor_reference.control.placeholder}}"
                                [ngStyle]="fields.vendor_reference.control.styles.style"
                                [ngClass]="fields.vendor_reference.control.styles.classes"
                                [matTooltip]="fields.vendor_reference.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.vendor_reference.invalid">
                          <ng-container *ngFor="let error of fields.vendor_reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expected_date" *ngIf="!fields.expected_date.hidden && !fields.expected_date.removed" 
                            class="field-container standard {{fields.expected_date.invalid ? 'invalid' : ''}} {{fields.expected_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.expected_date.styles.style"
                            [ngClass]="fields.expected_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expected_date.label + (fields.expected_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expected_date.label}}<span *ngIf="fields.expected_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="expected_date"
                            [format]="fields.expected_date.control.format"
                            [mode]="fields.expected_date.control.mode"
                            [ngStyle]="fields.expected_date.control.styles.style"
                            [ngClass]="fields.expected_date.control.styles.classes"
                            [tooltip]="fields.expected_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.expected_date.invalid">
                          <ng-container *ngFor="let error of fields.expected_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier" *ngIf="!fields.carrier.hidden && !fields.carrier.removed" 
                            class="field-container standard {{fields.carrier.invalid ? 'invalid' : ''}} {{fields.carrier.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.carrier.styles.style"
                            [ngClass]="fields.carrier.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.carrier.label + (fields.carrier.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.carrier.label}}<span *ngIf="fields.carrier.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Carriers-carriers_ownerscarriers_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier.control.type"
                            formControlName="carrier"
                            (displayTextChange)="fields.carrier.control.displayText=$event"
                            [placeholder]="fields.carrier.control.placeholder"
                            [styles]="fields.carrier.control.styles"
                            [tooltip]="fields.carrier.control.tooltip"
                          [ownerId]="$fields_carrier_selector_inParams_ownerId"
                        >
                        </Carriers-carriers_ownerscarriers_dd_single>
                        <ng-container *ngIf="fields.carrier.invalid">
                          <ng-container *ngFor="let error of fields.carrier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier_service" *ngIf="!fields.carrier_service.hidden && !fields.carrier_service.removed" 
                            class="field-container standard {{fields.carrier_service.invalid ? 'invalid' : ''}} {{fields.carrier_service.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.carrier_service.styles.style"
                            [ngClass]="fields.carrier_service.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.carrier_service.label + (fields.carrier_service.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.carrier_service.label}}<span *ngIf="fields.carrier_service.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Carriers-carrierservicetype_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier_service.control.type"
                            formControlName="carrier_service"
                            (displayTextChange)="fields.carrier_service.control.displayText=$event"
                            [placeholder]="fields.carrier_service.control.placeholder"
                            [styles]="fields.carrier_service.control.styles"
                            [tooltip]="fields.carrier_service.control.tooltip"
                          [carrierId]="$fields_carrier_service_selector_inParams_carrierId"
                        >
                        </Carriers-carrierservicetype_dd_single>
                        <ng-container *ngIf="fields.carrier_service.invalid">
                          <ng-container *ngFor="let error of fields.carrier_service.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reference" *ngIf="!fields.reference.hidden && !fields.reference.removed" 
                            class="field-container standard {{fields.reference.invalid ? 'invalid' : ''}} {{fields.reference.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.reference.styles.style"
                            [ngClass]="fields.reference.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.reference.label + (fields.reference.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.reference.label}}<span *ngIf="fields.reference.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.reference.control.readOnly || fields.reference.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.reference.control.placeholder}}"
                                [ngStyle]="fields.reference.control.styles.style"
                                [ngClass]="fields.reference.control.styles.classes"
                                [matTooltip]="fields.reference.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.reference.invalid">
                          <ng-container *ngFor="let error of fields.reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-container_identifier" *ngIf="!fields.container_identifier.hidden && !fields.container_identifier.removed" 
                            class="field-container standard {{fields.container_identifier.invalid ? 'invalid' : ''}} {{fields.container_identifier.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.container_identifier.styles.style"
                            [ngClass]="fields.container_identifier.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.container_identifier.label + (fields.container_identifier.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.container_identifier.label}}<span *ngIf="fields.container_identifier.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="container_identifier"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.container_identifier.control.readOnly || fields.container_identifier.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.container_identifier.control.placeholder}}"
                                [ngStyle]="fields.container_identifier.control.styles.style"
                                [ngClass]="fields.container_identifier.control.styles.classes"
                                [matTooltip]="fields.container_identifier.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.container_identifier.invalid">
                          <ng-container *ngFor="let error of fields.container_identifier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-bill_of_lading" *ngIf="!fields.bill_of_lading.hidden && !fields.bill_of_lading.removed" 
                            class="field-container standard {{fields.bill_of_lading.invalid ? 'invalid' : ''}} {{fields.bill_of_lading.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.bill_of_lading.styles.style"
                            [ngClass]="fields.bill_of_lading.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.bill_of_lading.label + (fields.bill_of_lading.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.bill_of_lading.label}}<span *ngIf="fields.bill_of_lading.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="bill_of_lading"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.bill_of_lading.control.readOnly || fields.bill_of_lading.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.bill_of_lading.control.placeholder}}"
                                [ngStyle]="fields.bill_of_lading.control.styles.style"
                                [ngClass]="fields.bill_of_lading.control.styles.classes"
                                [matTooltip]="fields.bill_of_lading.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.bill_of_lading.invalid">
                          <ng-container *ngFor="let error of fields.bill_of_lading.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-trailer" *ngIf="!fields.trailer.hidden && !fields.trailer.removed" 
                            class="field-container standard {{fields.trailer.invalid ? 'invalid' : ''}} {{fields.trailer.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.trailer.styles.style"
                            [ngClass]="fields.trailer.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.trailer.label + (fields.trailer.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.trailer.label}}<span *ngIf="fields.trailer.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="trailer"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.trailer.control.readOnly || fields.trailer.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.trailer.control.placeholder}}"
                                [ngStyle]="fields.trailer.control.styles.style"
                                [ngClass]="fields.trailer.control.styles.classes"
                                [matTooltip]="fields.trailer.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.trailer.invalid">
                          <ng-container *ngFor="let error of fields.trailer.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-seal" *ngIf="!fields.seal.hidden && !fields.seal.removed" 
                            class="field-container standard {{fields.seal.invalid ? 'invalid' : ''}} {{fields.seal.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.seal.styles.style"
                            [ngClass]="fields.seal.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.seal.label + (fields.seal.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.seal.label}}<span *ngIf="fields.seal.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="seal"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.seal.control.readOnly || fields.seal.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.seal.control.placeholder}}"
                                [ngStyle]="fields.seal.control.styles.style"
                                [ngClass]="fields.seal.control.styles.classes"
                                [matTooltip]="fields.seal.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.seal.invalid">
                          <ng-container *ngFor="let error of fields.seal.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-tracking" *ngIf="!fields.tracking.hidden && !fields.tracking.removed" 
                            class="field-container standard {{fields.tracking.invalid ? 'invalid' : ''}} {{fields.tracking.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.tracking.styles.style"
                            [ngClass]="fields.tracking.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.tracking.label + (fields.tracking.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.tracking.label}}<span *ngIf="fields.tracking.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="tracking"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.tracking.control.readOnly || fields.tracking.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.tracking.control.placeholder}}"
                                [ngStyle]="fields.tracking.control.styles.style"
                                [ngClass]="fields.tracking.control.styles.classes"
                                [matTooltip]="fields.tracking.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.tracking.invalid">
                          <ng-container *ngFor="let error of fields.tracking.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden && !fields.notes.removed" 
                            class="field-container double {{fields.notes.invalid ? 'invalid' : ''}} {{fields.notes.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.notes.control.readOnly || fields.notes.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-custom_fields" *ngIf="!fields.custom_fields.hidden && !fields.custom_fields.removed" 
                            class="field-container standard {{fields.custom_fields.invalid ? 'invalid' : ''}} {{fields.custom_fields.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.custom_fields.styles.style"
                            [ngClass]="fields.custom_fields.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.custom_fields.label + (fields.custom_fields.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.custom_fields.label}}<span *ngIf="fields.custom_fields.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.custom_fields.control.readOnly || fields.custom_fields.control.disabled"
                                [ngStyle]="fields.custom_fields.control.styles.style"
                                [ngClass]="fields.custom_fields.control.styles.classes"
                                [matTooltip]="fields.custom_fields.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_custom_fields_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.custom_fields.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.custom_fields.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.custom_fields.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.custom_fields.invalid">
                          <ng-container *ngFor="let error of fields.custom_fields.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="widgets">
          <div *ngIf="!widgets.order_total_units_widget.hidden && !widgets.order_total_units_widget.removed" class="widget-container">
            <app-custom_order_total_units_widget
            #$widgets_order_total_units_widget 
              [orderId]="$widgets_order_total_units_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_total_units_widget')"
            >
            </app-custom_order_total_units_widget>
          </div>
          <div *ngIf="!widgets.order_total_picked_units_widget.hidden && !widgets.order_total_picked_units_widget.removed" class="widget-container">
            <SalesOrders-order_total_picked_units_widget
            #$widgets_order_total_picked_units_widget 
              [orderId]="$widgets_order_total_picked_units_widget_inParams_orderId"
              [shipmentId]="$widgets_order_total_picked_units_widget_inParams_shipmentId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_total_picked_units_widget')"
            >
            </SalesOrders-order_total_picked_units_widget>
          </div>
          <div *ngIf="!widgets.order_total_picked_gross_widget.hidden && !widgets.order_total_picked_gross_widget.removed" class="widget-container">
            <app-custom_order_total_picked_gross_widget
            #$widgets_order_total_picked_gross_widget 
              [orderId]="$widgets_order_total_picked_gross_widget_inParams_orderId"
              [shipmentId]="$widgets_order_total_picked_gross_widget_inParams_shipmentId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_total_picked_gross_widget')"
            >
            </app-custom_order_total_picked_gross_widget>
          </div>
          <div *ngIf="!widgets.widget4.hidden && !widgets.widget4.removed" class="widget-container">
            <app-custom_order_total_picked_net_widget
            #$widgets_widget4 
              [orderId]="$widgets_widget4_inParams_orderId"
              [shipmentId]="$widgets_widget4_inParams_shipmentId"
            ($refreshEvent)="refresh(false, false, '$widgets_widget4')"
            >
            </app-custom_order_total_picked_net_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.sales_order_lines.hasTabToShow" class="tab" data-cy="tab-sales_order_lines">
              <h2 [className]="tabs.sales_order_lines.active? 'active': ''" (click)="tabs.sales_order_lines.activate()">{{tabs.sales_order_lines.title}}</h2>
            </div>
            <div *ngIf="tabs.picking_tasks.hasTabToShow" class="tab" data-cy="tab-picking_tasks">
              <h2 [className]="tabs.picking_tasks.active? 'active': ''" (click)="tabs.picking_tasks.activate()">{{tabs.picking_tasks.title}}</h2>
            </div>
            <div *ngIf="tabs.manual_allocation_tasks.hasTabToShow" class="tab" data-cy="tab-manual_allocation_tasks">
              <h2 [className]="tabs.manual_allocation_tasks.active? 'active': ''" (click)="tabs.manual_allocation_tasks.activate()">{{tabs.manual_allocation_tasks.title}}</h2>
            </div>
            <div *ngIf="tabs.shipping_contents.hasTabToShow" class="tab" data-cy="tab-shipping_contents">
              <h2 [className]="tabs.shipping_contents.active? 'active': ''" (click)="tabs.shipping_contents.activate()">{{tabs.shipping_contents.title}}</h2>
            </div>
            <div *ngIf="tabs.reports.hasTabToShow" class="tab" data-cy="tab-reports">
              <h2 [className]="tabs.reports.active? 'active': ''" (click)="tabs.reports.activate()">{{tabs.reports.title}}</h2>
            </div>
            <div *ngIf="tabs.billing.hasTabToShow" class="tab" data-cy="tab-billing">
              <h2 [className]="tabs.billing.active? 'active': ''" (click)="tabs.billing.activate()">{{tabs.billing.title}}</h2>
            </div>
            <div *ngIf="tabs.contacts.hasTabToShow" class="tab" data-cy="tab-contacts">
              <h2 [className]="tabs.contacts.active? 'active': ''" (click)="tabs.contacts.activate()">{{tabs.contacts.title}}</h2>
            </div>
            <div *ngIf="tabs.instructions.hasTabToShow" class="tab" data-cy="tab-instructions">
              <h2 [className]="tabs.instructions.active? 'active': ''" (click)="tabs.instructions.activate()">{{tabs.instructions.title}}</h2>
            </div>
            <div *ngIf="tabs.tasks.hasTabToShow" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
            <div *ngIf="tabs.annotation.hasTabToShow" class="tab" data-cy="tab-annotation">
              <h2 [className]="tabs.annotation.active? 'active': ''" (click)="tabs.annotation.activate()">{{tabs.annotation.title}}</h2>
            </div>
          </div>
        
              <app-custom_sales_order_lines_grid *ngIf="tabs.sales_order_lines.active"
              #$tabs_sales_order_lines
              [orderId]="$tabs_sales_order_lines_custom_sales_order_lines_grid_inParams_orderId"
              [shipmentId]="$tabs_sales_order_lines_custom_sales_order_lines_grid_inParams_shipmentId"
              [projectId]="$tabs_sales_order_lines_custom_sales_order_lines_grid_inParams_projectId"
              [orderStatusId]="$tabs_sales_order_lines_custom_sales_order_lines_grid_inParams_orderStatusId"
              [warehouseId]="$tabs_sales_order_lines_custom_sales_order_lines_grid_inParams_warehouseId"
              [orderState]="$tabs_sales_order_lines_custom_sales_order_lines_grid_inParams_orderState"
              ($refreshEvent)="refresh(false, false, '$tabs_sales_order_lines')">
              </app-custom_sales_order_lines_grid>
              <FootPrintManager-sales_order_picking_grid *ngIf="tabs.picking_tasks.active"
              #$tabs_picking_tasks
              [orderId]="$tabs_picking_tasks_sales_order_picking_grid_inParams_orderId"
              [shipmentId]="$tabs_picking_tasks_sales_order_picking_grid_inParams_shipmentId"
              [orderStatusId]="$tabs_picking_tasks_sales_order_picking_grid_inParams_orderStatusId"
              ($refreshEvent)="refresh(false, false, '$tabs_picking_tasks')">
              </FootPrintManager-sales_order_picking_grid>
              <FootPrintManager-manual_allocations_grid *ngIf="tabs.manual_allocation_tasks.active"
              #$tabs_manual_allocation_tasks
              [orderId]="$tabs_manual_allocation_tasks_manual_allocations_grid_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_manual_allocation_tasks')">
              </FootPrintManager-manual_allocations_grid>
              <FootPrintManager-shippingcontents_grid *ngIf="tabs.shipping_contents.active"
              #$tabs_shipping_contents
              [orderId]="$tabs_shipping_contents_shippingcontents_grid_inParams_orderId"
              [shipmentId]="$tabs_shipping_contents_shippingcontents_grid_inParams_shipmentId"
              ($refreshEvent)="refresh(false, false, '$tabs_shipping_contents')">
              </FootPrintManager-shippingcontents_grid>
              <ng-container
                *ngIf="tabs.reports.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.reports.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.reports.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.reports.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <SalesOrders-outbound_bill_of_lading_report
                   *ngIf="tabs.reports.tabs.bol_report.active"
                  #$tabs_reports_bol_report
                  [orderId]="$tabs_reports_bol_report_outbound_bill_of_lading_report_inParams_orderId"
                  ($refreshEvent)="refresh(false, false, '$tabs_reports_bol_report')">
                  </SalesOrders-outbound_bill_of_lading_report>
                  <SalesOrders-pick_slip_by_orderId_report
                   *ngIf="tabs.reports.tabs.pick_slip_report.active"
                  #$tabs_reports_pick_slip_report
                  [orderId]="$tabs_reports_pick_slip_report_pick_slip_by_orderId_report_inParams_orderId"
                  ($refreshEvent)="refresh(false, false, '$tabs_reports_pick_slip_report')">
                  </SalesOrders-pick_slip_by_orderId_report>
              </ng-container>
              <ng-container
                *ngIf="tabs.billing.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.billing.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.billing.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.billing.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootPrintManager-accessorial_tasks_grid
                   *ngIf="tabs.billing.tabs.accessorial_tasks.active"
                  #$tabs_billing_accessorial_tasks
                  [projectId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId"
                  [entityStatusId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId"
                  [orderId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId"
                  [warehouseId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId"
                  [shipmentId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_shipmentId"
                  ($refreshEvent)="refresh(false, false, '$tabs_billing_accessorial_tasks')">
                  </FootPrintManager-accessorial_tasks_grid>
                  <FootPrintManager-billing_records_grid
                   *ngIf="tabs.billing.tabs.billing_records.active"
                  #$tabs_billing_billing_records
                  [orderIds]="$tabs_billing_billing_records_billing_records_grid_inParams_orderIds"
                  [includeInvoiced]="$tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced"
                  [shipmentIds]="$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_billing_billing_records')">
                  </FootPrintManager-billing_records_grid>
              </ng-container>
              <FootPrintManager-orderaddresses_grid *ngIf="tabs.contacts.active"
              #$tabs_contacts
              [orderId]="$tabs_contacts_orderaddresses_grid_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_contacts')">
              </FootPrintManager-orderaddresses_grid>
              <FootPrintManager-instructions_grid *ngIf="tabs.instructions.active"
              #$tabs_instructions
              [entityName]="$tabs_instructions_instructions_grid_inParams_entityName"
              [enabled]="$tabs_instructions_instructions_grid_inParams_enabled"
              [entityKey1]="$tabs_instructions_instructions_grid_inParams_entityKey1"
              ($refreshEvent)="refresh(false, false, '$tabs_instructions')">
              </FootPrintManager-instructions_grid>
              <FootPrintManager-sales_order_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [orderId]="$tabs_tasks_sales_order_tasks_grid_inParams_orderId"
              [shipmentId]="$tabs_tasks_sales_order_tasks_grid_inParams_shipmentId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-sales_order_tasks_grid>
              <EndeavorLabs-annotations_grid *ngIf="tabs.annotation.active"
              #$tabs_annotation
              [OrderId]="$tabs_annotation_annotations_grid_inParams_OrderId"
              ($refreshEvent)="refresh(false, false, '$tabs_annotation')">
              </EndeavorLabs-annotations_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>