<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm_button" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel_button" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-equipmentTypeName" *ngIf="!fields.equipmentTypeName.hidden && !fields.equipmentTypeName.removed" 
                            class="field-container standard {{fields.equipmentTypeName.invalid ? 'invalid' : ''}} {{fields.equipmentTypeName.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.equipmentTypeName.styles.style"
                            [ngClass]="fields.equipmentTypeName.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.equipmentTypeName.label + (fields.equipmentTypeName.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.equipmentTypeName.label}}<span *ngIf="fields.equipmentTypeName.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="equipmentTypeName"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.equipmentTypeName.control.readOnly || fields.equipmentTypeName.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.equipmentTypeName.control.placeholder}}"
                                [ngStyle]="fields.equipmentTypeName.control.styles.style"
                                [ngClass]="fields.equipmentTypeName.control.styles.classes"
                                [matTooltip]="fields.equipmentTypeName.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.equipmentTypeName.invalid">
                          <ng-container *ngFor="let error of fields.equipmentTypeName.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-capabilities"
                     *ngIf="!fieldsets.capabilities.hidden && !fieldsets.capabilities.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.capabilities.collapsible }">
                    <div *ngIf="!fieldsets.capabilities.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.capabilities.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.capabilities.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.capabilities.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.capabilities.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.capabilities.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.capabilities.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-canhandleFullLPTasks" *ngIf="!fields.canhandleFullLPTasks.hidden && !fields.canhandleFullLPTasks.removed" 
                            class="field-container standard {{fields.canhandleFullLPTasks.invalid ? 'invalid' : ''}} {{fields.canhandleFullLPTasks.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.canhandleFullLPTasks.styles.style"
                            [ngClass]="fields.canhandleFullLPTasks.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.canhandleFullLPTasks.label + (fields.canhandleFullLPTasks.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.canhandleFullLPTasks.label}}<span *ngIf="fields.canhandleFullLPTasks.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="canhandleFullLPTasks"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.canhandleFullLPTasks.control.styles.style"
                                      [ngClass]="fields.canhandleFullLPTasks.control.styles.classes"
                                      [matTooltip]="fields.canhandleFullLPTasks.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.canhandleFullLPTasks.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.canhandleFullLPTasks.invalid">
                          <ng-container *ngFor="let error of fields.canhandleFullLPTasks.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-mobileOptions"
                     *ngIf="!fieldsets.mobileOptions.hidden && !fieldsets.mobileOptions.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.mobileOptions.collapsible }">
                    <div *ngIf="!fieldsets.mobileOptions.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.mobileOptions.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.mobileOptions.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.mobileOptions.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.mobileOptions.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.mobileOptions.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.mobileOptions.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-launchPickDropAfterPick" *ngIf="!fields.launchPickDropAfterPick.hidden && !fields.launchPickDropAfterPick.removed" 
                            class="field-container standard {{fields.launchPickDropAfterPick.invalid ? 'invalid' : ''}} {{fields.launchPickDropAfterPick.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.launchPickDropAfterPick.styles.style"
                            [ngClass]="fields.launchPickDropAfterPick.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.launchPickDropAfterPick.label + (fields.launchPickDropAfterPick.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.launchPickDropAfterPick.label}}<span *ngIf="fields.launchPickDropAfterPick.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="launchPickDropAfterPick"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.launchPickDropAfterPick.control.styles.style"
                                      [ngClass]="fields.launchPickDropAfterPick.control.styles.classes"
                                      [matTooltip]="fields.launchPickDropAfterPick.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.launchPickDropAfterPick.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.launchPickDropAfterPick.invalid">
                          <ng-container *ngFor="let error of fields.launchPickDropAfterPick.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-disableAutoConfirmOnTargetLP" *ngIf="!fields.disableAutoConfirmOnTargetLP.hidden && !fields.disableAutoConfirmOnTargetLP.removed" 
                            class="field-container standard {{fields.disableAutoConfirmOnTargetLP.invalid ? 'invalid' : ''}} {{fields.disableAutoConfirmOnTargetLP.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.disableAutoConfirmOnTargetLP.styles.style"
                            [ngClass]="fields.disableAutoConfirmOnTargetLP.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.disableAutoConfirmOnTargetLP.label + (fields.disableAutoConfirmOnTargetLP.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.disableAutoConfirmOnTargetLP.label}}<span *ngIf="fields.disableAutoConfirmOnTargetLP.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="disableAutoConfirmOnTargetLP"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.disableAutoConfirmOnTargetLP.control.styles.style"
                                      [ngClass]="fields.disableAutoConfirmOnTargetLP.control.styles.classes"
                                      [matTooltip]="fields.disableAutoConfirmOnTargetLP.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.disableAutoConfirmOnTargetLP.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.disableAutoConfirmOnTargetLP.invalid">
                          <ng-container *ngFor="let error of fields.disableAutoConfirmOnTargetLP.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>