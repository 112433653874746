<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-fields"
                     *ngIf="!filtersets.fields.hidden && !filtersets.fields.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.fields.collapsible }">
                    <div *ngIf="!filtersets.fields.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.fields.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.fields.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.fields.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.fields.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.fields.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.fields.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-shipping_container_id" *ngIf="!filters.shipping_container_id.hidden && !filters.shipping_container_id.removed" 
                                  class="field-container standard {{filters.shipping_container_id.invalid ? 'invalid' : ''}} {{filters.shipping_container_id.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.shipping_container_id.styles.style"
                                  [ngClass]="filters.shipping_container_id.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.shipping_container_id.label + (filters.shipping_container_id.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.shipping_container_id.label}}<span *ngIf="filters.shipping_container_id.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="numberBox" formControlName="shipping_container_id"
                                      matInput
                                      numberBox
                                      [format]="filters.shipping_container_id.control.format"
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-numberbox {{ (filters.shipping_container_id.control.readOnly || filters.shipping_container_id.control.disabled) ? 'readonly ' : ''}}"
                                      placeholder="{{filters.shipping_container_id.control.placeholder}}"
                                      [ngStyle]="filters.shipping_container_id.control.styles.style"
                                      [ngClass]="filters.shipping_container_id.control.styles.classes"
                                      [matTooltip]="filters.shipping_container_id.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">
                              <ng-container *ngIf="filters.shipping_container_id.invalid">
                                <ng-container *ngFor="let error of filters.shipping_container_id.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-test" *ngIf="!filters.test.hidden && !filters.test.removed" 
                                  class="field-container standard {{filters.test.invalid ? 'invalid' : ''}} {{filters.test.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.test.styles.style"
                                  [ngClass]="filters.test.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.test.label + (filters.test.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.test.label}}<span *ngIf="filters.test.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="test"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{ (filters.test.control.readOnly || filters.test.control.disabled) ? 'readonly ' : ''}}"
                                      placeholder="{{filters.test.control.placeholder}}"
                                      [ngStyle]="filters.test.control.styles.style"
                                      [ngClass]="filters.test.control.styles.classes"
                                      [matTooltip]="filters.test.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.test.invalid">
                                <ng-container *ngFor="let error of filters.test.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.preview_packing_list.hidden && !widgets.preview_packing_list.removed" class="widget-container">
            <PackVerification-packing_list_preview
            #$widgets_preview_packing_list 
              [shippingContainerId]="$widgets_preview_packing_list_inParams_shippingContainerId"
            ($refreshEvent)="refresh(false, false, '$widgets_preview_packing_list')"
            >
            </PackVerification-packing_list_preview>
          </div>
        </div>


      </ng-container>
    </div>
  </div>
</div>