import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootPrintManager_material_aliases_gridComponentEntity {
  MaterialId?: number, AccountId?: number, MaterialAliasDescription?: string, MaterialAliasLookupCode?: string, MaterialAliasName?: string, SalesOrderDiscount?: number, Account?: { LookupCode?: string, Name?: string }}

interface IFootPrintManager_material_aliases_gridComponentInParams {
  material_id: number, project_id: number, materialCode?: string}


class FootPrintManager_material_aliases_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_material_aliases_gridComponent;
  entity: IFootPrintManager_material_aliases_gridComponentEntity;

  vars: { accountId?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    material_alias_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    material_alias_description_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    sales_order_discount_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  override cells = {
    alias_account_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextModel(null, null, null, null, null)
      ),
    material_alias_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['material_alias_name_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    material_alias_description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['material_alias_description_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    sales_order_discount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['sales_order_discount_edit'] as DatexFormControl, null, false, false, '', '', null)
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_material_aliases_gridComponent, entity: IFootPrintManager_material_aliases_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.MaterialId,this.entity.AccountId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_material_aliases_gridComponent, entity?: IFootPrintManager_material_aliases_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.MaterialId,this.entity.AccountId].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'MaterialId',this.entity.MaterialId);
    set($resultKey, 'AccountId',this.entity.AccountId);
    const inParams = {
      $keys:[$resultKey],
      materialId:  $grid.inParams.material_id ,
    };
    const data = await this.datasources.Materials.ds_get_material_aliases.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_material_aliases_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.alias_account_id.displayControl as TextModel).text = $row.entity?.Account?.Name ?? $row.entity?.Account?.LookupCode;
    (this.cells.alias_account_id.editControl as TextModel).text = $row.entity?.Account?.Name ?? $row.cells.alias_account_id.editControl.text;
    (this.cells.material_alias_name.displayControl as TextModel).text = $row.entity?.MaterialAliasName;
    (this.cells.material_alias_name.editControl as TextBoxModel).reset($row.entity?.MaterialAliasName);
    (this.cells.material_alias_description.displayControl as TextModel).text = $row.entity?.MaterialAliasDescription;
    (this.cells.material_alias_description.editControl as TextBoxModel).reset($row.entity?.MaterialAliasDescription);
    (this.cells.sales_order_discount.displayControl as TextModel).text = $row.entity?.SalesOrderDiscount?.toString();;
    (this.cells.sales_order_discount.editControl as NumberBoxModel).reset($row.entity?.SalesOrderDiscount);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_material_aliases_gridComponentRowModel,
  $grid: FootPrintManager_material_aliases_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var requiredFields: [control: string, display: string][];
  var missingRequiredFields: string[] = [];
  
  requiredFields = [
      [$row.cells.material_alias_description.editControl.value, $grid.headers.material_alias_description.name],
      [$row.cells.material_alias_name.editControl.value, $grid.headers.material_alias_name.name]
  ]
  
  for (let required of requiredFields) {
      if (!$utils.isDefined(required[0])) {
          missingRequiredFields.push(required[1])
      }
  }
  
  if (missingRequiredFields.length === 1) {
      $shell.Materials.openErrorDialog('Save', `Missing Required field: ${missingRequiredFields.join('\r\n').trim().toUpperCase()}`);
      throw new Error(`Missing Required field: ${missingRequiredFields.join('\r\n').trim().toUpperCase()}`); // to prevent displayMode 
  
  } else if (missingRequiredFields.length > 1) {
      $shell.Materials.openErrorDialog('Save', `Missing Required fields: ${missingRequiredFields.join(' & ').trim().toUpperCase()}`);
      throw new Error(`Missing Required fields: ${missingRequiredFields.join(' & ').trim().toUpperCase()}`); // to prevent displayMode 
  }
  
  
  try {
  
  
  
      // Create substitution
      const payload = {
          "MaterialId": $grid.inParams.material_id,
          "AccountId": $row.vars.accountId,
          "MaterialAliasDescription": $row.cells.material_alias_description.editControl.value,
          "MaterialAliasLookupCode": $row.cells.material_alias_name.editControl.value,
          "MaterialAliasName": $row.cells.material_alias_name.editControl.value,
          "SalesOrderDiscount": $row.cells.sales_order_discount.editControl.value ?? 0
      };
  
      let results = (await $flows.Utilities.crud_create_flow({ entitySet: 'MaterialAccounts', entity: payload }));
      if($utils.isDefined(results.reason)){
          throw new Error(results.reason)
      }
  
    
  
  
  
      $grid.refresh();
  
  }
  catch (error) {
      $shell.Materials.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_material_aliases_gridComponentRowModel,
  $grid: FootPrintManager_material_aliases_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  let payload: any = {};
  
  if ($row.cells.material_alias_description?.editControl.isChanged) {
      payload.MaterialAliasDescription = $row.cells.material_alias_description?.editControl.value;
  }
  if ($row.cells.material_alias_name?.editControl.isChanged) {
      payload.MaterialAliasName = $row.cells.material_alias_name?.editControl.value;
      payload.MaterialAliasLookupCode = $row.cells.material_alias_name?.editControl.value;
  
  }
  if ($row.cells.sales_order_discount?.editControl.isChanged) {
      payload.SalesOrderDiscount = $row.cells.sales_order_discount?.editControl.value ?? 0;
  }
  
  try {
  
  
  
  
      let results = (await $flows.Utilities.crud_update_dual_pk_flow({
          entitySet: 'MaterialAccounts',
          entity: payload,
          pk1: $row.entity.AccountId,
          pk1String: 'AccountId',
          pk2: $row.entity.MaterialId,
          pk2String: 'MaterialId'
      }))
  
      if ($utils.isDefined(results.reason)) {
          throw new Error(results.reason)
      }
  
  
      await $grid.refresh();
  
  }
  catch (error) {
      await $shell.Materials.showErrorDetails('Save', 'Error on save.', error);
      //$shell.Materials.openErrorDialog('Error on save', error.message);
      throw error; // to prevent displayMode 
  }
  
  
  
  
  
  }
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_material_aliases_gridComponentRowModel,
  $grid: FootPrintManager_material_aliases_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  let ownerId = (await $datasources.Materials.ds_owners_dd.get({ projectId: $grid.inParams.project_id })).result[0].Id
  let excludeAccounts = []
  
  for (let row of $grid.rows) {
      excludeAccounts.push(row.entity.AccountId)
  }
  
  let accountInfo = (await $shell.FootPrintManager.openaccounts_gridDialog({
      ownerId: ownerId,
      accountTypeId: 2,
      typeIds: [2, 4, 5],
      allowSelection: true,
      title: `Select account for material alias`,
      excludeAccountIds: excludeAccounts
  }, "modal", EModalSize.Large));
  
  if ($utils.isDefined(accountInfo.accountId)) {
  
      let accountName = (await $datasources.Addresses.ds_contact_accounts_grid.get({ accountIds: [accountInfo.accountId], accountTypeId: 2, ownerId: ownerId })).result[0]
  
  
      $row.cells.alias_account_id.editControl.text = accountName?.AccountsContactsLookup[0]?.Account?.Name ?? accountName?.AccountsContactsLookup[0]?.Account?.LookupCode
      $row.cells.alias_account_id.displayControl.text = accountName?.AccountsContactsLookup[0]?.Account?.Name ?? accountName?.AccountsContactsLookup[0]?.Account?.LookupCode
  
      $row.vars.accountId = accountInfo.accountId
  } else {
      deleteRow();
  }
  
  
  
  
  
  async function deleteRow() {
      await new Promise(resolve => setTimeout(resolve, 10));
      const rowIndex = $grid.rows.indexOf($row);
      $grid.rows.splice(rowIndex, 1);
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-material_aliases_grid',
  templateUrl: './FootPrintManager.material_aliases_grid.component.html'
})
export class FootPrintManager_material_aliases_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_material_aliases_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fitedWidth', ['compact','noscroll-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_material_aliases_gridComponentInParams = { material_id: null, project_id: null, materialCode: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     alias_account_id: new GridHeaderModel(new HeaderStyles(null, null), 'alias_account_id', 'Alias account', true, false, null, false),       material_alias_name: new GridHeaderModel(new HeaderStyles(null, null), 'material_alias_name', 'Alias name', true, false, null, false),       material_alias_description: new GridHeaderModel(new HeaderStyles(null, null), 'material_alias_description', 'Alias description', true, false, null, false),       sales_order_discount: new GridHeaderModel(new HeaderStyles(null, null), 'sales_order_discount', 'Percent discount on sales order cost', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_material_aliases_gridComponentRowModel[] = [];

  @Input('material_id') set $inParams_material_id(value: any) {
    this.inParams['material_id'] = value;
  }
  get $inParams_material_id(): any {
    return this.inParams['material_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('materialCode') set $inParams_materialCode(value: any) {
    this.inParams['materialCode'] = value;
  }
  get $inParams_materialCode(): any {
    return this.inParams['materialCode'] ;
  }

  topToolbar = {
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Material aliases';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.material_id)) {
        this.$missingRequiredInParams.push('material_id');
      }
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 10;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      materialId:  $grid.inParams.material_id ,
    };
    try {
      const data = await this.datasources.Materials.ds_get_material_aliases.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_material_aliases_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_material_aliases_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_load();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_material_aliases_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_material_aliases_gridComponentEntity) {
    const row = new FootPrintManager_material_aliases_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_material_aliases_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $grid.topToolbar.on_delete.control.readOnly = true;
  
  const selected_row_count = $grid.selectedRows.length;
  
  if ($utils.isDefined(selected_row_count) && selected_row_count > 0) {
  
  
      $grid.topToolbar.on_delete.control.readOnly = false;
  
  }
  
  
  
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_material_aliases_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      // Initialize data
  
      const selected_rows = $grid.selectedRows;
  
      for (let selected_row of selected_rows) {
  
          const accountId = selected_row.entity.AccountId;
          const materialId = selected_row.entity.MaterialId;
  
          // Delete Substitution
          await $flows.Utilities.crud_delete_dual_pk_flow({entitySet: 'MaterialAccounts', pk1String: 'AccountId', pk1: accountId, pk2: materialId, pk2String: 'MaterialId'})
  
      }
  
  
  
      $grid.refresh();
  
  }
  
  catch (error) {
  
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
  
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_material_aliases_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.title = `Material aliases for ${$grid.inParams.materialCode}`
  }
  on_grid_data_load(event = null) {
    return this.on_grid_data_loadInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadInternal(
    $grid: FootPrintManager_material_aliases_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  for (let row of $grid.rows){
      row.cells.sales_order_discount.displayControl.text = `${row.cells.sales_order_discount.displayControl.text}%`
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
