import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { PalletTransactions_ShellService } from './PalletTransactions.shell.service';
import { PalletTransactions_OperationService } from './PalletTransactions.operation.service';
import { PalletTransactions_DatasourceService } from './PalletTransactions.datasource.index';
import { PalletTransactions_FlowService } from './PalletTransactions.flow.index';
import { PalletTransactions_ReportService } from './PalletTransactions.report.index';
import { PalletTransactions_LocalizationService } from './PalletTransactions.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './PalletTransactions.frontend.types'
import { $frontendTypes as $types} from './PalletTransactions.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'PalletTransactions-pallet_totals_widget',
  templateUrl: './PalletTransactions.pallet_totals_widget.component.html'
})
export class PalletTransactions_pallet_totals_widgetComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { project_id?: number, pallet_class_id?: number } = { project_id: null, pallet_class_id: null };
  //#region Inputs
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('pallet_class_id') set $inParams_pallet_class_id(v: number) {
    this.inParams.pallet_class_id = v;
  }
  get $inParams_pallet_class_id(): number {
    return this.inParams.pallet_class_id;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion  
  private entity: { Label?: string, Value?: number };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: PalletTransactions_ShellService,
private datasources: PalletTransactions_DatasourceService,
private flows: PalletTransactions_FlowService,
private reports: PalletTransactions_ReportService,
private localization: PalletTransactions_LocalizationService,
private operations: PalletTransactions_OperationService,
private logger: CleanupLoggerService,
) { 
    super();

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;
  $hasDataLoaded = false;

  async $init() {

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
      project_id:  $widget.inParams.project_id ,
      pallet_class_id:  $widget.inParams.pallet_class_id 
    };

    const data = await this.datasources.PalletTransactions.ds_pallet_totals_widget.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    this.value = this.format($widget.entity.Value, 1);

    
    this.originalValue = $widget.entity.Value;
  }

  format(num: number, digits: number): string {
    // at some point in time we should probably respect the selected culture from the localization service
    return isNil(num)
      ? ''
      : Intl.NumberFormat('en-US', { notation: "compact", maximumFractionDigits: digits}).format(num);
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

}
