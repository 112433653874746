import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { DockAppointments_dock_appointment_types_dd_singleComponent } from './DockAppointments.dock_appointment_types_dd_single.component'
import { DockAppointments_dock_appointment_statuses_singleComponent } from './DockAppointments.dock_appointment_statuses_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { DockAppointments_dock_doors_dd_singleComponent } from './DockAppointments.dock_doors_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { DockAppointments_orders_dd_multiComponent } from './DockAppointments.orders_dd_multi.component'
import { Carriers_carriers_dd_singleComponent } from './Carriers.carriers_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => DockAppointments_dock_appointment_types_dd_singleComponent),
    forwardRef(() => DockAppointments_dock_appointment_statuses_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => DockAppointments_dock_doors_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => DockAppointments_orders_dd_multiComponent),
    forwardRef(() => Carriers_carriers_dd_singleComponent),
  ],
  selector: 'FootPrintManager-dock_appointment_creation_form',
  templateUrl: './FootPrintManager.dock_appointment_creation_form.component.html'
})
export class FootPrintManager_dock_appointment_creation_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { warehouseId?: number[], lookupcode?: string, scheduledArrival?: string, scheduledDeparture?: string, dockDoor?: number, typeId?: number, ownerId?: number, projectId?: number, carrierId?: number, orderId?: number, loadContainerId?: number, shipmentId?: number } = { warehouseId: [], lookupcode: null, scheduledArrival: null, scheduledDeparture: null, dockDoor: null, typeId: null, ownerId: null, projectId: null, carrierId: null, orderId: null, loadContainerId: null, shipmentId: null };
  //#region Inputs
  @Input('warehouseId') set $inParams_warehouseId(v: number[]) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number[] {
    return this.inParams.warehouseId;
  }
  @Input('lookupcode') set $inParams_lookupcode(v: string) {
    this.inParams.lookupcode = v;
  }
  get $inParams_lookupcode(): string {
    return this.inParams.lookupcode;
  }
  @Input('scheduledArrival') set $inParams_scheduledArrival(v: string) {
    this.inParams.scheduledArrival = v;
  }
  get $inParams_scheduledArrival(): string {
    return this.inParams.scheduledArrival;
  }
  @Input('scheduledDeparture') set $inParams_scheduledDeparture(v: string) {
    this.inParams.scheduledDeparture = v;
  }
  get $inParams_scheduledDeparture(): string {
    return this.inParams.scheduledDeparture;
  }
  @Input('dockDoor') set $inParams_dockDoor(v: number) {
    this.inParams.dockDoor = v;
  }
  get $inParams_dockDoor(): number {
    return this.inParams.dockDoor;
  }
  @Input('typeId') set $inParams_typeId(v: number) {
    this.inParams.typeId = v;
  }
  get $inParams_typeId(): number {
    return this.inParams.typeId;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('carrierId') set $inParams_carrierId(v: number) {
    this.inParams.carrierId = v;
  }
  get $inParams_carrierId(): number {
    return this.inParams.carrierId;
  }
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  @Input('loadContainerId') set $inParams_loadContainerId(v: number) {
    this.inParams.loadContainerId = v;
  }
  get $inParams_loadContainerId(): number {
    return this.inParams.loadContainerId;
  }
  @Input('shipmentId') set $inParams_shipmentId(v: number) {
    this.inParams.shipmentId = v;
  }
  get $inParams_shipmentId(): number {
    return this.inParams.shipmentId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { warehouseId?: number, appointmentType?: string, doorType?: string } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    type: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    dock_door: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    scheduled_arrival: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    scheduled_departure: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Create', '', null)
    , false),
      search: new ToolModel(new ButtonModel('search', new ButtonStyles(['tertiary'], null), false, false, false, 'Search', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Appointment code', true, false),
    type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['type'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Type', true, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  true,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
    dock_door: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dock_door'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Scheduled dock door', false, false),
    scheduled_arrival: new FieldModel(new DateBoxModel(this.formGroup.controls['scheduled_arrival'] as DatexFormControl, null, false, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'Scheduled arrival', true, false),
    scheduled_departure: new FieldModel(new DateBoxModel(this.formGroup.controls['scheduled_departure'] as DatexFormControl, null, false, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'Scheduled departure', true, false),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    order: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Order', false, false),
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Carrier', false, false),
    reference_number: new FieldModel(new TextBoxModel(this.formGroup.controls['reference_number'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Reference number', false, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
};

  //#region fields inParams
  get $fields_type_selector_inParams_option(): string {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.vars.doorType;
    
    return expr;
  }

  cacheValueFor_$fields_dock_door_selector_inParams_warehouseIds: number[];
  get $fields_dock_door_selector_inParams_warehouseIds(): number[] {
    const $form = this;
    const $utils = this.utils;
    const expr = [$form.vars.warehouseId];
    
    if(!isEqual(this.cacheValueFor_$fields_dock_door_selector_inParams_warehouseIds, expr)) {
      this.cacheValueFor_$fields_dock_door_selector_inParams_warehouseIds = expr;
    }
    return this.cacheValueFor_$fields_dock_door_selector_inParams_warehouseIds;
  }

  get $fields_dock_door_selector_inParams_appointmentType(): string {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.vars.appointmentType;
    
    return expr;
  }

  get $fields_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.owner.control.value;
    
    return expr;
  }

  get $fields_order_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.project.control.value;
    
    return expr;
  }

  get $fields_order_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.vars.warehouseId;
    
    return expr;
  }

  get $fields_order_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.type.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    type: this.fields.type.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    dock_door: this.fields.dock_door.control.valueChanges
    ,
    scheduled_arrival: this.fields.scheduled_arrival.control.valueChanges
    ,
    scheduled_departure: this.fields.scheduled_departure.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    order: this.fields.order.control.valueChanges
    ,
    carrier: this.fields.carrier.control.valueChanges
    ,
    reference_number: this.fields.reference_number.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'custom_dock_appointment_creation_form';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.lookupcode.control as TextBoxModel).reset($form.inParams.lookupcode);
    (this.fields.type.control as SelectBoxModel).reset($form.inParams.typeId);
    (this.fields.status.control as SelectBoxModel).reset(0);
    (this.fields.dock_door.control as SelectBoxModel).reset($form.inParams.dockDoor);
    (this.fields.scheduled_arrival.control as DateBoxModel).reset($form.inParams.scheduledArrival);
    (this.fields.scheduled_departure.control as DateBoxModel).reset($form.inParams.scheduledDeparture);
    (this.fields.owner.control as SelectBoxModel).reset($form.inParams.ownerId);
    (this.fields.project.control as SelectBoxModel).reset($form.inParams.projectId);
    (this.fields.order.control as SelectBoxModel).reset($utils.isDefined($form.inParams.orderId) ? [$form.inParams.orderId] : null);
    (this.fields.carrier.control as SelectBoxModel).reset($form.inParams.carrierId);
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .type
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_type_change();
      });
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_change();
      });
    this.$formGroupFieldValidationObservables
      .dock_door
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_dock_door_changed();
      });
    this.$formGroupFieldValidationObservables
      .scheduled_arrival
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_scheduled_arrival_change();
      });
    this.$formGroupFieldValidationObservables
      .scheduled_departure
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.$formGroupFieldValidationObservables
      .order
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .carrier
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reference_number
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_dock_appointment_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  $form.toolbar.confirm.control.readOnly = true;
  
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.lookupcode.control.value,
      $form.fields.type.control.value,
      $form.fields.scheduled_arrival.control.value,
      $form.fields.scheduled_departure.control.value,
      $form.vars.warehouseId
  );
  
  if (!allRequiredFieldHaveValue) {
      $form.toolbar.confirm.control.readOnly = false;
      $shell.DockAppointments.openErrorDialog('Appointment Creation Error', 'Please enter data in all required fields.');
      return;
  }
  
  if ($form.fields.scheduled_arrival.control.value == 'null' || $form.fields.scheduled_departure.control.value == 'null') {
      $form.toolbar.confirm.control.readOnly = false;
      $shell.DockAppointments.openErrorDialog('Appointment Creation Error', 'Please enter data in all required fields.');
      return;
  
  }
  
  // Validate Fields
  // Enforce 15 minute window between scheduled arrival and scheduled departure
  if ($form.fields.scheduled_departure.control.value < $utils.date.add(15, 'minute', $form.fields.scheduled_arrival.control.value)) {
      $form.toolbar.confirm.control.readOnly = false;
      $shell.DockAppointments.openErrorDialog('Appointment Creation Error', 'The scheduled departure must be at least 15 minutes after the scheduled arrival.');
      return;
  }
  
  const user = (await $flows.Utilities.get_username_flow({})).userName;
  
  try {
  
      const create_appointment = (await $flows.DockAppointments.create_dock_appointment_flow({
          order_ids: $form.fields.order.control.value,
          load_container_id: $form.inParams.loadContainerId,
          shipment_id: $form.inParams.shipmentId,
          lookupcode: $form.fields.lookupcode.control.value,
          warehouse_id: $form.vars.warehouseId,
          scheduled_arrival: $form.fields.scheduled_arrival.control.value,
          scheduled_departure: $form.fields.scheduled_departure.control.value,
          reference_number: $form.fields.reference_number.control.value,
          notes: $form.fields.notes.control.value,
          carrier_id: $form.fields.carrier.control.value,
          scheduled_owner_id: $form.fields.owner.control.value,
          scheduled_project_id: $form.fields.project.control.value,
          appointment_type_id: $form.fields.type.control.value,
          dock_door_id: $form.fields.dock_door.control.value,
          user: user
  
      }));
  
      const dock_appointment_id = create_appointment.dock_appointment_id;
      const reason = create_appointment.reason;
  
      if ($utils.isDefined(reason)) {
          throw new Error(`${reason}`)
      }
  
      if ($utils.isDefined(dock_appointment_id)) {
          $form.outParams.confirm = true;
          $form.close();
      }
      else {
          $form.toolbar.confirm.control.readOnly = false;
          throw new Error('Unable to create the dock appointment.')
      }
  }
  
  
  catch (error) {
      $shell.DockAppointments.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $form: FootPrintManager_dock_appointment_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  // Clear out the project selection
  $form.fields.project.control.value = null;
  
  if (!$utils.isDefined($form.inParams.loadContainerId) && !$utils.isDefined($form.inParams.shipmentId)) {
  
      // Clear out the order selection
      $form.fields.order.control.value = null;
      $form.fields.order.control.displayText = null;
  
  };
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_dock_appointment_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  $form.close();
  }
  on_type_change(event = null) {
    return this.on_type_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_type_changeInternal(
    $form: FootPrintManager_dock_appointment_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const appointmentType = (await $datasources.DockAppointments.ds_dock_appointment_type_by_typeId.get({
      typeId: $form.fields.type.control.value
      })).result;
  
  if($utils.isDefined(appointmentType)){
      $form.vars.appointmentType = appointmentType.Options;
  }
  
  
  
  if (!$utils.isDefined($form.inParams.loadContainerId) && !$utils.isDefined($form.inParams.shipmentId)) {
  
      if ($form.fields.type.control.value == 1 || $form.fields.type.control.value == 2) {
  
          // Clear out the order selection
          $form.fields.order.hidden = false;
          $form.fields.order.control.value = null;
          $form.fields.order.control.displayText = null;
  
      }
      else {
  
          $form.fields.order.hidden = true;
      };
  };
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $form: FootPrintManager_dock_appointment_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  if (!$utils.isDefined($form.inParams.loadContainerId) && !$utils.isDefined($form.inParams.shipmentId)) {
  
      // Clear out the order selection
      if ($utils.isDefined($form.fields.order.control.value)) {
          $form.fields.order.control.value = null;
          $form.fields.order.control.displayText = null;
      }
  
      if ($form.vars.warehouseId == null) {
  
          $form.fields.order.hidden = true;
      }
  };
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_dock_appointment_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  // Hide the order information if the load container id or shipment id is submitted
  if ($utils.isDefined($form.inParams.loadContainerId) || ($utils.isDefined($form.inParams.shipmentId))) {
  
      $form.toolbar.search.hidden = true;
      $form.fields.order.control.readOnly = true;
      $form.fields.order.hidden = true;
  
  } else {
  
      // Do not allow searching for an existing appointment if the order input is not provided.
      if ($utils.isDefined($form.inParams.orderId)) {
          $form.toolbar.search.hidden = false;
          $form.fields.order.control.readOnly = true;
      } else {
          $form.toolbar.search.hidden = true;
      }
  
      // Only show the order drop down if the type is inbound, outbound or if the order is provide.
      if ($form.inParams.typeId == 1 || $form.inParams.typeId == 2 || $form.inParams.orderId) {
  
          $form.fields.order.hidden = false;
  
      }
      else {
  
          $form.fields.order.hidden = true;
      }
  
  };
  
  // Set LookupCode
  if (!$utils.isDefined($form.fields.lookupcode.control.value) || $form.fields.lookupcode.control.value === "null" || $form.fields.lookupcode.control.value === "undefined") {
      $form.fields.lookupcode.control.value = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId.toString();
  }
  
  // Set Date/Time Formats
  $form.fields.scheduled_arrival.control.format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  $form.fields.scheduled_departure.control.format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  
  // Default warehouse variable
  if ($utils.isDefined($form.inParams.warehouseId) && $form.inParams.warehouseId.length === 1) {
      $form.vars.warehouseId = $form.inParams.warehouseId[0]
      if ($form.vars.warehouseId > 0) {
          $form.fields.warehouse.hidden = true;
          $form.fields.dock_door.hidden = false;
      } else {
          $form.fields.warehouse.hidden = false;
          $form.fields.dock_door.hidden = true;
      }
  }
  else {
      $form.fields.warehouse.hidden = false;
      $form.fields.dock_door.hidden = true;
  }
  
  // If the dock door has a value call the dock_door_change flow to set the doorType variable
  if ($utils.isDefined($form.fields.dock_door.control.value)) {
  
      $form.on_dock_door_changed();
  }
  
  // If the input is passed in when opening the form set the variable that gets passed in to the dock door selector
  if ($utils.isDefined($form.inParams.typeId)) {
  
      const appointmentType = (await $datasources.DockAppointments.ds_dock_appointment_type_by_typeId.get({
          typeId: $form.inParams.typeId
      })).result;
  
      if ($utils.isDefined(appointmentType)) {
          $form.vars.appointmentType = appointmentType.Options;
      }
  
  }
  
  }
  on_scheduled_arrival_change(event = null) {
    return this.on_scheduled_arrival_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_scheduled_arrival_changeInternal(
    $form: FootPrintManager_dock_appointment_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  if ($form.fields.scheduled_arrival.control.isChanged) {
  
      $form.fields.scheduled_departure.control.value = $utils.date.add(1, 'hour', $form.fields.scheduled_arrival.control.value);
  }
  
  
  
  
  
  }
  on_search_clicked(event = null) {
    return this.on_search_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_search_clickedInternal(
    $form: FootPrintManager_dock_appointment_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  if ($utils.isDefined($form.vars.warehouseId)) {
  
      if ($utils.isDefined($form.fields.order.control.value)) {
  
          var selectionResult = await $shell.FootPrintManager.opendock_appointment_selection_wizardDialog({
              typeIds: [$form.fields.type.control.value],
              warehouseId: $form.vars.warehouseId,
              carrierId: $form.fields.carrier.control.value,
              orderId: $form.fields.order.control.value,
              dockDoor: $form.fields.dock_door.control.value,
              scheduledProjectId: $form.fields.project.control.value
          });
          if ($utils.isDefined(selectionResult)) {
  
              var userConfirm = selectionResult.confirm;
              if (userConfirm) {
                  $form.outParams.confirm = true;
                  $form.close();
  
              } else {
                  $form.outParams.confirm = false;
  
              }
  
          } else {
              $form.outParams.confirm = false;
          }
      } 
      else {
          $shell.FootPrintManager.openErrorDialog('Search error', 'Unable to determine order from the appointment.')
      }
  
  
  }
  else {
      $shell.FootPrintManager.openErrorDialog('Search error', 'Unable to determine the warehouse from the appointment.')
  }
  }
  on_dock_door_changed(event = null) {
    return this.on_dock_door_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_dock_door_changedInternal(
    $form: FootPrintManager_dock_appointment_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  var locationId = $form.fields.dock_door.control.value;
  
  if ($utils.isDefined(locationId)) {
      const locationContainer = (await $datasources.DockAppointments.ds_get_locationcontainer_by_locationId.get({ locationId: locationId })).result;
      if ($utils.isDefined(locationContainer)) {
  
          $form.vars.warehouseId = locationContainer.WarehouseId;
      }
      else {
  
          throw new Error('Unable to determine the warehouse from the selected dock door.')
      }
  
  
  
      if (!$utils.isDefined($form.inParams.loadContainerId) && (!$utils.isDefined($form.inParams.shipmentId))) {
          if ($utils.isAllDefined($form.fields.project.control.value, $form.vars.warehouseId)) {
              $form.fields.order.hidden = false;
          };
      };
  
  
      const dockDoor = (await $datasources.DockAppointments.ds_get_dock_door_by_locationId.get({
          locationId: locationId
      })).result;
  
      if ($utils.isDefined(dockDoor)) {
          const allowInbound = dockDoor.EligibleForAllocation;
          const allowOutbound = dockDoor.IsPrimaryPick;
  
          if (allowInbound) {
              $form.vars.doorType = 'Inbound'
          }
          else if (allowOutbound) {
              $form.vars.doorType = 'Outbound'
          }
          else {
              $form.vars.doorType = 'Both'
          }
      }
  
  }
  }
  on_warehouse_change(event = null) {
    return this.on_warehouse_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_changeInternal(
    $form: FootPrintManager_dock_appointment_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($form.fields.warehouse.control.value)){
  
      $form.vars.warehouseId = $form.fields.warehouse.control.value;
      $form.fields.warehouse.hidden = true;
      $form.fields.dock_door.hidden = false;
      $form.fields.dock_door.control.focus();
  }
  }
  //#endregion private flows
}
