import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootPrintManager_outbound_orders_gridComponent } from './FootPrintManager.outbound_orders_grid.component';
import { FootPrintManager_dock_appointments_calendarComponent } from './FootPrintManager.dock_appointments_calendar.component';
import { FootPrintManager_dock_appointments_dashboard_gridComponent } from './FootPrintManager.dock_appointments_dashboard_grid.component';
import { EndeavorLabs_predictions_reportComponent } from './EndeavorLabs.predictions_report.component';
import { SalesOrders_order_total_picked_order_count_by_date_widgetComponent } from './SalesOrders.order_total_picked_order_count_by_date_widget.component';
import { SalesOrders_order_total_completed_order_count_by_date_widgetComponent } from './SalesOrders.order_total_completed_order_count_by_date_widget.component';
import { SalesOrders_order_total_picked_units_by_date_widgetComponent } from './SalesOrders.order_total_picked_units_by_date_widget.component';
import { ExcelMaterialImport_owners_dd_singleComponent } from './ExcelMaterialImport.owners_dd_single.component'
import { ExcelMaterialImport_projects_dd_singleComponent } from './ExcelMaterialImport.projects_dd_single.component'
import { Materials_materials_by_project_dd_multiComponent } from './Materials.materials_by_project_dd_multi.component'
import { Locations_warehouses_dd_multiComponent } from './Locations.warehouses_dd_multi.component'
import { SalesOrders_orderstatuses_dd_multiComponent } from './SalesOrders.orderstatuses_dd_multi.component'
import { Carriers_carriers_dd_singleComponent } from './Carriers.carriers_dd_single.component'
import { Carriers_carrierservicetype_dd_singleComponent } from './Carriers.carrierservicetype_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_outbound_orders_gridComponent),
    forwardRef(() => FootPrintManager_dock_appointments_calendarComponent),
    forwardRef(() => FootPrintManager_dock_appointments_dashboard_gridComponent),
    forwardRef(() => EndeavorLabs_predictions_reportComponent),
    forwardRef(() => SalesOrders_order_total_picked_order_count_by_date_widgetComponent),
    forwardRef(() => SalesOrders_order_total_completed_order_count_by_date_widgetComponent),
    forwardRef(() => SalesOrders_order_total_picked_units_by_date_widgetComponent),
    forwardRef(() => ExcelMaterialImport_owners_dd_singleComponent),
    forwardRef(() => ExcelMaterialImport_projects_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_multiComponent),
    forwardRef(() => Locations_warehouses_dd_multiComponent),
    forwardRef(() => SalesOrders_orderstatuses_dd_multiComponent),
    forwardRef(() => Carriers_carriers_dd_singleComponent),
    forwardRef(() => Carriers_carrierservicetype_dd_singleComponent),
  ],
  selector: 'app-custom_outbound_orders_hub',
  templateUrl: './app.custom_outbound_orders_hub.component.html'
})
export class app_custom_outbound_orders_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { currentDate?: string, calendar_viewDate?: string, calendar_dockDoorIds?: number[], pred_state?: number } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    materials: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier_service: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      create_sales_order: new ToolModel(new ButtonModel('create_sales_order', new ButtonStyles(null, null), false, false, false, 'Create sales order', 'ms-Icon ms-Icon--Add', null)
    , false),
      import_orders: new ToolModel(new ButtonModel('import_orders', new ButtonStyles(null, null), false, false, false, 'Import orders', 'ms-Icon ms-Icon--ExcelDocument', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      manage_carriers: new ToolModel(new ButtonModel('manage_carriers', new ButtonStyles(null, null), false, false, false, 'Manage carriers', 'ms-Icon ms-Icon--SettingsAdd', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      predictions_report: new ToolModel(new ButtonModel('predictions_report', new ButtonStyles(null, null), false, false, false, 'Predictions report', 'ms-Icon ms-Icon--ReportAdd', null)
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      export_to_sftp: new ToolModel(new ButtonModel('export_to_sftp', new ButtonStyles(null, null), false, false, false, 'Export to sftp', 'ms-Icon ms-Icon--CloudDownload', null)
    , false),
      import_from_sftp: new ToolModel(new ButtonModel('import_from_sftp', new ButtonStyles(null, null), false, false, false, 'Import from sftp', 'ms-Icon ms-Icon--CloudUpload', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    materials: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['materials'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Materials', false, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouses', false, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Reference', false, false),
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Carrier', false, false),
    carrier_service: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier_service'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Service', false, false),
    from: new FieldModel(new DateBoxModel(this.formGroup.controls['from'] as DatexFormControl, null, false, false, 'YYYY-MM-DD', 'date', null)
, new ControlContainerStyles(null, null), 'From:', false, false),
    to: new FieldModel(new DateBoxModel(this.formGroup.controls['to'] as DatexFormControl, null, false, false, 'YYYY-MM-DD', 'date', null)
, new ControlContainerStyles(null, null), 'To:', false, false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('Filters', false, true, false, false),
  Reporting: new FieldsetModel('Reporting', true, false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inbound_orders: new TabItemModel(
        this.rootTabGroup, 
        'Orders', 
        false,
        ),
      calendar: new TabItemModel(
        this.rootTabGroup, 
        'Calendar', 
        false,
        ),
      dock_appointment_dashboard: new TabItemModel(
        this.rootTabGroup, 
        'Orders dashboard', 
        false,
        ),
      Report: new TabItemModel(
        this.rootTabGroup, 
        'Predictions Report', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_inbound_orders_outbound_orders_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_inbound_orders_outbound_orders_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_warehouseId: number[];
    get $tabs_inbound_orders_outbound_orders_grid_inParams_warehouseId(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_warehouseId, expr)) {
        this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_warehouseId = expr;
      }
      return this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_warehouseId;
    }
  
    cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_statusIds: number[];
    get $tabs_inbound_orders_outbound_orders_grid_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_statusIds;
    }
  
    get $tabs_inbound_orders_outbound_orders_grid_inParams_carrierId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.carrier.control.value;
      
      return expr;
    }
  
    get $tabs_inbound_orders_outbound_orders_grid_inParams_carrierServiceId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.carrier_service.control.value;
      
      return expr;
    }
  
    get $tabs_inbound_orders_outbound_orders_grid_inParams_reference(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.reference.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_materialIds: number[];
    get $tabs_inbound_orders_outbound_orders_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_materialIds;
    }
  
    cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_warehouseIds: number[];
    get $tabs_calendar_dock_appointments_calendar_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_warehouseIds;
    }
  
    cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_typeIds: number[];
    get $tabs_calendar_dock_appointments_calendar_inParams_typeIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [2];
      
      if(!isEqual(this.cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_typeIds, expr)) {
        this.cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_typeIds = expr;
      }
      return this.cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_typeIds;
    }
  
    get $tabs_calendar_dock_appointments_calendar_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_calendar_dock_appointments_calendar_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_calendar_dock_appointments_calendar_inParams_carrierId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.carrier.control.value;
      
      return expr;
    }
  
    get $tabs_calendar_dock_appointments_calendar_inParams_viewDate(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.calendar_viewDate;
      
      return expr;
    }
  
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds: number[];
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds;
    }
  
    cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds: number[];
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = [2];
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds, expr)) {
        this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds;
    }
  
    get $tabs_Report_predictions_report_inParams_FromDate(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.from.control.value.toString();
      
      return expr;
    }
  
    get $tabs_Report_predictions_report_inParams_ToDate(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.to.control.value.toString();
      
      return expr;
    }
  
    get $tabs_Report_predictions_report_inParams_projectid(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_Report_predictions_report_inParams_whid: number[];
    get $tabs_Report_predictions_report_inParams_whid(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_Report_predictions_report_inParams_whid, expr)) {
        this.cacheValueFor_$tabs_Report_predictions_report_inParams_whid = expr;
      }
      return this.cacheValueFor_$tabs_Report_predictions_report_inParams_whid;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_inbound_orders', { read: FootPrintManager_outbound_orders_gridComponent }) $tabs_inbound_orders: FootPrintManager_outbound_orders_gridComponent;
      @ViewChild('$tabs_calendar', { read: FootPrintManager_dock_appointments_calendarComponent }) $tabs_calendar: FootPrintManager_dock_appointments_calendarComponent;
      @ViewChild('$tabs_dock_appointment_dashboard', { read: FootPrintManager_dock_appointments_dashboard_gridComponent }) $tabs_dock_appointment_dashboard: FootPrintManager_dock_appointments_dashboard_gridComponent;
      @ViewChild('$tabs_Report', { read: EndeavorLabs_predictions_reportComponent }) $tabs_Report: EndeavorLabs_predictions_reportComponent;
    //#endregion tabs children
    widgets = {
      order_total_picked_order_count_by_date_widget: new WidgetModel(false),
      order_total_completed_order_count_by_date_wdiget: new WidgetModel(false),
      order_total_picked_units_by_date_widget: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_order_total_picked_order_count_by_date_widget_inParams_date(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.currentDate;
      
      return expr;
    }
  
    get $widgets_order_total_picked_order_count_by_date_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_order_total_picked_order_count_by_date_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_order_total_picked_order_count_by_date_widget_inParams_warehouseId: number[];
    get $widgets_order_total_picked_order_count_by_date_widget_inParams_warehouseId(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_order_total_picked_order_count_by_date_widget_inParams_warehouseId, expr)) {
        this.cacheValueFor_$widgets_order_total_picked_order_count_by_date_widget_inParams_warehouseId = expr;
      }
      return this.cacheValueFor_$widgets_order_total_picked_order_count_by_date_widget_inParams_warehouseId;
    }
  
    get $widgets_order_total_completed_order_count_by_date_wdiget_inParams_date(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.currentDate;
      
      return expr;
    }
  
    get $widgets_order_total_completed_order_count_by_date_wdiget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_order_total_completed_order_count_by_date_wdiget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_order_total_completed_order_count_by_date_wdiget_inParams_warehouseId: number[];
    get $widgets_order_total_completed_order_count_by_date_wdiget_inParams_warehouseId(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_order_total_completed_order_count_by_date_wdiget_inParams_warehouseId, expr)) {
        this.cacheValueFor_$widgets_order_total_completed_order_count_by_date_wdiget_inParams_warehouseId = expr;
      }
      return this.cacheValueFor_$widgets_order_total_completed_order_count_by_date_wdiget_inParams_warehouseId;
    }
  
    get $widgets_order_total_picked_units_by_date_widget_inParams_date(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.currentDate;
      
      return expr;
    }
  
    get $widgets_order_total_picked_units_by_date_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_order_total_picked_units_by_date_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_order_total_picked_units_by_date_widget_inParams_warehouseId: number[];
    get $widgets_order_total_picked_units_by_date_widget_inParams_warehouseId(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_order_total_picked_units_by_date_widget_inParams_warehouseId, expr)) {
        this.cacheValueFor_$widgets_order_total_picked_units_by_date_widget_inParams_warehouseId = expr;
      }
      return this.cacheValueFor_$widgets_order_total_picked_units_by_date_widget_inParams_warehouseId;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_order_total_picked_order_count_by_date_widget', { read:  SalesOrders_order_total_picked_order_count_by_date_widgetComponent }) $widgets_order_total_picked_order_count_by_date_widget: SalesOrders_order_total_picked_order_count_by_date_widgetComponent;
      @ViewChild('$widgets_order_total_completed_order_count_by_date_wdiget', { read:  SalesOrders_order_total_completed_order_count_by_date_widgetComponent }) $widgets_order_total_completed_order_count_by_date_wdiget: SalesOrders_order_total_completed_order_count_by_date_widgetComponent;
      @ViewChild('$widgets_order_total_picked_units_by_date_widget', { read:  SalesOrders_order_total_picked_units_by_date_widgetComponent }) $widgets_order_total_picked_units_by_date_widget: SalesOrders_order_total_picked_units_by_date_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  get $fields_materials_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  cacheValueFor_$fields_status_selector_inParams_statusIds: number[];
  get $fields_status_selector_inParams_statusIds(): number[] {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = [1,2,4,8];
    
    if(!isEqual(this.cacheValueFor_$fields_status_selector_inParams_statusIds, expr)) {
      this.cacheValueFor_$fields_status_selector_inParams_statusIds = expr;
    }
    return this.cacheValueFor_$fields_status_selector_inParams_statusIds;
  }



  get $fields_carrier_service_selector_inParams_carrierId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.carrier.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Outbound orders hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Your landing page to view outbound orders and their related actions.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: app_ShellService,
  private datasources: app_DatasourceService,
  private flows: app_FlowService,
  private reports: app_ReportService,
  private localization: app_LocalizationService,
  private operations: app_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inbound_orders,
      this.tabs.calendar,
      this.tabs.dock_appointment_dashboard,
      this.tabs.Report,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Outbound Orders Hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.status.control as SelectBoxModel).reset([1]);
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.formGroup
      .controls['from']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_predictions_click();
      });
    this.formGroup
      .controls['to']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_predictions_click();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_order_total_picked_order_count_by_date_widget') {
      if (!isNil(this.$widgets_order_total_picked_order_count_by_date_widget) && !this.widgets.order_total_picked_order_count_by_date_widget.hidden) {
        this.$widgets_order_total_picked_order_count_by_date_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_total_completed_order_count_by_date_wdiget') {
      if (!isNil(this.$widgets_order_total_completed_order_count_by_date_wdiget) && !this.widgets.order_total_completed_order_count_by_date_wdiget.hidden) {
        this.$widgets_order_total_completed_order_count_by_date_wdiget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_total_picked_units_by_date_widget') {
      if (!isNil(this.$widgets_order_total_picked_units_by_date_widget) && !this.widgets.order_total_picked_units_by_date_widget.hidden) {
        this.$widgets_order_total_picked_units_by_date_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_inbound_orders') {
      if (!isNil(this.$tabs_inbound_orders) && !this.tabs.inbound_orders.hidden) {
        this.$tabs_inbound_orders.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_calendar') {
      if (!isNil(this.$tabs_calendar) && !this.tabs.calendar.hidden) {
        this.$tabs_calendar.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_dock_appointment_dashboard') {
      if (!isNil(this.$tabs_dock_appointment_dashboard) && !this.tabs.dock_appointment_dashboard.hidden) {
        this.$tabs_dock_appointment_dashboard.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_Report') {
      if (!isNil(this.$tabs_Report) && !this.tabs.Report.hidden) {
        this.$tabs_Report.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_sales_order_clicked(event = null) {
    return this.on_create_sales_order_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_sales_order_clickedInternal(
    $hub: app_custom_outbound_orders_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_outbound_orders_hub.on_create_sales_order_clicked');
  
  
  // Initialize the first warehouse selected
  var warehouseId = null;
   $hub.tabs.Report.hidden= true;
   $hub.filtersets.Reporting.hidden=true;
  if ($utils.isDefined($hub.filters.warehouse.control.value)) {
      warehouseId = $hub.filters.warehouse.control.value[0];
  };
  
  
   const dialogResult = (await $shell.FootPrintManager.opensales_order_creation_form({
       ownerId: $hub.filters.owner.control.value,
       projectId: $hub.filters.project.control.value,
       warehouseId: $utils.isDefined(warehouseId) ? warehouseId : null
       }));
  $hub.toolbar.export_to_sftp.hidden=true;
  $hub.toolbar.import_from_sftp.hidden=true;
  $hub.vars.pred_state=0;
  $hub.refresh()
  
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: app_custom_outbound_orders_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_outbound_orders_hub.on_owner_change');
  
   $hub.filtersets.Reporting.hidden=true;
   $hub.tabs.Report.hidden= true;
  $hub.filters.project.control.value = null;
  $hub.toolbar.export_to_sftp.hidden=true;
  $hub.toolbar.import_from_sftp.hidden=true;
  $hub.vars.pred_state=0;
  $hub.refresh()
  
  
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $hub: app_custom_outbound_orders_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_outbound_orders_hub.on_project_change');
  const projectId = $hub.filters.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($hub.filters.owner.control.value)) {
      const project = (await $datasources.Materials.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $hub.filters.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  $hub.toolbar.export_to_sftp.hidden=true;
  $hub.toolbar.import_from_sftp.hidden=true;
  
  $hub.refresh
  
  
  
  
  }
  on_import_orders_clicked(event = null) {
    return this.on_import_orders_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_import_orders_clickedInternal(
    $hub: app_custom_outbound_orders_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_outbound_orders_hub.on_import_orders_clicked');
  
   $hub.filtersets.Reporting.hidden=true;
   $hub.tabs.Report.hidden= true;
  $shell.ExcelOrderImport.openexcel_order_import_grid();
  $hub.toolbar.export_to_sftp.hidden=true;
  $hub.toolbar.import_from_sftp.hidden=true;
  $hub.vars.pred_state=0;
  $hub.refresh
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: app_custom_outbound_orders_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_outbound_orders_hub.on_init');
  $hub.vars.currentDate = $utils.date.now();
  
  var defaultStatus = (await $operations.FootPrintManager.Enable_SalesOrders_DefaultOrderHubCreatedStatus.isAuthorized());
  
  if (defaultStatus == false) {
      // Push processing status to control
      $hub.filters.status.control.value.push(2);
  }
  else {
      console.log('Do nothing, screendefaults to status 1 (created)')
  }
  $hub.vars.pred_state=0;
  $hub.filters.from.hidden= true;
  $hub.filters.to.hidden= true;
   $hub.tabs.Report.hidden= true;
  $hub.filtersets.Reporting.hidden=true;
  $hub.toolbar.export_to_sftp.hidden=true;
  $hub.toolbar.import_from_sftp.hidden=true;
  
  
  
  if ((await $operations.FootPrintManager.Enable_Locations_DefaultWarehouseByActivity.isAuthorized())) {
  
      let username = (await $flows.Utilities.get_username_flow({})).userName;
  
      let defaultWarehouseResult = (await $datasources.Locations.ds_get_default_warehouse_by_user.get({ userName: username })).result;
  
      $hub.filters.warehouse.control.value = $utils.isDefined(defaultWarehouseResult?.warehouseId) ? [defaultWarehouseResult.warehouseId] : null;
  
  }
  $hub.refresh
  }
  on_calendar_filters_updated(event = null) {
    return this.on_calendar_filters_updatedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_calendar_filters_updatedInternal(
    $hub: app_custom_outbound_orders_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_outbound_orders_hub.on_calendar_filters_updated');
  // Update tab's variables to event values
  $hub.vars.calendar_dockDoorIds = $event.filters.dockDoorIds;
  $hub.vars.calendar_viewDate = $event.filters.viewDate;
   $hub.tabs.Report.hidden= true;
  $hub.filtersets.Reporting.hidden=true;
  $hub.toolbar.export_to_sftp.hidden=true;
  $hub.toolbar.import_from_sftp.hidden=true;
  $hub.vars.pred_state=0;
  $hub.refresh()
  
  }
  on_manage_carriers_clicked(event = null) {
    return this.on_manage_carriers_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_carriers_clickedInternal(
    $hub: app_custom_outbound_orders_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_outbound_orders_hub.on_manage_carriers_clicked');
  
   $hub.filtersets.Reporting.hidden=true;
   $hub.tabs.Report.hidden= true;
  $shell.FootPrintManager.opencarriers_grid();
  $hub.toolbar.export_to_sftp.hidden=true;
  $hub.toolbar.import_from_sftp.hidden=true;
  $hub.vars.pred_state=0;
  $hub.refresh()
  
  
  
  }
  on_predictions_click(event = null) {
    return this.on_predictions_clickInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_predictions_clickInternal(
    $hub: app_custom_outbound_orders_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_outbound_orders_hub.on_predictions_click');
  
  
  
  $hub.tabs.Report.activate
  $hub.filtersets.Reporting.hidden=false;
  $hub.tabs.Report.hidden = false;
  $hub.filters.from.hidden= false
  $hub.filters.to.hidden= false;
  $hub.filters.carrier.hidden=true
  $hub.filters.owner.hidden=true
  $hub.filters.materials.hidden=true
  $hub.filters.carrier_service.hidden=true
  $hub.filters.reference.hidden=true
  $hub.filters.status.hidden=true
  $hub.filters.warehouse.hidden=false
  $hub.filters.project.hidden=false
  $hub.vars.pred_state=1
  $reports.EndeavorLabs.predictions_report.exportAsHtml
  
  if ($settings.FootPrintAPI.url=="https://cswtestapi.footprintwms.com/") 
  {
  $hub.toolbar.export_to_sftp.hidden=false;
  $hub.toolbar.import_from_sftp.hidden=false;
  }
  else
  {
  $hub.toolbar.export_to_sftp.hidden=true;
  $hub.toolbar.import_from_sftp.hidden=true;
  }
  $hub.refresh()
  
  }
  on_export(event = null) {
    return this.on_exportInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_exportInternal(
    $hub: app_custom_outbound_orders_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_outbound_orders_hub.on_export');
   
   var varenv:string;
  
   if ($settings.FootPrintAPI.url=="https://cswtestapi.footprintwms.com/") {varenv = "Test"} else {varenv = "Prod"}
   
   await $flows.EndeavorLabs.endeavor_csv_to_sftp_flow({})
  
  
  
  
  }
  on_import(event = null) {
    return this.on_importInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_importInternal(
    $hub: app_custom_outbound_orders_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_outbound_orders_hub.on_import');
   var varenv:string;
  
   if ($settings.FootPrintAPI.url=="https://cswtestapi.footprintwms.com/") {varenv = "Test"} else {varenv = "Prod"}
   
   await $flows.EndeavorLabs.endeavor_csv_from_sftp_flow({})
  
  }
  //#endregion private flows
}
