import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Orderful_ds_get_completed_shipments_by_projectService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projects: string[], shipped_date: string, shipment_type?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, ShippedDate?: string, StatusId?: number, TypeId?: number, OrderLookups?: { OrderId?: number, Order?: { IntegrationHubId?: string, IntegrationHubSourceId?: string, IntegrationHubSourceLookupCode?: string, IntegrationHubSourcePlatform?: string, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, VendorReference?: string, OrderClass?: { LookupCode?: string, Name?: string }, Status?: { Name?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, WavelengthConnectorOrderTransmissions?: { Id?: number, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[] } }[], Status?: { Name?: string }, Type?: { Name?: string }, ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projects)) {
      missingRequiredInParams.push('\'projects\'');
    }
    if (isNil(inParams.shipped_date)) {
      missingRequiredInParams.push('\'shipped_date\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Orderful/datasources/ds_get_completed_shipments_by_project/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projects: string[], shipped_date: string, shipment_type?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, ShippedDate?: string, StatusId?: number, TypeId?: number, OrderLookups?: { OrderId?: number, Order?: { IntegrationHubId?: string, IntegrationHubSourceId?: string, IntegrationHubSourceLookupCode?: string, IntegrationHubSourcePlatform?: string, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, VendorReference?: string, OrderClass?: { LookupCode?: string, Name?: string }, Status?: { Name?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, WavelengthConnectorOrderTransmissions?: { Id?: number, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[] } }[], Status?: { Name?: string }, Type?: { Name?: string }, ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projects)) {
      missingRequiredInParams.push('\'projects\'');
    }
    if (isNil(inParams.shipped_date)) {
      missingRequiredInParams.push('\'shipped_date\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Orderful/datasources/ds_get_completed_shipments_by_project/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projects: string[], shipped_date: string, shipment_type?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, ShippedDate?: string, StatusId?: number, TypeId?: number, OrderLookups?: { OrderId?: number, Order?: { IntegrationHubId?: string, IntegrationHubSourceId?: string, IntegrationHubSourceLookupCode?: string, IntegrationHubSourcePlatform?: string, LookupCode?: string, OrderStatusId?: number, OwnerReference?: string, VendorReference?: string, OrderClass?: { LookupCode?: string, Name?: string }, Status?: { Name?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, WavelengthConnectorOrderTransmissions?: { Id?: number, DirectionId?: number, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, TypeId?: number, WavelengthConnectorTransmissionDirection?: { Name?: string }, WavelengthConnectorTransmissionType?: { Name?: string } }[] } }[], Status?: { Name?: string }, Type?: { Name?: string }, ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projects)) {
      missingRequiredInParams.push('\'projects\'');
    }
    if (isNil(inParams.shipped_date)) {
      missingRequiredInParams.push('\'shipped_date\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Orderful/datasources/ds_get_completed_shipments_by_project/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      projects: string[], shipped_date: string, shipment_type?: number    }) {
        if(isNil(inParams.projects)) {
          return true; 
        }
        if(isNil(inParams.shipped_date)) {
          return true; 
        }
      return false;
    }
}
