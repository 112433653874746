<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="lineCount" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
            >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <label class="import-button">
        <input type="file" class="hidden" #excelFileInput (change)="$importExcel(excelFileInput)" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv" />
        <div class="button-label">
          <div class="button-icon"><i class="icon icon-ic_fluent_cloud_arrow_up_20_regular"></i></div>
          <div class="button-text">Import</div>
        </div>
      </label>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="content_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.content_id.displayControl.styles.style"
          [ngClass]="row.cells.content_id.displayControl.styles.classes"
          [matTooltip]="row.cells.content_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.content_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="row_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.row_number.displayControl.styles.style"
          [ngClass]="row.cells.row_number.displayControl.styles.classes"
          [matTooltip]="row.cells.row_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.row_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="errors">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.errors.displayControl.styles.style"
          [ngClass]="row.cells.errors.displayControl.styles.classes"
          [matTooltip]="row.cells.errors.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.errors.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.owner.displayControl.styles.style"
          [ngClass]="row.cells.owner.displayControl.styles.classes"
          [matTooltip]="row.cells.owner.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['owner_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.owner.editControl.readOnly || row.cells.owner.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.owner.editControl.placeholder}}"
            [ngStyle]="row.cells.owner.editControl.styles.style"
            [ngClass]="row.cells.owner.editControl.styles.classes"
            [matTooltip]="row.cells.owner.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes"
          [matTooltip]="row.cells.project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['project_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.project.editControl.readOnly || row.cells.project.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.project.editControl.placeholder}}"
            [ngStyle]="row.cells.project.editControl.styles.style"
            [ngClass]="row.cells.project.editControl.styles.classes"
            [matTooltip]="row.cells.project.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouse.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['warehouse_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.warehouse.editControl.readOnly || row.cells.warehouse.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.warehouse.editControl.placeholder}}"
            [ngStyle]="row.cells.warehouse.editControl.styles.style"
            [ngClass]="row.cells.warehouse.editControl.styles.classes"
            [matTooltip]="row.cells.warehouse.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.location.displayControl.styles.style"
          [ngClass]="row.cells.location.displayControl.styles.classes"
          [matTooltip]="row.cells.location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['location_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.location.editControl.readOnly || row.cells.location.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.location.editControl.placeholder}}"
            [ngStyle]="row.cells.location.editControl.styles.style"
            [ngClass]="row.cells.location.editControl.styles.classes"
            [matTooltip]="row.cells.location.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate.displayControl.styles.classes"
          [matTooltip]="row.cells.licenseplate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['licenseplate_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.licenseplate.editControl.readOnly || row.cells.licenseplate.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.licenseplate.editControl.placeholder}}"
            [ngStyle]="row.cells.licenseplate.editControl.styles.style"
            [ngClass]="row.cells.licenseplate.editControl.styles.classes"
            [matTooltip]="row.cells.licenseplate.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material.displayControl.styles.style"
          [ngClass]="row.cells.material.displayControl.styles.classes"
          [matTooltip]="row.cells.material.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['material_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.material.editControl.readOnly || row.cells.material.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.material.editControl.placeholder}}"
            [ngStyle]="row.cells.material.editControl.styles.style"
            [ngClass]="row.cells.material.editControl.styles.classes"
            [matTooltip]="row.cells.material.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['lot_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.lot.editControl.readOnly || row.cells.lot.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.lot.editControl.placeholder}}"
            [ngStyle]="row.cells.lot.editControl.styles.style"
            [ngClass]="row.cells.lot.editControl.styles.classes"
            [matTooltip]="row.cells.lot.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendorlot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendorlot.displayControl.styles.style"
          [ngClass]="row.cells.vendorlot.displayControl.styles.classes"
          [matTooltip]="row.cells.vendorlot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendorlot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['vendorlot_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.vendorlot.editControl.readOnly || row.cells.vendorlot.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.vendorlot.editControl.placeholder}}"
            [ngStyle]="row.cells.vendorlot.editControl.styles.style"
            [ngClass]="row.cells.vendorlot.editControl.styles.classes"
            [matTooltip]="row.cells.vendorlot.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vat">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vat.displayControl.styles.style"
          [ngClass]="row.cells.vat.displayControl.styles.classes"
          [matTooltip]="row.cells.vat.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vat.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['vat_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.vat.editControl.readOnly || row.cells.vat.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.vat.editControl.placeholder}}"
            [ngStyle]="row.cells.vat.editControl.styles.style"
            [ngClass]="row.cells.vat.editControl.styles.classes"
            [matTooltip]="row.cells.vat.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['packaging_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.packaging.editControl.readOnly || row.cells.packaging.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.packaging.editControl.placeholder}}"
            [ngStyle]="row.cells.packaging.editControl.styles.style"
            [ngClass]="row.cells.packaging.editControl.styles.classes"
            [matTooltip]="row.cells.packaging.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.packaged_amount.displayControl.styles.classes"
          [matTooltip]="row.cells.packaged_amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['packaged_amount_edit']"
            matInput
            numberBox
            [format]="row.cells.packaged_amount.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.packaged_amount.editControl.readOnly || row.cells.packaged_amount.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.packaged_amount.editControl.placeholder}}"
            [ngStyle]="row.cells.packaged_amount.editControl.styles.style"
            [ngClass]="row.cells.packaged_amount.editControl.styles.classes"
            [matTooltip]="row.cells.packaged_amount.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="serial_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.serial_number.displayControl.styles.style"
          [ngClass]="row.cells.serial_number.displayControl.styles.classes"
          [matTooltip]="row.cells.serial_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.serial_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['serial_number_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.serial_number.editControl.readOnly || row.cells.serial_number.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.serial_number.editControl.placeholder}}"
            [ngStyle]="row.cells.serial_number.editControl.styles.style"
            [ngClass]="row.cells.serial_number.editControl.styles.classes"
            [matTooltip]="row.cells.serial_number.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="net_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.net_weight.displayControl.styles.style"
          [ngClass]="row.cells.net_weight.displayControl.styles.classes"
          [matTooltip]="row.cells.net_weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.net_weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['net_weight_edit']"
            matInput
            numberBox
            [format]="row.cells.net_weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.net_weight.editControl.readOnly || row.cells.net_weight.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.net_weight.editControl.placeholder}}"
            [ngStyle]="row.cells.net_weight.editControl.styles.style"
            [ngClass]="row.cells.net_weight.editControl.styles.classes"
            [matTooltip]="row.cells.net_weight.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="gross_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.gross_weight.displayControl.styles.style"
          [ngClass]="row.cells.gross_weight.displayControl.styles.classes"
          [matTooltip]="row.cells.gross_weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.gross_weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['gross_weight_edit']"
            matInput
            numberBox
            [format]="row.cells.gross_weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.gross_weight.editControl.readOnly || row.cells.gross_weight.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.gross_weight.editControl.placeholder}}"
            [ngStyle]="row.cells.gross_weight.editControl.styles.style"
            [ngClass]="row.cells.gross_weight.editControl.styles.classes"
            [matTooltip]="row.cells.gross_weight.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.weight_uom.displayControl.styles.style"
          [ngClass]="row.cells.weight_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.weight_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.weight_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['weight_uom_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.weight_uom.editControl.readOnly || row.cells.weight_uom.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.weight_uom.editControl.placeholder}}"
            [ngStyle]="row.cells.weight_uom.editControl.styles.style"
            [ngClass]="row.cells.weight_uom.editControl.styles.classes"
            [matTooltip]="row.cells.weight_uom.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="reasoncode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.reasoncode.displayControl.styles.style"
          [ngClass]="row.cells.reasoncode.displayControl.styles.classes"
          [matTooltip]="row.cells.reasoncode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.reasoncode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['reasoncode_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.reasoncode.editControl.readOnly || row.cells.reasoncode.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.reasoncode.editControl.placeholder}}"
            [ngStyle]="row.cells.reasoncode.editControl.styles.style"
            [ngClass]="row.cells.reasoncode.editControl.styles.classes"
            [matTooltip]="row.cells.reasoncode.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendorlot_manufacture_date_on_creation">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendorlot_manufacture_date_on_creation.displayControl.styles.style"
          [ngClass]="row.cells.vendorlot_manufacture_date_on_creation.displayControl.styles.classes"
          [matTooltip]="row.cells.vendorlot_manufacture_date_on_creation.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendorlot_manufacture_date_on_creation.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['vendorlot_manufacture_date_on_creation_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.vendorlot_manufacture_date_on_creation.editControl.readOnly || row.cells.vendorlot_manufacture_date_on_creation.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.vendorlot_manufacture_date_on_creation.editControl.placeholder}}"
            [ngStyle]="row.cells.vendorlot_manufacture_date_on_creation.editControl.styles.style"
            [ngClass]="row.cells.vendorlot_manufacture_date_on_creation.editControl.styles.classes"
            [matTooltip]="row.cells.vendorlot_manufacture_date_on_creation.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendorlot_expiration_date_on_creation">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendorlot_expiration_date_on_creation.displayControl.styles.style"
          [ngClass]="row.cells.vendorlot_expiration_date_on_creation.displayControl.styles.classes"
          [matTooltip]="row.cells.vendorlot_expiration_date_on_creation.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendorlot_expiration_date_on_creation.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['vendorlot_expiration_date_on_creation_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.vendorlot_expiration_date_on_creation.editControl.readOnly || row.cells.vendorlot_expiration_date_on_creation.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.vendorlot_expiration_date_on_creation.editControl.placeholder}}"
            [ngStyle]="row.cells.vendorlot_expiration_date_on_creation.editControl.styles.style"
            [ngClass]="row.cells.vendorlot_expiration_date_on_creation.editControl.styles.classes"
            [matTooltip]="row.cells.vendorlot_expiration_date_on_creation.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_receive_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot_receive_date.displayControl.styles.style"
          [ngClass]="row.cells.lot_receive_date.displayControl.styles.classes"
          [matTooltip]="row.cells.lot_receive_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot_receive_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['lot_receive_date_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.lot_receive_date.editControl.readOnly || row.cells.lot_receive_date.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.lot_receive_date.editControl.placeholder}}"
            [ngStyle]="row.cells.lot_receive_date.editControl.styles.style"
            [ngClass]="row.cells.lot_receive_date.editControl.styles.classes"
            [matTooltip]="row.cells.lot_receive_date.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
