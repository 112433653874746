<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm_button" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel_button" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-wave_title"
                     *ngIf="!fieldsets.wave_title.hidden && !fieldsets.wave_title.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.wave_title.collapsible }">
                    <div *ngIf="!fieldsets.wave_title.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.wave_title.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.wave_title.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.wave_title.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.wave_title.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.wave_title.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.wave_title.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden && !fields.warehouse.removed" 
                            class="field-container full {{fields.warehouse.invalid ? 'invalid' : ''}} {{fields.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <label data-cy="label" class="datex-label"
                                [ngStyle]="fields.warehouse.control.styles.style"
                                [ngClass]="fields.warehouse.control.styles.classes"
                                [matTooltip]="fields.warehouse.control.tooltip"
                                matTooltipClass="datex-control-tooltip">{{fields.warehouse.control.label}}</label>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden && !fields.status.removed" 
                            class="field-container full {{fields.status.invalid ? 'invalid' : ''}} {{fields.status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.status.label + (fields.status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.status.label}}<span *ngIf="fields.status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <label data-cy="label" class="datex-label"
                                [ngStyle]="fields.status.control.styles.style"
                                [ngClass]="fields.status.control.styles.classes"
                                [matTooltip]="fields.status.control.tooltip"
                                matTooltipClass="datex-control-tooltip">{{fields.status.control.label}}</label>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-description" *ngIf="!fields.description.hidden && !fields.description.removed" 
                            class="field-container full {{fields.description.invalid ? 'invalid' : ''}} {{fields.description.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.description.label + (fields.description.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.description.label}}<span *ngIf="fields.description.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="description"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.description.control.readOnly || fields.description.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.description.control.placeholder}}"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"
                                [matTooltip]="fields.description.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden && !fields.notes.removed" 
                            class="field-container full {{fields.notes.invalid ? 'invalid' : ''}} {{fields.notes.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.notes.control.readOnly || fields.notes.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-priority" *ngIf="!fields.priority.hidden && !fields.priority.removed" 
                            class="field-container full {{fields.priority.invalid ? 'invalid' : ''}} {{fields.priority.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.priority.styles.style"
                            [ngClass]="fields.priority.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.priority.label + (fields.priority.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.priority.label}}<span *ngIf="fields.priority.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Waves-wave_priorities_dd_single 
                            data-cy="selector"
                            [type]="fields.priority.control.type"
                            formControlName="priority"
                            (displayTextChange)="fields.priority.control.displayText=$event"
                            [placeholder]="fields.priority.control.placeholder"
                            [styles]="fields.priority.control.styles"
                            [tooltip]="fields.priority.control.tooltip"
                        >
                        </Waves-wave_priorities_dd_single>
                        <ng-container *ngIf="fields.priority.invalid">
                          <ng-container *ngFor="let error of fields.priority.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-batch_pick" *ngIf="!fields.batch_pick.hidden && !fields.batch_pick.removed" 
                            class="field-container standard {{fields.batch_pick.invalid ? 'invalid' : ''}} {{fields.batch_pick.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.batch_pick.styles.style"
                            [ngClass]="fields.batch_pick.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.batch_pick.label + (fields.batch_pick.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.batch_pick.label}}<span *ngIf="fields.batch_pick.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="batch_pick"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.batch_pick.control.styles.style"
                                      [ngClass]="fields.batch_pick.control.styles.classes"
                                      [matTooltip]="fields.batch_pick.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.batch_pick.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.batch_pick.invalid">
                          <ng-container *ngFor="let error of fields.batch_pick.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-staging_location" *ngIf="!fields.staging_location.hidden && !fields.staging_location.removed" 
                            class="field-container standard {{fields.staging_location.invalid ? 'invalid' : ''}} {{fields.staging_location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.staging_location.styles.style"
                            [ngClass]="fields.staging_location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.staging_location.label + (fields.staging_location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.staging_location.label}}<span *ngIf="fields.staging_location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.staging_location.control.type"
                            formControlName="staging_location"
                            (displayTextChange)="fields.staging_location.control.displayText=$event"
                            [placeholder]="fields.staging_location.control.placeholder"
                            [styles]="fields.staging_location.control.styles"
                            [tooltip]="fields.staging_location.control.tooltip"
                          [warehouseId]="$fields_staging_location_selector_inParams_warehouseId"
                          [typeId]="$fields_staging_location_selector_inParams_typeId"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.staging_location.invalid">
                          <ng-container *ngFor="let error of fields.staging_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>