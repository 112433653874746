<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="manage" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div *ngIf="!tool.control.buttons.operation_codes.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.operation_codes.readOnly || tool.control.buttons.operation_codes.disabled"
                              [ngStyle]="tool.control.buttons.operation_codes.styles.style"
                              [ngClass]="tool.control.buttons.operation_codes.styles.classes"
                              [matTooltip]="tool.control.buttons.operation_codes.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_manage_operation_codes_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.operation_codes.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.operation_codes.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.operation_codes.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filters"
                     *ngIf="!filtersets.filters.hidden && !filtersets.filters.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters.collapsible }">
                    <div *ngIf="!filtersets.filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.filters.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden && !filters.warehouse.removed" 
                                  class="field-container standard {{filters.warehouse.invalid ? 'invalid' : ''}} {{filters.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.warehouse.styles.style"
                                  [ngClass]="filters.warehouse.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouse.label + (filters.warehouse.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Locations-warehouses_dd_single 
                                  data-cy="selector"
                                  [type]="filters.warehouse.control.type"
                                  formControlName="warehouse"
                                  (displayTextChange)="filters.warehouse.control.displayText=$event"
                                  [placeholder]="filters.warehouse.control.placeholder"
                                  [styles]="filters.warehouse.control.styles"
                                  [tooltip]="filters.warehouse.control.tooltip"
                              >
                              </Locations-warehouses_dd_single>
                              <ng-container *ngIf="filters.warehouse.invalid">
                                <ng-container *ngFor="let error of filters.warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-operation_code" *ngIf="!filters.operation_code.hidden && !filters.operation_code.removed" 
                                  class="field-container standard {{filters.operation_code.invalid ? 'invalid' : ''}} {{filters.operation_code.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.operation_code.styles.style"
                                  [ngClass]="filters.operation_code.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.operation_code.label + (filters.operation_code.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.operation_code.label}}<span *ngIf="filters.operation_code.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Inspections-inspection_operation_codes_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.operation_code.control.type"
                                  formControlName="operation_code"
                                  (displayTextChange)="filters.operation_code.control.displayText=$event"
                                  [placeholder]="filters.operation_code.control.placeholder"
                                  [styles]="filters.operation_code.control.styles"
                                  [tooltip]="filters.operation_code.control.tooltip"
                              >
                              </Inspections-inspection_operation_codes_dd_multi>
                              <ng-container *ngIf="filters.operation_code.invalid">
                                <ng-container *ngFor="let error of filters.operation_code.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.inspection_tasks.hasTabToShow" class="tab" data-cy="tab-inspection_tasks">
              <h2 [className]="tabs.inspection_tasks.active? 'active': ''" (click)="tabs.inspection_tasks.activate()">{{tabs.inspection_tasks.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-inspection_tasks_grid *ngIf="tabs.inspection_tasks.active"
              #$tabs_inspection_tasks
              [warehouseId]="$tabs_inspection_tasks_inspection_tasks_grid_inParams_warehouseId"
              [operationCodeIds]="$tabs_inspection_tasks_inspection_tasks_grid_inParams_operationCodeIds"
              ($refreshEvent)="refresh(false, false, '$tabs_inspection_tasks')">
              </FootPrintManager-inspection_tasks_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>