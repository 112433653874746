import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './InventoryTransformations.frontend.types'

@Injectable({ providedIn: 'root' })
export class InventoryTransformations_create_inventory_transformation_target_line_on_existing_licenseplate_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { inventory_transformation_id: number, lot_id?: number, material_id?: number, packaged_amount: number, package_id: number, source_line_id?: number, target_license_plate_id: number }): Promise<{ inventory_transformation_target_line_id?: number, reason?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inventory_transformation_id)) {
      missingRequiredInParams.push('\'inventory_transformation_id\'');
    }
    if (isNil(inParams.packaged_amount)) {
      missingRequiredInParams.push('\'packaged_amount\'');
    }
    if (isNil(inParams.package_id)) {
      missingRequiredInParams.push('\'package_id\'');
    }
    if (isNil(inParams.target_license_plate_id)) {
      missingRequiredInParams.push('\'target_license_plate_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransformations/functions/create_inventory_transformation_target_line_on_existing_licenseplate_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

