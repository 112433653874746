import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'


interface IFootPrintManager_billing_projects_gridComponentEntity {
  Id?: number, BillingContractLineId?: number, Percentage?: number, ProjectId?: number, Project?: { Name?: string }}

interface IFootPrintManager_billing_projects_gridComponentInParams {
  billingContractLineId: number, edit: boolean}


class FootPrintManager_billing_projects_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_billing_projects_gridComponent;
  entity: IFootPrintManager_billing_projects_gridComponentEntity;

  vars: { rateId?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    Project_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    Percentage_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    Id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['Project_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      ),
    Percentage: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['Percentage_edit'] as DatexFormControl, null, false, false, '0.0', '', null)
      ),
  }

  get $fields_Project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_billing_projects_gridComponent, entity: IFootPrintManager_billing_projects_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_billing_projects_gridComponent, entity?: IFootPrintManager_billing_projects_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      billingContractLineId:  $grid.inParams.billingContractLineId ,
      billingContractId:  null ,
    };
    const data = await this.datasources.Invoices.ds_billing_projects_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_billing_projects_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.Id.displayControl as TextModel).text = $row.entity.Id?.toString();
    (this.cells.Project.displayControl as TextModel).text = $row.entity.Project?.Name;
    (this.cells.Project.editControl as SelectBoxModel).reset($row.entity.ProjectId);
    (this.cells.Percentage.displayControl as TextModel).text = $row.entity.Percentage?.toString();
    (this.cells.Percentage.editControl as NumberBoxModel).reset($row.entity.Percentage);

    await this.on_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_existing_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $row: FootPrintManager_billing_projects_gridComponentRowModel,
  $grid: FootPrintManager_billing_projects_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias 07/20/2023
  
  //Setting the rate id on a variable so we can work with the tab and new row
  $row.vars.rateId = $row.entity.Id;
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $row: FootPrintManager_billing_projects_gridComponentRowModel,
  $grid: FootPrintManager_billing_projects_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/20/2023
  
  //Check if the editor was opened in edit mode
  if ($grid.inParams.edit) {
  
      $grid.canEdit = true;
  
  
  } else {
  
      $grid.canEdit = false;
  
  };
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_billing_projects_gridComponentRowModel,
  $grid: FootPrintManager_billing_projects_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/27/2023
  
  try {
  
      if (!$utils.isDefined($row.cells.Percentage)) {
          await $shell.FootPrintManager.openErrorDialog('Line cannot be saved.', 'The percentage cannot be blank.');
          $grid.refresh();
          return;
      } else if (!$utils.isDefined($row.cells.Project)) {
          await $shell.FootPrintManager.openErrorDialog('Line cannot be saved.', 'The project cannot be blank.');
          $grid.refresh();
          return;
      } else {
  
          $row.cells.Percentage.displayControl.text = $row.cells.Percentage.editControl.value;
          $row.cells.Project.displayControl.text = $row.cells.Project.editControl.displayText;
  
          let percentage = $row.cells.Percentage.editControl.value;
          let project_id = $row.cells.Project.editControl.value;
  
          let line_id = $row.entity?.Id ? $row.entity.Id : -1;
          let percentage_total = 0;
  
          let current_lines = (await $datasources.Invoices.ds_billing_projects_grid.get({ billingContractLineId: $grid.inParams.billingContractLineId })).result;
  
          if ($utils.isDefined(current_lines)) {
  
              let current_array = current_lines.map(l => l.Id);
  
              let line_index = current_array.indexOf(line_id)
              if (line_index >= 0) {
                  current_lines[line_index].Percentage = percentage;
                  percentage_total = current_lines.map(l => l.Percentage).reduce((a, b) => a + b);
              } else {
                  percentage_total = current_lines.map(l => l.Percentage).reduce((a, b) => a + b) + percentage;
              };
  
          } else {
              percentage_total = percentage;
          };
  
          if (percentage_total > 100) {
              await $shell.FootPrintManager.openErrorDialog('Line cannot be saved.', 'The percentage cannot exceed 100%.');
              $grid.refresh();
              return;
          };
  
          if (percentage_total <= 0) {
              await $shell.FootPrintManager.openErrorDialog('Line cannot be saved.', 'The percentage needs to be greater than zero!');
              $grid.refresh();
              return;
          };
  
          if (line_id > 0) {
              //Update
              const payload = {
                  "Id": line_id,
                  "BillingContractLineId": $grid.inParams.billingContractLineId,
                  "ProjectId": project_id,
                  "Percentage": percentage
              };
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'BillingProjects', entity: payload, id: line_id });
  
          } else {
              //Insert
              const payload = {
                  "BillingContractLineId": $grid.inParams.billingContractLineId,
                  "ProjectId": project_id,
                  "Percentage": percentage
              };
  
              await $flows.Utilities.crud_create_flow({ entitySet: 'BillingProjects', entity: payload });
          };
  
          //$grid.refresh();
  
      };
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Line Id ${$grid.inParams.billingContractLineId} - ${errorMessage}`;
      await $shell.FootPrintManager.openErrorDialog('Error saving the line!', 'An error occurred during saving of the line', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      $grid.refresh();
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_projects_dd_singleComponent),
  ],
  selector: 'FootPrintManager-billing_projects_grid',
  templateUrl: './FootPrintManager.billing_projects_grid.component.html'
})
export class FootPrintManager_billing_projects_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_billing_projects_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);


  inParams: IFootPrintManager_billing_projects_gridComponentInParams = { billingContractLineId: null, edit: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Id: new GridHeaderModel(new HeaderStyles(null, null), 'Id', 'ID', false, false, null, false),       Project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', 'Project', true, false, null, false),       Percentage: new GridHeaderModel(new HeaderStyles(null, null), 'Percentage', 'Percentage', true, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_billing_projects_gridComponentRowModel[] = [];

  @Input('billingContractLineId') set $inParams_billingContractLineId(value: any) {
    this.inParams['billingContractLineId'] = value;
  }
  get $inParams_billingContractLineId(): any {
    return this.inParams['billingContractLineId'] ;
  }
  @Input('edit') set $inParams_edit(value: any) {
    this.inParams['edit'] = value;
  }
  get $inParams_edit(): any {
    return this.inParams['edit'] ;
  }

  topToolbar = {
      deleteLine: new ToolModel(new ButtonModel('deleteLine', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Billing projects grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.billingContractLineId)) {
        this.$missingRequiredInParams.push('billingContractLineId');
      }
      if(isNil(this.inParams.edit)) {
        this.$missingRequiredInParams.push('edit');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      billingContractLineId:  $grid.inParams.billingContractLineId ,
      billingContractId:  null ,
    };
    try {
      const data = await this.datasources.Invoices.ds_billing_projects_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_billing_projects_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_billing_projects_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_billing_projects_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_select();
  }

  async addRow(entity?: IFootPrintManager_billing_projects_gridComponentEntity) {
    const row = new FootPrintManager_billing_projects_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootPrintManager_billing_projects_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/27/2023
  
  const lines = $grid.selectedRows;
  
  if (lines.length === 0) {
  
      $shell.FootPrintManager.openErrorDialog('Delete lines errors', 'No lines were selected.');
      return;
  
  } else {
  
      const delete_success = [];
      const delete_failures = [];
      const errorMsgList = [];
      const errorMsgListDetails = [];
  
      for (let line of lines) {
  
          try {
  
              await $flows.Utilities.crud_delete_flow({ entitySet: "BillingProjects", id: line.entity.Id })
              delete_success.push(line);
  
          } catch (error) {
  
              delete_failures.push(line);
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `lines ${line.entity.Id.toString()} - ${errorMessage}`;
              errorMsgList.push(errorDescription);
              errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
          }
      }
  
      // all succeeded
      if (delete_success.length === lines.length) {
  
          const title = 'All lines(s) deleted';
          const message = `lines(s) ${delete_success.map(c => c.entity.Id.toString()).join(',')} deleted`;
          await $shell.FootPrintManager.openInfoDialog(title, message);
          await $grid.refresh();
  
      } else {
  
          // all failures
          if (delete_failures.length === lines.length) {
  
              const title = 'All lines(s) failed to delete';
              const message = `lines(s) ${delete_failures.map(c => c.entity.Id.toString()).join(',')} could not be deleted`;
              await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
  
          } else {
  
              const title = 'Some lines could not be deleted';
              const success = `lines(s) ${delete_success.map(c => c.entity.Id.toString()).join(',')} were deleted.`;
              const errors = `The following lines(s) could not be deleted: ${delete_failures.map(c => c.entity.Id.toString()).join(',')}`;
              const message = `${success} \r\n\r\n${errors}`;
              await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
              await $grid.refresh();
  
          }
      }
  }
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_billing_projects_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/21/2023
  
  //Start as read only
  $grid.topToolbar.deleteLine.control.readOnly = true;
  //$grid.topToolbar.deleteLine.hidden = true;
  
  //If we get the edit parameter
  if ($grid.inParams.edit) {
  
      $grid.bottomToolbar.addLine.control.readOnly = false;
      $grid.bottomToolbar.addLine.hidden = false;
      $grid.canEdit = true;
      $grid.headers.Project.required = true;   
      $grid.headers.Percentage.required = true;   
  
  } else {
  
      $grid.bottomToolbar.addLine.control.readOnly = true;
      $grid.bottomToolbar.addLine.hidden = true;
      $grid.canEdit = false;
      $grid.headers.Project.required = false;
      $grid.headers.Percentage.required = false;
  
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_billing_projects_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/27/2023
  
  //Start as read only
  $grid.topToolbar.deleteLine.control.readOnly = true;
  //$grid.topToolbar.deleteLine.hidden = true;
  
  //If we get the edit parameter
  if ($grid.inParams.edit) {
      $grid.bottomToolbar.addLine.control.readOnly = false;
      $grid.bottomToolbar.addLine.hidden = false;
      $grid.canEdit = true;
      $grid.headers.Project.required = true;
      $grid.headers.Percentage.required = true;
  } else {
      $grid.bottomToolbar.addLine.control.readOnly = true;
      $grid.bottomToolbar.addLine.hidden = true;
      $grid.canEdit = false;
      $grid.headers.Project.required = false;
      $grid.headers.Percentage.required = false;
  }
  }
  on_select(event = null) {
    return this.on_selectInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_selectInternal(
    $grid: FootPrintManager_billing_projects_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/21/2023
  
  //If we get the edit parameter
  if ($grid.inParams.edit) {
      if ($grid.selectedRows.length === 0) {
          $grid.topToolbar.deleteLine.control.readOnly = true;
          //$grid.topToolbar.deleteLine.hidden = true;
      } else {
          $grid.topToolbar.deleteLine.control.readOnly = false;
          //$grid.topToolbar.deleteLine.hidden = false;
      };
  } else {
      $grid.topToolbar.deleteLine.control.readOnly = true;
      //$grid.topToolbar.deleteLine.hidden = true;
  };
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
