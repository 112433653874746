<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="refresh_dropdowns" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_refresh_dropdowns($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-application"
                     *ngIf="!filtersets.application.hidden && !filtersets.application.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.application.collapsible }">
                    <div *ngIf="!filtersets.application.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.application.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.application.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.application.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.application.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.application.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.application.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-application_name" *ngIf="!filters.application_name.hidden && !filters.application_name.removed" 
                                  class="field-container full {{filters.application_name.invalid ? 'invalid' : ''}} {{filters.application_name.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.application_name.styles.style"
                                  [ngClass]="filters.application_name.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.application_name.label + (filters.application_name.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.application_name.label}}<span *ngIf="filters.application_name.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Utilities-applications_dd_single 
                                  data-cy="selector"
                                  [type]="filters.application_name.control.type"
                                  formControlName="application_name"
                                  (displayTextChange)="filters.application_name.control.displayText=$event"
                                  [placeholder]="filters.application_name.control.placeholder"
                                  [styles]="filters.application_name.control.styles"
                                  [tooltip]="filters.application_name.control.tooltip"
                              >
                              </Utilities-applications_dd_single>
                              <ng-container *ngIf="filters.application_name.invalid">
                                <ng-container *ngFor="let error of filters.application_name.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.messages.hasTabToShow" class="tab" data-cy="tab-messages">
              <h2 [className]="tabs.messages.active? 'active': ''" (click)="tabs.messages.activate()">{{tabs.messages.title}}</h2>
            </div>
            <div *ngIf="tabs.logs.hasTabToShow" class="tab" data-cy="tab-logs">
              <h2 [className]="tabs.logs.active? 'active': ''" (click)="tabs.logs.activate()">{{tabs.logs.title}}</h2>
            </div>
            <div *ngIf="tabs.configurations.hasTabToShow" class="tab" data-cy="tab-configurations">
              <h2 [className]="tabs.configurations.active? 'active': ''" (click)="tabs.configurations.activate()">{{tabs.configurations.title}}</h2>
            </div>
          </div>
        
              <Utilities-messages_grid *ngIf="tabs.messages.active"
              #$tabs_messages
              [application_name]="$tabs_messages_messages_grid_inParams_application_name"
              [column_names]="$tabs_messages_messages_grid_inParams_column_names"
              [filter_values]="$tabs_messages_messages_grid_inParams_filter_values"
              ($refreshEvent)="refresh(false, false, '$tabs_messages')">
              </Utilities-messages_grid>
              <Utilities-logs_grid *ngIf="tabs.logs.active"
              #$tabs_logs
              [application_name]="$tabs_logs_logs_grid_inParams_application_name"
              [column_names]="$tabs_logs_logs_grid_inParams_column_names"
              [filter_values]="$tabs_logs_logs_grid_inParams_filter_values"
              ($refreshEvent)="refresh(false, false, '$tabs_logs')">
              </Utilities-logs_grid>
              <Utilities-configurations_grid *ngIf="tabs.configurations.active"
              #$tabs_configurations
              [application_name]="$tabs_configurations_configurations_grid_inParams_application_name"
              [user_name]="$tabs_configurations_configurations_grid_inParams_user_name"
              [column_names]="$tabs_configurations_configurations_grid_inParams_column_names"
              [filter_values]="$tabs_configurations_configurations_grid_inParams_filter_values"
              ($refreshEvent)="refresh(false, false, '$tabs_configurations')">
              </Utilities-configurations_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>