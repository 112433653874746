<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-header"
                     *ngIf="!fieldsets.header.hidden && !fieldsets.header.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.header.collapsible }">
                    <div *ngIf="!fieldsets.header.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.header.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.header.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.header.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.header.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.header.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.header.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-frequency_interval" *ngIf="!fields.frequency_interval.hidden && !fields.frequency_interval.removed" 
                            class="field-container standard {{fields.frequency_interval.invalid ? 'invalid' : ''}} {{fields.frequency_interval.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.frequency_interval.styles.style"
                            [ngClass]="fields.frequency_interval.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.frequency_interval.label + (fields.frequency_interval.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.frequency_interval.label}}<span *ngIf="fields.frequency_interval.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Notifications-frequency_interval_dd_single 
                            data-cy="selector"
                            [type]="fields.frequency_interval.control.type"
                            formControlName="frequency_interval"
                            (displayTextChange)="fields.frequency_interval.control.displayText=$event"
                            [placeholder]="fields.frequency_interval.control.placeholder"
                            [styles]="fields.frequency_interval.control.styles"
                            [tooltip]="fields.frequency_interval.control.tooltip"
                        >
                        </Notifications-frequency_interval_dd_single>
                        <ng-container *ngIf="fields.frequency_interval.invalid">
                          <ng-container *ngFor="let error of fields.frequency_interval.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-daily_frequency"
                     *ngIf="!fieldsets.daily_frequency.hidden && !fieldsets.daily_frequency.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.daily_frequency.collapsible }">
                    <div *ngIf="!fieldsets.daily_frequency.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.daily_frequency.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.daily_frequency.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.daily_frequency.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.daily_frequency.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.daily_frequency.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.daily_frequency.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-number_of_days" *ngIf="!fields.number_of_days.hidden && !fields.number_of_days.removed" 
                            class="field-container standard {{fields.number_of_days.invalid ? 'invalid' : ''}} {{fields.number_of_days.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.number_of_days.styles.style"
                            [ngClass]="fields.number_of_days.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.number_of_days.label + (fields.number_of_days.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.number_of_days.label}}<span *ngIf="fields.number_of_days.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="number_of_days"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.number_of_days.control.readOnly || fields.number_of_days.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.number_of_days.control.placeholder}}"
                                [ngStyle]="fields.number_of_days.control.styles.style"
                                [ngClass]="fields.number_of_days.control.styles.classes"
                                [matTooltip]="fields.number_of_days.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.number_of_days.invalid">
                          <ng-container *ngFor="let error of fields.number_of_days.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-weekly_frequency"
                     *ngIf="!fieldsets.weekly_frequency.hidden && !fieldsets.weekly_frequency.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.weekly_frequency.collapsible }">
                    <div *ngIf="!fieldsets.weekly_frequency.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.weekly_frequency.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.weekly_frequency.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.weekly_frequency.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.weekly_frequency.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.weekly_frequency.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.weekly_frequency.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-weekly_days_of_week" *ngIf="!fields.weekly_days_of_week.hidden && !fields.weekly_days_of_week.removed" 
                            class="field-container full {{fields.weekly_days_of_week.invalid ? 'invalid' : ''}} {{fields.weekly_days_of_week.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.weekly_days_of_week.styles.style"
                            [ngClass]="fields.weekly_days_of_week.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.weekly_days_of_week.label + (fields.weekly_days_of_week.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.weekly_days_of_week.label}}<span *ngIf="fields.weekly_days_of_week.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Notifications-weekly_frequency_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.weekly_days_of_week.control.type"
                            formControlName="weekly_days_of_week"
                            (displayTextChange)="fields.weekly_days_of_week.control.displayText=$event"
                            [placeholder]="fields.weekly_days_of_week.control.placeholder"
                            [styles]="fields.weekly_days_of_week.control.styles"
                            [tooltip]="fields.weekly_days_of_week.control.tooltip"
                        >
                        </Notifications-weekly_frequency_dd_multi>
                        <ng-container *ngIf="fields.weekly_days_of_week.invalid">
                          <ng-container *ngFor="let error of fields.weekly_days_of_week.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-monthly_frequency"
                     *ngIf="!fieldsets.monthly_frequency.hidden && !fieldsets.monthly_frequency.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.monthly_frequency.collapsible }">
                    <div *ngIf="!fieldsets.monthly_frequency.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.monthly_frequency.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.monthly_frequency.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.monthly_frequency.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.monthly_frequency.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.monthly_frequency.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.monthly_frequency.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-monthly_day_of_month" *ngIf="!fields.monthly_day_of_month.hidden && !fields.monthly_day_of_month.removed" 
                            class="field-container double {{fields.monthly_day_of_month.invalid ? 'invalid' : ''}} {{fields.monthly_day_of_month.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.monthly_day_of_month.styles.style"
                            [ngClass]="fields.monthly_day_of_month.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.monthly_day_of_month.label + (fields.monthly_day_of_month.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.monthly_day_of_month.label}}<span *ngIf="fields.monthly_day_of_month.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Notifications-monthly_frequency_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.monthly_day_of_month.control.type"
                            formControlName="monthly_day_of_month"
                            (displayTextChange)="fields.monthly_day_of_month.control.displayText=$event"
                            [placeholder]="fields.monthly_day_of_month.control.placeholder"
                            [styles]="fields.monthly_day_of_month.control.styles"
                            [tooltip]="fields.monthly_day_of_month.control.tooltip"
                        >
                        </Notifications-monthly_frequency_dd_multi>
                        <ng-container *ngIf="fields.monthly_day_of_month.invalid">
                          <ng-container *ngFor="let error of fields.monthly_day_of_month.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-time_frequency"
                     *ngIf="!fieldsets.time_frequency.hidden && !fieldsets.time_frequency.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.time_frequency.collapsible }">
                    <div *ngIf="!fieldsets.time_frequency.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.time_frequency.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.time_frequency.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.time_frequency.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.time_frequency.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.time_frequency.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.time_frequency.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-time_frequency" *ngIf="!fields.time_frequency.hidden && !fields.time_frequency.removed" 
                            class="field-container full {{fields.time_frequency.invalid ? 'invalid' : ''}} {{fields.time_frequency.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.time_frequency.styles.style"
                            [ngClass]="fields.time_frequency.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.time_frequency.label + (fields.time_frequency.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.time_frequency.label}}<span *ngIf="fields.time_frequency.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Notifications-time_frequency_dd_single 
                            data-cy="selector"
                            [type]="fields.time_frequency.control.type"
                            formControlName="time_frequency"
                            (displayTextChange)="fields.time_frequency.control.displayText=$event"
                            [placeholder]="fields.time_frequency.control.placeholder"
                            [styles]="fields.time_frequency.control.styles"
                            [tooltip]="fields.time_frequency.control.tooltip"
                        >
                        </Notifications-time_frequency_dd_single>
                        <ng-container *ngIf="fields.time_frequency.invalid">
                          <ng-container *ngFor="let error of fields.time_frequency.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-time_selector" *ngIf="!fields.time_selector.hidden && !fields.time_selector.removed" 
                            class="field-container standard {{fields.time_selector.invalid ? 'invalid' : ''}} {{fields.time_selector.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.time_selector.styles.style"
                            [ngClass]="fields.time_selector.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.time_selector.label + (fields.time_selector.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.time_selector.label}}<span *ngIf="fields.time_selector.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Notifications-time_selector_dd_single 
                            data-cy="selector"
                            [type]="fields.time_selector.control.type"
                            formControlName="time_selector"
                            (displayTextChange)="fields.time_selector.control.displayText=$event"
                            [placeholder]="fields.time_selector.control.placeholder"
                            [styles]="fields.time_selector.control.styles"
                            [tooltip]="fields.time_selector.control.tooltip"
                          [intervalMinutes]="$fields_time_selector_selector_inParams_intervalMinutes"
                        >
                        </Notifications-time_selector_dd_single>
                        <ng-container *ngIf="fields.time_selector.invalid">
                          <ng-container *ngFor="let error of fields.time_selector.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-time_meridiem" *ngIf="!fields.time_meridiem.hidden && !fields.time_meridiem.removed" 
                            class="field-container standard {{fields.time_meridiem.invalid ? 'invalid' : ''}} {{fields.time_meridiem.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.time_meridiem.styles.style"
                            [ngClass]="fields.time_meridiem.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.time_meridiem.label + (fields.time_meridiem.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.time_meridiem.label}}<span *ngIf="fields.time_meridiem.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Notifications-time_meridiem_dd_single 
                            data-cy="selector"
                            [type]="fields.time_meridiem.control.type"
                            formControlName="time_meridiem"
                            (displayTextChange)="fields.time_meridiem.control.displayText=$event"
                            [placeholder]="fields.time_meridiem.control.placeholder"
                            [styles]="fields.time_meridiem.control.styles"
                            [tooltip]="fields.time_meridiem.control.tooltip"
                        >
                        </Notifications-time_meridiem_dd_single>
                        <ng-container *ngIf="fields.time_meridiem.invalid">
                          <ng-container *ngFor="let error of fields.time_meridiem.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-time_interval" *ngIf="!fields.time_interval.hidden && !fields.time_interval.removed" 
                            class="field-container standard {{fields.time_interval.invalid ? 'invalid' : ''}} {{fields.time_interval.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.time_interval.styles.style"
                            [ngClass]="fields.time_interval.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.time_interval.label + (fields.time_interval.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.time_interval.label}}<span *ngIf="fields.time_interval.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="time_interval"
                                matInput
                                numberBox
                                [format]="fields.time_interval.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.time_interval.control.readOnly || fields.time_interval.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.time_interval.control.placeholder}}"
                                [ngStyle]="fields.time_interval.control.styles.style"
                                [ngClass]="fields.time_interval.control.styles.classes"
                                [matTooltip]="fields.time_interval.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.time_interval.invalid">
                          <ng-container *ngFor="let error of fields.time_interval.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-time_interval_options" *ngIf="!fields.time_interval_options.hidden && !fields.time_interval_options.removed" 
                            class="field-container standard {{fields.time_interval_options.invalid ? 'invalid' : ''}} {{fields.time_interval_options.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.time_interval_options.styles.style"
                            [ngClass]="fields.time_interval_options.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.time_interval_options.label + (fields.time_interval_options.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.time_interval_options.label}}<span *ngIf="fields.time_interval_options.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Notifications-time_interval_dd_single 
                            data-cy="selector"
                            [type]="fields.time_interval_options.control.type"
                            formControlName="time_interval_options"
                            (displayTextChange)="fields.time_interval_options.control.displayText=$event"
                            [placeholder]="fields.time_interval_options.control.placeholder"
                            [styles]="fields.time_interval_options.control.styles"
                            [tooltip]="fields.time_interval_options.control.tooltip"
                        >
                        </Notifications-time_interval_dd_single>
                        <ng-container *ngIf="fields.time_interval_options.invalid">
                          <ng-container *ngFor="let error of fields.time_interval_options.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>