import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootPrintManager_billing_contracts_gridComponent } from './FootPrintManager.billing_contracts_grid.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Invoices_billing_contracts_status_dd_multiComponent } from './Invoices.billing_contracts_status_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_billing_contracts_gridComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Invoices_billing_contracts_status_dd_multiComponent),
  ],
  selector: 'FootPrintManager-billing_contracts_hub',
  templateUrl: './FootPrintManager.billing_contracts_hub.component.html'
})
export class FootPrintManager_billing_contracts_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { currentDate?: string, endDate?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expiring: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    expiring_days: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      new_contract: new ToolModel(new ButtonModel('new_contract', new ButtonStyles(null, null), false, false, false, 'New contract', 'icon-ic_fluent_add_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    expiring: new FieldModel(new CheckBoxModel(this.formGroup.controls['expiring'] as DatexFormControl, null, false, false, 'Filter by expiring contracts', null)
, new ControlContainerStyles(null, null), '', false, false),
    expiring_days: new FieldModel(new NumberBoxModel(this.formGroup.controls['expiring_days'] as DatexFormControl, null, false, false, '', '90', null)
, new ControlContainerStyles(null, null), 'Contracts expiring within (days)', false, false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('Filters', false, true, false, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      billing_contracts: new TabItemModel(
        this.rootTabGroup, 
        'Billing contracts', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_billing_contracts_billing_contracts_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_billing_contracts_billing_contracts_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_contracts_billing_contracts_grid_inParams_statusIds: number[];
    get $tabs_billing_contracts_billing_contracts_grid_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_contracts_billing_contracts_grid_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_billing_contracts_billing_contracts_grid_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_contracts_billing_contracts_grid_inParams_statusIds;
    }
  
    get $tabs_billing_contracts_billing_contracts_grid_inParams_endDate(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.endDate;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_billing_contracts', { read: FootPrintManager_billing_contracts_gridComponent }) $tabs_billing_contracts: FootPrintManager_billing_contracts_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Billing contracts hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Manage and create your 3PL contracts.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.billing_contracts,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Billing Contracts Hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.status.control as SelectBoxModel).reset([1]);
    (this.filters.expiring.control as CheckBoxModel).reset(false);
    (this.filters.expiring_days.control as NumberBoxModel).reset(90);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.formGroup
      .controls['expiring']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_expiring_toggle();
      });
    this.formGroup
      .controls['expiring_days']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_expiring_days();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_billing_contracts') {
      if (!isNil(this.$tabs_billing_contracts) && !this.tabs.billing_contracts.hidden) {
        this.$tabs_billing_contracts.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_billing_contracts_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if (await $operations.FootPrintManager.Disable_Navigation_Billing_Billing_Contracts.isAuthorized()) {
      
      $hub.close();
      throw new Error ('This screen currently requires authorization, please see your system admin for access.')
    
  }
  
  
  $hub.filters.expiring_days.control.readOnly = true;
  $hub.filters.expiring_days.hidden = true;
  
  $hub.vars.currentDate = $utils.date.now();
  delete $hub.vars.endDate;
  
  if ((await $operations.FootPrintManager.Disable_Billing_ContractCreation.isAuthorized())){
      $hub.toolbar.new_contract.hidden = true;
  }
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: FootPrintManager_billing_contracts_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.filters.project.control.value = null;
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $hub: FootPrintManager_billing_contracts_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const projectId = $hub.filters.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($hub.filters.owner.control.value)) {
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $hub.filters.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  
  }
  on_new_clicked(event = null) {
    return this.on_new_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_clickedInternal(
    $hub: FootPrintManager_billing_contracts_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/03/2023
  
  const dialogResult = (await $shell.FootPrintManager.openbilling_contract_creation_formDialog({ projectId: $hub.filters.project.control.value, ownerId: $hub.filters.owner.control.value, copy: false}));
  }
  on_expiring_days(event = null) {
    return this.on_expiring_daysInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_expiring_daysInternal(
    $hub: FootPrintManager_billing_contracts_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias 06/30/2023
  
  if ($hub.filters.expiring_days.control.value > 0) {
  
      let today = new Date($utils.date.now());
      today.setHours(0, 0, 0, 0);
  
      today.setDate(today.getDate() + $hub.filters.expiring_days.control.value);
  
      $hub.vars.endDate = $utils.date.format(today.toString(), "YYYY-MM-DD")
  
  };
  }
  on_expiring_toggle(event = null) {
    return this.on_expiring_toggleInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_expiring_toggleInternal(
    $hub: FootPrintManager_billing_contracts_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/15/2023
  
  if ($hub.filters.expiring.control.value === true) {
  
      //$grid.filters.expiring.control.label = 'Showing Expiring Contracts';
  
      $hub.filters.expiring_days.control.readOnly = false;
      $hub.filters.expiring_days.hidden = false;
  
      if ($hub.filters.expiring_days.control.value > 0) {
  
          let today = new Date($utils.date.now());
          today.setHours(0, 0, 0, 0);
          today.setDate(today.getDate() + $hub.filters.expiring_days.control.value);
          $hub.vars.endDate = $utils.date.format(today.toString(), "YYYY-MM-DD")
  
      };
  
  } else {
  
      //$grid.filters.expiring.control.label = 'Showing All Contracts';
      $hub.filters.expiring_days.control.readOnly = true;
      $hub.filters.expiring_days.hidden = true;
      delete $hub.vars.endDate;
  
  };
  }
  //#endregion private flows
}
