<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="create_rule" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_create_rule($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="discard" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_discard_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden && !fields.warehouse.removed" 
                            class="field-container double {{fields.warehouse.invalid ? 'invalid' : ''}} {{fields.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                            [tooltip]="fields.warehouse.control.tooltip"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-location" *ngIf="!fields.location.hidden && !fields.location.removed" 
                            class="field-container double {{fields.location.invalid ? 'invalid' : ''}} {{fields.location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.location.styles.style"
                            [ngClass]="fields.location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.location.label + (fields.location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.location.label}}<span *ngIf="fields.location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.location.control.type"
                            formControlName="location"
                            (displayTextChange)="fields.location.control.displayText=$event"
                            [placeholder]="fields.location.control.placeholder"
                            [styles]="fields.location.control.styles"
                            [tooltip]="fields.location.control.tooltip"
                          [warehouseId]="$fields_location_selector_inParams_warehouseId"
                          [typeId]="$fields_location_selector_inParams_typeId"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.location.invalid">
                          <ng-container *ngFor="let error of fields.location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden && !fields.owner.removed" 
                            class="field-container double {{fields.owner.invalid ? 'invalid' : ''}} {{fields.owner.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.owner.label + (fields.owner.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.owner.label}}<span *ngIf="fields.owner.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                            [tooltip]="fields.owner.control.tooltip"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden && !fields.project.removed" 
                            class="field-container double {{fields.project.invalid ? 'invalid' : ''}} {{fields.project.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                            [tooltip]="fields.project.control.tooltip"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden && !fields.material.removed" 
                            class="field-container double {{fields.material.invalid ? 'invalid' : ''}} {{fields.material.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.material.label + (fields.material.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.material.label}}<span *ngIf="fields.material.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-materials_by_project_dd_single 
                            data-cy="selector"
                            [type]="fields.material.control.type"
                            formControlName="material"
                            (displayTextChange)="fields.material.control.displayText=$event"
                            [placeholder]="fields.material.control.placeholder"
                            [styles]="fields.material.control.styles"
                            [tooltip]="fields.material.control.tooltip"
                          [projectId]="$fields_material_selector_inParams_projectId"
                        >
                        </Materials-materials_by_project_dd_single>
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-minimum_packaged_quantity" *ngIf="!fields.minimum_packaged_quantity.hidden && !fields.minimum_packaged_quantity.removed" 
                            class="field-container standard {{fields.minimum_packaged_quantity.invalid ? 'invalid' : ''}} {{fields.minimum_packaged_quantity.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.minimum_packaged_quantity.styles.style"
                            [ngClass]="fields.minimum_packaged_quantity.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.minimum_packaged_quantity.label + (fields.minimum_packaged_quantity.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.minimum_packaged_quantity.label}}<span *ngIf="fields.minimum_packaged_quantity.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="minimum_packaged_quantity"
                                matInput
                                numberBox
                                [format]="fields.minimum_packaged_quantity.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.minimum_packaged_quantity.control.readOnly || fields.minimum_packaged_quantity.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.minimum_packaged_quantity.control.placeholder}}"
                                [ngStyle]="fields.minimum_packaged_quantity.control.styles.style"
                                [ngClass]="fields.minimum_packaged_quantity.control.styles.classes"
                                [matTooltip]="fields.minimum_packaged_quantity.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.minimum_packaged_quantity.invalid">
                          <ng-container *ngFor="let error of fields.minimum_packaged_quantity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-minimum_packaging" *ngIf="!fields.minimum_packaging.hidden && !fields.minimum_packaging.removed" 
                            class="field-container standard {{fields.minimum_packaging.invalid ? 'invalid' : ''}} {{fields.minimum_packaging.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.minimum_packaging.styles.style"
                            [ngClass]="fields.minimum_packaging.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.minimum_packaging.label + (fields.minimum_packaging.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.minimum_packaging.label}}<span *ngIf="fields.minimum_packaging.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.minimum_packaging.control.type"
                            formControlName="minimum_packaging"
                            (displayTextChange)="fields.minimum_packaging.control.displayText=$event"
                            [placeholder]="fields.minimum_packaging.control.placeholder"
                            [styles]="fields.minimum_packaging.control.styles"
                            [tooltip]="fields.minimum_packaging.control.tooltip"
                          [materialId]="$fields_minimum_packaging_selector_inParams_materialId"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.minimum_packaging.invalid">
                          <ng-container *ngFor="let error of fields.minimum_packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-replenish_by_quantity_toggle" *ngIf="!fields.replenish_by_quantity_toggle.hidden && !fields.replenish_by_quantity_toggle.removed" 
                            class="field-container full {{fields.replenish_by_quantity_toggle.invalid ? 'invalid' : ''}} {{fields.replenish_by_quantity_toggle.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.replenish_by_quantity_toggle.styles.style"
                            [ngClass]="fields.replenish_by_quantity_toggle.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.replenish_by_quantity_toggle.label + (fields.replenish_by_quantity_toggle.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.replenish_by_quantity_toggle.label}}<span *ngIf="fields.replenish_by_quantity_toggle.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="replenish_by_quantity_toggle"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.replenish_by_quantity_toggle.control.styles.style"
                                      [ngClass]="fields.replenish_by_quantity_toggle.control.styles.classes"
                                      [matTooltip]="fields.replenish_by_quantity_toggle.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.replenish_by_quantity_toggle.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.replenish_by_quantity_toggle.invalid">
                          <ng-container *ngFor="let error of fields.replenish_by_quantity_toggle.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-replenishment_packaged_amount" *ngIf="!fields.replenishment_packaged_amount.hidden && !fields.replenishment_packaged_amount.removed" 
                            class="field-container standard {{fields.replenishment_packaged_amount.invalid ? 'invalid' : ''}} {{fields.replenishment_packaged_amount.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.replenishment_packaged_amount.styles.style"
                            [ngClass]="fields.replenishment_packaged_amount.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.replenishment_packaged_amount.label + (fields.replenishment_packaged_amount.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.replenishment_packaged_amount.label}}<span *ngIf="fields.replenishment_packaged_amount.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="replenishment_packaged_amount"
                                matInput
                                numberBox
                                [format]="fields.replenishment_packaged_amount.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.replenishment_packaged_amount.control.readOnly || fields.replenishment_packaged_amount.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.replenishment_packaged_amount.control.placeholder}}"
                                [ngStyle]="fields.replenishment_packaged_amount.control.styles.style"
                                [ngClass]="fields.replenishment_packaged_amount.control.styles.classes"
                                [matTooltip]="fields.replenishment_packaged_amount.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.replenishment_packaged_amount.invalid">
                          <ng-container *ngFor="let error of fields.replenishment_packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-replenishment_packaging" *ngIf="!fields.replenishment_packaging.hidden && !fields.replenishment_packaging.removed" 
                            class="field-container standard {{fields.replenishment_packaging.invalid ? 'invalid' : ''}} {{fields.replenishment_packaging.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.replenishment_packaging.styles.style"
                            [ngClass]="fields.replenishment_packaging.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.replenishment_packaging.label + (fields.replenishment_packaging.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.replenishment_packaging.label}}<span *ngIf="fields.replenishment_packaging.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.replenishment_packaging.control.type"
                            formControlName="replenishment_packaging"
                            (displayTextChange)="fields.replenishment_packaging.control.displayText=$event"
                            [placeholder]="fields.replenishment_packaging.control.placeholder"
                            [styles]="fields.replenishment_packaging.control.styles"
                            [tooltip]="fields.replenishment_packaging.control.tooltip"
                          [materialId]="$fields_replenishment_packaging_selector_inParams_materialId"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.replenishment_packaging.invalid">
                          <ng-container *ngFor="let error of fields.replenishment_packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-maximum_packaged_amount" *ngIf="!fields.maximum_packaged_amount.hidden && !fields.maximum_packaged_amount.removed" 
                            class="field-container standard {{fields.maximum_packaged_amount.invalid ? 'invalid' : ''}} {{fields.maximum_packaged_amount.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.maximum_packaged_amount.styles.style"
                            [ngClass]="fields.maximum_packaged_amount.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.maximum_packaged_amount.label + (fields.maximum_packaged_amount.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.maximum_packaged_amount.label}}<span *ngIf="fields.maximum_packaged_amount.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="maximum_packaged_amount"
                                matInput
                                numberBox
                                [format]="fields.maximum_packaged_amount.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.maximum_packaged_amount.control.readOnly || fields.maximum_packaged_amount.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.maximum_packaged_amount.control.placeholder}}"
                                [ngStyle]="fields.maximum_packaged_amount.control.styles.style"
                                [ngClass]="fields.maximum_packaged_amount.control.styles.classes"
                                [matTooltip]="fields.maximum_packaged_amount.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.maximum_packaged_amount.invalid">
                          <ng-container *ngFor="let error of fields.maximum_packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-maximum_packaging" *ngIf="!fields.maximum_packaging.hidden && !fields.maximum_packaging.removed" 
                            class="field-container standard {{fields.maximum_packaging.invalid ? 'invalid' : ''}} {{fields.maximum_packaging.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.maximum_packaging.styles.style"
                            [ngClass]="fields.maximum_packaging.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.maximum_packaging.label + (fields.maximum_packaging.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.maximum_packaging.label}}<span *ngIf="fields.maximum_packaging.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.maximum_packaging.control.type"
                            formControlName="maximum_packaging"
                            (displayTextChange)="fields.maximum_packaging.control.displayText=$event"
                            [placeholder]="fields.maximum_packaging.control.placeholder"
                            [styles]="fields.maximum_packaging.control.styles"
                            [tooltip]="fields.maximum_packaging.control.tooltip"
                          [materialId]="$fields_maximum_packaging_selector_inParams_materialId"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.maximum_packaging.invalid">
                          <ng-container *ngFor="let error of fields.maximum_packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden && !fieldsets.newGroup2.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup2.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-rule_trigger" *ngIf="!fields.rule_trigger.hidden && !fields.rule_trigger.removed" 
                            class="field-container double {{fields.rule_trigger.invalid ? 'invalid' : ''}} {{fields.rule_trigger.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.rule_trigger.styles.style"
                            [ngClass]="fields.rule_trigger.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.rule_trigger.label + (fields.rule_trigger.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.rule_trigger.label}}<span *ngIf="fields.rule_trigger.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Replenishments-replenishment_comparison_dd_single 
                            data-cy="selector"
                            [type]="fields.rule_trigger.control.type"
                            formControlName="rule_trigger"
                            (displayTextChange)="fields.rule_trigger.control.displayText=$event"
                            [placeholder]="fields.rule_trigger.control.placeholder"
                            [styles]="fields.rule_trigger.control.styles"
                            [tooltip]="fields.rule_trigger.control.tooltip"
                        >
                        </Replenishments-replenishment_comparison_dd_single>
                        <ng-container *ngIf="fields.rule_trigger.invalid">
                          <ng-container *ngFor="let error of fields.rule_trigger.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-system_triggered" *ngIf="!fields.system_triggered.hidden && !fields.system_triggered.removed" 
                            class="field-container double {{fields.system_triggered.invalid ? 'invalid' : ''}} {{fields.system_triggered.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.system_triggered.styles.style"
                            [ngClass]="fields.system_triggered.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.system_triggered.label + (fields.system_triggered.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.system_triggered.label}}<span *ngIf="fields.system_triggered.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="system_triggered"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.system_triggered.control.styles.style"
                                      [ngClass]="fields.system_triggered.control.styles.classes"
                                      [matTooltip]="fields.system_triggered.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.system_triggered.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.system_triggered.invalid">
                          <ng-container *ngFor="let error of fields.system_triggered.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-on_demand" *ngIf="!fields.on_demand.hidden && !fields.on_demand.removed" 
                            class="field-container double {{fields.on_demand.invalid ? 'invalid' : ''}} {{fields.on_demand.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.on_demand.styles.style"
                            [ngClass]="fields.on_demand.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.on_demand.label + (fields.on_demand.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.on_demand.label}}<span *ngIf="fields.on_demand.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="on_demand"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.on_demand.control.styles.style"
                                      [ngClass]="fields.on_demand.control.styles.classes"
                                      [matTooltip]="fields.on_demand.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.on_demand.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.on_demand.invalid">
                          <ng-container *ngFor="let error of fields.on_demand.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>