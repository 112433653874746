<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="edit" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_edit_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator1" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="change_status" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_status_change($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator2" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="create_inventory" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_create_inventory_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden && !filtersets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-material" *ngIf="!filters.material.hidden && !filters.material.removed" 
                                  class="field-container standard {{filters.material.invalid ? 'invalid' : ''}} {{filters.material.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.material.styles.style"
                                  [ngClass]="filters.material.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.material.label + (filters.material.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.material.label}}<span *ngIf="filters.material.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <div data-cy="text" class="datex-text" 
                                    [ngStyle]="filters.material.control.styles.style"
                                    [ngClass]="filters.material.control.styles.classes"
                                    [matTooltip]="filters.material.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">{{filters.material.control.text }}</div>
                              <ng-container *ngIf="filters.material.invalid">
                                <ng-container *ngFor="let error of filters.material.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden && !filters.project.removed" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}} {{filters.project.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.project.label + (filters.project.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.project.label}}<span *ngIf="filters.project.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <div data-cy="text" class="datex-text" 
                                    [ngStyle]="filters.project.control.styles.style"
                                    [ngClass]="filters.project.control.styles.classes"
                                    [matTooltip]="filters.project.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">{{filters.project.control.text }}</div>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-manufactured_on" *ngIf="!filters.manufactured_on.hidden && !filters.manufactured_on.removed" 
                                  class="field-container standard {{filters.manufactured_on.invalid ? 'invalid' : ''}} {{filters.manufactured_on.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.manufactured_on.styles.style"
                                  [ngClass]="filters.manufactured_on.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.manufactured_on.label + (filters.manufactured_on.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.manufactured_on.label}}<span *ngIf="filters.manufactured_on.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <div data-cy="text" class="datex-text" 
                                    [ngStyle]="filters.manufactured_on.control.styles.style"
                                    [ngClass]="filters.manufactured_on.control.styles.classes"
                                    [matTooltip]="filters.manufactured_on.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">{{filters.manufactured_on.control.text }}</div>
                              <ng-container *ngIf="filters.manufactured_on.invalid">
                                <ng-container *ngFor="let error of filters.manufactured_on.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-expires_on" *ngIf="!filters.expires_on.hidden && !filters.expires_on.removed" 
                                  class="field-container standard {{filters.expires_on.invalid ? 'invalid' : ''}} {{filters.expires_on.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.expires_on.styles.style"
                                  [ngClass]="filters.expires_on.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.expires_on.label + (filters.expires_on.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.expires_on.label}}<span *ngIf="filters.expires_on.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <div data-cy="text" class="datex-text" 
                                    [ngStyle]="filters.expires_on.control.styles.style"
                                    [ngClass]="filters.expires_on.control.styles.classes"
                                    [matTooltip]="filters.expires_on.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">{{filters.expires_on.control.text }}</div>
                              <ng-container *ngIf="filters.expires_on.invalid">
                                <ng-container *ngFor="let error of filters.expires_on.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-status" *ngIf="!filters.status.hidden && !filters.status.removed" 
                                  class="field-container double {{filters.status.invalid ? 'invalid' : ''}} {{filters.status.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.status.styles.style"
                                  [ngClass]="filters.status.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.status.label + (filters.status.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.status.label}}<span *ngIf="filters.status.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <div data-cy="text" class="datex-text" 
                                    [ngStyle]="filters.status.control.styles.style"
                                    [ngClass]="filters.status.control.styles.classes"
                                    [matTooltip]="filters.status.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">{{filters.status.control.text }}</div>
                              <ng-container *ngIf="filters.status.invalid">
                                <ng-container *ngFor="let error of filters.status.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.total_available_amount_widget.hidden && !widgets.total_available_amount_widget.removed" class="widget-container">
            <Inventory-total_available_amount_widget
            #$widgets_total_available_amount_widget 
              [lotId]="$widgets_total_available_amount_widget_inParams_lotId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_available_amount_widget')"
            >
            </Inventory-total_available_amount_widget>
          </div>
          <div *ngIf="!widgets.total_gross_weight_widget.hidden && !widgets.total_gross_weight_widget.removed" class="widget-container">
            <Inventory-total_gross_weight_widget
            #$widgets_total_gross_weight_widget 
              [lotId]="$widgets_total_gross_weight_widget_inParams_lotId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_gross_weight_widget')"
            >
            </Inventory-total_gross_weight_widget>
          </div>
          <div *ngIf="!widgets.total_packaged_amount_widget.hidden && !widgets.total_packaged_amount_widget.removed" class="widget-container">
            <Inventory-total_packaged_amount_widget
            #$widgets_total_packaged_amount_widget 
              [lotId]="$widgets_total_packaged_amount_widget_inParams_lotId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_packaged_amount_widget')"
            >
            </Inventory-total_packaged_amount_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.inventory.hasTabToShow" class="tab" data-cy="tab-inventory">
              <h2 [className]="tabs.inventory.active? 'active': ''" (click)="tabs.inventory.activate()">{{tabs.inventory.title}}</h2>
            </div>
            <div *ngIf="tabs.licenseplates.hasTabToShow" class="tab" data-cy="tab-licenseplates">
              <h2 [className]="tabs.licenseplates.active? 'active': ''" (click)="tabs.licenseplates.activate()">{{tabs.licenseplates.title}}</h2>
            </div>
            <div *ngIf="tabs.tasks.hasTabToShow" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
            <div *ngIf="tabs.serialnumbers.hasTabToShow" class="tab" data-cy="tab-serialnumbers">
              <h2 [className]="tabs.serialnumbers.active? 'active': ''" (click)="tabs.serialnumbers.activate()">{{tabs.serialnumbers.title}}</h2>
            </div>
            <div *ngIf="tabs.udfs.hasTabToShow" class="tab" data-cy="tab-udfs">
              <h2 [className]="tabs.udfs.active? 'active': ''" (click)="tabs.udfs.activate()">{{tabs.udfs.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-inventory_location_grid *ngIf="tabs.inventory.active"
              #$tabs_inventory
              [lotId]="$tabs_inventory_inventory_location_grid_inParams_lotId"
              ($refreshEvent)="refresh(false, false, '$tabs_inventory')">
              </FootPrintManager-inventory_location_grid>
              <FootPrintManager-licenseplate_list *ngIf="tabs.licenseplates.active"
              #$tabs_licenseplates
              [lotId]="$tabs_licenseplates_licenseplate_list_inParams_lotId"
              ($refreshEvent)="refresh(false, false, '$tabs_licenseplates')">
              </FootPrintManager-licenseplate_list>
              <FootPrintManager-inventory_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [lotId]="$tabs_tasks_inventory_tasks_grid_inParams_lotId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-inventory_tasks_grid>
              <FootPrintManager-serialnumbers_grid *ngIf="tabs.serialnumbers.active"
              #$tabs_serialnumbers
              [lotId]="$tabs_serialnumbers_serialnumbers_grid_inParams_lotId"
              [archived]="$tabs_serialnumbers_serialnumbers_grid_inParams_archived"
              ($refreshEvent)="refresh(false, false, '$tabs_serialnumbers')">
              </FootPrintManager-serialnumbers_grid>
              <FootPrintManager-lots_udf_grid *ngIf="tabs.udfs.active"
              #$tabs_udfs
              [lotId]="$tabs_udfs_lots_udf_grid_inParams_lotId"
              ($refreshEvent)="refresh(false, false, '$tabs_udfs')">
              </FootPrintManager-lots_udf_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>