<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-main"
                     *ngIf="!fieldsets.main.hidden && !fieldsets.main.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.main.collapsible }">
                    <div *ngIf="!fieldsets.main.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.main.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.main.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.main.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.main.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.main.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.main.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-location" *ngIf="!fields.location.hidden && !fields.location.removed" 
                            class="field-container standard {{fields.location.invalid ? 'invalid' : ''}} {{fields.location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.location.styles.style"
                            [ngClass]="fields.location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.location.label + (fields.location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.location.label}}<span *ngIf="fields.location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <PackVerification-verification_locations_dd_single 
                            data-cy="selector"
                            [type]="fields.location.control.type"
                            formControlName="location"
                            (displayTextChange)="fields.location.control.displayText=$event"
                            [placeholder]="fields.location.control.placeholder"
                            [styles]="fields.location.control.styles"
                            [tooltip]="fields.location.control.tooltip"
                          [warehouseId]="$fields_location_selector_inParams_warehouseId"
                        >
                        </PackVerification-verification_locations_dd_single>
                        <ng-container *ngIf="fields.location.invalid">
                          <ng-container *ngFor="let error of fields.location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>