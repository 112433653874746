<div class="dataview">
  <div class="summary-container">
    <div class="data-results-summary">
      <span class="results-count">{{totalCount}} {{totalCount === 1 ? 'item' : 'items'}}</span>
      <ng-container *ngIf="selection && hasSelectedRows()">
        <div class="results-selected"
             data-cy="selection-info">
          <div matTooltip="{{ selectedRowsCount }} selected"
               matTooltipClass="datex-control-tooltip"
               class="datex-text"
               data-cy="selection-info-text">({{ selectedRowsCount }} selected)</div>
          <button (click)="onClearSelectionClicked()"
                  class="datex-button"
                  data-cy="selection-info-clear-button">
            <div class="button-label">
              <div class="button-icon"><i class="icon icon-ic_fluent_dismiss_20_regular"></i></div>
              <div class="button-text"></div>
            </div>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="dataview-tools">
    <ng-content select="[topToolbar]"></ng-content>
  </div>
  <div class="dataview-filters">
    <ng-content select="[filters]"></ng-content>
  </div>

  <div class="dataview-grid styled-grid">
    <div class="grid-container"
         [ngClass]="containerStyles.classes"
         [ngStyle]="containerStyles.style"
         data-cy="grid">
      <div class="grid-table grid-table-striped"
           [ngStyle]="tableStyleComputed">
        <div class="grid-table-header">
          <div *ngIf="rowCanExpand"
               class="grid-table-cell-header grid-table-row-expander-header grid-table-cell-header-controls">
          </div>
          <div *ngIf="selection"
               class="grid-table-cell-header grid-table-cell-checkbox">
            <mat-checkbox [(ngModel)]="allSelected"
                          data-cy="selection-all-selected"
                          [indeterminate]="allSelected === undefined"
                          color="primary"
                          class="datex-checkbox"></mat-checkbox>
          </div>
          <ng-container *ngFor="let headerId of headersOrder">
            <div class="grid-table-cell-header"
                 *ngIf="!headers[headerId].hidden && !headers[headerId].removed"
                 [ngStyle]="getHeaderStyle(headers[headerId])"
                 [ngClass]="headers[headerId].styles.classes"
                 [matTooltip]="headers[headerId].name + (headers[headerId].required ? ' (required)' : '')"
                 matTooltipClass="datex-control-tooltip">
              {{headers[headerId].name}}
              <span *ngIf="headers[headerId].required"
                    class="required-asterisk">*</span>
            </div>
          </ng-container>
          <div *ngIf="editable || rowCommandTemplateRef"
               class="grid-table-cell-header grid-table-cell-header-command"
               [ngClass]="{
                'grid-table-cell-header-controls': editable,
                'single': rowCommandTemplateRef && !editable
              }">
          </div>
        </div>
        <ng-container *ngFor="let row of rows; index as i">
          <div class="grid-table-row 
                      {{ selection && row.selected ? 'selected-row' : '' }} 
                      {{ row.isEdit ? 'new-row-creator' : '' }} 
                      {{ rowCanExpand && i % 2 === 0 ? 'even-row' : '' }} 
                      {{ rowCanExpand && i % 2 !== 0 ? 'odd-row' : '' }}"
               [ngClass]="{'expanded-row': row.expand}"
               [ngClass]="row.styles.classes"
               [ngStyle]="row.styles.style"
               data-cy="grid-row">
            <div *ngIf="rowCanExpand"
                 class="grid-table-cell-data grid-table-row-expander"
                 (click)="onExpandRowClicked(row)"
                 [ngClass]="{
                  'expanded': row.expand, 
                  'border-even-row': i % 2 === 0, 
                  'border-odd-row': i % 2 !== 0
                }">
              <i data-cy="button-expand-row"
                 class="icon"
                 [ngClass]="row.expand ? 'icon-ic_fluent_chevron_down_20_regular':'icon-ic_fluent_chevron_right_20_regular'"></i>
            </div>
            <div *ngIf="selection && !row.isNew"
                 class="grid-table-cell-data grid-table-cell-checkbox"
                 [ngClass]="{'show_on_mouse_over': !row.selected}">
              <mat-checkbox [ngModel]="row.selected"
                            [attr.data-cy]="'selection-row-selected-' + i"
                            (click)="onSelectedClick($event, i)"
                            [indeterminate]="row.selected === undefined"
                            color="primary"
                            style="display: flex;"
                            class="datex-checkbox">
              </mat-checkbox>
            </div>
            <div *ngIf="selection && row.isNew"
                 class="grid-table-cell-data">
            </div>
            <ng-container *ngFor="let headerId of headersOrder">
              <ng-container [formGroup]="row.formGroup">
                <div class="grid-table-cell-data {{row.cells[headerId].isEditable && row.cells[headerId].isDisplay ? 'outline-border' : ''}}"
                     *ngIf="!headers[headerId].hidden && !headers[headerId].removed"
                     [ngStyle]="getCellStyle(headers[headerId], row.cells[headerId])"
                     [ngClass]="row.cells[headerId].styles.classes"
                     (click)="onCellClick(headerId, row, i)"
                     (keydown)="onCellKeyDown($event, headerId, row, i)"
                     [attr.data-cy]="'grid-cell-id-'+headerId">

                  <ng-container *ngIf="row.cells[headerId].isDisplay">
                    <ng-container
                                  *ngTemplateOutlet="_columnDefsByName.get(headerId).displayControlDef.template; context: {$implicit: row}">
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="row.cells[headerId].isEdit && _columnDefsByName.get(headerId).editControlDef">
                    <ng-container
                                  *ngTemplateOutlet="_columnDefsByName.get(headerId).editControlDef.template; context: {$implicit: row}">
                    </ng-container>
                  </ng-container>

                </div>
              </ng-container>
            </ng-container>
            <div *ngIf="editable || rowCommandTemplateRef"
                 class="grid-table-cell-data grid-table-cell-command">
              <ng-container *ngIf="row.isEdit">
                <button class="grid-command datex-button"
                        [matMenuTriggerFor]="menu"
                        [disabled]="row.$isInAction">
                  <i class="icon icon-ic_fluent_more_horizontal_20_regular"></i>
                </button>
                <mat-menu #menu="matMenu"
                          class="options-menu">
                  <button *ngIf="row.isNew"
                          mat-menu-item
                          (click)="commandCancelNewRowClicked(row)">
                    <span>Cancel new row</span>
                  </button>
                  <button *ngIf="!row.isNew"
                          mat-menu-item
                          (click)="commandCancelExistingRowClicked(row)">
                    <span>Cancel edit row</span>
                  </button>
                </mat-menu>
                <button *ngIf="row.$isInAction"
                        class="grid-command datex-button row-confirm row-processing"
                        [disabled]="true">
                  <i class="icon datex-default-spinner"></i>
                </button>
                <button *ngIf="row.isInvalid"
                        class="grid-command datex-button row-confirm row-invalid"
                        [disabled]="true">
                  <i class="icon icon-ic_fluent_checkmark_20_regular"></i>
                </button>
                <button *ngIf="row.$isReadyToConfirm"
                        class="grid-command datex-button row-confirm row-valid"
                        (click)="commandConfirmClicked(row)"
                        data-cy="button-confirm">
                  <!-- <i *ngIf="row.isChanged"
                   class="icon icon-ic_fluent_save_20_regular"></i> -->
                  <i class="icon icon-ic_fluent_checkmark_20_regular"></i>
                </button>
              </ng-container>
              <ng-container *ngIf="rowCommandTemplateRef && !row.isEdit">
                <button class="grid-command datex-button {{gridOptionsMenu.menuOpen ? 'open' : ''}}"
                        [matMenuTriggerFor]="optionsMenu"
                        #gridOptionsMenu="matMenuTrigger">
                  <i class="icon icon-ic_fluent_more_horizontal_20_regular"></i>
                </button>
                <mat-menu #optionsMenu="matMenu"
                          class="options-menu">
                  <ng-container *ngTemplateOutlet="rowCommandTemplateRef; context: {$implicit: row }">
                  </ng-container>
                </mat-menu>
              </ng-container>
            </div>
          </div>

          <div class="grid-table-row-child"
               *ngIf="expandableRowDef && row.expand"
               [ngClass]="{'border-even-row': i % 2 === 0, 'border-odd-row': i % 2 !== 0}">
            <td colspan="9999">
              <ng-container *ngTemplateOutlet="expandableRowDef.template; context: {$implicit: row}"></ng-container>
            </td>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="loadingStatus === ELoadingStatus.Loading"
         class="grid-loading"
         data-cy="grid-loading">
      <h3 [ngClass]="{'extra-loading': rows?.length > 20}">Loading...</h3>
    </div>
    <div *ngIf="loadingStatus === ELoadingStatus.Error"
         class="grid-error">
      <h3>Error occured while loading...</h3>
    </div>
    <div *ngIf="loadingStatus === ELoadingStatus.NoResults"
         class="no-results"
         data-cy="grid-no-results">
      <h3>No results found</h3>
    </div>
  </div>

  <div class="grid-tools">
    <div *ngIf="canAdd && addLineModel && !addLineModel.hidden"
         class="toolContainer"
         data-cy="add-new-row">
      <button mat-button
              class="datex-button creation"
              [ngClass]="addLineModel.control.styles"
              [disabled]="addLineModel.control.readOnly || addLineModel.control.disabled || !canSwitchRow"
              (click)="onCommandAddLineClicked()">
        <div class="button-label">
          <div *ngIf="addLineModel.control.icon"
               class="button-icon">
            <i class="icon {{addLineModel.control.icon}}"></i>
          </div>
          <div class="button-text">{{addLineModel.control.label}}</div>
        </div>
      </button>
    </div>

    <ng-content select="[bottomToolbar]"></ng-content>
  </div>

  <div class="pagination-container"
       data-cy="pagination">
    <div *ngIf="pagesCount > 1"
         class="pagination">
      <span data-cy="pagination-info">
        {{currentPageFrom}}
        to
        {{currentPageTo}} of {{totalCount}}
      </span>
      <a (click)="goToPage(1)"
         data-cy="pagination-goto-first"><i class="icon icon-ic_fluent_previous_20_regular"></i></a>
      <a (click)="goToPage(currentPage - 1)"
         data-cy="pagination-goto-previous"><i class="icon icon-ic_fluent_triangle_left_20_regular"></i></a>
      <span>Page {{currentPage}} of {{pagesCount}}</span>
      <a (click)="goToPage(currentPage + 1)"
         data-cy="pagination-goto-next"><i class="icon icon-ic_fluent_triangle_right_20_regular"></i></a>
      <a (click)="goToPage(pagesCount)"
         data-cy="pagination-goto-last"><i class="icon icon-ic_fluent_next_20_regular"></i></a>
    </div>
  </div>

</div>