import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_survey_grid_input_values_gridComponent } from './FootPrintManager.survey_grid_input_values_grid.component';

interface IFootPrintManager_submitted_survey_inputs_gridComponentEntity {
  id?: number, index?: number, inputBoolean?: boolean, inputDate?: string, inputNumeric?: number, inputText?: string, isCompleted?: boolean, Element?: { elementTypeId?: number, text?: string }}

interface IFootPrintManager_submitted_survey_inputs_gridComponentInParams {
  surveyId: number}


class FootPrintManager_submitted_survey_inputs_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_submitted_survey_inputs_gridComponent;
  entity: IFootPrintManager_submitted_survey_inputs_gridComponentEntity;

  vars: { values?: { header1?: string, header2?: string, header3?: string, header4?: string, header5?: string }[], headers?: { header1?: string, header2?: string, header3?: string, header4?: string, header5?: string } } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    question: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    input_value: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }


  //#region rowExpand inParams

  cacheValueFor_$rowExpand_FootPrintManager_survey_grid_input_values_grid_inParams_values: { header1?: string, header2?: string, header3?: string, header4?: string, header5?: string }[];

  get $rowExpand_FootPrintManager_survey_grid_input_values_grid_inParams_values(): { header1?: string, header2?: string, header3?: string, header4?: string, header5?: string }[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.vars.values as { header1: string,header2: string,header3: string,header4: string,header5: string}[];
    
    if(!isEqual(this.cacheValueFor_$rowExpand_FootPrintManager_survey_grid_input_values_grid_inParams_values, expr)) {
      this.cacheValueFor_$rowExpand_FootPrintManager_survey_grid_input_values_grid_inParams_values = expr;
    }
    return this.cacheValueFor_$rowExpand_FootPrintManager_survey_grid_input_values_grid_inParams_values;
  }

  cacheValueFor_$rowExpand_FootPrintManager_survey_grid_input_values_grid_inParams_headers: { header1?: string, header2?: string, header3?: string, header4?: string, header5?: string };

  get $rowExpand_FootPrintManager_survey_grid_input_values_grid_inParams_headers(): { header1?: string, header2?: string, header3?: string, header4?: string, header5?: string } {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.vars.headers as { header1: string,header2: string,header3: string,header4: string,header5: string};
    
    if(!isEqual(this.cacheValueFor_$rowExpand_FootPrintManager_survey_grid_input_values_grid_inParams_headers, expr)) {
      this.cacheValueFor_$rowExpand_FootPrintManager_survey_grid_input_values_grid_inParams_headers = expr;
    }
    return this.cacheValueFor_$rowExpand_FootPrintManager_survey_grid_input_values_grid_inParams_headers;
  }
  //#endregion rowExpand inParams

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_submitted_survey_inputs_gridComponent, entity: IFootPrintManager_submitted_survey_inputs_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_submitted_survey_inputs_gridComponent, entity?: IFootPrintManager_submitted_survey_inputs_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      surveyId:  $grid.inParams.surveyId ,
    };
    const data = await this.datasources.Surveys.ds_submitted_survey_inputs_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_submitted_survey_inputs_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.question.displayControl as TextModel).text = $row.entity.Element?.text;
    (this.cells.input_value.displayControl as TextModel).text = $row.entity.inputText;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_submitted_survey_inputs_gridComponentRowModel,
  $grid: FootPrintManager_submitted_survey_inputs_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.entity.Element.elementTypeId === 2) {
      var aTags = document.getElementsByTagName("div");
      var div;
  
      for (var i = 0; i < aTags.length; i++) {
          if (aTags[i].textContent == $row.entity.inputText) {
              div = aTags[i];
              break;
          }
      }
  
      if (div) {
          // You can now use myDivByValue
          div.setAttribute('data-cy', 'image');
          div.setAttribute('class', 'datex-image ng-star-inserted');
          div.setAttribute('style', 'width: 250px; max-height: 250px; display: inline;');
          div.setAttribute('src', $row.entity.inputText);
          div.textContent = '';
  
          const newElement = document.createElement('img');
  
          newElement.innerHTML = div.innerHTML;
  
          for (let i = 0; i < div.attributes.length; i++) {
              const attribute = div.attributes[i];
              newElement.setAttribute(attribute.name, attribute.value);
          }
  
          div.parentNode?.replaceChild(newElement, div);
      }
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_survey_grid_input_values_gridComponent)
  ],
  selector: 'FootPrintManager-submitted_survey_inputs_grid',
  templateUrl: './FootPrintManager.submitted_survey_inputs_grid.component.html'
})
export class FootPrintManager_submitted_survey_inputs_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_submitted_survey_inputs_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_submitted_survey_inputs_gridComponentInParams = { surveyId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     question: new GridHeaderModel(new HeaderStyles(null, null), 'question', 'Question', false, false, null, false),       input_value: new GridHeaderModel(new HeaderStyles(null, null), 'input_value', 'Response', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_submitted_survey_inputs_gridComponentRowModel[] = [];

  @Input('surveyId') set $inParams_surveyId(value: any) {
    this.inParams['surveyId'] = value;
  }
  get $inParams_surveyId(): any {
    return this.inParams['surveyId'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Survey inputs';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.surveyId)) {
        this.$missingRequiredInParams.push('surveyId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 100;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      surveyId:  $grid.inParams.surveyId ,
    };
    try {
      const data = await this.datasources.Surveys.ds_submitted_survey_inputs_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_submitted_survey_inputs_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_submitted_survey_inputs_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_submitted_survey_inputs_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_submitted_survey_inputs_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  formatRows();
  
  /****************************************************
   * FUNCTIONS
  *****************************************************/
  async function renderImage(row: any) {
  
      var aTags = document.getElementsByTagName("div");
      var div;
  
      for (var i = 0; i < aTags.length; i++) {
          if (aTags[i].textContent == row.entity.inputText) {
              div = aTags[i];
              break;
          }
      }
  
      if (div) {
          // You can now use myDivByValue
          div.setAttribute('data-cy', 'image');
          div.setAttribute('class', 'datex-image ng-star-inserted');
          div.setAttribute('style', 'width: 125px; max-height: 125px; display: inline;');
          div.setAttribute('src', row.entity.inputText);
          div.textContent = '';
  
          const newElement = document.createElement('img');
  
          newElement.innerHTML = div.innerHTML;
  
          for (let i = 0; i < div.attributes.length; i++) {
              const attribute = div.attributes[i];
              newElement.setAttribute(attribute.name, attribute.value);
          }
  
          div.parentNode?.replaceChild(newElement, div);
      }
  }
  
  
  async function formatRows() {
      await new Promise(result => setTimeout(result, 50));
  
      /**** Get HTML elements ***/
      const gridElement: NodeListOf<HTMLElement> = document.querySelectorAll(`footprintmanager-submitted_survey_inputs_grid`);
  
      const attributeName = "data-cy";
      const attributeValue = "grid-row";
  
      const divsWithAttribute: NodeListOf<HTMLDivElement> = (gridElement[0] ?? document).querySelectorAll(`div[${attributeName}="${attributeValue}"]`);
  
      // Convert the NodeList to an array if needed
      const gridRowDivs: HTMLDivElement[] = Array.from(divsWithAttribute);
  
      /*** Format based on question type ***/
      let index = 0;
      for (let row of $grid.rows) {
          let expandField: HTMLElement;
  
          switch (row.entity.Element.elementTypeId) {
              case 1: // Question
                  if ($utils.isDefined(row.entity.inputDate) || (!isNaN(Date.parse(row.entity.inputText)) && (is_valid_date(row.entity.inputText)))) {
  
                      let format = `MM/DD/yyyy, LT`;
                      row.cells.input_value.displayControl.text = $utils.date.format(row.entity.inputDate, format);
                  }
  
                  expandField = gridRowDivs[index].querySelector('i');
                  expandField.setAttribute('hidden', 'true');
  
                  break;
              case 2:  // Signature
                  expandField = gridRowDivs[index].querySelector('i');
                  expandField.setAttribute('hidden', 'true');
  
                  renderImage(row)
  
                  break;
              case 3: // Image
                  // Preview ???
                  expandField = gridRowDivs[index].querySelector('i');
                  expandField.setAttribute('hidden', 'true');
  
                  renderImage(row)
  
                  break;
              case 4: // Grid
                  // Hide grids
                  row.cells.input_value.displayControl.text = null;
  
                  row.vars.values = [];
                  row.vars.headers = {};
  
                  // Set headers
                  let gridRows = row.entity.inputText.split('[|]');
  
                  gridRows.forEach((inputRow, index) => {
                      let cells = inputRow.split('[;]');
  
                      let value: any = {};
  
                      cells.forEach((inputCell, j) => {
                          let keyValue = { key: inputCell.split('[:]')[0], value: inputCell.split('[:]')[1] };
  
                          if (index === 0) {
                              row.vars.headers[`header${j + 1}`] = keyValue.key;
                          }
  
                          value[`header${j + 1}`] = keyValue.value;
                      })
  
                      row.vars.values.push(value);
                  });
  
                  break;
          }
  
          index++;
      }
  
  
      // // Aggregate lists
      for (let i = $grid.rows.length - 1; i >= 0; i--) {
          let matchingRow = $grid.rows.find((row, index) => row.entity.Element.text === $grid.rows[i].entity.Element.text && index !== i);
  
          if ($utils.isDefined(matchingRow)) {
              matchingRow.cells.input_value.displayControl.text += ', ' + $grid.rows[i].cells.input_value.displayControl.text;
  
              $grid.rows.splice(i, 1);
          }
      }
  }
  
  function is_valid_date(dateString: string): boolean {
      // Regex to match common date formats, timestamps with timezones, and milliseconds
      const datePattern = /^\d{4}-\d{2}-\d{2}$|^\d{2}\/\d{2}\/\d{4}$|^\d{2}-\d{2}-\d{4}$|^\d{4}\/\d{2}\/\d{2}$|^\d{2}\/\d{2}\/\d{2}$|^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$|^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}$|^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$|^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}$|^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$|^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/;
  
      if (!datePattern.test(dateString)) {
          return false;
      }
  
      const date = new Date(dateString);
      return !isNaN(date.getTime());
  }
  }
  on_export_clicked(event = null) {
    return this.on_export_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_clickedInternal(
    $grid: FootPrintManager_submitted_survey_inputs_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      // Export as excel
      let wb = $event;
  
      let content = [];
      $grid.rows.map(row => {
          if ($grid.selectedRows.length === 0 || row.selected) {
              if (!$utils.isDefined(content[0])) { content.push({}); }
  
              if (row.entity.Element.elementTypeId === 1) { // Question
                  content[0][row.entity.Element.text] = row.cells.input_value.displayControl.text;
              }
              else if (row.entity.Element.elementTypeId === 4) { // Grid
                  let headerIndex = 0;
                  for (let key in row.vars.headers) {
                      if (headerIndex === 0) {
                          content[0][row.entity.Element.text] = row.vars.headers[key];
                      }
                      else {
                          content[0][''.padEnd(headerIndex, ' ')] = row.vars.headers[key];
                      }
  
                      headerIndex++;
                  }
  
                  let rowIndex = 0;
                  row.vars.values.forEach(value => {
                      if (!$utils.isDefined(content[rowIndex + 1])) {
                          content.push({})
                      }
  
                      headerIndex = 0;
                      for (let key in row.vars.headers) {
                          if (headerIndex === 0) {
                              content[rowIndex + 1][row.entity.Element.text] = row.vars.values[rowIndex][key];
                          }
                          else {
                              content[rowIndex + 1][''.padEnd(headerIndex, ' ')] = row.vars.values[rowIndex][key];
                          }
  
                          headerIndex++;
                      }
  
                      rowIndex++;
                  });
              }
              else {
                  if ($grid.selectedRows.length === 1) {
                      // Download image
                      $utils.blob.saveFile($utils.blob.fromBase64(row.entity.inputText), { fileName: row.entity.Element.text + '.' + getFileExtensionFromBase64(row.entity.inputText) });
  
                      throw new Error('Abort excel download');
                  }
                  else {
                      content[0][row.entity.Element.text] = `IMAGE`;
                  }
              }
          }
      });
  
      let ws = $utils.excel.json_to_sheet(content);
  
      $utils.excel.book_append_sheet(wb, ws, `Survey${$grid.inParams.surveyId}`);
  
  }
  catch (error) {
      // Do nothing
  }
  
  function getFileExtensionFromBase64(base64String: string): string | null {
      const matches = base64String.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/);
  
      if (!matches || matches.length < 2) {
          return null; // Invalid data URI
      }
  
      const mimeType = matches[1];
      const extensionMatches = mimeType.match(/\/([a-zA-Z0-9-.+]+)$/);
  
      if (!extensionMatches || extensionMatches.length < 2) {
          return null; // Invalid MIME type
      }
  
      return extensionMatches[1];
  }
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_clicked(wb);

    writeExcelFile(wb, 'FootPrintManager_submitted_survey_inputs_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
