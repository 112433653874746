import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  NgZone,
  Inject
} from '@angular/core';

import {
  ChartComponent,
  ApexTitleSubtitle,
  ApexChart,
  ApexLegend,
  ApexTooltip,
  ApexDataLabels,
  ApexStroke,
  ApexTheme
} from 'ng-apexcharts';

import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { PalletTransactions_ShellService } from './PalletTransactions.shell.service';
import { PalletTransactions_OperationService } from './PalletTransactions.operation.service';
import { PalletTransactions_DatasourceService } from './PalletTransactions.datasource.index';
import { PalletTransactions_FlowService } from './PalletTransactions.flow.index';
import { PalletTransactions_ReportService } from './PalletTransactions.report.index';
import { PalletTransactions_LocalizationService } from './PalletTransactions.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './PalletTransactions.frontend.types'
import { $frontendTypes as $types} from './PalletTransactions.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'PalletTransactions-pallet_transactions_by_direction_widget',
  templateUrl: './PalletTransactions.pallet_transactions_by_direction_widget.component.html'
})
export class PalletTransactions_pallet_transactions_by_direction_widgetComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  outParamsChange = new EventEmitter<{ slice?: { entity?: { Label?: string, Value?: number } } }>();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { days?: number, project_id?: number, pallet_class_id?: number } = { days: null, project_id: null, pallet_class_id: null };
  //#region Inputs
  @Input('days') set $inParams_days(v: number) {
    this.inParams.days = v;
  }
  get $inParams_days(): number {
    return this.inParams.days;
  }
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('pallet_class_id') set $inParams_pallet_class_id(v: number) {
    this.inParams.pallet_class_id = v;
  }
  get $inParams_pallet_class_id(): number {
    return this.inParams.pallet_class_id;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion
  @ViewChild('pieChart') 
  pieChart: ChartComponent;

  private selected = false;
  private entities: { Label?: string, Value?: number }[];

  series: number[] = [];
  labels: string[] = [];

  chart: ApexChart = {
    type: 'pie',
    width: '100%',
    height: '170px', // fixed heigh because of issues with ApexCharts not being able to respect the container height
    redrawOnParentResize: true,
    fontFamily: '"Segoe UI", "Roboto", sans-serif',
    events: {
      dataPointSelection: (event, chartContext, config) => {
        // run inside the Angular zone, since ng-apexcharts runs almost everything outside of it
        // see: https://github.com/apexcharts/ng-apexcharts/blob/master/projects/ng-apexcharts/src/lib/chart/chart.component.ts
        this.ngZone.run(() => {
          if (this.isPiePointSelected(config.dataPointIndex)) {
            this.outParamsChange.emit({ slice: null});
          } else {
            this.outParamsChange.emit({ slice: { entity: this.entities[config.dataPointIndex] } } );
          }
        });
      }
    }
  };
  dataLabels: ApexDataLabels = {
    enabled: false,
  };
  stroke: ApexStroke = {
    width: 0
  };
  title: ApexTitleSubtitle = {
  };
  legend: ApexLegend = {
    show: false
  };
  tooltip: ApexTooltip = {
    followCursor: false,
    fillSeriesColor: true,
    onDatasetHover: {
      highlightDataSeries: true,
    },
    style: {
      fontSize: '14px',
      fontFamily: '"Segoe UI", "Roboto", sans-serif',
    },
    enabled: true
  };
  theme: ApexTheme = {
    palette: 'palette2',
  };

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: PalletTransactions_ShellService,
    private datasources: PalletTransactions_DatasourceService,
    private flows: PalletTransactions_FlowService,
    private reports: PalletTransactions_ReportService,
    private localization: PalletTransactions_LocalizationService,
    private operations: PalletTransactions_OperationService,
    private logger: CleanupLoggerService,
    private ngZone: NgZone
  ) { 
    super();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  async $init() {
    
    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
      days:  $widget.inParams.days ,
      project_id:  $widget.inParams.project_id ,
      pallet_class_id:  $widget.inParams.pallet_class_id 
    };

    const data = await this.datasources.PalletTransactions.ds_pallet_transactions_widget.getList(dsParams);
    this.entities = data.result;
    await this.$dataLoaded();
  }

   async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    if(this.entities) {
      this.labels = this.entities.map(e => { 
        const $slice = { entity:  e };
        return $slice.entity.Label;
      });

      this.series = this.entities.map(e => { 
        const $slice = { entity:  e };
        return $slice.entity.Value;
      });
    }

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  private isPiePointSelected(pointIndex: number) {
    if (this.pieChart) {
      const pieSlice = (this.pieChart as any).chartObj.w.globals.dom.Paper.select(`.apexcharts-pie-slice-${pointIndex}`).members[0];
      const isSelected = pieSlice.attr('data:pieClicked');
      return isSelected === 'true';
    }
    return false;
  }

}
