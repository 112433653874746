import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootPrintManager_shipping_containers_gridComponent } from './FootPrintManager.shipping_containers_grid.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { ExcelOrderImport_warehouses_dd_multiComponent } from './ExcelOrderImport.warehouses_dd_multi.component'
import { ShippingContainers_shipment_statuses_dd_multiComponent } from './ShippingContainers.shipment_statuses_dd_multi.component'
import { ShippingContainers_order_types_multiComponent } from './ShippingContainers.order_types_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_shipping_containers_gridComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => ExcelOrderImport_warehouses_dd_multiComponent),
    forwardRef(() => ShippingContainers_shipment_statuses_dd_multiComponent),
    forwardRef(() => ShippingContainers_order_types_multiComponent),
  ],
  selector: 'FootPrintManager-shipping_containers_hub',
  templateUrl: './FootPrintManager.shipping_containers_hub.component.html'
})
export class FootPrintManager_shipping_containers_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { projectIds?: number[], ownerIds?: number[] } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shipment_statuses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    warehouses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouses'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouses', false, false),
    shipment_statuses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['shipment_statuses'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Statuses', false, false),
    order_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_type'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Order type', false, false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('Filters', false, true, false, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      shipping_containers: new TabItemModel(
        this.rootTabGroup, 
        'Shipping containers', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_ownerIds: number[];
    get $tabs_shipping_containers_shipping_containers_grid_inParams_ownerIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.ownerIds;
      
      if(!isEqual(this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_ownerIds, expr)) {
        this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_ownerIds = expr;
      }
      return this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_ownerIds;
    }
  
    cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_projectIds: number[];
    get $tabs_shipping_containers_shipping_containers_grid_inParams_projectIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.projectIds;
      
      if(!isEqual(this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_projectIds, expr)) {
        this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_projectIds = expr;
      }
      return this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_projectIds;
    }
  
    cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_warehouseIds: number[];
    get $tabs_shipping_containers_shipping_containers_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_warehouseIds;
    }
  
    cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_orderTypeIds: number[];
    get $tabs_shipping_containers_shipping_containers_grid_inParams_orderTypeIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.order_type.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_orderTypeIds, expr)) {
        this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_orderTypeIds = expr;
      }
      return this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_orderTypeIds;
    }
  
    cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_shipmentStatuses: number[];
    get $tabs_shipping_containers_shipping_containers_grid_inParams_shipmentStatuses(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.shipment_statuses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_shipmentStatuses, expr)) {
        this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_shipmentStatuses = expr;
      }
      return this.cacheValueFor_$tabs_shipping_containers_shipping_containers_grid_inParams_shipmentStatuses;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_shipping_containers', { read: FootPrintManager_shipping_containers_gridComponent }) $tabs_shipping_containers: FootPrintManager_shipping_containers_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_project_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Shipping containers hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Your landing page to view your shipping containers.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.shipping_containers,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Shipping containers hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.shipment_statuses.control as SelectBoxModel).reset([1,2,4]);
    (this.filters.order_type.control as SelectBoxModel).reset([1,2]);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_shipping_containers') {
      if (!isNil(this.$tabs_shipping_containers) && !this.tabs.shipping_containers.hidden) {
        this.$tabs_shipping_containers.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: FootPrintManager_shipping_containers_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.filters.project.control.value = null;
  $hub.vars.projectIds = null;
  
  if ($utils.isDefined($hub.filters.owner.control.value)) {
      $hub.vars.ownerIds = [$hub.filters.owner.control.value];
  } else {
      $hub.vars.ownerIds = null;
  };
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $hub: FootPrintManager_shipping_containers_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const projectId = $hub.filters.project.control.value
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($hub.filters.owner.control.value)) {
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
  
      if ($utils.isDefined(project)) {
          $hub.vars.ownerIds = [project[0].OwnerId];
          $hub.filters.owner.control.value = project[0].OwnerId;
      };
  };
  
  if ($utils.isDefined($hub.filters.project.control.value)) {
      $hub.vars.projectIds = [$hub.filters.project.control.value];
  } else {
     $hub.vars.projectIds = null; 
  };
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_shipping_containers_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (await $operations.FootPrintManager.Disable_Navigation_Transportation_Shipping_Containers.isAuthorized()) {
      
      $hub.close();
      throw new Error ('This screen currently requires authorization, please see your system admin for access.')
    
  }
  
  
  }
  //#endregion private flows
}
