import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Materials_materials_by_project_dd_multiComponent } from './Materials.materials_by_project_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_multiComponent),
  ],
  selector: 'FootPrintManager-material_copy_form',
  templateUrl: './FootPrintManager.material_copy_form.component.html'
})
export class FootPrintManager_material_copy_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { projectId?: number, ownerId?: number, materialIds?: number[] } = { projectId: null, ownerId: null, materialIds: [] };
  //#region Inputs
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('materialIds') set $inParams_materialIds(v: number[]) {
    this.inParams.materialIds = v;
  }
  get $inParams_materialIds(): number[] {
    return this.inParams.materialIds;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean, materialId?: number } = { confirm: null, materialId: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean, materialId?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    materialList: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    material: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    targetOwner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    targetProject: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Source owner', true, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Source project', true, false),
    materialList: new FieldModel(new TextBoxModel(this.formGroup.controls['materialList'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Material list', false, false),
    material: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Material', true, false),
    targetOwner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['targetOwner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Target owner', false, false),
    targetProject: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['targetProject'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Target project', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Copy material', false, false, true, false),
};

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.owner.control.value;
    
    return expr;
  }

  get $fields_material_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.project.control.value;
    
    return expr;
  }

  get $fields_targetOwner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_targetOwner_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.targetProject.control.value;
    
    return expr;
  }

  get $fields_targetProject_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_targetProject_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.targetOwner.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    materialList: this.fields.materialList.control.valueChanges
    ,
    material: this.fields.material.control.valueChanges
    ,
    targetOwner: this.fields.targetOwner.control.valueChanges
    ,
    targetProject: this.fields.targetProject.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Copy Material';
  
    const $form = this;
    const $utils = this.utils;

    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.$formGroupFieldValidationObservables
      .materialList
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .material
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .targetOwner
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .targetProject
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_target_project_changed();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $form: FootPrintManager_material_copy_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const projectId = $form.fields.project.control.value;
  
  if ($utils.isDefined(projectId)) {
  
      const project = (await $datasources.Materials.ds_get_project_by_projectId.get({ projectId: projectId })).result;
  
      if ($utils.isAllDefined(project, project[0].OwnerId)) {
          $form.fields.owner.control.value = project[0].OwnerId;
          $form.fields.material.control.value = null;
          
      }
  
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_material_copy_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  
  // Delay to give the form time to load before setting the focus logic
  await new Promise(resolve => setTimeout(resolve, 500));
  
  if ($utils.isDefined($form.inParams.ownerId) && !$utils.isDefined($form.inParams.projectId)) {
      $form.fields.owner.control.value = $form.inParams.ownerId;
      $form.fields.project.control.focus();
  }
  
  if ($utils.isDefined($form.inParams.projectId)) {
      $form.fields.project.control.value = $form.inParams.projectId;
  
      const project = (await $datasources.Materials.ds_get_project_by_projectId.get({ projectId: $form.inParams.projectId })).result;
      if ($utils.isAllDefined(project, project[0].OwnerId)) {
          $form.fields.owner.control.value = project[0].OwnerId;
      }
  }
  
  //validate material count
  var materialList: number[];
  
  if ($utils.isDefined($form.inParams.materialIds))
      {
      materialList = $form.inParams.materialIds;
      $form.fields.material.hidden = true;
      $form.fields.material.control.value = $form.inParams.materialIds;
      $form.fields.project.hidden = true;
      $form.fields.owner.hidden = true;
      $form.fields.materialList.hidden = false;
  
      
      var materials = (await $datasources.Materials.ds_get_materials_by_materialIds.get({materialIds: materialList})).result;
  
  
      $form.fields.materialList.control.value = materials.map(m=> padEnd(m.Id.toString(), 16)  +'\t' + m.LookupCode.trim()).join('\n')
      }
  else
      {
      materialList == $form.fields.material.control.value;
      $form.fields.materialList.hidden = true;
      $form.fields.material.hidden = false;
      }
  
  if ( $utils.isDefined(materialList)) {
      if (materialList.length > 10)
      {$shell.Materials.openErrorDialog('Material Copy Error', 'Only 10 materials allowed to copy at a time.');
      $form.close();}
  }
  
  
  function padEnd (stringValue: string, totalLength: number, padChar: string=null)
  {
      let paddedValue = stringValue ?? '';
  
      if (!$utils.isDefinedTrimmed(padChar))
      {
          padChar = ' ';
      }
  
  
      while (paddedValue.length < totalLength)
      {
          paddedValue += padChar
      }
  
      return paddedValue
  }
  
  
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $form: FootPrintManager_material_copy_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'material_copy_form.on_owner_change');
  $form.fields.project.control.value = null;
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_material_copy_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.toolbar.confirm.control.readOnly = true;
  
  let materialsList: [];
  
  
  
  $form.fields.material.control.value.forEach(async function (material: number) {
  
      let result = (await $flows.Materials.copy_material_flow(
          {
              materialId: material,
              targetProjectId: $form.fields.targetProject.control.value
          }));
  
      // By default the copy material UDF is blank. Flow would be replaced at the application level
      if ($utils.isDefined(result.nextMaterialId)) {
          let copyUdf = (await $flows.Materials.copy_material_udf_flow(
              {
                  sourceMaterialId: material,
                  targetMaterialId: result.nextMaterialId
              }
          ));
          if ($utils.isDefined(copyUdf.reason)) {
              $shell.Materials.openErrorDialog('Error copy material udfs', copyUdf.reason);
          }
      }
  
      if ($utils.isDefined(result.reason)) {
          $shell.Materials.openErrorDialog('Error copy material', result.reason);
      }
  
      else {
          $form.outParams.confirm = true;
          $form.close();
      }
  
  });
  
  
  
  
  
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_material_copy_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.materialId = null;
  $form.outParams.confirm = false;
  $form.close();
  
  }
  on_target_project_changed(event = null) {
    return this.on_target_project_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_target_project_changedInternal(
    $form: FootPrintManager_material_copy_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const targetProjectId = $form.fields.targetProject.control.value;
  
  if ($utils.isDefined(targetProjectId)) {
  
      const targetProject = (await $datasources.Materials.ds_get_project_by_projectId.get({ projectId: targetProjectId })).result;
  
      if ($utils.isAllDefined(targetProject, targetProject[0].OwnerId)) {
          $form.fields.targetOwner.control.value = targetProject[0].OwnerId;
  
          
      }
  
  }
  }
  //#endregion private flows
}
