<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded " class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="ingress" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div *ngIf="!tool.control.buttons.all_in.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.all_in.readOnly || tool.control.buttons.all_in.disabled"
                            [ngStyle]="tool.control.buttons.all_in.styles.style"
                            [ngClass]="tool.control.buttons.all_in.styles.classes"
                            [matTooltip]="tool.control.buttons.all_in.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="all_in($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.all_in.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.all_in.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.all_in.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.map_940.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_940.readOnly || tool.control.buttons.map_940.disabled"
                            [ngStyle]="tool.control.buttons.map_940.styles.style"
                            [ngClass]="tool.control.buttons.map_940.styles.classes"
                            [matTooltip]="tool.control.buttons.map_940.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_940_map($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_940.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_940.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_940.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.map_943.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_943.readOnly || tool.control.buttons.map_943.disabled"
                            [ngStyle]="tool.control.buttons.map_943.styles.style"
                            [ngClass]="tool.control.buttons.map_943.styles.classes"
                            [matTooltip]="tool.control.buttons.map_943.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_943_map($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_943.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_943.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_943.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.map_947_in.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_947_in.readOnly || tool.control.buttons.map_947_in.disabled"
                            [ngStyle]="tool.control.buttons.map_947_in.styles.style"
                            [ngClass]="tool.control.buttons.map_947_in.styles.classes"
                            [matTooltip]="tool.control.buttons.map_947_in.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="under_development($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_947_in.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_947_in.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_947_in.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.query_poller.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.query_poller.readOnly || tool.control.buttons.query_poller.disabled"
                            [ngStyle]="tool.control.buttons.query_poller.styles.style"
                            [ngClass]="tool.control.buttons.query_poller.styles.classes"
                            [matTooltip]="tool.control.buttons.query_poller.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="query_poller($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.query_poller.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.query_poller.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.query_poller.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.map_orders.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_orders.readOnly || tool.control.buttons.map_orders.disabled"
                            [ngStyle]="tool.control.buttons.map_orders.styles.style"
                            [ngClass]="tool.control.buttons.map_orders.styles.classes"
                            [matTooltip]="tool.control.buttons.map_orders.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="map_imported_orders($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_orders.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_orders.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_orders.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.submit_orders.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.submit_orders.readOnly || tool.control.buttons.submit_orders.disabled"
                            [ngStyle]="tool.control.buttons.submit_orders.styles.style"
                            [ngClass]="tool.control.buttons.submit_orders.styles.classes"
                            [matTooltip]="tool.control.buttons.submit_orders.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="submit_orders_footprint($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.submit_orders.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.submit_orders.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.submit_orders.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.confirm_orders.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.confirm_orders.readOnly || tool.control.buttons.confirm_orders.disabled"
                            [ngStyle]="tool.control.buttons.confirm_orders.styles.style"
                            [ngClass]="tool.control.buttons.confirm_orders.styles.classes"
                            [matTooltip]="tool.control.buttons.confirm_orders.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="confirm_orders($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.confirm_orders.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.confirm_orders.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.confirm_orders.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.check_order_status.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.check_order_status.readOnly || tool.control.buttons.check_order_status.disabled"
                            [ngStyle]="tool.control.buttons.check_order_status.styles.style"
                            [ngClass]="tool.control.buttons.check_order_status.styles.classes"
                            [matTooltip]="tool.control.buttons.check_order_status.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="check_order_status($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.check_order_status.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.check_order_status.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.check_order_status.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.insert_transaction.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.insert_transaction.readOnly || tool.control.buttons.insert_transaction.disabled"
                            [ngStyle]="tool.control.buttons.insert_transaction.styles.style"
                            [ngClass]="tool.control.buttons.insert_transaction.styles.classes"
                            [matTooltip]="tool.control.buttons.insert_transaction.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="insert_transaction($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.insert_transaction.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.insert_transaction.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.insert_transaction.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="egress" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div *ngIf="!tool.control.buttons.all_out.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.all_out.readOnly || tool.control.buttons.all_out.disabled"
                            [ngStyle]="tool.control.buttons.all_out.styles.style"
                            [ngClass]="tool.control.buttons.all_out.styles.classes"
                            [matTooltip]="tool.control.buttons.all_out.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="all_out($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.all_out.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.all_out.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.all_out.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.map_945.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_945.readOnly || tool.control.buttons.map_945.disabled"
                            [ngStyle]="tool.control.buttons.map_945.styles.style"
                            [ngClass]="tool.control.buttons.map_945.styles.classes"
                            [matTooltip]="tool.control.buttons.map_945.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_945_map($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_945.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_945.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_945.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.map_944.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_944.readOnly || tool.control.buttons.map_944.disabled"
                            [ngStyle]="tool.control.buttons.map_944.styles.style"
                            [ngClass]="tool.control.buttons.map_944.styles.classes"
                            [matTooltip]="tool.control.buttons.map_944.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_944_map($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_944.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_944.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_944.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.map_846.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_846.readOnly || tool.control.buttons.map_846.disabled"
                            [ngStyle]="tool.control.buttons.map_846.styles.style"
                            [ngClass]="tool.control.buttons.map_846.styles.classes"
                            [matTooltip]="tool.control.buttons.map_846.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="under_development($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_846.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_846.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_846.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.map_947.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_947.readOnly || tool.control.buttons.map_947.disabled"
                            [ngStyle]="tool.control.buttons.map_947.styles.style"
                            [ngClass]="tool.control.buttons.map_947.styles.classes"
                            [matTooltip]="tool.control.buttons.map_947.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="under_development($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_947.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_947.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_947.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.generate_846.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.generate_846.readOnly || tool.control.buttons.generate_846.disabled"
                            [ngStyle]="tool.control.buttons.generate_846.styles.style"
                            [ngClass]="tool.control.buttons.generate_846.styles.classes"
                            [matTooltip]="tool.control.buttons.generate_846.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="under_development($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.generate_846.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.generate_846.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.generate_846.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.generate_947.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.generate_947.readOnly || tool.control.buttons.generate_947.disabled"
                            [ngStyle]="tool.control.buttons.generate_947.styles.style"
                            [ngClass]="tool.control.buttons.generate_947.styles.classes"
                            [matTooltip]="tool.control.buttons.generate_947.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="under_development($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.generate_947.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.generate_947.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.generate_947.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.query_footprint.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.query_footprint.readOnly || tool.control.buttons.query_footprint.disabled"
                            [ngStyle]="tool.control.buttons.query_footprint.styles.style"
                            [ngClass]="tool.control.buttons.query_footprint.styles.classes"
                            [matTooltip]="tool.control.buttons.query_footprint.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="query_footprint($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.query_footprint.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.query_footprint.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.query_footprint.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.map_orders.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.map_orders.readOnly || tool.control.buttons.map_orders.disabled"
                            [ngStyle]="tool.control.buttons.map_orders.styles.style"
                            [ngClass]="tool.control.buttons.map_orders.styles.classes"
                            [matTooltip]="tool.control.buttons.map_orders.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="map_exported_orders($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.map_orders.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.map_orders.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.map_orders.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.submit_orders.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.submit_orders.readOnly || tool.control.buttons.submit_orders.disabled"
                            [ngStyle]="tool.control.buttons.submit_orders.styles.style"
                            [ngClass]="tool.control.buttons.submit_orders.styles.classes"
                            [matTooltip]="tool.control.buttons.submit_orders.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="submit_orders_orderful($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.submit_orders.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.submit_orders.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.submit_orders.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="test_map" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div *ngIf="!tool.control.buttons.test_940.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.test_940.readOnly || tool.control.buttons.test_940.disabled"
                            [ngStyle]="tool.control.buttons.test_940.styles.style"
                            [ngClass]="tool.control.buttons.test_940.styles.classes"
                            [matTooltip]="tool.control.buttons.test_940.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_940_test($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.test_940.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.test_940.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.test_940.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.test_945.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.test_945.readOnly || tool.control.buttons.test_945.disabled"
                            [ngStyle]="tool.control.buttons.test_945.styles.style"
                            [ngClass]="tool.control.buttons.test_945.styles.classes"
                            [matTooltip]="tool.control.buttons.test_945.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_945_test($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.test_945.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.test_945.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.test_945.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.test_943.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.test_943.readOnly || tool.control.buttons.test_943.disabled"
                            [ngStyle]="tool.control.buttons.test_943.styles.style"
                            [ngClass]="tool.control.buttons.test_943.styles.classes"
                            [matTooltip]="tool.control.buttons.test_943.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_943_test($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.test_943.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.test_943.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.test_943.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div *ngIf="!tool.control.buttons.test_944.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.test_944.readOnly || tool.control.buttons.test_944.disabled"
                            [ngStyle]="tool.control.buttons.test_944.styles.style"
                            [ngClass]="tool.control.buttons.test_944.styles.classes"
                            [matTooltip]="tool.control.buttons.test_944.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_944_test($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.test_944.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.test_944.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.test_944.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-code_box"
                     *ngIf="!fieldsets.code_box.hidden && !fieldsets.code_box.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.code_box.collapsible }">
                    <div *ngIf="!fieldsets.code_box.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.code_box.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.code_box.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.code_box.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.code_box.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.code_box.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.code_box.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-runs" *ngIf="!fields.runs.hidden && !fields.runs.removed" 
                            class="field-container standard {{fields.runs.invalid ? 'invalid' : ''}} {{fields.runs.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.runs.styles.style"
                            [ngClass]="fields.runs.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.runs.label + (fields.runs.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.runs.label}}<span *ngIf="fields.runs.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="runs"
                                matInput
                                numberBox
                                [format]="fields.runs.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.runs.control.readOnly || fields.runs.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.runs.control.placeholder}}"
                                [ngStyle]="fields.runs.control.styles.style"
                                [ngClass]="fields.runs.control.styles.classes"
                                [matTooltip]="fields.runs.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.runs.invalid">
                          <ng-container *ngFor="let error of fields.runs.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden && !fields.project.removed" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}} {{fields.project.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="project"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.project.control.readOnly || fields.project.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.project.control.placeholder}}"
                                [ngStyle]="fields.project.control.styles.style"
                                [ngClass]="fields.project.control.styles.classes"
                                [matTooltip]="fields.project.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden && !fields.warehouse.removed" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}} {{fields.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="warehouse"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.warehouse.control.readOnly || fields.warehouse.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.warehouse.control.placeholder}}"
                                [ngStyle]="fields.warehouse.control.styles.style"
                                [ngClass]="fields.warehouse.control.styles.classes"
                                [matTooltip]="fields.warehouse.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_class" *ngIf="!fields.order_class.hidden && !fields.order_class.removed" 
                            class="field-container standard {{fields.order_class.invalid ? 'invalid' : ''}} {{fields.order_class.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.order_class.styles.style"
                            [ngClass]="fields.order_class.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order_class.label + (fields.order_class.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order_class.label}}<span *ngIf="fields.order_class.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="order_class"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.order_class.control.readOnly || fields.order_class.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.order_class.control.placeholder}}"
                                [ngStyle]="fields.order_class.control.styles.style"
                                [ngClass]="fields.order_class.control.styles.classes"
                                [matTooltip]="fields.order_class.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.order_class.invalid">
                          <ng-container *ngFor="let error of fields.order_class.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden && !fields.material.removed" 
                            class="field-container standard {{fields.material.invalid ? 'invalid' : ''}} {{fields.material.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.material.label + (fields.material.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.material.label}}<span *ngIf="fields.material.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="material"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.material.control.readOnly || fields.material.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.material.control.placeholder}}"
                                [ngStyle]="fields.material.control.styles.style"
                                [ngClass]="fields.material.control.styles.classes"
                                [matTooltip]="fields.material.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-packaging" *ngIf="!fields.packaging.hidden && !fields.packaging.removed" 
                            class="field-container standard {{fields.packaging.invalid ? 'invalid' : ''}} {{fields.packaging.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.packaging.styles.style"
                            [ngClass]="fields.packaging.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.packaging.label + (fields.packaging.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.packaging.label}}<span *ngIf="fields.packaging.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="packaging"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.packaging.control.readOnly || fields.packaging.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.packaging.control.placeholder}}"
                                [ngStyle]="fields.packaging.control.styles.style"
                                [ngClass]="fields.packaging.control.styles.classes"
                                [matTooltip]="fields.packaging.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.packaging.invalid">
                          <ng-container *ngFor="let error of fields.packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-stream" *ngIf="!fields.stream.hidden && !fields.stream.removed" 
                            class="field-container standard {{fields.stream.invalid ? 'invalid' : ''}} {{fields.stream.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.stream.styles.style"
                            [ngClass]="fields.stream.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.stream.label + (fields.stream.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.stream.label}}<span *ngIf="fields.stream.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="stream"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.stream.control.readOnly || fields.stream.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.stream.control.placeholder}}"
                                [ngStyle]="fields.stream.control.styles.style"
                                [ngClass]="fields.stream.control.styles.classes"
                                [matTooltip]="fields.stream.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.stream.invalid">
                          <ng-container *ngFor="let error of fields.stream.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-confirm" *ngIf="!fields.confirm.hidden && !fields.confirm.removed" 
                            class="field-container standard {{fields.confirm.invalid ? 'invalid' : ''}} {{fields.confirm.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.confirm.styles.style"
                            [ngClass]="fields.confirm.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.confirm.label + (fields.confirm.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.confirm.label}}<span *ngIf="fields.confirm.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="confirm"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.confirm.control.styles.style"
                                      [ngClass]="fields.confirm.control.styles.classes"
                                      [matTooltip]="fields.confirm.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.confirm.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.confirm.invalid">
                          <ng-container *ngFor="let error of fields.confirm.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-code_box_output"
                     *ngIf="!fieldsets.code_box_output.hidden && !fieldsets.code_box_output.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.code_box_output.collapsible }">
                    <div *ngIf="!fieldsets.code_box_output.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.code_box_output.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.code_box_output.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.code_box_output.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.code_box_output.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.code_box_output.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.code_box_output.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-code_box" *ngIf="!fields.code_box.hidden && !fields.code_box.removed" 
                            class="field-container full {{fields.code_box.invalid ? 'invalid' : ''}} {{fields.code_box.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.code_box.styles.style"
                            [ngClass]="fields.code_box.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.code_box.label + (fields.code_box.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.code_box.label}}<span *ngIf="fields.code_box.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <ngx-codemirror
                                    formControlName="code_box"
                                    [options]="{
                                    theme: 'base16-light',
                                    mode: fields.code_box.control.codeMirrorMode,
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    foldGutter: true,
                                    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                                    autoCloseBrackets: true,
                                    matchBrackets: true,
                                    lint: true
                                    }"
                                    [CodeValidator]="fields.code_box.control.mode"
                                    [matTooltip]="fields.code_box.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">
                        </ngx-codemirror>
                        
                        <button type="button margin-top-10"
                                (click)="fields.code_box.control.beautify();"
                                class="datex-button secondary ">Beautify</button>
                        <ng-container *ngIf="fields.code_box.invalid">
                          <ng-container *ngFor="let error of fields.code_box.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-code_box_input"
                     *ngIf="!fieldsets.code_box_input.hidden && !fieldsets.code_box_input.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.code_box_input.collapsible }">
                    <div *ngIf="!fieldsets.code_box_input.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.code_box_input.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.code_box_input.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.code_box_input.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.code_box_input.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.code_box_input.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.code_box_input.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-code_box_input" *ngIf="!fields.code_box_input.hidden && !fields.code_box_input.removed" 
                            class="field-container full {{fields.code_box_input.invalid ? 'invalid' : ''}} {{fields.code_box_input.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.code_box_input.styles.style"
                            [ngClass]="fields.code_box_input.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.code_box_input.label + (fields.code_box_input.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.code_box_input.label}}<span *ngIf="fields.code_box_input.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <ngx-codemirror
                                    formControlName="code_box_input"
                                    [options]="{
                                    theme: 'base16-light',
                                    mode: fields.code_box_input.control.codeMirrorMode,
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    foldGutter: true,
                                    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                                    autoCloseBrackets: true,
                                    matchBrackets: true,
                                    lint: true
                                    }"
                                    [CodeValidator]="fields.code_box_input.control.mode"
                                    [matTooltip]="fields.code_box_input.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">
                        </ngx-codemirror>
                        
                        <button type="button margin-top-10"
                                (click)="fields.code_box_input.control.beautify();"
                                class="datex-button secondary ">Beautify</button>
                        <ng-container *ngIf="fields.code_box_input.invalid">
                          <ng-container *ngFor="let error of fields.code_box_input.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>


      </ng-container>

      <div *ngIf="initialized && !$hasDataLoaded " class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>