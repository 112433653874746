import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_locations_dd_singleComponent),
  ],
  selector: 'FootPrintManager-auto_receive_and_putaway_form',
  templateUrl: './FootPrintManager.auto_receive_and_putaway_form.component.html'
})
export class FootPrintManager_auto_receive_and_putaway_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { orderId: number, taskIds?: number[], licenseplateIds?: number[] } = { orderId: null, taskIds: [], licenseplateIds: [] };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  @Input('taskIds') set $inParams_taskIds(v: number[]) {
    this.inParams.taskIds = v;
  }
  get $inParams_taskIds(): number[] {
    return this.inParams.taskIds;
  }
  @Input('licenseplateIds') set $inParams_licenseplateIds(v: number[]) {
    this.inParams.licenseplateIds = v;
  }
  get $inParams_licenseplateIds(): number[] {
    return this.inParams.licenseplateIds;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { isReceived?: boolean, reason?: string, isPutaway?: boolean, isConfirm?: boolean } = { isReceived: null, reason: null, isPutaway: null, isConfirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { orderLookupCode?: string, warehouseId?: number, shipmentId?: number, isEntireOrder?: boolean } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ isReceived?: boolean, reason?: string, isPutaway?: boolean, isConfirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    message: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    putaway_target_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Receive', '', null)
    , false)
  };

  fields = {
    message: new FieldModel(new TextBoxModel(this.formGroup.controls['message'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    putaway_target_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['putaway_target_location'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Putaway location', false, false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true, false),
};

  //#region fields inParams
  get $fields_putaway_target_location_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.vars.warehouseId;
    
    return expr;
  }

  get $fields_putaway_target_location_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    message: this.fields.message.control.valueChanges
    ,
    putaway_target_location: this.fields.putaway_target_location.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Auto-receive and putaway';
  
    const $form = this;
    const $utils = this.utils;

    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .message
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .putaway_target_location
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_putaway_target_location_changed();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_auto_receive_and_putaway_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.isConfirm = false;
  $form.outParams.isReceived = false;
  $form.outParams.isPutaway = false;
  
  
  
  let order = (await $datasources.AsnOrders.ds_get_order_by_orderId.get({ orderId: $form.inParams.orderId })).result;
  let shipments = (await $datasources.AsnOrders.ds_get_shipments_by_orderId.get({ orderId: order.Id })).result.filter(r => r.Shipment.StatusId !== 16);
  
  if (shipments.length === 0) {
      throw new Error(`No shipment exists for order ${order.LookupCode}.`);
  }
  else if (shipments.length > 1) {
      throw new Error(`Multiple shipments found for order ${order.LookupCode} which is currently not supported.`);
  }
  
  
  $form.vars.orderLookupCode = order.LookupCode;
  $form.vars.warehouseId = shipments[0].Shipment.ActualWarehouseId ?? order.PreferredWarehouseId ?? shipments[0].Shipment.ExpectedWarehouseId;
  $form.vars.shipmentId = shipments[0].ShipmentId;
  $form.vars.isEntireOrder = false;
  
  if ($utils.isDefined($form.inParams.licenseplateIds) && $utils.isDefined($form.inParams.taskIds)) {
      throw new Error(`Form error: Please only provide either licenseplateIds or taskIds to the form's input parameters. Both were provided.`);
  }
  else if ($utils.isDefined($form.inParams.taskIds)) {
      let allReceivingTasks = (await $datasources.AsnOrders.ds_find_tasks.get({ orderId: order.Id, shipmentId: shipments[0].ShipmentId, operationCodeId: 2 })).result;
      let inputTasks = allReceivingTasks.filter(t => $form.inParams.taskIds.indexOf(t.Id) >= 0);
  
      $form.inParams.licenseplateIds = [...new Set(inputTasks.map(t => t.ActualTargetLicensePlateId))];
  
      for (let i = $form.inParams.licenseplateIds.length - 1; i >= 0; i--) {
          if (allReceivingTasks.find(t =>
              t.ActualTargetLicensePlateId === $form.inParams.licenseplateIds[i] &&
              (t.StatusId === 1 || t.StatusId === 4) &&
              !inputTasks.find(it => it.Id === t.Id)
          )) {
              $form.inParams.licenseplateIds.splice(i, 1);
          }
      }
  
      $form.fields.message.control.value = `Are you sure you want to automatically receive ${inputTasks.length} tasks for order ${$form.vars.orderLookupCode}?`;
  }
  else if ($utils.isDefined($form.inParams.licenseplateIds)) {
      let allReceivingTasks = (await $datasources.AsnOrders.ds_find_tasks.get({ orderId: order.Id, shipmentId: shipments[0].ShipmentId, operationCodeId: 2 })).result;
      let licenseplateTasks = allReceivingTasks.filter(t => $form.inParams.licenseplateIds.indexOf(t.ActualTargetLicensePlateId) >= 0 && (t.StatusId === 1 || t.StatusId === 4));
  
      $form.inParams.taskIds = licenseplateTasks.map(t => t.Id);
  
      $form.fields.message.control.value = `Are you sure you want to automatically receive ${licenseplateTasks.length} tasks for order ${$form.vars.orderLookupCode}?`;
  }
  else {
      $form.vars.isEntireOrder = true;
      $form.fields.message.control.value = `Are you sure you want to automatically receive all license plates for order ${$form.vars.orderLookupCode}?`;
  
      let allReceivingTasks = (await $datasources.AsnOrders.ds_find_tasks.get({ orderId: order.Id, shipmentId: shipments[0].ShipmentId, operationCodeId: 2 })).result;
      let releasedTasks = allReceivingTasks.filter(t => t.StatusId === 1 || t.StatusId === 4);
  
      $form.inParams.taskIds = [...new Set(releasedTasks.map(t => t.Id))];
      $form.inParams.licenseplateIds = [...new Set(releasedTasks.map(t => t.ActualTargetLicensePlateId))];
  }
  
  
  // Hide putaway
  if ($form.inParams.licenseplateIds.length === 0) {
      $form.fields.putaway_target_location.hidden = true;
  }
  if ($form.inParams.taskIds.length === 0) {
      let existingPutawayTasks = (await $datasources.AsnOrders.ds_find_tasks.get({ shipmentId: shipments[0].ShipmentId, operationCodeId: 3, statusId: [1] })).result;
  
      $form.inParams.licenseplateIds = $form.inParams.licenseplateIds.filter(licenseplateId => existingPutawayTasks.find(t => t.ActualTargetLicensePlateId === licenseplateId));
  
      if ($form.inParams.licenseplateIds.length === 0) {
          $shell.FootPrintManager.openErrorDialog('Error on receiving and putaway', 'No available tasks to receive or license plates to putaway.');
          $form.close();
      }
      else {
          $form.fields.message.control.value = `Are you sure you want to putaway ${$form.inParams.licenseplateIds.length} license plates from order ${$form.vars.orderLookupCode}?`;
      }
  }
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_auto_receive_and_putaway_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.toolbar.confirm.control.readOnly = true;
  $form.fields.putaway_target_location.control.readOnly = true;
  
  let header = '';
  let message = '';
  let receivingErrors: string[] = [];
  let putawayErrors: string[] = [];
  
  let promises: any[] = [];
  for (let taskId of $form.inParams.taskIds) {
      promises.push(await $flows.AsnOrders.auto_receive_asn_task({ taskId: taskId }));
  }
  
  
  await Promise.all(promises)
      .then(function (results) {
          for (let result of results) {
              if ($utils.isDefined(result)) {
                  // No output set on result
              }
          }
      });
  
  
  // Check/Display response
  if ($utils.isDefined($form.fields.putaway_target_location.control.value)) {
      let putawayTasks = (await $datasources.AsnOrders.ds_find_tasks.get({ shipmentId: $form.vars.shipmentId, operationCodeId: 3, statusId: [1] })).result;
  
      putawayTasks = putawayTasks.filter(t => $form.inParams.licenseplateIds.indexOf(t.ActualSourceLicensePlateId) >= 0);
  
      let promises: any[] = [];
      for (let task of putawayTasks) {
          promises.push($flows.AsnOrders.complete_putaway_flow({ licenseplateId: task.ActualSourceLicensePlateId, targetLocationId: $form.fields.putaway_target_location.control.value, taskId: task.Id }));
      }
  
      await Promise.all(promises)
          .then(results => {
              for (let result of results) {
                  if ($utils.isDefined(result)) {
                      putawayErrors = putawayErrors.concat(result.reasons);
                  }
              }
          });
  }
  
  if (!$utils.isDefined(receivingErrors)) {
  
      $form.outParams.isReceived = true;
  }
  else {
      $form.outParams.isReceived = false;
      $form.outParams.reason = `${(await $flows.Utilities.grammar_format_string_array_flow({ values: receivingErrors })).formattedValue}.`;
  }
  
  if ($utils.isDefined($form.fields.putaway_target_location.control.value)) {
      if (!$utils.isDefined(putawayErrors)) {
          $form.outParams.isPutaway = true;
      }
      else {
          $form.outParams.isPutaway = false;
          $form.outParams.reason = `${(await $flows.Utilities.grammar_format_string_array_flow({ values: putawayErrors })).formattedValue}.`;
      }
  }
  
  $form.toolbar.confirm.control.readOnly = false;
  $form.fields.putaway_target_location.control.readOnly = false;
  
  $form.outParams.isConfirm = true;
  $form.close();
  }
  on_putaway_target_location_changed(event = null) {
    return this.on_putaway_target_location_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_putaway_target_location_changedInternal(
    $form: FootPrintManager_auto_receive_and_putaway_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($form.fields.putaway_target_location.control.value)) {
      $form.toolbar.confirm.control.label = 'Receive and putaway';
      if ($form.vars.isEntireOrder) {
          $form.fields.message.control.value = `Are you sure you want to automatically receive all license plates for order ${$form.vars.orderLookupCode} and putaway to the selected location?`;
      }
      else {
          $form.fields.message.control.value = `Are you sure you want to automatically receive ${$form.inParams.taskIds.length} tasks for order ${$form.vars.orderLookupCode} and putaway ${$form.inParams.licenseplateIds.length} license plates to the selected location?`;
      }
  }
  else {
      $form.toolbar.confirm.control.label = 'Receive';
      if ($form.vars.isEntireOrder) {
          $form.fields.message.control.value = `Are you sure you want to automatically receive all license plates for order ${$form.vars.orderLookupCode}?`;
      }
      else {
          $form.fields.message.control.value = `Are you sure you want to automatically receive ${$form.inParams.taskIds.length} tasks for order ${$form.vars.orderLookupCode}?`;
      }
  }
  }
  //#endregion private flows
}
