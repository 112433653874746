<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-general"
                     *ngIf="!fieldsets.general.hidden && !fieldsets.general.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.general.collapsible }">
                    <div *ngIf="!fieldsets.general.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.general.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.general.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.general.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.general.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.general.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.general.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden && !fields.warehouse.removed" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}} {{fields.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="warehouse"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.warehouse.control.readOnly || fields.warehouse.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.warehouse.control.placeholder}}"
                                [ngStyle]="fields.warehouse.control.styles.style"
                                [ngClass]="fields.warehouse.control.styles.classes"
                                [matTooltip]="fields.warehouse.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden && !fields.status.removed" 
                            class="field-container standard {{fields.status.invalid ? 'invalid' : ''}} {{fields.status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.status.label + (fields.status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.status.label}}<span *ngIf="fields.status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="status"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.status.control.readOnly || fields.status.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.status.control.placeholder}}"
                                [ngStyle]="fields.status.control.styles.style"
                                [ngClass]="fields.status.control.styles.classes"
                                [matTooltip]="fields.status.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-operation_code" *ngIf="!fields.operation_code.hidden && !fields.operation_code.removed" 
                            class="field-container standard {{fields.operation_code.invalid ? 'invalid' : ''}} {{fields.operation_code.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.operation_code.styles.style"
                            [ngClass]="fields.operation_code.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.operation_code.label + (fields.operation_code.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.operation_code.label}}<span *ngIf="fields.operation_code.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="operation_code"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.operation_code.control.readOnly || fields.operation_code.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.operation_code.control.placeholder}}"
                                [ngStyle]="fields.operation_code.control.styles.style"
                                [ngClass]="fields.operation_code.control.styles.classes"
                                [matTooltip]="fields.operation_code.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.operation_code.invalid">
                          <ng-container *ngFor="let error of fields.operation_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-created_on" *ngIf="!fields.created_on.hidden && !fields.created_on.removed" 
                            class="field-container standard {{fields.created_on.invalid ? 'invalid' : ''}} {{fields.created_on.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.created_on.styles.style"
                            [ngClass]="fields.created_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.created_on.label + (fields.created_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.created_on.label}}<span *ngIf="fields.created_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="created_on"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.created_on.control.readOnly || fields.created_on.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.created_on.control.placeholder}}"
                                [ngStyle]="fields.created_on.control.styles.style"
                                [ngClass]="fields.created_on.control.styles.classes"
                                [matTooltip]="fields.created_on.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.created_on.invalid">
                          <ng-container *ngFor="let error of fields.created_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-created_sys_user" *ngIf="!fields.created_sys_user.hidden && !fields.created_sys_user.removed" 
                            class="field-container standard {{fields.created_sys_user.invalid ? 'invalid' : ''}} {{fields.created_sys_user.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.created_sys_user.styles.style"
                            [ngClass]="fields.created_sys_user.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.created_sys_user.label + (fields.created_sys_user.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.created_sys_user.label}}<span *ngIf="fields.created_sys_user.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="created_sys_user"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.created_sys_user.control.readOnly || fields.created_sys_user.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.created_sys_user.control.placeholder}}"
                                [ngStyle]="fields.created_sys_user.control.styles.style"
                                [ngClass]="fields.created_sys_user.control.styles.classes"
                                [matTooltip]="fields.created_sys_user.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.created_sys_user.invalid">
                          <ng-container *ngFor="let error of fields.created_sys_user.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-compleyted_on" *ngIf="!fields.compleyted_on.hidden && !fields.compleyted_on.removed" 
                            class="field-container standard {{fields.compleyted_on.invalid ? 'invalid' : ''}} {{fields.compleyted_on.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.compleyted_on.styles.style"
                            [ngClass]="fields.compleyted_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.compleyted_on.label + (fields.compleyted_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.compleyted_on.label}}<span *ngIf="fields.compleyted_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="compleyted_on"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.compleyted_on.control.readOnly || fields.compleyted_on.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.compleyted_on.control.placeholder}}"
                                [ngStyle]="fields.compleyted_on.control.styles.style"
                                [ngClass]="fields.compleyted_on.control.styles.classes"
                                [matTooltip]="fields.compleyted_on.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.compleyted_on.invalid">
                          <ng-container *ngFor="let error of fields.compleyted_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-details"
                     *ngIf="!fieldsets.details.hidden && !fieldsets.details.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.details.collapsible }">
                    <div *ngIf="!fieldsets.details.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.details.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.details.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.details.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.details.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.details.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.details.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-project" *ngIf="!fields.project.hidden && !fields.project.removed" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}} {{fields.project.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="project"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.project.control.readOnly || fields.project.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.project.control.placeholder}}"
                                [ngStyle]="fields.project.control.styles.style"
                                [ngClass]="fields.project.control.styles.classes"
                                [matTooltip]="fields.project.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden && !fields.material.removed" 
                            class="field-container standard {{fields.material.invalid ? 'invalid' : ''}} {{fields.material.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.material.label + (fields.material.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.material.label}}<span *ngIf="fields.material.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="material"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.material.control.readOnly || fields.material.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.material.control.placeholder}}"
                                [ngStyle]="fields.material.control.styles.style"
                                [ngClass]="fields.material.control.styles.classes"
                                [matTooltip]="fields.material.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lot" *ngIf="!fields.lot.hidden && !fields.lot.removed" 
                            class="field-container standard {{fields.lot.invalid ? 'invalid' : ''}} {{fields.lot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lot.styles.style"
                            [ngClass]="fields.lot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lot.label + (fields.lot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lot.label}}<span *ngIf="fields.lot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lot"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.lot.control.readOnly || fields.lot.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.lot.control.placeholder}}"
                                [ngStyle]="fields.lot.control.styles.style"
                                [ngClass]="fields.lot.control.styles.classes"
                                [matTooltip]="fields.lot.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lot.invalid">
                          <ng-container *ngFor="let error of fields.lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_lot" *ngIf="!fields.vendor_lot.hidden && !fields.vendor_lot.removed" 
                            class="field-container standard {{fields.vendor_lot.invalid ? 'invalid' : ''}} {{fields.vendor_lot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.vendor_lot.styles.style"
                            [ngClass]="fields.vendor_lot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vendor_lot.label + (fields.vendor_lot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vendor_lot.label}}<span *ngIf="fields.vendor_lot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="vendor_lot"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.vendor_lot.control.readOnly || fields.vendor_lot.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.vendor_lot.control.placeholder}}"
                                [ngStyle]="fields.vendor_lot.control.styles.style"
                                [ngClass]="fields.vendor_lot.control.styles.classes"
                                [matTooltip]="fields.vendor_lot.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.vendor_lot.invalid">
                          <ng-container *ngFor="let error of fields.vendor_lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-serial_number" *ngIf="!fields.serial_number.hidden && !fields.serial_number.removed" 
                            class="field-container standard {{fields.serial_number.invalid ? 'invalid' : ''}} {{fields.serial_number.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.serial_number.styles.style"
                            [ngClass]="fields.serial_number.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.serial_number.label + (fields.serial_number.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.serial_number.label}}<span *ngIf="fields.serial_number.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="serial_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.serial_number.control.readOnly || fields.serial_number.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.serial_number.control.placeholder}}"
                                [ngStyle]="fields.serial_number.control.styles.style"
                                [ngClass]="fields.serial_number.control.styles.classes"
                                [matTooltip]="fields.serial_number.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.serial_number.invalid">
                          <ng-container *ngFor="let error of fields.serial_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-actual" *ngIf="!fields.actual.hidden && !fields.actual.removed" 
                            class="field-container standard {{fields.actual.invalid ? 'invalid' : ''}} {{fields.actual.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.actual.styles.style"
                            [ngClass]="fields.actual.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.actual.label + (fields.actual.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.actual.label}}<span *ngIf="fields.actual.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="actual"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.actual.control.readOnly || fields.actual.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.actual.control.placeholder}}"
                                [ngStyle]="fields.actual.control.styles.style"
                                [ngClass]="fields.actual.control.styles.classes"
                                [matTooltip]="fields.actual.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.actual.invalid">
                          <ng-container *ngFor="let error of fields.actual.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-actual_packaging" *ngIf="!fields.actual_packaging.hidden && !fields.actual_packaging.removed" 
                            class="field-container standard {{fields.actual_packaging.invalid ? 'invalid' : ''}} {{fields.actual_packaging.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.actual_packaging.styles.style"
                            [ngClass]="fields.actual_packaging.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.actual_packaging.label + (fields.actual_packaging.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.actual_packaging.label}}<span *ngIf="fields.actual_packaging.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="actual_packaging"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.actual_packaging.control.readOnly || fields.actual_packaging.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.actual_packaging.control.placeholder}}"
                                [ngStyle]="fields.actual_packaging.control.styles.style"
                                [ngClass]="fields.actual_packaging.control.styles.classes"
                                [matTooltip]="fields.actual_packaging.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.actual_packaging.invalid">
                          <ng-container *ngFor="let error of fields.actual_packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expected" *ngIf="!fields.expected.hidden && !fields.expected.removed" 
                            class="field-container standard {{fields.expected.invalid ? 'invalid' : ''}} {{fields.expected.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.expected.styles.style"
                            [ngClass]="fields.expected.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expected.label + (fields.expected.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expected.label}}<span *ngIf="fields.expected.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="expected"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.expected.control.readOnly || fields.expected.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.expected.control.placeholder}}"
                                [ngStyle]="fields.expected.control.styles.style"
                                [ngClass]="fields.expected.control.styles.classes"
                                [matTooltip]="fields.expected.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.expected.invalid">
                          <ng-container *ngFor="let error of fields.expected.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expected_packaging" *ngIf="!fields.expected_packaging.hidden && !fields.expected_packaging.removed" 
                            class="field-container standard {{fields.expected_packaging.invalid ? 'invalid' : ''}} {{fields.expected_packaging.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.expected_packaging.styles.style"
                            [ngClass]="fields.expected_packaging.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expected_packaging.label + (fields.expected_packaging.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expected_packaging.label}}<span *ngIf="fields.expected_packaging.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="expected_packaging"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.expected_packaging.control.readOnly || fields.expected_packaging.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.expected_packaging.control.placeholder}}"
                                [ngStyle]="fields.expected_packaging.control.styles.style"
                                [ngClass]="fields.expected_packaging.control.styles.classes"
                                [matTooltip]="fields.expected_packaging.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.expected_packaging.invalid">
                          <ng-container *ngFor="let error of fields.expected_packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-actual_source_lp" *ngIf="!fields.actual_source_lp.hidden && !fields.actual_source_lp.removed" 
                            class="field-container standard {{fields.actual_source_lp.invalid ? 'invalid' : ''}} {{fields.actual_source_lp.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.actual_source_lp.styles.style"
                            [ngClass]="fields.actual_source_lp.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.actual_source_lp.label + (fields.actual_source_lp.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.actual_source_lp.label}}<span *ngIf="fields.actual_source_lp.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="actual_source_lp"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.actual_source_lp.control.readOnly || fields.actual_source_lp.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.actual_source_lp.control.placeholder}}"
                                [ngStyle]="fields.actual_source_lp.control.styles.style"
                                [ngClass]="fields.actual_source_lp.control.styles.classes"
                                [matTooltip]="fields.actual_source_lp.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.actual_source_lp.invalid">
                          <ng-container *ngFor="let error of fields.actual_source_lp.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-actual_source_location" *ngIf="!fields.actual_source_location.hidden && !fields.actual_source_location.removed" 
                            class="field-container standard {{fields.actual_source_location.invalid ? 'invalid' : ''}} {{fields.actual_source_location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.actual_source_location.styles.style"
                            [ngClass]="fields.actual_source_location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.actual_source_location.label + (fields.actual_source_location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.actual_source_location.label}}<span *ngIf="fields.actual_source_location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="actual_source_location"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.actual_source_location.control.readOnly || fields.actual_source_location.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.actual_source_location.control.placeholder}}"
                                [ngStyle]="fields.actual_source_location.control.styles.style"
                                [ngClass]="fields.actual_source_location.control.styles.classes"
                                [matTooltip]="fields.actual_source_location.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.actual_source_location.invalid">
                          <ng-container *ngFor="let error of fields.actual_source_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-actual_target_lp" *ngIf="!fields.actual_target_lp.hidden && !fields.actual_target_lp.removed" 
                            class="field-container standard {{fields.actual_target_lp.invalid ? 'invalid' : ''}} {{fields.actual_target_lp.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.actual_target_lp.styles.style"
                            [ngClass]="fields.actual_target_lp.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.actual_target_lp.label + (fields.actual_target_lp.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.actual_target_lp.label}}<span *ngIf="fields.actual_target_lp.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="actual_target_lp"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.actual_target_lp.control.readOnly || fields.actual_target_lp.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.actual_target_lp.control.placeholder}}"
                                [ngStyle]="fields.actual_target_lp.control.styles.style"
                                [ngClass]="fields.actual_target_lp.control.styles.classes"
                                [matTooltip]="fields.actual_target_lp.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.actual_target_lp.invalid">
                          <ng-container *ngFor="let error of fields.actual_target_lp.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-actual_target_loc" *ngIf="!fields.actual_target_loc.hidden && !fields.actual_target_loc.removed" 
                            class="field-container standard {{fields.actual_target_loc.invalid ? 'invalid' : ''}} {{fields.actual_target_loc.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.actual_target_loc.styles.style"
                            [ngClass]="fields.actual_target_loc.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.actual_target_loc.label + (fields.actual_target_loc.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.actual_target_loc.label}}<span *ngIf="fields.actual_target_loc.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="actual_target_loc"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.actual_target_loc.control.readOnly || fields.actual_target_loc.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.actual_target_loc.control.placeholder}}"
                                [ngStyle]="fields.actual_target_loc.control.styles.style"
                                [ngClass]="fields.actual_target_loc.control.styles.classes"
                                [matTooltip]="fields.actual_target_loc.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.actual_target_loc.invalid">
                          <ng-container *ngFor="let error of fields.actual_target_loc.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expected_source_lp" *ngIf="!fields.expected_source_lp.hidden && !fields.expected_source_lp.removed" 
                            class="field-container standard {{fields.expected_source_lp.invalid ? 'invalid' : ''}} {{fields.expected_source_lp.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.expected_source_lp.styles.style"
                            [ngClass]="fields.expected_source_lp.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expected_source_lp.label + (fields.expected_source_lp.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expected_source_lp.label}}<span *ngIf="fields.expected_source_lp.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="expected_source_lp"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.expected_source_lp.control.readOnly || fields.expected_source_lp.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.expected_source_lp.control.placeholder}}"
                                [ngStyle]="fields.expected_source_lp.control.styles.style"
                                [ngClass]="fields.expected_source_lp.control.styles.classes"
                                [matTooltip]="fields.expected_source_lp.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.expected_source_lp.invalid">
                          <ng-container *ngFor="let error of fields.expected_source_lp.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expected_source_loc" *ngIf="!fields.expected_source_loc.hidden && !fields.expected_source_loc.removed" 
                            class="field-container standard {{fields.expected_source_loc.invalid ? 'invalid' : ''}} {{fields.expected_source_loc.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.expected_source_loc.styles.style"
                            [ngClass]="fields.expected_source_loc.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expected_source_loc.label + (fields.expected_source_loc.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expected_source_loc.label}}<span *ngIf="fields.expected_source_loc.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="expected_source_loc"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.expected_source_loc.control.readOnly || fields.expected_source_loc.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.expected_source_loc.control.placeholder}}"
                                [ngStyle]="fields.expected_source_loc.control.styles.style"
                                [ngClass]="fields.expected_source_loc.control.styles.classes"
                                [matTooltip]="fields.expected_source_loc.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.expected_source_loc.invalid">
                          <ng-container *ngFor="let error of fields.expected_source_loc.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expected_target_lp" *ngIf="!fields.expected_target_lp.hidden && !fields.expected_target_lp.removed" 
                            class="field-container standard {{fields.expected_target_lp.invalid ? 'invalid' : ''}} {{fields.expected_target_lp.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.expected_target_lp.styles.style"
                            [ngClass]="fields.expected_target_lp.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expected_target_lp.label + (fields.expected_target_lp.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expected_target_lp.label}}<span *ngIf="fields.expected_target_lp.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="expected_target_lp"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.expected_target_lp.control.readOnly || fields.expected_target_lp.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.expected_target_lp.control.placeholder}}"
                                [ngStyle]="fields.expected_target_lp.control.styles.style"
                                [ngClass]="fields.expected_target_lp.control.styles.classes"
                                [matTooltip]="fields.expected_target_lp.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.expected_target_lp.invalid">
                          <ng-container *ngFor="let error of fields.expected_target_lp.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expected_target_loc" *ngIf="!fields.expected_target_loc.hidden && !fields.expected_target_loc.removed" 
                            class="field-container standard {{fields.expected_target_loc.invalid ? 'invalid' : ''}} {{fields.expected_target_loc.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.expected_target_loc.styles.style"
                            [ngClass]="fields.expected_target_loc.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expected_target_loc.label + (fields.expected_target_loc.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expected_target_loc.label}}<span *ngIf="fields.expected_target_loc.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="expected_target_loc"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.expected_target_loc.control.readOnly || fields.expected_target_loc.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.expected_target_loc.control.placeholder}}"
                                [ngStyle]="fields.expected_target_loc.control.styles.style"
                                [ngClass]="fields.expected_target_loc.control.styles.classes"
                                [matTooltip]="fields.expected_target_loc.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.expected_target_loc.invalid">
                          <ng-container *ngFor="let error of fields.expected_target_loc.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order" *ngIf="!fields.order.hidden && !fields.order.removed" 
                            class="field-container standard {{fields.order.invalid ? 'invalid' : ''}} {{fields.order.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.order.styles.style"
                            [ngClass]="fields.order.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order.label + (fields.order.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order.label}}<span *ngIf="fields.order.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="order"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.order.control.readOnly || fields.order.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.order.control.placeholder}}"
                                [ngStyle]="fields.order.control.styles.style"
                                [ngClass]="fields.order.control.styles.classes"
                                [matTooltip]="fields.order.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.order.invalid">
                          <ng-container *ngFor="let error of fields.order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-wave" *ngIf="!fields.wave.hidden && !fields.wave.removed" 
                            class="field-container standard {{fields.wave.invalid ? 'invalid' : ''}} {{fields.wave.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.wave.styles.style"
                            [ngClass]="fields.wave.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.wave.label + (fields.wave.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.wave.label}}<span *ngIf="fields.wave.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="wave"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.wave.control.readOnly || fields.wave.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.wave.control.placeholder}}"
                                [ngStyle]="fields.wave.control.styles.style"
                                [ngClass]="fields.wave.control.styles.classes"
                                [matTooltip]="fields.wave.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.wave.invalid">
                          <ng-container *ngFor="let error of fields.wave.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_reference" *ngIf="!fields.order_reference.hidden && !fields.order_reference.removed" 
                            class="field-container standard {{fields.order_reference.invalid ? 'invalid' : ''}} {{fields.order_reference.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.order_reference.styles.style"
                            [ngClass]="fields.order_reference.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order_reference.label + (fields.order_reference.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order_reference.label}}<span *ngIf="fields.order_reference.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="order_reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.order_reference.control.readOnly || fields.order_reference.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.order_reference.control.placeholder}}"
                                [ngStyle]="fields.order_reference.control.styles.style"
                                [ngClass]="fields.order_reference.control.styles.classes"
                                [matTooltip]="fields.order_reference.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.order_reference.invalid">
                          <ng-container *ngFor="let error of fields.order_reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_po" *ngIf="!fields.order_po.hidden && !fields.order_po.removed" 
                            class="field-container standard {{fields.order_po.invalid ? 'invalid' : ''}} {{fields.order_po.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.order_po.styles.style"
                            [ngClass]="fields.order_po.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order_po.label + (fields.order_po.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order_po.label}}<span *ngIf="fields.order_po.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="order_po"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.order_po.control.readOnly || fields.order_po.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.order_po.control.placeholder}}"
                                [ngStyle]="fields.order_po.control.styles.style"
                                [ngClass]="fields.order_po.control.styles.classes"
                                [matTooltip]="fields.order_po.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.order_po.invalid">
                          <ng-container *ngFor="let error of fields.order_po.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden && !fields.notes.removed" 
                            class="field-container standard {{fields.notes.invalid ? 'invalid' : ''}} {{fields.notes.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.notes.control.readOnly || fields.notes.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>


      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>