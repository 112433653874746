import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Utilities_utilities_homeComponent } from './Utilities.utilities_home.component';
import { Utilities_configurations_gridComponent } from './Utilities.configurations_grid.component';
import { Utilities_logs_gridComponent } from './Utilities.logs_grid.component';
import { Utilities_messages_gridComponent } from './Utilities.messages_grid.component';
import { Utilities_transaction_hubComponent } from './Utilities.transaction_hub.component';
import { Utilities_utilities_hubComponent } from './Utilities.utilities_hub.component';
import { Utilities_view_json_payloadComponent } from './Utilities.view_json_payload.component';
import { Utilities_view_xml_payloadComponent } from './Utilities.view_xml_payload.component';
import { Utilities_applications_dd_singleComponent } from './Utilities.applications_dd_single.component';
import { Utilities_directions_dd_singleComponent } from './Utilities.directions_dd_single.component';
import { Utilities_processes_dd_singleComponent } from './Utilities.processes_dd_single.component';
import { Utilities_statuses_dd_singleComponent } from './Utilities.statuses_dd_single.component';
import { Utilities_transaction_types_dd_singleComponent } from './Utilities.transaction_types_dd_single.component';
import { Utilities_applications_dd_multiComponent } from './Utilities.applications_dd_multi.component';
import { Utilities_directions_dd_multiComponent } from './Utilities.directions_dd_multi.component';
import { Utilities_processes_dd_multiComponent } from './Utilities.processes_dd_multi.component';
import { Utilities_statuses_dd_multiComponent } from './Utilities.statuses_dd_multi.component';
import { Utilities_transaction_types_dd_multiComponent } from './Utilities.transaction_types_dd_multi.component';


@Injectable({ providedIn: 'root' })
export class Utilities_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
  ) {
    super(dialog, toastr);
  }

  public Utilities: Utilities_ShellService = this;

  public openutilities_home(replaceCurrentView?: boolean) {
    this.logger.log('Utilities', 'utilities_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'utilities_home',
        referenceName: 'Utilities_utilities_home',
        component: Utilities_utilities_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openutilities_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Utilities', 'utilities_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Utilities_utilities_homeComponent,
      'utilities_home',
      mode,
      dialogSize
    )
  }
  public openconfigurations_grid(inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string } }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Configurations',
        referenceName: 'Utilities_configurations_grid',
        component: Utilities_configurations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openconfigurations_gridDialog(
    inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string } }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Utilities_configurations_gridComponent,
      'Configurations',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlogs_grid(inParams:{ application_name?: string, transaction_id?: string, grid_title?: string, column_names?: { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string }, filter_values?: { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'logs_grid',
        referenceName: 'Utilities_logs_grid',
        component: Utilities_logs_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlogs_gridDialog(
    inParams:{ application_name?: string, transaction_id?: string, grid_title?: string, column_names?: { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string }, filter_values?: { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Utilities_logs_gridComponent,
      'logs_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmessages_grid(inParams:{ application_name?: string, transaction_id?: string, grid_title?: string, column_names?: { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string }, filter_values?: { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Messages',
        referenceName: 'Utilities_messages_grid',
        component: Utilities_messages_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmessages_gridDialog(
    inParams:{ application_name?: string, transaction_id?: string, grid_title?: string, column_names?: { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string }, filter_values?: { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Utilities_messages_gridComponent,
      'Messages',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransaction_hub(inParams:{ transaction_id?: string, activate_grid?: string, column_names?: any, filter_values?: any }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'transaction_hub',
        referenceName: 'Utilities_transaction_hub',
        component: Utilities_transaction_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransaction_hubDialog(
    inParams:{ transaction_id?: string, activate_grid?: string, column_names?: any, filter_values?: any }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Utilities_transaction_hubComponent,
      'transaction_hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openutilities_hub(inParams:{ application_name?: string, column_names?: any, filter_values?: any, user_name?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'utilities_hub',
        referenceName: 'Utilities_utilities_hub',
        component: Utilities_utilities_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openutilities_hubDialog(
    inParams:{ application_name?: string, column_names?: any, filter_values?: any, user_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Utilities_utilities_hubComponent,
      'utilities_hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_json_payload(inParams:{ payload?: any, title?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'view_json_payload',
        referenceName: 'Utilities_view_json_payload',
        component: Utilities_view_json_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_json_payloadDialog(
    inParams:{ payload?: any, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Utilities_view_json_payloadComponent,
      'view_json_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_xml_payload(inParams:{ payload?: any, title?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'view_xml_payload',
        referenceName: 'Utilities_view_xml_payload',
        component: Utilities_view_xml_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_xml_payloadDialog(
    inParams:{ payload?: any, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Utilities_view_xml_payloadComponent,
      'view_xml_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Utilities_utilities_home') {
      this.logger.log('Utilities', 'utilities_home');
      const title = 'utilities_home';
      const component = Utilities_utilities_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_configurations_grid') {
      const title = 'Configurations';
      const component = Utilities_configurations_gridComponent;
      const inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string } } = { application_name: null, user_name: null, column_names: { string_1: null, string_2: null, string_3: null }, filter_values: { string_1: null, string_2: null, string_3: null } };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('user_name'))) {
        const paramValueString = params.get('user_name');
        // TODO: date
        inParams.user_name = paramValueString;
              }
      if (!isNil(params.get('column_names'))) {
        const paramValueString = params.get('column_names');
        inParams.column_names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filter_values'))) {
        const paramValueString = params.get('filter_values');
        inParams.filter_values = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_logs_grid') {
      const title = 'logs_grid';
      const component = Utilities_logs_gridComponent;
      const inParams:{ application_name?: string, transaction_id?: string, grid_title?: string, column_names?: { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string }, filter_values?: { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } } = { application_name: null, transaction_id: null, grid_title: null, column_names: { number_1: null, number_2: null, number_3: null, number_4: null, number_5: null, string_1: null, string_2: null, string_3: null, string_4: null, string_5: null }, filter_values: { number_1: null, number_2: null, number_3: null, number_4: null, number_5: null, string_1: null, string_2: null, string_3: null, string_4: null, string_5: null } };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('grid_title'))) {
        const paramValueString = params.get('grid_title');
        // TODO: date
        inParams.grid_title = paramValueString;
              }
      if (!isNil(params.get('column_names'))) {
        const paramValueString = params.get('column_names');
        inParams.column_names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filter_values'))) {
        const paramValueString = params.get('filter_values');
        inParams.filter_values = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_messages_grid') {
      const title = 'Messages';
      const component = Utilities_messages_gridComponent;
      const inParams:{ application_name?: string, transaction_id?: string, grid_title?: string, column_names?: { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string }, filter_values?: { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } } = { application_name: null, transaction_id: null, grid_title: null, column_names: { number_1: null, number_2: null, number_3: null, number_4: null, number_5: null, string_1: null, string_2: null, string_3: null, string_4: null, string_5: null }, filter_values: { number_1: null, number_2: null, number_3: null, number_4: null, number_5: null, string_1: null, string_2: null, string_3: null, string_4: null, string_5: null } };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('grid_title'))) {
        const paramValueString = params.get('grid_title');
        // TODO: date
        inParams.grid_title = paramValueString;
              }
      if (!isNil(params.get('column_names'))) {
        const paramValueString = params.get('column_names');
        inParams.column_names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filter_values'))) {
        const paramValueString = params.get('filter_values');
        inParams.filter_values = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_transaction_hub') {
      const title = 'transaction_hub';
      const component = Utilities_transaction_hubComponent;
      const inParams:{ transaction_id?: string, activate_grid?: string, column_names?: any, filter_values?: any } = { transaction_id: null, activate_grid: null, column_names: null, filter_values: null };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('activate_grid'))) {
        const paramValueString = params.get('activate_grid');
        // TODO: date
        inParams.activate_grid = paramValueString;
              }
      if (!isNil(params.get('column_names'))) {
        const paramValueString = params.get('column_names');
        inParams.column_names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filter_values'))) {
        const paramValueString = params.get('filter_values');
        inParams.filter_values = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_utilities_hub') {
      const title = 'utilities_hub';
      const component = Utilities_utilities_hubComponent;
      const inParams:{ application_name?: string, column_names?: any, filter_values?: any, user_name?: string } = { application_name: null, column_names: null, filter_values: null, user_name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('column_names'))) {
        const paramValueString = params.get('column_names');
        inParams.column_names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filter_values'))) {
        const paramValueString = params.get('filter_values');
        inParams.filter_values = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('user_name'))) {
        const paramValueString = params.get('user_name');
        // TODO: date
        inParams.user_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_view_json_payload') {
      const title = 'view_json_payload';
      const component = Utilities_view_json_payloadComponent;
      const inParams:{ payload?: any, title?: string } = { payload: null, title: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_view_xml_payload') {
      const title = 'view_xml_payload';
      const component = Utilities_view_xml_payloadComponent;
      const inParams:{ payload?: any, title?: string } = { payload: null, title: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_applications_dd_single') {
      const title = 'applications_dd';
      const component = Utilities_applications_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_directions_dd_single') {
      const title = 'directions_dd';
      const component = Utilities_directions_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_processes_dd_single') {
      const title = 'processes_dd';
      const component = Utilities_processes_dd_singleComponent;
      const inParams:{ application_name?: string } = { application_name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_statuses_dd_single') {
      const title = 'statuses_dd';
      const component = Utilities_statuses_dd_singleComponent;
      const inParams:{ application_name?: string } = { application_name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_transaction_types_dd_single') {
      const title = 'transaction_types_dd';
      const component = Utilities_transaction_types_dd_singleComponent;
      const inParams:{ application_name?: string } = { application_name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_applications_dd_multi') {
      const title = 'applications_dd';
      const component = Utilities_applications_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_directions_dd_multi') {
      const title = 'directions_dd';
      const component = Utilities_directions_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_processes_dd_multi') {
      const title = 'processes_dd';
      const component = Utilities_processes_dd_multiComponent;
      const inParams:{ application_name?: string } = { application_name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_statuses_dd_multi') {
      const title = 'statuses_dd';
      const component = Utilities_statuses_dd_multiComponent;
      const inParams:{ application_name?: string } = { application_name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Utilities_transaction_types_dd_multi') {
      const title = 'transaction_types_dd';
      const component = Utilities_transaction_types_dd_multiComponent;
      const inParams:{ application_name?: string } = { application_name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
