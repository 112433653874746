<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel_button" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-reason_entity" *ngIf="!fields.reason_entity.hidden && !fields.reason_entity.removed" 
                            class="field-container standard {{fields.reason_entity.invalid ? 'invalid' : ''}} {{fields.reason_entity.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.reason_entity.styles.style"
                            [ngClass]="fields.reason_entity.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.reason_entity.label + (fields.reason_entity.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.reason_entity.label}}<span *ngIf="fields.reason_entity.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <FootPrintManager-reason_code_parent_entities_dd_single 
                            data-cy="selector"
                            [type]="fields.reason_entity.control.type"
                            formControlName="reason_entity"
                            (displayTextChange)="fields.reason_entity.control.displayText=$event"
                            [placeholder]="fields.reason_entity.control.placeholder"
                            [styles]="fields.reason_entity.control.styles"
                            [tooltip]="fields.reason_entity.control.tooltip"
                        >
                        </FootPrintManager-reason_code_parent_entities_dd_single>
                        <ng-container *ngIf="fields.reason_entity.invalid">
                          <ng-container *ngFor="let error of fields.reason_entity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reason_type" *ngIf="!fields.reason_type.hidden && !fields.reason_type.removed" 
                            class="field-container standard {{fields.reason_type.invalid ? 'invalid' : ''}} {{fields.reason_type.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.reason_type.styles.style"
                            [ngClass]="fields.reason_type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.reason_type.label + (fields.reason_type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.reason_type.label}}<span *ngIf="fields.reason_type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <FootPrintManager-reason_code_parent_entity_ids_dd_single 
                            data-cy="selector"
                            [type]="fields.reason_type.control.type"
                            formControlName="reason_type"
                            (displayTextChange)="fields.reason_type.control.displayText=$event"
                            [placeholder]="fields.reason_type.control.placeholder"
                            [styles]="fields.reason_type.control.styles"
                            [tooltip]="fields.reason_type.control.tooltip"
                          [parent]="$fields_reason_type_selector_inParams_parent"
                        >
                        </FootPrintManager-reason_code_parent_entity_ids_dd_single>
                        <ng-container *ngIf="fields.reason_type.invalid">
                          <ng-container *ngFor="let error of fields.reason_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reason_code" *ngIf="!fields.reason_code.hidden && !fields.reason_code.removed" 
                            class="field-container standard {{fields.reason_code.invalid ? 'invalid' : ''}} {{fields.reason_code.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.reason_code.styles.style"
                            [ngClass]="fields.reason_code.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.reason_code.label + (fields.reason_code.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.reason_code.label}}<span *ngIf="fields.reason_code.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="reason_code"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.reason_code.control.readOnly || fields.reason_code.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.reason_code.control.placeholder}}"
                                [ngStyle]="fields.reason_code.control.styles.style"
                                [ngClass]="fields.reason_code.control.styles.classes"
                                [matTooltip]="fields.reason_code.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.reason_code.invalid">
                          <ng-container *ngFor="let error of fields.reason_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-displayed_label" *ngIf="!fields.displayed_label.hidden && !fields.displayed_label.removed" 
                            class="field-container standard {{fields.displayed_label.invalid ? 'invalid' : ''}} {{fields.displayed_label.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.displayed_label.styles.style"
                            [ngClass]="fields.displayed_label.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.displayed_label.label + (fields.displayed_label.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.displayed_label.label}}<span *ngIf="fields.displayed_label.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="displayed_label"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.displayed_label.control.readOnly || fields.displayed_label.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.displayed_label.control.placeholder}}"
                                [ngStyle]="fields.displayed_label.control.styles.style"
                                [ngClass]="fields.displayed_label.control.styles.classes"
                                [matTooltip]="fields.displayed_label.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.displayed_label.invalid">
                          <ng-container *ngFor="let error of fields.displayed_label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-inventory_status_in_minutes" *ngIf="!fields.inventory_status_in_minutes.hidden && !fields.inventory_status_in_minutes.removed" 
                            class="field-container standard {{fields.inventory_status_in_minutes.invalid ? 'invalid' : ''}} {{fields.inventory_status_in_minutes.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.inventory_status_in_minutes.styles.style"
                            [ngClass]="fields.inventory_status_in_minutes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.inventory_status_in_minutes.label + (fields.inventory_status_in_minutes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.inventory_status_in_minutes.label}}<span *ngIf="fields.inventory_status_in_minutes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="inventory_status_in_minutes"
                                matInput
                                numberBox
                                [format]="fields.inventory_status_in_minutes.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.inventory_status_in_minutes.control.readOnly || fields.inventory_status_in_minutes.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.inventory_status_in_minutes.control.placeholder}}"
                                [ngStyle]="fields.inventory_status_in_minutes.control.styles.style"
                                [ngClass]="fields.inventory_status_in_minutes.control.styles.classes"
                                [matTooltip]="fields.inventory_status_in_minutes.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.inventory_status_in_minutes.invalid">
                          <ng-container *ngFor="let error of fields.inventory_status_in_minutes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>