import { Utilities_Types } from './Utilities.frontend.types'

export namespace PrintNode_Types {
  /**
   * Holds the device types supported by PrintNode.
   */
  export enum EDeviceTypes {
    Printer = 'printer',
    Scale = 'scale'
  }
  /**
   * Holds the entity types to be used for PrintNode associations.
   */
  export enum EEntityTypes {
    Location = 'location'
  }
}

export namespace $frontendTypes {
  export import Utilities =  Utilities_Types;
  export import PrintNode = PrintNode_Types;
}




