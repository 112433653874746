import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Inventory_warehouse_dd_singleComponent } from './Inventory.warehouse_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { PrimaryPicks_materials_dd_singleComponent } from './PrimaryPicks.materials_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { FootPrintManager_enabled_disabled_toggle_dd_multiComponent } from './FootPrintManager.enabled_disabled_toggle_dd_multi.component'


interface IFootPrintManager_primary_picks_gridComponentEntity {
  Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LocationId?: number, MaterialId?: number, OverAllocate?: boolean, PackagingId?: number, Location?: { Id?: number, IsPrimaryPick?: boolean, Name?: string, ScannableLookup?: string, Warehouse?: { Id?: number, Name?: string } }, Material?: { Id?: number, LookupCode?: string, Name?: string }, Packaging?: { Id?: number, Name?: string, ShortName?: string }}

interface IFootPrintManager_primary_picks_gridComponentInParams {
  warehouse_id?: number, location_id?: number, material_id?: number}


class FootPrintManager_primary_picks_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_primary_picks_gridComponent;
  entity: IFootPrintManager_primary_picks_gridComponentEntity;

  vars: { warehouse_id?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    warehouse_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    location_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    material_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    packaging_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    overallocate_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    overallocate_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_primary_pick_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_primary_pick_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['warehouse_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    location: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['location_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    material: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['material_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['packaging_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    overallocate: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['overallocate_display'] as DatexFormControl, null, false, false, '', null)
,
      new CheckBoxModel(this.formGroup.controls['overallocate_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    is_primary_pick: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['is_primary_pick_display'] as DatexFormControl, null, false, false, '', null)
,
      new CheckBoxModel(this.formGroup.controls['is_primary_pick_edit'] as DatexFormControl, null, false, false, '', null)
      ),
  }

  cacheValueFor_$fields_warehouse_selector_inParams_warehouseId: number[];
  get $fields_warehouse_selector_inParams_warehouseId(): number[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = [$grid.inParams.warehouse_id];
    
    if(!isEqual(this.cacheValueFor_$fields_warehouse_selector_inParams_warehouseId, expr)) {
      this.cacheValueFor_$fields_warehouse_selector_inParams_warehouseId = expr;
    }
    return this.cacheValueFor_$fields_warehouse_selector_inParams_warehouseId;
  }
  get $fields_location_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.warehouse_id ?? $row.cells.warehouse.editControl.value;
    
    return expr;
  }
  get $fields_location_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }
  get $fields_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.material_id ?? $row.cells.material.editControl.value;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
    this.formGroup
      .controls['warehouse_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_change();
      });
    this.formGroup
      .controls['location_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_change();
      });
    this.formGroup
      .controls['material_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_change();
      });
  }

  async $initializeExisting(grid: FootPrintManager_primary_picks_gridComponent, entity: IFootPrintManager_primary_picks_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_primary_picks_gridComponent, entity?: IFootPrintManager_primary_picks_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      warehouse_id:  $grid.inParams.warehouse_id ?? $grid.vars.warehouse_id ,
      location_id:  $grid.inParams.location_id ,
      material_id:  $grid.inParams.material_id ,
      is_primary_pick:  $grid.filters.primary_pick_enabled.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.PrimaryPicks.ds_primary_picks_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_primary_picks_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.Location?.Warehouse?.Name;
    (this.cells.warehouse.editControl as SelectBoxModel).reset($row.entity.Location?.Warehouse?.Id);
    (this.cells.location.displayControl as TextModel).text = $row.entity?.Location?.Name;
    (this.cells.location.editControl as SelectBoxModel).reset($row.entity?.LocationId);
    (this.cells.material.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.material.editControl as SelectBoxModel).reset($row.entity?.MaterialId);
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.Packaging?.ShortName;
    (this.cells.packaging.editControl as SelectBoxModel).reset($row.entity?.PackagingId);
    (this.cells.overallocate.displayControl as CheckBoxModel).reset($row.entity?.OverAllocate);
    (this.cells.overallocate.editControl as CheckBoxModel).reset($row.entity?.OverAllocate);
    (this.cells.is_primary_pick.displayControl as CheckBoxModel).reset($row.entity?.Location?.IsPrimaryPick ?? true);
    (this.cells.is_primary_pick.editControl as CheckBoxModel).reset($row.entity?.Location?.IsPrimaryPick ?? true);

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_primary_picks_gridComponentRowModel,
  $grid: FootPrintManager_primary_picks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  try {
  
      let location_payload: any = {}
  
      if (!$utils.isAllDefined($row.cells.location.editControl.value ?? $grid.inParams.location_id, $row.cells.material.editControl.value ?? $grid.inParams.material_id)) {
          throw new Error('Unable to create Primary Pick Location. Location and/or material is missing.')
      }
  
  
  
  
  
      if ($row.cells.is_primary_pick.editControl.isChanged) {
          location_payload.IsPrimaryPick = $row.cells.is_primary_pick.editControl.value
      }
  
      let location_result = (await $flows.Utilities.crud_update_flow({ entitySet: 'LocationContainers', id: $row.cells.location.editControl.value ?? $row.entity.LocationId ?? $grid.inParams.location_id, entity: location_payload }))
      if ($utils.isDefined(location_result?.reason)) { throw new Error('Unable to update location. ' + location_result?.reason) }
      //}
  
  
      let payload = {
          "LocationId": $row.cells.location.editControl.value ?? $grid.inParams.location_id,
          "MaterialId": $row.cells.material.editControl.value ?? $grid.inParams.material_id,
          "PackagingId": $row.cells.packaging.editControl.value,
          "OverAllocate": $row.cells.overallocate.editControl.value ?? false,
      }
      let result = (await $flows.Utilities.crud_create_flow({ entitySet: 'PrimaryPickLocations', entity: payload }))
      if ($utils.isDefined(result?.reason)) { throw new Error('Unable to create Primary Pick Location. ' + result?.reason) }
  
      $grid.refresh()
  
  
  
  
  }
  catch (error) {
      $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
      throw error;
  }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_primary_picks_gridComponentRowModel,
  $grid: FootPrintManager_primary_picks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  try {
  
  if (!$utils.isAllDefined($row.cells.location.editControl.value ?? $grid.inParams.location_id, $row.cells.material.editControl.value ?? $grid.inParams.material_id))
  {
       throw new Error('Unable to update Primary Pick Location. Location and/or material is missing.')
  }
  
  let primary_pick_payload: any = {}
  let location_payload: any = {}
  
  if ($row.cells.location.editControl.isChanged) {
      primary_pick_payload.LocationId = $row.cells.location.editControl.value
  }
  
  if ($row.cells.material.editControl.isChanged) {
      primary_pick_payload.MaterialId = $row.cells.material.editControl.value
  }
  
  if ($row.cells.packaging.editControl.isChanged) {
      primary_pick_payload.PackagingId = $row.cells.packaging.editControl.value
  }
  
  if ($row.cells.overallocate.editControl.isChanged) {
      primary_pick_payload.OverAllocate = $row.cells.overallocate.editControl.value
  }
  
  if ($row.cells.is_primary_pick.editControl.isChanged) {
      location_payload.IsPrimaryPick = $row.cells.is_primary_pick.editControl.value
  }
  
  
      let primary_pick_result = (await $flows.Utilities.crud_update_flow({ entitySet: 'PrimaryPickLocations', id: $row.entity.Id, entity: primary_pick_payload }))
      if ($utils.isDefined(primary_pick_result?.reason)) { throw new Error('Unable to update primary pick. ' + primary_pick_result?.reason) }
  
      let location_result = (await $flows.Utilities.crud_update_flow({ entitySet: 'LocationContainers', id: $row.entity.LocationId, entity:location_payload}))
      if ($utils.isDefined(location_result?.reason)) { throw new Error('Unable to update location. ' + location_result?.reason) }
  
      $grid.refresh()
  
  
  }
  catch (error) {
      $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
      throw error;
  }
  }
  on_location_change(event = null) {
    return this.on_location_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_changeInternal(
    $row: FootPrintManager_primary_picks_gridComponentRowModel,
  $grid: FootPrintManager_primary_picks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($row.cells.location.editControl.value))
  {
  let warehouse = (await $datasources.Locations.ds_get_warehouse_by_warehouseId.get({warehouseId: $row.cells.location.editControl.value ?? $row.entity.LocationId}))?.result
  
  if ($utils.isDefined(warehouse))
  {
      $row.cells.warehouse.editControl.value = warehouse.Id
  
  }
  }
  
  
  
  }
  on_material_change(event = null) {
    return this.on_material_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changeInternal(
    $row: FootPrintManager_primary_picks_gridComponentRowModel,
  $grid: FootPrintManager_primary_picks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.cells.packaging.editControl.value = null
  
  }
  on_warehouse_change(event = null) {
    return this.on_warehouse_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_changeInternal(
    $row: FootPrintManager_primary_picks_gridComponentRowModel,
  $grid: FootPrintManager_primary_picks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.cells.location.editControl.value = null
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Inventory_warehouse_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => PrimaryPicks_materials_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => FootPrintManager_enabled_disabled_toggle_dd_multiComponent),
  ],
  selector: 'FootPrintManager-primary_picks_grid',
  templateUrl: './FootPrintManager.primary_picks_grid.component.html'
})
export class FootPrintManager_primary_picks_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_primary_picks_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_primary_picks_gridComponentInParams = { warehouse_id: null, location_id: null, material_id: null };


  //#region Variables
  vars: { warehouse_id?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),       location: new GridHeaderModel(new HeaderStyles(null, null), 'location', 'Location', false, false, null, false),       material: new GridHeaderModel(new HeaderStyles(null, null), 'material', 'Material', false, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', false, false, null, false),       overallocate: new GridHeaderModel(new HeaderStyles(null, null), 'overallocate', 'Overallocate', false, false, null, false),       is_primary_pick: new GridHeaderModel(new HeaderStyles(null, null), 'is_primary_pick', 'Enabled/Disabled', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_primary_picks_gridComponentRowModel[] = [];

  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('location_id') set $inParams_location_id(value: any) {
    this.inParams['location_id'] = value;
  }
  get $inParams_location_id(): any {
    return this.inParams['location_id'] ;
  }
  @Input('material_id') set $inParams_material_id(value: any) {
    this.inParams['material_id'] = value;
  }
  get $inParams_material_id(): any {
    return this.inParams['material_id'] ;
  }

  topToolbar = {
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(null, null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };

  formGroup: FormGroup = new FormGroup({
    primary_pick_enabled: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    primary_pick_enabled: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['primary_pick_enabled'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'View', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Primary picks';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouse_id:  $grid.inParams.warehouse_id ?? $grid.vars.warehouse_id ,
      location_id:  $grid.inParams.location_id ,
      material_id:  $grid.inParams.material_id ,
      is_primary_pick:  $grid.filters.primary_pick_enabled.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
      const data = await this.datasources.PrimaryPicks.ds_primary_picks_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_primary_picks_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_primary_picks_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_primary_picks_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_primary_picks_gridComponentEntity) {
    const row = new FootPrintManager_primary_picks_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_primary_picks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  let first_warehouse = (await $datasources.Locations.ds_get_warehouse_top1.get({})).result.Id
  let warehouse_id: number
  
  
  if ($utils.isDefined($grid.inParams.location_id)) {
      warehouse_id = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: $grid.inParams.location_id })).result[0].WarehouseId
      $grid.vars.warehouse_id = warehouse_id
  }
  
  if ($utils.isDefined($grid.inParams.warehouse_id)) {
      $grid.vars.warehouse_id = warehouse_id
      $grid.headers.warehouse.hidden = true
  
  }
  
  if ($utils.isDefined($grid.inParams.material_id)) {
      $grid.headers.material.hidden = true
  }
  
  if ($utils.isDefined($grid.inParams.location_id)) {
      $grid.headers.location.hidden = true
  }
  
  
  $grid.filters.primary_pick_enabled.control.value = [true]
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_primary_picks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // One or more rows selected and order status is created
  
  const selectedRowsCount = $grid.selectedRows.length;
  if (selectedRowsCount > 0) {
      $grid.topToolbar.on_delete.control.readOnly = false;
  
  }
  else {
      $grid.topToolbar.on_delete.control.readOnly = true;
  
  }
  
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootPrintManager_primary_picks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  if (selectedRowsCount > 0) {
  
      for (let row of $grid.selectedRows) {
  
          try {
              let delete_result = (await $flows.Utilities.crud_delete_flow({ id: row.entity.Id, entitySet: "PrimaryPickLocations" }))
              if ($utils.isDefined(delete_result?.reason)) { throw new Error('Unable to delete primary pick. ' + delete_result?.reason) }
              $grid.refresh()
          }
          catch (error) {
              $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
              throw error;
          }
      }
  }
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
