<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="new_transaction" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_create_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-Project" *ngIf="!filters.Project.hidden && !filters.Project.removed" 
            class="field-container standard {{filters.Project.invalid ? 'invalid' : ''}} {{filters.Project.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.Project.styles.style"
            [ngClass]="filters.Project.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.Project.label + (filters.Project.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.Project.label}}<span *ngIf="filters.Project.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Owners-projects_dd_single 
            data-cy="selector"
            [type]="filters.Project.control.type"
            formControlName="Project"
            (displayTextChange)="filters.Project.control.displayText=$event"
            [placeholder]="filters.Project.control.placeholder"
            [styles]="filters.Project.control.styles"
            [tooltip]="filters.Project.control.tooltip"
          [statusId]="$fields_Project_selector_inParams_statusId"
        >
        </Owners-projects_dd_single>
        <ng-container *ngIf="filters.Project.invalid">
          <ng-container *ngFor="let error of filters.Project.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-PalletClass" *ngIf="!filters.PalletClass.hidden && !filters.PalletClass.removed" 
            class="field-container standard {{filters.PalletClass.invalid ? 'invalid' : ''}} {{filters.PalletClass.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.PalletClass.styles.style"
            [ngClass]="filters.PalletClass.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.PalletClass.label + (filters.PalletClass.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.PalletClass.label}}<span *ngIf="filters.PalletClass.required" class="required-asterisk">*</span>
          </label>
        </div>
        <FootPrintManager-pallet_classes_dd_single 
            data-cy="selector"
            [type]="filters.PalletClass.control.type"
            formControlName="PalletClass"
            (displayTextChange)="filters.PalletClass.control.displayText=$event"
            [placeholder]="filters.PalletClass.control.placeholder"
            [styles]="filters.PalletClass.control.styles"
            [tooltip]="filters.PalletClass.control.tooltip"
          [project_id]="$fields_PalletClass_selector_inParams_project_id"
        >
        </FootPrintManager-pallet_classes_dd_single>
        <ng-container *ngIf="filters.PalletClass.invalid">
          <ng-container *ngFor="let error of filters.PalletClass.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="PalletClass">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.PalletClass.displayControl.styles.style"
          [ngClass]="row.cells.PalletClass.displayControl.styles.classes"
          [matTooltip]="row.cells.PalletClass.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.PalletClass.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Amount.displayControl.styles.style"
          [ngClass]="row.cells.Amount.displayControl.styles.classes"
          [matTooltip]="row.cells.Amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Projects">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Projects.displayControl.styles.style"
          [ngClass]="row.cells.Projects.displayControl.styles.classes"
          [matTooltip]="row.cells.Projects.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Projects.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
