<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="refresh" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_refresh_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-from_date" *ngIf="!filters.from_date.hidden && !filters.from_date.removed" 
            class="field-container standard {{filters.from_date.invalid ? 'invalid' : ''}} {{filters.from_date.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.from_date.styles.style"
            [ngClass]="filters.from_date.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.from_date.label + (filters.from_date.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.from_date.label}}<span *ngIf="filters.from_date.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="from_date"
            [format]="filters.from_date.control.format"
            [mode]="filters.from_date.control.mode"
            [ngStyle]="filters.from_date.control.styles.style"
            [ngClass]="filters.from_date.control.styles.classes"
            [tooltip]="filters.from_date.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.from_date.invalid">
          <ng-container *ngFor="let error of filters.from_date.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-to_date" *ngIf="!filters.to_date.hidden && !filters.to_date.removed" 
            class="field-container standard {{filters.to_date.invalid ? 'invalid' : ''}} {{filters.to_date.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.to_date.styles.style"
            [ngClass]="filters.to_date.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.to_date.label + (filters.to_date.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.to_date.label}}<span *ngIf="filters.to_date.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="to_date"
            [format]="filters.to_date.control.format"
            [mode]="filters.to_date.control.mode"
            [ngStyle]="filters.to_date.control.styles.style"
            [ngClass]="filters.to_date.control.styles.classes"
            [tooltip]="filters.to_date.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.to_date.invalid">
          <ng-container *ngFor="let error of filters.to_date.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden && !filters.warehouse.removed" 
            class="field-container double {{filters.warehouse.invalid ? 'invalid' : ''}} {{filters.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.warehouse.styles.style"
            [ngClass]="filters.warehouse.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.warehouse.label + (filters.warehouse.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required" class="required-asterisk">*</span>
          </label>
        </div>
        <ExcelOrderImport-warehouses_dd_multi 
            data-cy="selector-multi"
            [type]="filters.warehouse.control.type"
            formControlName="warehouse"
            (displayTextChange)="filters.warehouse.control.displayText=$event"
            [placeholder]="filters.warehouse.control.placeholder"
            [styles]="filters.warehouse.control.styles"
            [tooltip]="filters.warehouse.control.tooltip"
        >
        </ExcelOrderImport-warehouses_dd_multi>
        <ng-container *ngIf="filters.warehouse.invalid">
          <ng-container *ngFor="let error of filters.warehouse.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="appointment_lookup">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.appointment_lookup.displayControl.styles.style"
          [ngClass]="row.cells.appointment_lookup.displayControl.styles.classes"
          [matTooltip]="row.cells.appointment_lookup.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.appointment_lookup.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="order_class">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.order_class.displayControl.styles.style"
          [ngClass]="row.cells.order_class.displayControl.styles.classes"
          [matTooltip]="row.cells.order_class.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.order_class.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project_lookup_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project_lookup_code.displayControl.styles.style"
          [ngClass]="row.cells.project_lookup_code.displayControl.styles.classes"
          [matTooltip]="row.cells.project_lookup_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project_lookup_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="item_lookup">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.item_lookup.displayControl.styles.style"
          [ngClass]="row.cells.item_lookup.displayControl.styles.classes"
          [matTooltip]="row.cells.item_lookup.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.item_lookup.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="scheduled_arrival">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.scheduled_arrival.displayControl.styles.style"
          [ngClass]="row.cells.scheduled_arrival.displayControl.styles.classes"
          [matTooltip]="row.cells.scheduled_arrival.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.scheduled_arrival.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="checked_in">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.checked_in.displayControl.styles.style"
          [ngClass]="row.cells.checked_in.displayControl.styles.classes"
          [matTooltip]="row.cells.checked_in.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.checked_in.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="carrier_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.carrier_status.displayControl.styles.style"
          [ngClass]="row.cells.carrier_status.displayControl.styles.classes"
          [matTooltip]="row.cells.carrier_status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.carrier_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="wait_time">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.wait_time.displayControl.styles.style"
          [ngClass]="row.cells.wait_time.displayControl.styles.classes"
          [matTooltip]="row.cells.wait_time.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.wait_time.displayControl.text | formatText : row.cells.wait_time.displayControl.formatType : row.cells.wait_time.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pick_receive_percent">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.pick_receive_percent.displayControl.styles.style"
          [ngClass]="row.cells.pick_receive_percent.displayControl.styles.classes"
          [matTooltip]="row.cells.pick_receive_percent.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.pick_receive_percent.displayControl.text | formatText : row.cells.pick_receive_percent.displayControl.formatType : row.cells.pick_receive_percent.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="load_putaway_percent">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.load_putaway_percent.displayControl.styles.style"
          [ngClass]="row.cells.load_putaway_percent.displayControl.styles.classes"
          [matTooltip]="row.cells.load_putaway_percent.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.load_putaway_percent.displayControl.text | formatText : row.cells.load_putaway_percent.displayControl.formatType : row.cells.load_putaway_percent.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expected_cases">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expected_cases.displayControl.styles.style"
          [ngClass]="row.cells.expected_cases.displayControl.styles.classes"
          [matTooltip]="row.cells.expected_cases.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expected_cases.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expected_full_lp">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expected_full_lp.displayControl.styles.style"
          [ngClass]="row.cells.expected_full_lp.displayControl.styles.classes"
          [matTooltip]="row.cells.expected_full_lp.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expected_full_lp.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="last_pick_received_user">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.last_pick_received_user.displayControl.styles.style"
          [ngClass]="row.cells.last_pick_received_user.displayControl.styles.classes"
          [matTooltip]="row.cells.last_pick_received_user.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.last_pick_received_user.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="last_load_putaway_user">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.last_load_putaway_user.displayControl.styles.style"
          [ngClass]="row.cells.last_load_putaway_user.displayControl.styles.classes"
          [matTooltip]="row.cells.last_load_putaway_user.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.last_load_putaway_user.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.length.displayControl.styles.style"
          [ngClass]="row.cells.length.displayControl.styles.classes"
          [matTooltip]="row.cells.length.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual.displayControl.styles.style"
          [ngClass]="row.cells.actual.displayControl.styles.classes"
          [matTooltip]="row.cells.actual.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual.displayControl.text | formatText : row.cells.actual.displayControl.formatType : row.cells.actual.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="dock_ddoor">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.dock_ddoor.displayControl.styles.style"
          [ngClass]="row.cells.dock_ddoor.displayControl.styles.classes"
          [matTooltip]="row.cells.dock_ddoor.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.dock_ddoor.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
