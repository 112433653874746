import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Locations_warehouses_dd_multiComponent } from './Locations.warehouses_dd_multi.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { SalesOrders_sales_order_date_types_dd_singleComponent } from './SalesOrders.sales_order_date_types_dd_single.component'


interface IFootPrintManager_outbound_orders_eligible_for_return_gridComponentEntity {
  Id?: number, CreatedSysDateTime?: string, FulfillmentDate?: string, LookupCode?: string, Notes?: string, OwnerReference?: string, RequestedDeliveryDate?: string, VendorReference?: string, Project?: { Name?: string, Owner?: { Name?: string } }, OrderClass?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { LookupCode?: string, ActualWarehouse?: { Name?: string }, Carrier?: { Name?: string }, CarrierServiceType?: { Name?: string } } }[]}

interface IFootPrintManager_outbound_orders_eligible_for_return_gridComponentInParams {
  warehouseIds?: number[], ownerId?: number, projectId?: number}

interface IFootPrintManager_outbound_orders_eligible_for_return_gridComponentOutParams {
  orderIds?: number[]}

class FootPrintManager_outbound_orders_eligible_for_return_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_outbound_orders_eligible_for_return_gridComponent;
  entity: IFootPrintManager_outbound_orders_eligible_for_return_gridComponentEntity;

  vars: { isSelected?: boolean } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    order_class: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    owner_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    vendor_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    owner: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    carrier: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    service_type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    created_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    fulfilled_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    requested_delivery_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_outbound_orders_eligible_for_return_gridComponent, entity: IFootPrintManager_outbound_orders_eligible_for_return_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_outbound_orders_eligible_for_return_gridComponent, entity?: IFootPrintManager_outbound_orders_eligible_for_return_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      warehouseIds:  $grid.filters.warehouses.control.value ,
      ownerId:  $grid.filters.owner.control.value ,
      projectId:  $grid.filters.project.control.value ,
      dateFrom:  $grid.filters.date_from.control.value ,
      dateTo:  $grid.filters.date_to.control.value ,
      dateType:  $grid.filters.date_to.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.Returns.ds_outbound_orders_eligible_for_return_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_outbound_orders_eligible_for_return_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.order_class.displayControl as TextModel).text = $row.entity.OrderClass?.Name;
    (this.cells.owner_reference.displayControl as TextModel).text = $row.entity.OwnerReference;
    (this.cells.vendor_reference.displayControl as TextModel).text = $row.entity.VendorReference;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity?.ShipmentOrderLookups[0]?.Shipment?.ActualWarehouse?.Name;
    (this.cells.owner.displayControl as TextModel).text = $row.entity.Project?.Owner?.Name;
    (this.cells.project.displayControl as TextModel).text = $row.entity.Project?.Name;
    (this.cells.carrier.displayControl as TextModel).text = $row.entity?.ShipmentOrderLookups[0]?.Shipment?.Carrier?.Name;
    (this.cells.service_type.displayControl as TextModel).text = $row.entity?.ShipmentOrderLookups[0]?.Shipment?.CarrierServiceType?.Name;
    (this.cells.created_on.displayControl as TextModel).text = $row.entity.CreatedSysDateTime?.toString();
    (this.cells.fulfilled_on.displayControl as TextModel).text = $row.entity.FulfillmentDate?.toString();
    (this.cells.requested_delivery_on.displayControl as TextModel).text = $row.entity.RequestedDeliveryDate?.toString();
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_outbound_orders_eligible_for_return_gridComponentRowModel,
  $grid: FootPrintManager_outbound_orders_eligible_for_return_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Set Date/Time Formats
  const format = `${$settings.FootPrintManager.DateFormat}`;
  
  if ($utils.isDefined(format)) {
  
      if ($utils.isDefined($row.entity?.CreatedSysDateTime)) {
          $row.cells.created_on.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime, format);
      };
  
      if ($utils.isDefined($row.entity?.FulfillmentDate)) {
          $row.cells.fulfilled_on.displayControl.text = $utils.date.format($row.entity.FulfillmentDate, format);
      };
  
      if ($utils.isDefined($row.entity?.RequestedDeliveryDate)) {
          $row.cells.requested_delivery_on.displayControl.text = $utils.date.format($row.entity.RequestedDeliveryDate, format);
      };
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_warehouses_dd_multiComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => SalesOrders_sales_order_date_types_dd_singleComponent),
  ],
  selector: 'FootPrintManager-outbound_orders_eligible_for_return_grid',
  templateUrl: './FootPrintManager.outbound_orders_eligible_for_return_grid.component.html'
})
export class FootPrintManager_outbound_orders_eligible_for_return_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_outbound_orders_eligible_for_return_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_outbound_orders_eligible_for_return_gridComponentInParams = { warehouseIds: [], ownerId: null, projectId: null };

  outParams: IFootPrintManager_outbound_orders_eligible_for_return_gridComponentOutParams = { orderIds: [] };

  //#region Variables
  vars: { suppressFlows?: boolean } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ orderIds?: number[] }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'lookupcode', 'Order', false, false, null, false),       order_class: new GridHeaderModel(new HeaderStyles(null, null), 'order_class', 'Order class', false, false, null, false),       owner_reference: new GridHeaderModel(new HeaderStyles(null, null), 'owner_reference', 'Owner reference', false, false, null, false),       vendor_reference: new GridHeaderModel(new HeaderStyles(null, null), 'vendor_reference', 'Vendor reference', false, false, null, false),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),       owner: new GridHeaderModel(new HeaderStyles(null, null), 'owner', 'Owner', false, false, null, false),       project: new GridHeaderModel(new HeaderStyles(null, null), 'project', 'Project', false, false, null, false),       carrier: new GridHeaderModel(new HeaderStyles(null, null), 'carrier', 'Carrier', false, false, null, false),       service_type: new GridHeaderModel(new HeaderStyles(null, null), 'service_type', 'Service type', false, false, null, false),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'created_on', 'Created on', false, false, null, false),       fulfilled_on: new GridHeaderModel(new HeaderStyles(null, null), 'fulfilled_on', 'Fulfilled on', false, false, null, false),       requested_delivery_on: new GridHeaderModel(new HeaderStyles(null, null), 'requested_delivery_on', 'Requested delivery on', false, false, null, false),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'notes', 'Notes', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_outbound_orders_eligible_for_return_gridComponentRowModel[] = [];

  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }
  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }

  topToolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    warehouses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    warehouses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouses'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouses', false, false),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    date_from: new FieldModel(new DateBoxModel(this.formGroup.controls['date_from'] as DatexFormControl, null, false, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Date from', false, false),
    date_to: new FieldModel(new DateBoxModel(this.formGroup.controls['date_to'] as DatexFormControl, null, false, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Date to', false, false),
    date_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['date_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Date type', false, false),
  }

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $grid = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    const $grid = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    const $grid = this;
    const $utils = this.utils;
    const expr = $grid.filters.owner.control.value;
    
    return expr;
  }

  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Completed outbound orders';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;

    (this.filters.warehouses.control as SelectBoxModel).reset($grid.inParams.warehouseIds);
    (this.filters.owner.control as SelectBoxModel).reset($grid.inParams.ownerId);
    (this.filters.project.control as SelectBoxModel).reset($grid.inParams.projectId);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouseIds:  $grid.filters.warehouses.control.value ,
      ownerId:  $grid.filters.owner.control.value ,
      projectId:  $grid.filters.project.control.value ,
      dateFrom:  $grid.filters.date_from.control.value ,
      dateTo:  $grid.filters.date_to.control.value ,
      dateType:  $grid.filters.date_to.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
      const data = await this.datasources.Returns.ds_outbound_orders_eligible_for_return_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_outbound_orders_eligible_for_return_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_outbound_orders_eligible_for_return_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_outbound_orders_eligible_for_return_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_outbound_orders_eligible_for_return_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // REMOVED (TO REVIEW): BROKEN AFTER GAMMA UPDATE
  // if (!$grid.vars.suppressFlows) {
  //     $grid.vars.suppressFlows = true;
  
  //     for (let row of $grid.rows) {
  //         if (row.selected) {
  //             if (row.vars.isSelected) {
  //                 row.selected = false;
  //                 row.vars.isSelected = false;
  //             }
  //             else {
  //                 row.vars.isSelected = true;
  //             }
  //         }
  //         else {
  //             row.vars.isSelected = false;
  //         }
  //     }
  
  //     $grid.vars.suppressFlows = false;
  // }
  
  if ($grid.selectedRows.length === 1) {
      $grid.topToolbar.confirm.control.readOnly = false;
  }
  else {
      $grid.topToolbar.confirm.control.readOnly = true;
  }
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $grid: FootPrintManager_outbound_orders_eligible_for_return_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($grid.selectedRows.length === 0) {
      throw new Error('No order has been selected');
  }
  
  if ($grid.selectedRows.length > 1) {
      throw new Error('Only one order can be selected');
  }
  
  $grid.outParams.orderIds = [$grid.selectedRows[0].entity.Id];
  
  $grid.close();
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootPrintManager_outbound_orders_eligible_for_return_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_outbound_orders_eligible_for_return_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.filters.warehouses.control.value = $grid.inParams.warehouseIds;
  $grid.filters.owner.control.value = $grid.inParams.ownerId;
  $grid.filters.project.control.value = $grid.inParams.projectId;
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
