import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { InventoryTransformations_create_inventory_transformation_flowService } from './InventoryTransformations.flow.index';
import { InventoryTransformations_create_inventory_transformation_source_line_by_serial_numbers_flowService } from './InventoryTransformations.flow.index';
import { InventoryTransformations_create_inventory_transformation_source_line_flowService } from './InventoryTransformations.flow.index';
import { InventoryTransformations_create_inventory_transformation_target_line_by_serial_numbers_on_existing_licenseplate_flowService } from './InventoryTransformations.flow.index';
import { InventoryTransformations_create_inventory_transformation_target_line_by_serial_numbers_on_new_licenseplate_flowService } from './InventoryTransformations.flow.index';
import { InventoryTransformations_create_inventory_transformation_target_line_on_existing_licenseplate_flowService } from './InventoryTransformations.flow.index';
import { InventoryTransformations_create_inventory_transformation_target_line_on_new_licenseplate_flowService } from './InventoryTransformations.flow.index';

import { $frontendTypes } from './InventoryTransformations.frontend.types'

@Injectable({ providedIn: 'root' })
export class InventoryTransformations_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public InventoryTransformations: InventoryTransformations_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_inventory_transformation_flow: InventoryTransformations_create_inventory_transformation_flowService;
  public async create_inventory_transformation_flow(inParams: { lookup_code: string, notes?: string, project_id: number, warehouse_id: number }): Promise< { inventory_transformation_id?: number, reason?: string }> {
    if(!this._create_inventory_transformation_flow) { 
      this._create_inventory_transformation_flow = this.injector.get(InventoryTransformations_create_inventory_transformation_flowService);
    }
    return this._create_inventory_transformation_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_transformation_source_line_by_serial_numbers_flow: InventoryTransformations_create_inventory_transformation_source_line_by_serial_numbers_flowService;
  public async create_inventory_transformation_source_line_by_serial_numbers_flow(inParams: { inventory_transformation_id: number, serial_number_ids: number[] }): Promise< { inventory_transformation_source_line_ids?: number[], reason?: string }> {
    if(!this._create_inventory_transformation_source_line_by_serial_numbers_flow) { 
      this._create_inventory_transformation_source_line_by_serial_numbers_flow = this.injector.get(InventoryTransformations_create_inventory_transformation_source_line_by_serial_numbers_flowService);
    }
    return this._create_inventory_transformation_source_line_by_serial_numbers_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_transformation_source_line_flow: InventoryTransformations_create_inventory_transformation_source_line_flowService;
  public async create_inventory_transformation_source_line_flow(inParams: { inventory_transformation_id: number, license_plate_id?: number, lot_id?: number, material_id?: number, package_amount: number, package_id: number, vendorlot_id?: number }): Promise< { inventory_transformation_source_line_id?: number, reason?: string }> {
    if(!this._create_inventory_transformation_source_line_flow) { 
      this._create_inventory_transformation_source_line_flow = this.injector.get(InventoryTransformations_create_inventory_transformation_source_line_flowService);
    }
    return this._create_inventory_transformation_source_line_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_transformation_target_line_by_serial_numbers_on_existing_licenseplate_flow: InventoryTransformations_create_inventory_transformation_target_line_by_serial_numbers_on_existing_licenseplate_flowService;
  public async create_inventory_transformation_target_line_by_serial_numbers_on_existing_licenseplate_flow(inParams: { inventory_transformation_id: number, lot_id?: number, material_id?: number, package_id: number, serial_numbers?: $frontendTypes.InventoryTransformations.SerialNumber[], source_line_id?: number, target_licenseplate_id: number }): Promise< { inventory_transformation_target_line_id?: number, reason?: string }> {
    if(!this._create_inventory_transformation_target_line_by_serial_numbers_on_existing_licenseplate_flow) { 
      this._create_inventory_transformation_target_line_by_serial_numbers_on_existing_licenseplate_flow = this.injector.get(InventoryTransformations_create_inventory_transformation_target_line_by_serial_numbers_on_existing_licenseplate_flowService);
    }
    return this._create_inventory_transformation_target_line_by_serial_numbers_on_existing_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_transformation_target_line_by_serial_numbers_on_new_licenseplate_flow: InventoryTransformations_create_inventory_transformation_target_line_by_serial_numbers_on_new_licenseplate_flowService;
  public async create_inventory_transformation_target_line_by_serial_numbers_on_new_licenseplate_flow(inParams: { inventory_transformation_id: number, license_plate_lookupcode: string, location_id: number, lot_id?: number, material_id?: number, package_id: number, serial_numbers: $frontendTypes.InventoryTransformations.SerialNumber[], source_line_id?: number }): Promise< { inventory_transformation_target_line_id?: number, reason?: string }> {
    if(!this._create_inventory_transformation_target_line_by_serial_numbers_on_new_licenseplate_flow) { 
      this._create_inventory_transformation_target_line_by_serial_numbers_on_new_licenseplate_flow = this.injector.get(InventoryTransformations_create_inventory_transformation_target_line_by_serial_numbers_on_new_licenseplate_flowService);
    }
    return this._create_inventory_transformation_target_line_by_serial_numbers_on_new_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_transformation_target_line_on_existing_licenseplate_flow: InventoryTransformations_create_inventory_transformation_target_line_on_existing_licenseplate_flowService;
  public async create_inventory_transformation_target_line_on_existing_licenseplate_flow(inParams: { inventory_transformation_id: number, lot_id?: number, material_id?: number, packaged_amount: number, package_id: number, source_line_id?: number, target_license_plate_id: number }): Promise< { inventory_transformation_target_line_id?: number, reason?: string }> {
    if(!this._create_inventory_transformation_target_line_on_existing_licenseplate_flow) { 
      this._create_inventory_transformation_target_line_on_existing_licenseplate_flow = this.injector.get(InventoryTransformations_create_inventory_transformation_target_line_on_existing_licenseplate_flowService);
    }
    return this._create_inventory_transformation_target_line_on_existing_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_transformation_target_line_on_new_licenseplate_flow: InventoryTransformations_create_inventory_transformation_target_line_on_new_licenseplate_flowService;
  public async create_inventory_transformation_target_line_on_new_licenseplate_flow(inParams: { inventory_transformation_id: number, license_plate_lookupcode: string, location_id: number, lot_id?: number, material_id?: number, packaged_amount: number, package_id: number, source_line_id?: number }): Promise< { inventory_transformation_target_line_id?: number, reason?: string }> {
    if(!this._create_inventory_transformation_target_line_on_new_licenseplate_flow) { 
      this._create_inventory_transformation_target_line_on_new_licenseplate_flow = this.injector.get(InventoryTransformations_create_inventory_transformation_target_line_on_new_licenseplate_flowService);
    }
    return this._create_inventory_transformation_target_line_on_new_licenseplate_flow.run(inParams);
  }
   
   

   
}
