import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootPrintManager_invoices_gridComponentEntity {
  Id?: number, AttentionOf?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, DueDate?: string, InvoiceDate?: string, LookupCode?: string, Notes?: string, ReferenceCode?: string, Status?: { Name?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, InvoiceLines?: { Id?: number, BillingRecords?: { BillingTaskId?: number, BillingTask?: { ShipmentId?: number, WarehouseId?: number, Warehouse?: { Name?: string }, Shipment?: { LookupCode?: string } } }[] }[]}

interface IFootPrintManager_invoices_gridComponentInParams {
  ownerId?: number, projectId?: number, statusIds?: number[]}


class FootPrintManager_invoices_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_invoices_gridComponent;
  entity: IFootPrintManager_invoices_gridComponentEntity;

  vars: { hasCompletedReceivingTasks?: boolean, hasRemainingReceivingTasks?: boolean } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    owner: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total: new GridCellModel(
      new CellStyles(['right'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    created_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    due_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    shipment: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    reference_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    attention_of: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_invoices_gridComponent, entity: IFootPrintManager_invoices_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_invoices_gridComponent, entity?: IFootPrintManager_invoices_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      createdFrom:  $grid.filters.created_from.control.value ,
      createdTo:  $grid.filters.created_to.control.value ,
    };
    const data = await this.datasources.Invoices.ds_invoices_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_invoices_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.InvoiceLines[0]?.BillingRecords[0]?.BillingTask?.Warehouse?.Name;
    (this.cells.shipment.displayControl as TextModel).text = $row.entity.InvoiceLines[0]?.BillingRecords[0]?.BillingTask?.Shipment?.LookupCode;
    (this.cells.reference_code.displayControl as TextModel).text = $row.entity.ReferenceCode;
    (this.cells.attention_of.displayControl as TextModel).text = $row.entity.AttentionOf;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_lookupcode_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $row: FootPrintManager_invoices_gridComponentRowModel,
  $grid: FootPrintManager_invoices_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (await $operations.FootPrintManager.Disable_Billing_InvoiceEditing.isAuthorized()) {
      await $shell.FootPrintManager.openInfoDialog('Authorization', 'You are not authorized for this operation.');
  } else {
      $shell.FootPrintManager.openinvoice_editor({ invoiceId: $row.entity.Id });
  }
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_invoices_gridComponentRowModel,
  $grid: FootPrintManager_invoices_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Set Date/Time Formats
  const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  // Apply date format
  
  if ($utils.isDefined($row.entity.CreatedSysDateTime)){
  $row.cells.created_date.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime , format);
  }
  
  if ($utils.isDefined($row.entity.DueDate)){
  $row.cells.due_date.displayControl.text = $utils.date.format($row.entity.DueDate , format);
  }
  
  
  // Apply custom project string logic
  if ($row.entity.Project?.LookupCode === $row.entity.Project?.Name) {
      $row.cells.project.displayControl.text = $row.entity.Project.LookupCode
  } else {
      $row.cells.project.displayControl.text = $row.entity.Project.LookupCode + ' ' + $row.entity.Project.Name
  }
  
  // Apply custom owner string logic
  if ($row.entity.Project?.Owner?.LookupCode === $row.entity.Project?.Owner?.Name) {
      $row.cells.owner.displayControl.text = $row.entity.Project.Owner.LookupCode
  } else {
      $row.cells.owner.displayControl.text = $row.entity.Project.Owner.LookupCode + ' ' + $row.entity.Project.Owner.Name
  }
  
  
  
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-invoices_grid',
  templateUrl: './FootPrintManager.invoices_grid.component.html'
})
export class FootPrintManager_invoices_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_invoices_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_invoices_gridComponentInParams = { ownerId: null, projectId: null, statusIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  @Output()
  filtersUpdated = new EventEmitter<{ createdFrom?: string, createdTo?: string }>();
  
  events = {
    filtersUpdated: this.filtersUpdated,
  }
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'lookupcode', 'Invoice', false, false, null, false),       status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       owner: new GridHeaderModel(new HeaderStyles(null, null), 'owner', 'Owner', false, false, null, false),       project: new GridHeaderModel(new HeaderStyles(null, null), 'project', 'Project', false, false, null, false),       total: new GridHeaderModel(new HeaderStyles(['right'], null), 'total', 'Total', false, false, null, false),       created_date: new GridHeaderModel(new HeaderStyles(null, null), 'created_date', 'Created', false, false, null, false),       due_date: new GridHeaderModel(new HeaderStyles(null, null), 'due_date', 'Due date', false, false, null, false),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),       shipment: new GridHeaderModel(new HeaderStyles(null, null), 'shipment', 'Shipment', false, false, null, false),       reference_code: new GridHeaderModel(new HeaderStyles(null, null), 'reference_code', 'Reference', false, false, null, false),       attention_of: new GridHeaderModel(new HeaderStyles(null, null), 'attention_of', 'Attention of', false, false, null, false),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'notes', 'Notes', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_invoices_gridComponentRowModel[] = [];

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }

  topToolbar = {
      status_change: new ToolModel(new ButtonModel('status_change', new ButtonStyles(null, null), false, false, false, 'Update', 'icon-ic_fluent_edit_20_filled', null)
    , false),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, false, false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      on_export: new ToolModel(new ButtonModel('on_export', new ButtonStyles(null, null), false, false, false, 'Export for accounting', 'icon-ic_fluent_document_lightning_20_regular', null)
    , false),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    , false),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_print_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    created_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    created_from: new FieldModel(new DateBoxModel(this.formGroup.controls['created_from'] as DatexFormControl, null, false, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Created from', false, false),
    created_to: new FieldModel(new DateBoxModel(this.formGroup.controls['created_to'] as DatexFormControl, null, false, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Created to', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Invoices';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 15;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['created_from']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });
    this.formGroup
      .controls['created_to']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      createdFrom:  $grid.filters.created_from.control.value ,
      createdTo:  $grid.filters.created_to.control.value ,
    };
    try {
      const data = await this.datasources.Invoices.ds_invoices_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_invoices_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_invoices_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_invoices_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_invoices_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/02/2024
  
  //Start read only
  $grid.topToolbar.on_export.control.readOnly = true;
  $grid.topToolbar.cancel.control.readOnly = true;
  $grid.topToolbar.on_delete.control.readOnly = true;
  $grid.topToolbar.complete.control.readOnly = true;
  $grid.topToolbar.print.control.readOnly = true;
  $grid.topToolbar.status_change.control.readOnly = true;
  
  if ($utils.isDefined($grid.selectedRows)) {
  
      const selectedRowsCount = $grid.selectedRows.length;
  
      // One or more rows selected
      if (selectedRowsCount > 0) {
          $grid.topToolbar.on_export.control.readOnly = false;
          $grid.topToolbar.cancel.control.readOnly = false;
          $grid.topToolbar.on_delete.control.readOnly = false;
          $grid.topToolbar.complete.control.readOnly = false;
          $grid.topToolbar.print.control.readOnly = false;
          $grid.topToolbar.status_change.control.readOnly = false;
          $grid.topToolbar.attachments.control.readOnly = selectedRowsCount > 1;
  
          // Completion
          $grid.topToolbar.complete.control.readOnly = !(await checkAllCompletable());
  
          // Cancellation
          $grid.topToolbar.cancel.control.readOnly = !(await checkAllCancellable());
  
          // Export
          $grid.topToolbar.on_export.control.readOnly = !(await checkAllExportable());
  
          // Deletion
          $grid.topToolbar.on_delete.control.readOnly = !(await checkAllDeletable());
      }
  }
  
  async function checkAllCompletable() {
      let allow = $grid.selectedRows.length > 0;
      for (let row of $grid.selectedRows) {
          if ($utils.isDefined((await $flows.Invoices.is_invoice_completable({ invoiceId: row.entity.Id })).reason)) {
              return false;
          }
      }
      return allow;
  }
  
  async function checkAllDeletable() {
      let allow = $grid.selectedRows.length > 0;
      for (let row of $grid.selectedRows) {
          if ($utils.isDefined((await $flows.Invoices.is_invoice_deletable({ invoiceId: row.entity.Id })).reason)) {
              return false;
          }
      }
      return allow;
  }
  
  async function checkAllCancellable() {
      let allow = $grid.selectedRows.length > 0;
      for (let row of $grid.selectedRows) {
          if ($utils.isDefined((await $flows.Invoices.is_invoice_cancelable({ invoiceId: row.entity.Id })).reason)) {
              return false;
          }
      }
      return allow;
  }
  
  async function checkAllExportable() {
      let allow = $grid.selectedRows.length > 0;
      for (let row of $grid.selectedRows) {
          if ($utils.isDefined((await $flows.Invoices.is_invoice_exportable({ invoiceId: row.entity.Id })).reason)) {
              return false;
          }
      }
      return allow;
  }
  }
  on_export_clicked(event = null) {
    return this.on_export_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_clickedInternal(
    $grid: FootPrintManager_invoices_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Invoice Export Error', 'No work orders selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              invoiceId: row.entity.Id
          };
  
  
          var result = await $flows.Invoices.is_invoice_exportable(flowParams);
  
  
  
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Invoice ${row.entity.LookupCode} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Invoice export errors';
          const errorMessage = `Invoice(s) ${failures.map(c => c.entity.LookupCode).join(',')} cannot be exported.`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Invoice(s) ${candidates.map(c => c.entity.LookupCode).join(',')} - Once exported, the invoice can be completed.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some invoice(s) cannot be exported.';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Export the following invoice(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const candidateSuccess = [];
              const candidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const user = (await $flows.Utilities.get_username_flow({})).userName;
                      var userName = user.substring(
                          user.indexOf(`\\`) + 1,
                          user.indexOf('@') >= 0 ? user.indexOf('@') : user.length
                      );
  
                      const dateNow = $utils.date.format($utils.date.now(), 'MM/DD/YY LT');
  
                      const flowParams = {
                          invoiceId: candidate.entity.Id,
                          statusId: 2, // Exported
                          notes: `Invoice exported on ${dateNow} by ${userName}`
                      }
                      var result = await $flows.Invoices.update_invoice_status_flow(flowParams);
  
  
  
                      const reason = result.reason;
  
                      if ($utils.isDefined(reason)) {
                          candidateFailures.push(candidate);
                          errorMsgList.push(`Invoice ${candidate.entity.LookupCode} - ${reason}`);
                      } else {
                          candidateSuccess.push(candidate);
                      }
  
                  } catch (error) {
                      candidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Invoice ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (candidateSuccess.length === candidates.length) {
                  const title = 'All invoice(s) exported';
                  const message = `Invoice(s) ${candidateSuccess.map(c => c.entity.LookupCode).join(',')} exported`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (candidateFailures.length === candidates.length) {
                      const title = 'All invoice(s) failed to export';
                      const message = `Invoice(s) ${candidateFailures.map(c => c.entity.LookupCode).join(',')} could not be exported`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some invoice(s) could not be exported';
                      const success = `Invoice(s) ${candidateSuccess.map(c => c.entity.LookupCode).join(',')} were exported.`;
                      const errors = `The following invoice(s) could not be exported: ${candidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootPrintManager_invoices_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Invoice Cancelled Error', 'No orders selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
  
  
          const flowParams = {
              invoiceId: row.entity.Id
          };
          var result = await $flows.Invoices.is_invoice_cancelable(flowParams);
  
          const reason = result?.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Invoice ${row.entity.LookupCode} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Invoice Cancelled errors';
          const errorMessage = `Invoice(s) ${failures.map(c => c.entity.LookupCode).join(', ')} cannot be cancelled`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Invoice(s) ${candidates.map(c => c.entity.LookupCode).join(', ')} - Once cancelled, the invoice cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some invoice(s) cannot be cancelled';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Cancel the following invoice(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
  
                      const user = (await $flows.Utilities.get_username_flow({})).userName;
                      var userName = user.substring(
                          user.indexOf(`\\`) + 1,
                          user.indexOf('@') >= 0 ? user.indexOf('@') : user.length
                      );
  
                      const dateNow = $utils.date.format($utils.date.now(), 'MM/DD/YY LT');
  
                      const flowParams = {
                          invoiceId: candidate.entity.Id,
                          statusId: 4, // Cancelled,
                          notes: `Invoice cancelled on ${dateNow} by ${userName}`
                      }
                      var result = await $flows.Invoices.update_invoice_status_flow(flowParams);
  
  
                      const reason = result.reason;
                      if ($utils.isDefined(reason)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Invoice ${candidate.entity.LookupCode} - ${reason}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Invoice ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All invoice(s) cancelled';
                  const message = `Invoice(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} cancelled`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All invoice(s) failed to cancel';
                      const message = `Invoice(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be cancelled`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some invoice(s) could not be cancelled';
                      const success = `Invoice(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were cancelled.`;
                      const errors = `The following invoice(s) could not be cancelled: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $grid: FootPrintManager_invoices_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Invoice Complete Error', 'No invoices selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              invoiceId: row.entity.Id
          };
  
          var result = await $flows.Invoices.is_invoice_completable(flowParams);
  
          const reason = result?.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Invoice ${row.entity.LookupCode} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Invoice complete errors';
          const errorMessage = `Invoice(s) ${failures.map(c => c.entity.LookupCode).join(', ')} cannot be completed`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Invoice(s) ${candidates.map(c => c.entity.LookupCode).join(',')} - Once completed, the invoice should not be changed.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some invoice(s) cannot be completed';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Complete the following invoice(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const user = (await $flows.Utilities.get_username_flow({})).userName;
                      var userName = user.substring(
                          user.indexOf(`\\`) + 1,
                          user.indexOf('@') >= 0 ? user.indexOf('@') : user.length
                      );
  
                      const dateNow = $utils.date.format($utils.date.now(), 'MM/DD/YY LT');
  
                      const flowParams = {
                          invoiceId: candidate.entity.Id,
                          statusId: 3, // completed
                          notes: `Invoice completed on ${dateNow} by ${userName}`
                      }
                      var result = await $flows.Invoices.update_invoice_status_flow(flowParams);
  
  
  
                      const reason = result.reason;
                      if ($utils.isDefined(reason)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Invoice ${candidate.entity.LookupCode} - ${reason}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Invoice ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All invoice(s) completed';
                  const message = `Invoice(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} completed`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All invoice(s) failed to complete';
                      const message = `Invoice(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be completed`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some invoice(s) could not be completed';
                      const success = `Invoice(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were completed.`;
                      const errors = `The following invoice(s) could not be completed: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_invoices_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Invoice Delete Error', 'No invoices selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
  
          const flowParams = {
              invoiceId: row.entity.Id
          };
          var result = await $flows.Invoices.is_invoice_deletable(flowParams);
  
          const reason = result?.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Invoice ${row.entity.LookupCode} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Invoice delete errors';
          const errorMessage = `Invoice(s) ${failures.map(c => c.entity.LookupCode).join(', ')} cannot be deleted`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Invoice(s) ${candidates.map(c => c.entity.LookupCode).join(', ')} - Once deleted, the invoice cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some invoice(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following invoice(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
  
                      const flowParams = {
                          invoiceId: candidate.entity.Id
                      }
                      var result = await $flows.Invoices.delete_invoice_flow(flowParams);
  
  
                      const reason = result.reason;
                      if ($utils.isDefined(reason)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Invoice ${candidate.entity.LookupCode} - ${reason}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Invoice ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All invoice(s) deleted';
                  const message = `Invoice(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} deleted`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All invoice(s) failed to delete';
                      const message = `Invoice(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be deleted`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some invoice(s) could not be deleted';
                      const success = `Invoice(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were deleted.`;
                      const errors = `The following invoice(s) could not be deleted: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_invoices_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/02/2024
  
  // Initialize date format
  var dateFormat = $settings.FootPrintManager.DateFormat;
  
  $grid.filters.created_from.control.format = dateFormat;
  $grid.filters.created_to.control.format = dateFormat;
  
  //Let's see if we find a custom label for the export button
  
  try {
  
      let on_export_config = (await $flows.Utilities.configurations_get_one({
          input: [{ name: "label", string_1: "invoices_grid", string_2: "on_export" }],
          criteria: { application_name: "FootprintManager" }
      }))?.output
  
      if ($utils.isDefined(on_export_config?.value)) {
          $grid.topToolbar.on_export.control.label = on_export_config.value;
      } else {
  
          await $flows.Utilities.configurations_write({
              input: [{ name: "label", string_1: "invoices_grid", string_2: "on_export", value: "Export for accounting", notes: "This is the custom value we'll show on the on_export button for the invoices grid." }],
              criteria: { application_name: "FootprintManager" }
          })
      }
  } catch (e) { /*Do not throw an error */ }
  
  //Start read only
  $grid.topToolbar.on_export.control.readOnly = true;
  $grid.topToolbar.cancel.control.readOnly = true;
  $grid.topToolbar.on_delete.control.readOnly = true;
  $grid.topToolbar.complete.control.readOnly = true;
  $grid.topToolbar.print.control.readOnly = true;
  $grid.topToolbar.status_change.control.readOnly = true;
  
  if (await $operations.FootPrintManager.Disable_Billing_InvoiceEditing.isAuthorized()) {
      $grid.topToolbar.on_export.hidden = true;
      $grid.topToolbar.cancel.hidden = true;
      $grid.topToolbar.on_delete.hidden = true;
      $grid.topToolbar.complete.hidden = true;
      $grid.topToolbar.attachments.hidden = true;
      $grid.topToolbar.status_change.hidden = true;
  }
  
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $grid: FootPrintManager_invoices_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
   const selectedRows = $grid.selectedRows;
  
  for (let selectedRow of selectedRows) {
      let invoiceId = selectedRow.entity.Id;
      await $shell.Invoices.openinvoice_reportDialog({ invoiceId: invoiceId });
  }
  }
  on_filter_changed(event = null) {
    return this.on_filter_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_filter_changedInternal(
    $grid: FootPrintManager_invoices_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $grid.events.filtersUpdated.emit({
      createdFrom: $grid.filters.created_from.control.value,
      createdTo: $grid.filters.created_to.control.value
  });
  }
  on_change_status_clicked(event = null) {
    return this.on_change_status_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_status_clickedInternal(
    $grid: FootPrintManager_invoices_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const candidates = $grid.selectedRows;
  
  
  if (candidates.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Status Change Error', 'No lines selected.');
      return;
  } else {
  
  
      const dialogResult = await $shell.FootPrintManager.openinvoice_status_change_options_formDialog({
      });
      var userConfirmed = dialogResult.confirm;
      var statusId = dialogResult.statusId;
      var notes = dialogResult.notes;
  
      if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
          return;
      }
  
      var statuses = (await $datasources.Invoices.ds_get_invoicestatus_by_statusId.get({ statusId: statusId })).result;
      var statusName = statuses.Name;
  
      const processSuccess = [];
      const processFailures = [];
      const errorMsgList = [];
      const errorMsgListDetails = [];
  
      for (const candidate of candidates) {
  
          try {
  
              const user = (await $flows.Utilities.get_username_flow({})).userName;
              var userName = user.substring(
                  user.indexOf(`\\`) + 1,
                  user.indexOf('@') >= 0 ? user.indexOf('@') : user.length
              );
  
              const dateNow = $utils.date.format($utils.date.now(), 'MM/DD/YY LT');
  
  
              const flowParams = {
                  invoiceId: candidate.entity.Id,
                  statusId: statusId, // Cancelled,
                  notes: `Invoice ${statusName} on ${dateNow} by ${userName} ` + notes
              }
              var result = await $flows.Invoices.update_invoice_status_flow(flowParams);
  
  
              const reason = result.reason;
              if ($utils.isDefined(reason)) {
                  processFailures.push(candidate);
                  errorMsgList.push(`Invoice ${candidate.entity.LookupCode} - ${reason}`);
              } else {
                  processSuccess.push(candidate);
              }
  
  
          } catch (error) {
              processFailures.push(candidate);
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Invoice ${candidate.entity.LookupCode} - ${errorMessage}`;
              errorMsgList.push(errorDescription);
              errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
          }
      }
  
  
      // all succeeded
      if (processSuccess.length === candidates.length) {
          const title = 'All invoice(s) statuses changed';
          const message = `Invoice(s) ${processSuccess.map(c => c.entity.LookupCode).join(',')} statuses changed.`;
          await $shell.FootPrintManager.openInfoDialog(title, message);
          await $grid.refresh();
      } else {
          // all failures
          if (processFailures.length === candidates.length) {
              const title = 'All invoice(s) failed to change status';
              const message = `Invoice(s) ${processFailures.map(c => c.entity.LookupCode).join(',')} statuses could not be changed.`;
              await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
          } else {
              const title = 'Some invoice(s) status could not be changed';
              const success = `Invoice(s) ${processSuccess.map(c => c.entity.LookupCode).join(',')} statuses were changed.`;
              const errors = `The following invoice(s) status could not be changed: ${processFailures.map(c => c.entity.LookupCode).join(',')}`;
              const message = `${success} \r\n\r\n${errors}`;
              await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
              await $grid.refresh();
          }
      }
  
  
  
  
  }
  
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_invoices_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/25/2024
  
  // Create a currency formatter for USD
  const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // Optional: Specify minimumFractionDigits or maximumFractionDigits if needed
      // minimumFractionDigits: 2, // (for whole numbers, this suffices but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });
  
  const invoiceIds: number[] = $grid.rows?.map(r => r.entity.Id) ?? [];
  
  const invoiceLines = (await $datasources.Invoices.ds_get_invoice_lines_grouped_by_invoiceId.get({
      invoiceIds: invoiceIds
  })).result;
  
  if ($utils.isDefined(invoiceLines)) {
  
      for (let row of $grid.rows) {
  
          row.cells.total.displayControl.text = formatter.format(invoiceLines?.find(il => il.InvoiceId === row.entity.Id)?.LineTotal ?? 0)
      }
  }
  
  const attn_exists: boolean = $utils.isDefined($grid.rows?.filter(r => $utils.isDefined(r.entity.AttentionOf)))
  
  if (attn_exists) {
      $grid.headers.attention_of.hidden = false;
  } else {
      $grid.headers.attention_of.hidden = true;
  }
  
  const ref_exists: boolean = $utils.isDefined($grid.rows?.filter(r => $utils.isDefined(r.entity.ReferenceCode)))
  
  if (ref_exists) {
      $grid.headers.reference_code.hidden = false;
  } else {
      $grid.headers.reference_code.hidden = true;
  }
  
  const due_exists: boolean = $utils.isDefined($grid.rows?.filter(r => $utils.isDefined(r.entity.DueDate)))
  
  if (due_exists) {
      $grid.headers.due_date.hidden = false;
  } else {
      $grid.headers.due_date.hidden = true;
  }
  
  const war_exists: boolean = $utils.isDefined($grid.rows?.filter(r => $utils.isDefined(r.entity.InvoiceLines[0]?.BillingRecords[0]?.BillingTask?.Warehouse?.Name)))
  
  if (war_exists) {
      $grid.headers.warehouse.hidden = false;
  } else {
      $grid.headers.warehouse.hidden = true;
  }
  
  const shp_exists: boolean = $utils.isDefined($grid.rows?.filter(r => $utils.isDefined(r.entity.InvoiceLines[0]?.BillingRecords[0]?.BillingTask?.Shipment?.LookupCode)))
  
  if (shp_exists) {
      $grid.headers.shipment.hidden = false;
  } else {
      $grid.headers.shipment.hidden = true;
  }
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $grid: FootPrintManager_invoices_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  for (let row of $grid.selectedRows) {
      await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'Invoice', entityKeys: [{ name: 'Id', value: row.entity.Id }]}, 'flyout', EModalSize.Xlarge);
  }
  }
  on_export_to_file(event = null) {
    return this.on_export_to_fileInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_to_fileInternal(
    $grid: FootPrintManager_invoices_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/23/2024
  
  const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  
  let we_have_rows: boolean = false;
  
  if ($utils.isDefined($grid.rows)) {
      we_have_rows = true;
  }
  
  if (we_have_rows) {
  
      let selected = $grid.selectedRows;
  
      const wb = $event;
  
      let export_records: any[] = [];
  
      if ($utils.isDefined(selected)) {
  
          const invoice_ids: number[] = selected.map(c => c.entity.Id) ?? [];
  
          const invoice_lines = (await $datasources.Invoices.ds_get_invoice_lines_grouped_by_invoiceId.get({
              invoiceIds: invoice_ids
          })).result;
  
          export_records = map_records(selected.map(s => s.entity), invoice_lines);
  
      } else {
  
          let skip: number = 0;
          let take: number = 100;
          let current: number = 1;
  
          while (current > 0) {
  
              let current_payload = (await $datasources.Invoices.ds_invoices_grid.getList({
                  ownerId: $grid.inParams.ownerId,
                  projectId: $grid.inParams.projectId,
                  statusIds: $grid.inParams.statusIds,
                  fullTextSearch: $grid.fullTextSearch,
                  createdFrom: $grid.filters.created_from.control.value,
                  createdTo: $grid.filters.created_to.control.value,
                  $skip: skip,
                  $top: take
              })).result;
  
              if ($utils.isDefined(current_payload)) {
                  current = current_payload.length;
  
                  const invoice_ids: number[] = current_payload.map(c => c.Id) ?? [];
  
                  const invoice_lines = (await $datasources.Invoices.ds_get_invoice_lines_grouped_by_invoiceId.get({
                      invoiceIds: invoice_ids
                  })).result;
  
                  export_records.push(...map_records(current_payload, invoice_lines));
  
                  if (current < take) {
                      current = 0
                  } else {
                      skip = skip + take;
                  }
              } else {
                  current = 0;
              }
          }
      }
  
      if ($utils.isDefined(export_records)) {
          const ws = $utils.excel.json_to_sheet(export_records);
          $utils.excel.book_append_sheet(wb, ws, 'Invoices');
      }
  }
  
  function map_records(candidate: any[], lines: any[]) {
      let results: any[];
  
      results = candidate.map(c => {
  
          let createdDate: string = c.CreatedSysDateTime;
          let dueDate: string = c.DueDate;
  
          if ($utils.isDefined(createdDate) && $utils.isDefined(format)) {
              createdDate = $utils.date.format(createdDate, format);
          }
  
          if ($utils.isDefined(dueDate) && $utils.isDefined(format)) {
              dueDate = $utils.date.format(dueDate, format);
          }
  
          let shipment: string;
          let warehouse: string;
  
          if ($utils.isDefined(c.InvoiceLines)) {
  
              let InvoiceLines = c.InvoiceLines.filter(l => $utils.isDefined(l.BillingRecords));
  
              if ($utils.isDefined(InvoiceLines)) {
  
                  let BillingRecord = InvoiceLines[0].BillingRecords[0];
                  shipment = BillingRecord?.BillingTask?.Shipment?.LookupCode;
                  warehouse = BillingRecord?.BillingTask?.Warehouse?.Name;
              }
          }
  
          return {
              "Lookup": c.LookupCode,
              "Status": c.Status?.Name,
              "Owner": c.Project?.Owner?.LookupCode,
              "Project": c.Project?.LookupCode,
              "Total": lines ? formatter.format(lines?.find(il => il.InvoiceId === c.Id)?.LineTotal ?? 0) : ``,
              "Created": createdDate,
              "Due Date": dueDate,
              "Warehouse": warehouse,
              "Shipment": shipment,
              "Reference": c.ReferenceCode,
              "Attention Of": c.AttentionOf,
              "Notes": c.Notes
          }
      })
      return results;
  }
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_to_file(wb);

    writeExcelFile(wb, 'FootPrintManager_invoices_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
