<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"


[rowCanExpand]="true"
>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="release_button" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_release_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="reprocess_button" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_reprocess_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="cancel_button" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_cancel_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="Order_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Order_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.Order_LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.Order_LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Order_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="orderline_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.orderline_number.displayControl.styles.style"
          [ngClass]="row.cells.orderline_number.displayControl.styles.classes"
          [matTooltip]="row.cells.orderline_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.orderline_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Order_OwnerReference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Order_OwnerReference.displayControl.styles.style"
          [ngClass]="row.cells.Order_OwnerReference.displayControl.styles.classes"
          [matTooltip]="row.cells.Order_OwnerReference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Order_OwnerReference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Order_VendorReference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Order_VendorReference.displayControl.styles.style"
          [ngClass]="row.cells.Order_VendorReference.displayControl.styles.classes"
          [matTooltip]="row.cells.Order_VendorReference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Order_VendorReference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Material_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Material_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.Material_LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.Material_LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Material_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ExpectedPackagedAmount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ExpectedPackagedAmount.displayControl.styles.style"
          [ngClass]="row.cells.ExpectedPackagedAmount.displayControl.styles.classes"
          [matTooltip]="row.cells.ExpectedPackagedAmount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ExpectedPackagedAmount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Material_PackagingLookups_Packaging_ShortName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Material_PackagingLookups_Packaging_ShortName.displayControl.styles.style"
          [ngClass]="row.cells.Material_PackagingLookups_Packaging_ShortName.displayControl.styles.classes"
          [matTooltip]="row.cells.Material_PackagingLookups_Packaging_ShortName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Material_PackagingLookups_Packaging_ShortName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Lot_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Lot_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.Lot_LookupCode.displayControl.styles.classes"
          [matTooltip]="row.cells.Lot_LookupCode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Lot_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="VendorLot_ExpirationDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.VendorLot_ExpirationDate.displayControl.styles.style"
          [ngClass]="row.cells.VendorLot_ExpirationDate.displayControl.styles.classes"
          [matTooltip]="row.cells.VendorLot_ExpirationDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.VendorLot_ExpirationDate.displayControl.text | formatText : row.cells.VendorLot_ExpirationDate.displayControl.formatType : row.cells.VendorLot_ExpirationDate.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="VendorLot_ManufactureDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.VendorLot_ManufactureDate.displayControl.styles.style"
          [ngClass]="row.cells.VendorLot_ManufactureDate.displayControl.styles.classes"
          [matTooltip]="row.cells.VendorLot_ManufactureDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.VendorLot_ManufactureDate.displayControl.text | formatText : row.cells.VendorLot_ManufactureDate.displayControl.formatType : row.cells.VendorLot_ManufactureDate.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="task_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.task_id.displayControl.styles.style"
          [ngClass]="row.cells.task_id.displayControl.styles.classes"
          [matTooltip]="row.cells.task_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.task_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <FootPrintManager-available_inventory_grid
    [materialId]="row.$rowExpand_FootPrintManager_available_inventory_grid_inParams_materialId"
    [warehouseId]="row.$rowExpand_FootPrintManager_available_inventory_grid_inParams_warehouseId"
    [recommendedAmount]="row.$rowExpand_FootPrintManager_available_inventory_grid_inParams_recommendedAmount"
    [taskId]="row.$rowExpand_FootPrintManager_available_inventory_grid_inParams_taskId"
    [lotId]="row.$rowExpand_FootPrintManager_available_inventory_grid_inParams_lotId"
    [vendorLotId]="row.$rowExpand_FootPrintManager_available_inventory_grid_inParams_vendorLotId"
    ($refreshEvent)="row.refresh()">>
  </FootPrintManager-available_inventory_grid>
</ng-template>

</datex-grid>
