import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootPrintManager_blind_shipping_container_return_tasks_gridComponent } from './FootPrintManager.blind_shipping_container_return_tasks_grid.component';
import { FootPrintManager_return_orders_gridComponent } from './FootPrintManager.return_orders_grid.component';
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Returns_returns_location_dd_singleComponent } from './Returns.returns_location_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_blind_shipping_container_return_tasks_gridComponent),
    forwardRef(() => FootPrintManager_return_orders_gridComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Returns_returns_location_dd_singleComponent),
  ],
  selector: 'FootPrintManager-blind_return_shipping_container_hub',
  templateUrl: './FootPrintManager.blind_return_shipping_container_hub.component.html'
})
export class FootPrintManager_blind_return_shipping_container_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { shippingContainerId?: number } = { shippingContainerId: null };
  //#region Inputs
  @Input('shippingContainerId') set $inParams_shippingContainerId(v: number) {
    this.inParams.shippingContainerId = v;
  }
  get $inParams_shippingContainerId(): number {
    return this.inParams.shippingContainerId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { shippingContainerId?: number, licensePlateId?: number, lastScan?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    location: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    shipping_container_code: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    input_scan: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      confirm_container: new ToolModel(new ButtonModel('confirm_container', new ButtonStyles(null, null), false, false, false, 'Confirm container', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
    location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['location'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Returns station', true, false),
    shipping_container_code: new FieldModel(new TextBoxModel(this.formGroup.controls['shipping_container_code'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Shipping container', true, false),
    input_scan: new FieldModel(new TextBoxModel(this.formGroup.controls['input_scan'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Scan serial barcode', false, false),
  };


  filtersets = {
  filters: new FieldsetModel('', true, false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      return_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Return tasks', 
        false,
        ),
      container_orders: new TabItemModel(
        this.rootTabGroup, 
        'Orders', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_return_tasks_blind_shipping_container_return_tasks_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    get $tabs_return_tasks_blind_shipping_container_return_tasks_grid_inParams_locationId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.location.control.value;
      
      return expr;
    }
  
    get $tabs_return_tasks_blind_shipping_container_return_tasks_grid_inParams_sourceShippingContainerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.shippingContainerId;
      
      return expr;
    }
  
    get $tabs_return_tasks_blind_shipping_container_return_tasks_grid_inParams_sourceLicensePlateId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.licensePlateId;
      
      return expr;
    }
  
    get $tabs_return_tasks_blind_shipping_container_return_tasks_grid_inParams_inputScan(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.lastScan;
      
      return expr;
    }
  
    get $tabs_container_orders_return_orders_grid_inParams_blindShippingContainerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.shippingContainerId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_return_tasks', { read: FootPrintManager_blind_shipping_container_return_tasks_gridComponent }) $tabs_return_tasks: FootPrintManager_blind_shipping_container_return_tasks_gridComponent;
      @ViewChild('$tabs_container_orders', { read: FootPrintManager_return_orders_gridComponent }) $tabs_container_orders: FootPrintManager_return_orders_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_location_selector_inParams_warehouseId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.warehouse.control.value;
    
    return expr;
  }



  get $fields_location_selector_inParams_typeId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 3;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Blind return`;
  }


  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.return_tasks,
      this.tabs.container_orders,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Blind return';
    
    const $hub = this;
    const $utils = this.utils;

    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['warehouse']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_change();
      });
    this.formGroup
      .controls['location']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_changed();
      });
    this.formGroup
      .controls['shipping_container_code']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_shipping_container_code_changed();
      });
    this.formGroup
      .controls['input_scan']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_input_scan_changed();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_return_tasks') {
      if (!isNil(this.$tabs_return_tasks) && !this.tabs.return_tasks.hidden) {
        this.$tabs_return_tasks.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_container_orders') {
      if (!isNil(this.$tabs_container_orders) && !this.tabs.container_orders.hidden) {
        this.$tabs_container_orders.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_blind_return_shipping_container_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($hub.inParams.shippingContainerId)) {
      let shippingContainer = (await $datasources.Returns.ds_get_shipping_container_by_shippingContainerId.get({ shippingContainerId: $hub.inParams.shippingContainerId })).result;
  
      if ($utils.isDefined(shippingContainer)) {
          $hub.filters.shipping_container_code.control.value = shippingContainer.LookupCode;
          $hub.vars.shippingContainerId = shippingContainer.Id;
  
          $hub.on_shipping_container_code_changed();
  
          if ($utils.isDefined(shippingContainer.LicensePlates)) {
              $hub.filters.warehouse.control.value = shippingContainer.LicensePlates[0].WarehouseId;
          }
      }
  }
  
  $hub.set_hub_state();
  }
  on_input_scan_changed(event = null) {
    return this.on_input_scan_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_input_scan_changedInternal(
    $hub: FootPrintManager_blind_return_shipping_container_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      if ($utils.isDefinedTrimmed($hub.filters.input_scan.control.value)) {
          $hub.vars.lastScan = $hub.filters.input_scan.control.value;
      }
  }
  catch (error) {
      let targetError = error;
  
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.Returns.openErrorDialog('Error confirming return task', `Could not confirm return task: ${targetError.message}.`);
  }
  
  
  $hub.filters.input_scan.control.value = null;
  }
  on_shipping_container_code_changed(event = null) {
    return this.on_shipping_container_code_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_shipping_container_code_changedInternal(
    $hub: FootPrintManager_blind_return_shipping_container_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      if ($utils.isDefined($hub.filters.shipping_container_code.control.value)) {
  
          // Find shipping container
          let shippingContainer = (await $datasources.Returns.ds_get_shipping_container_by_shippingContainerCode.get({ shippingContainerCode: $hub.filters.shipping_container_code.control.value })).result;
  
          if (!$utils.isDefined(shippingContainer)) { throw new Error(`Shipping container '${$hub.filters.shipping_container_code.control.value}' does not exist.`); }
  
          // If not on a return order, throw error
          if ([3,13].indexOf(shippingContainer.Shipment.OrderLookups[0]?.Order?.OrderClass?.OrderClassTypeId) < 0) {
              throw new Error(`Shipping container '${shippingContainer.LookupCode}' is not on a return order.`);
              $hub.filters.shipping_container_code.control.value = null;
          }
  
          // Validate shipping container
          $hub.vars.shippingContainerId = shippingContainer.Id;
  
          if ($utils.isDefined(shippingContainer.LicensePlates)) {
              let warehouseId = shippingContainer.LicensePlates[0].WarehouseId;
              if ($utils.isDefined($hub.filters.warehouse.control.value)) {
                  if (warehouseId !== $hub.filters.warehouse.control.value) { throw new Error(`Shipping container '${shippingContainer.LookupCode}' is not located in warehouse '${$hub.filters.warehouse.control.displayText}'`)}
              }
              else {
                  $hub.filters.warehouse.control.value = warehouseId;
              }
          }
  
          // Get license plates and populate variable
          if (shippingContainer.LicensePlates.length > 0) {
              $hub.vars.licensePlateId = shippingContainer.LicensePlates[0].Id;
          }
      }
      else {
          $hub.vars.licensePlateId = null;
          $hub.vars.shippingContainerId = null;
      }
  }
  catch (error) {
      while ($utils.isDefined(error.error)) { error = error.error; }
  
      await $shell.Returns.openErrorDialog('Error finding scanned shipping container', error.message);
  }
  
  $hub.set_hub_state();
  
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  
  }
  on_location_changed(event = null) {
    return this.on_location_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_changedInternal(
    $hub: FootPrintManager_blind_return_shipping_container_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.set_hub_state();
  }
  on_warehouse_change(event = null) {
    return this.on_warehouse_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_changeInternal(
    $hub: FootPrintManager_blind_return_shipping_container_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.filters.location.control.value = null;
  $hub.filters.shipping_container_code.control.value = null;
  
  $hub.set_hub_state();
  }
  set_hub_state(event = null) {
    return this.set_hub_stateInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_hub_stateInternal(
    $hub: FootPrintManager_blind_return_shipping_container_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.tabs.container_orders.hidden = true;
  
  let hasAllRequired = true;
  
  for (let key in $hub.filters) {
      if ($hub.filters[key].required && !$utils.isDefined($hub.filters[key].control.value)) {
          hasAllRequired = false;
      }
  }
  
  let isScanReadOnly = !hasAllRequired;
  
  if (hasAllRequired) {
      $hub.toolbar.confirm_container.hidden = false;
  }
  
  var licenseplates = (await $datasources.Returns.ds_find_licenseplates.get({ shippingContainerIds: [$hub.vars.shippingContainerId] })).result;
  if ($utils.isDefined(licenseplates)) { $hub.vars.licensePlateId = licenseplates[0].Id; }
  
  let tasks = (await $datasources.Returns.ds_blind_shipping_container_return_tasks_grid.getList({ shippingContainerId: $hub.vars.shippingContainerId })).result;
  
  if (!$utils.isDefined(licenseplates)) { // Closed
      $hub.filters.location.control.value = tasks[0]?.ExpectedTargetLocationId;
      $hub.filters.location.control.readOnly = true;
  
      $hub.filters.warehouse.control.value = tasks[0]?.WarehouseId;
      $hub.filters.warehouse.control.readOnly = true;
  
      $hub.toolbar.confirm_container.hidden = true;
  
      $hub.tabs.container_orders.hidden = false;
  
      isScanReadOnly = true;
  }
  else if ($utils.isDefined(tasks)) { // In progress
      // Set location
      $hub.filters.location.control.value = tasks[0].ExpectedTargetLocationId;
      $hub.filters.location.control.readOnly = true;
  
      $hub.filters.warehouse.control.value = tasks[0].WarehouseId;
      $hub.filters.warehouse.control.readOnly = true;
  
      $hub.toolbar.confirm_container.hidden = false;
  
      isScanReadOnly = false;
  }
  else { // Open
      $hub.filters.location.control.readOnly = false;
  
      $hub.filters.warehouse.control.readOnly = false;
  
      // Hide confirm container button
      $hub.toolbar.confirm_container.hidden = true;
  }
  
  $hub.filters.input_scan.control.readOnly = isScanReadOnly;
  }
  on_confirm_container_clicked(event = null) {
    return this.on_confirm_container_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_container_clickedInternal(
    $hub: FootPrintManager_blind_return_shipping_container_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      if (await $shell.FootPrintManager.openConfirmationDialog('Confirm shipping container?', 'Once confirmed, inbound RMA orders will be created and content will no longer be able to be attributed to the shipping container.', 'Confirm', 'Cancel')) {
          let result = (await $flows.Returns.confirm_blind_container_flow({ shippingContainerId: $hub.vars.shippingContainerId, isAutoReceive: true }));
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error(`Shipping container cannot be confirmed: ${(await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue}`);
          }
  
          $hub.refresh();
          $hub.set_hub_state();
      }
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error confirming container', error.message);
  }
  }
  on_tasks_changed(event = null) {
    return this.on_tasks_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_tasks_changedInternal(
    $hub: FootPrintManager_blind_return_shipping_container_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $hub.set_hub_state();
  
  $hub.filters.input_scan.control.focus();
  }
  //#endregion private flows
}
