<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="confirm_and_new" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_and_new($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-material"
                     *ngIf="!fieldsets.material.hidden && !fieldsets.material.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.material.collapsible }">
                    <div *ngIf="!fieldsets.material.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.material.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.material.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.material.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.material.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.material.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.material.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden && !fields.owner.removed" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}} {{fields.owner.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.owner.label + (fields.owner.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.owner.label}}<span *ngIf="fields.owner.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                            [tooltip]="fields.owner.control.tooltip"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden && !fields.project.removed" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}} {{fields.project.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                            [tooltip]="fields.project.control.tooltip"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden && !fields.material.removed" 
                            class="field-container standard {{fields.material.invalid ? 'invalid' : ''}} {{fields.material.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.material.label + (fields.material.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.material.label}}<span *ngIf="fields.material.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-materials_by_project_dd_single 
                            data-cy="selector"
                            [type]="fields.material.control.type"
                            formControlName="material"
                            (displayTextChange)="fields.material.control.displayText=$event"
                            [placeholder]="fields.material.control.placeholder"
                            [styles]="fields.material.control.styles"
                            [tooltip]="fields.material.control.tooltip"
                          [projectId]="$fields_material_selector_inParams_projectId"
                        >
                        </Materials-materials_by_project_dd_single>
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-lot"
                     *ngIf="!fieldsets.lot.hidden && !fieldsets.lot.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.lot.collapsible }">
                    <div *ngIf="!fieldsets.lot.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.lot.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.lot.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.lot.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.lot.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.lot.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.lot.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lot" *ngIf="!fields.lot.hidden && !fields.lot.removed" 
                            class="field-container standard {{fields.lot.invalid ? 'invalid' : ''}} {{fields.lot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lot.styles.style"
                            [ngClass]="fields.lot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lot.label + (fields.lot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lot.label}}<span *ngIf="fields.lot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lot"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.lot.control.readOnly || fields.lot.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.lot.control.placeholder}}"
                                [ngStyle]="fields.lot.control.styles.style"
                                [ngClass]="fields.lot.control.styles.classes"
                                [matTooltip]="fields.lot.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lot.invalid">
                          <ng-container *ngFor="let error of fields.lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-description" *ngIf="!fields.description.hidden && !fields.description.removed" 
                            class="field-container standard {{fields.description.invalid ? 'invalid' : ''}} {{fields.description.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.description.label + (fields.description.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.description.label}}<span *ngIf="fields.description.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="description"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.description.control.readOnly || fields.description.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.description.control.placeholder}}"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"
                                [matTooltip]="fields.description.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-create_new_vendorlot" *ngIf="!fields.create_new_vendorlot.hidden && !fields.create_new_vendorlot.removed" 
                            class="field-container standard {{fields.create_new_vendorlot.invalid ? 'invalid' : ''}} {{fields.create_new_vendorlot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.create_new_vendorlot.styles.style"
                            [ngClass]="fields.create_new_vendorlot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.create_new_vendorlot.label + (fields.create_new_vendorlot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.create_new_vendorlot.label}}<span *ngIf="fields.create_new_vendorlot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="create_new_vendorlot"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.create_new_vendorlot.control.styles.style"
                                      [ngClass]="fields.create_new_vendorlot.control.styles.classes"
                                      [matTooltip]="fields.create_new_vendorlot.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.create_new_vendorlot.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.create_new_vendorlot.invalid">
                          <ng-container *ngFor="let error of fields.create_new_vendorlot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_lot_lookup" *ngIf="!fields.vendor_lot_lookup.hidden && !fields.vendor_lot_lookup.removed" 
                            class="field-container standard {{fields.vendor_lot_lookup.invalid ? 'invalid' : ''}} {{fields.vendor_lot_lookup.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.vendor_lot_lookup.styles.style"
                            [ngClass]="fields.vendor_lot_lookup.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vendor_lot_lookup.label + (fields.vendor_lot_lookup.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vendor_lot_lookup.label}}<span *ngIf="fields.vendor_lot_lookup.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="vendor_lot_lookup"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.vendor_lot_lookup.control.readOnly || fields.vendor_lot_lookup.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.vendor_lot_lookup.control.placeholder}}"
                                [ngStyle]="fields.vendor_lot_lookup.control.styles.style"
                                [ngClass]="fields.vendor_lot_lookup.control.styles.classes"
                                [matTooltip]="fields.vendor_lot_lookup.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.vendor_lot_lookup.invalid">
                          <ng-container *ngFor="let error of fields.vendor_lot_lookup.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_lot" *ngIf="!fields.vendor_lot.hidden && !fields.vendor_lot.removed" 
                            class="field-container standard {{fields.vendor_lot.invalid ? 'invalid' : ''}} {{fields.vendor_lot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.vendor_lot.styles.style"
                            [ngClass]="fields.vendor_lot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vendor_lot.label + (fields.vendor_lot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vendor_lot.label}}<span *ngIf="fields.vendor_lot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Lots-vendorlots_dd_single 
                            data-cy="selector"
                            [type]="fields.vendor_lot.control.type"
                            formControlName="vendor_lot"
                            (displayTextChange)="fields.vendor_lot.control.displayText=$event"
                            [placeholder]="fields.vendor_lot.control.placeholder"
                            [styles]="fields.vendor_lot.control.styles"
                            [tooltip]="fields.vendor_lot.control.tooltip"
                          [materialId]="$fields_vendor_lot_selector_inParams_materialId"
                        >
                        </Lots-vendorlots_dd_single>
                        <ng-container *ngIf="fields.vendor_lot.invalid">
                          <ng-container *ngFor="let error of fields.vendor_lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-manufacture_date" *ngIf="!fields.manufacture_date.hidden && !fields.manufacture_date.removed" 
                            class="field-container standard {{fields.manufacture_date.invalid ? 'invalid' : ''}} {{fields.manufacture_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.manufacture_date.styles.style"
                            [ngClass]="fields.manufacture_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.manufacture_date.label + (fields.manufacture_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.manufacture_date.label}}<span *ngIf="fields.manufacture_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="manufacture_date"
                            [format]="fields.manufacture_date.control.format"
                            [mode]="fields.manufacture_date.control.mode"
                            [ngStyle]="fields.manufacture_date.control.styles.style"
                            [ngClass]="fields.manufacture_date.control.styles.classes"
                            [tooltip]="fields.manufacture_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.manufacture_date.invalid">
                          <ng-container *ngFor="let error of fields.manufacture_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expiration_date" *ngIf="!fields.expiration_date.hidden && !fields.expiration_date.removed" 
                            class="field-container standard {{fields.expiration_date.invalid ? 'invalid' : ''}} {{fields.expiration_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.expiration_date.styles.style"
                            [ngClass]="fields.expiration_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.expiration_date.label + (fields.expiration_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.expiration_date.label}}<span *ngIf="fields.expiration_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="expiration_date"
                            [format]="fields.expiration_date.control.format"
                            [mode]="fields.expiration_date.control.mode"
                            [ngStyle]="fields.expiration_date.control.styles.style"
                            [ngClass]="fields.expiration_date.control.styles.classes"
                            [tooltip]="fields.expiration_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.expiration_date.invalid">
                          <ng-container *ngFor="let error of fields.expiration_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden && !fields.notes.removed" 
                            class="field-container standard {{fields.notes.invalid ? 'invalid' : ''}} {{fields.notes.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.notes.control.readOnly || fields.notes.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vat" *ngIf="!fields.vat.hidden && !fields.vat.removed" 
                            class="field-container standard {{fields.vat.invalid ? 'invalid' : ''}} {{fields.vat.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.vat.styles.style"
                            [ngClass]="fields.vat.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vat.label + (fields.vat.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vat.label}}<span *ngIf="fields.vat.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="vat"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.vat.control.readOnly || fields.vat.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.vat.control.placeholder}}"
                                [ngStyle]="fields.vat.control.styles.style"
                                [ngClass]="fields.vat.control.styles.classes"
                                [matTooltip]="fields.vat.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.vat.invalid">
                          <ng-container *ngFor="let error of fields.vat.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>