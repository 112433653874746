import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { SalesOrders_licenseplates_by_locationId_lotId_packagingId_dd_singleComponent } from './SalesOrders.licenseplates_by_locationId_lotId_packagingId_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'


interface IFootPrintManager_sales_order_picking_gridComponentEntity {
  Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocationId?: number, LotId?: number, MaterialId?: number, OperationCodeId?: number, OrderId?: number, OrderLineNumber?: number, PickSlipId?: number, ProjectId?: number, ShipmentId?: number, ShipmentLineId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string, ProjectId?: number }, ExpectedPackagedPack?: { ShortName?: string }, ActualPackagedPack?: { ShortName?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string, Vat?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string, ShortName?: string } }, OrderLine?: { GrossWeight?: number, LotId?: number, NetWeight?: number, PackagedAmount?: number, WeightUomId?: number }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LocationId?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } }, ExpectedTargetShippingContainer?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, total_picked?: { TotalCount?: number, ExpectedCount?: number, ShipmentLine?: { OrderId?: number, OrderLineNumber?: number } }}

interface IFootPrintManager_sales_order_picking_gridComponentInParams {
  orderId?: number, shipmentId?: number, waveId?: number, orderStatusId?: number, waveStatusId?: number}


class FootPrintManager_sales_order_picking_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_sales_order_picking_gridComponent;
  entity: IFootPrintManager_sales_order_picking_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    quantity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    source_licenseplate_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    target_location_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    target_licenseplate_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    operation: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    linenumber: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    material: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    picked: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    remaining: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    quantity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['quantity_edit'] as DatexFormControl, null, false, false, '', '', null)
      ),
    source_location: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    source_licenseplate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['source_licenseplate_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    target_location: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['target_location_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
    target_licenseplate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['target_licenseplate_edit'] as DatexFormControl, null, false, false, '', null)
      ),
    expected_target_shipping_container: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }

  get $fields_source_licenseplate_selector_inParams_locationId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $utils.isDefined($row.entity.ExpectedSourceLicensePlate?.LocationId) ? $row.entity.ExpectedSourceLicensePlate?.LocationId : $row.entity.ExpectedSourceLocationId;
    
    return expr;
  }
  get $fields_source_licenseplate_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.LotId;
    
    return expr;
  }
  get $fields_source_licenseplate_selector_inParams_packagedId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.ActualPackagedPackId;
    
    return expr;
  }
  get $fields_target_location_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.WarehouseId;
    
    return expr;
  }
  get $fields_target_location_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }
  get $fields_target_location_selector_inParams_isLoose(): boolean {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = false;
    
    return expr;
  }
  get $fields_target_location_selector_inParams_eligibleForAllocation(): boolean {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = false;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
    this.formGroup
      .controls['quantity_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_quantity_changed();
      });
    this.formGroup
      .controls['target_licenseplate_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_licenseplate_change();
      });
  }

  async $initializeExisting(grid: FootPrintManager_sales_order_picking_gridComponent, entity: IFootPrintManager_sales_order_picking_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_sales_order_picking_gridComponent, entity?: IFootPrintManager_sales_order_picking_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
      shipmentId:  $grid.inParams.shipmentId ,
      waveId:  $grid.inParams.waveId ,
    };
    const data = await this.datasources.SalesOrders.ds_sales_order_picking_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_sales_order_picking_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.operation.displayControl as TextModel).text = $row.entity.OperationCode.Name;
    (this.cells.linenumber.displayControl as TextModel).text = $row.entity.OrderLineNumber?.toString();
    (this.cells.material.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.ActualPackagedPack?.ShortName;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity.VendorLot?.LookupCode;
    (this.cells.picked.displayControl as TextModel).text = $utils.isDefined($row.entity.total_picked?.TotalCount) ? $row.entity.total_picked?.TotalCount?.toString(): '0';
    (this.cells.source_location.displayControl as TextModel).text = $utils.isDefined($row.entity.ExpectedSourceLicensePlate?.Location.Name) ? $row.entity.ExpectedSourceLicensePlate?.Location.Name : $row.entity.ExpectedSourceLocation?.Name;
    (this.cells.target_location.displayControl as TextModel).text = $row.entity.ActualTargetLocation?.Name;
    (this.cells.target_location.editControl as SelectBoxModel).reset($row.entity.ActualTargetLocationId);
    (this.cells.target_licenseplate.displayControl as TextModel).text = '';
    (this.cells.target_licenseplate.editControl as TextBoxModel).reset('');
    (this.cells.expected_target_shipping_container.displayControl as TextModel).text = $row.entity?.ExpectedTargetShippingContainer?.LookupCode;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_licenseplate_change(event = null) {
    return this.on_licenseplate_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_licenseplate_changeInternal(
    $row: FootPrintManager_sales_order_picking_gridComponentRowModel,
  $grid: FootPrintManager_sales_order_picking_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefinedTrimmed($row.cells.target_licenseplate.editControl.value)) {
          let exists = true;
  
          const licenseplate = (await $datasources.Locations.ds_get_licenseplates_by_lookupcode_and_warehouseId.get({
              warehouseId: $row.entity.WarehouseId,
              lookupcode: $row.cells.target_licenseplate.editControl.value
          })).result;
          exists = $utils.isDefined(licenseplate);
  
          if (exists) {
              $row.cells.target_licenseplate.styles.setAttentionClass();
          } else {
              $row.cells.target_licenseplate.styles.resetClasses();
          }
      } else {
          $row.cells.target_licenseplate.styles.resetClasses();
      }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_sales_order_picking_gridComponentRowModel,
  $grid: FootPrintManager_sales_order_picking_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const operationCodeId = $row.entity.OperationCodeId;
  
  if (operationCodeId == 8) {
      // Set remaining amount
      const actualPackagedAmount = $row.cells.quantity.editControl.value;
      $row.cells.remaining.displayControl.text =
          `${$row.entity.ExpectedPackagedAmount} ${$row.entity.ExpectedPackagedPack?.ShortName}`;
      if ($row.entity.ExpectedPackagedAmount !== actualPackagedAmount &&
          actualPackagedAmount > 0) {
          $row.cells.remaining.styles.resetClasses();
          $row.cells.remaining.styles.setAttentionClass();
      } else {
          $row.cells.remaining.styles.resetClasses();
      }
  
  
      //Set default target LP
      $row.cells.target_licenseplate.editControl.value = `${$row.entity.OrderId}`;
      $row.cells.target_licenseplate.displayControl.text = `${$row.entity.OrderId}`;
      //Set Qty to expected Qty from task
      $row.cells.quantity.editControl.value = $row.entity.ExpectedPackagedAmount;
      $row.cells.quantity.displayControl.text = `${$row.entity.ExpectedPackagedAmount} `;
  
  
      //Set default target LP
      $row.cells.target_licenseplate.editControl.value = `${$row.entity.OrderId}`
  
      //Default picking quantity
      $row.cells.quantity.editControl.value = $row.entity.ExpectedPackagedAmount
  
      const sourceLocationId = $utils.isDefined($row.entity.ExpectedSourceLicensePlate?.LocationId) ? $row.entity.ExpectedSourceLicensePlate?.LocationId : $row.entity.ExpectedSourceLocationId
  
      //Set source LP when source location is loose
      const licenseplates = (await $datasources.Locations.ds_get_licenseplate_by_loose_locationId.get({ locationId: sourceLocationId })).result;
      if ($utils.isDefined(licenseplates)) {
  
          $row.cells.source_licenseplate.editControl.reset(licenseplates[0].Id);
          $row.cells.source_licenseplate.editControl.readOnly = true;
          $row.cells.source_licenseplate.displayControl.text = `${licenseplates[0].LookupCode}`;
  
      } else {
          //set value and defaultValue to keep changed to false
          $row.cells.source_licenseplate.editControl.reset('');
          $row.cells.source_licenseplate.editControl.readOnly = false;
      }
  
      // If there is only one LP in the source LP that matches the criteria of the pick task then default it
      const sourcelicenseplates = (await $datasources.SalesOrders.ds_get_licenseplates_by_locationId_lotId_packagingId.get({
          locationId: sourceLocationId,
          lotId: $row.entity.LotId,
          packagedId: $row.entity.ActualPackagedPackId
      })).result;
      if ($utils.isDefined(sourcelicenseplates) && sourcelicenseplates.length === 1) {
  
          $row.cells.source_licenseplate.editControl.value = sourcelicenseplates[0].Id;
          $row.cells.source_licenseplate.displayControl.text = sourcelicenseplates[0].LookupCode;
      }
  
  }
  
  else if (operationCodeId == 79) {
  
      // Initialize total quantity of children pick task
      var totalExpectedAmount = 0;
  
  
      const childTasks = (await $datasources.SalesOrders.ds_get_full_licenseplate_pick_task_child_tasks.get({ taskId: $row.entity.Id })).result;
      if ($utils.isDefined(childTasks)) {
  
          // Default to the first tasks data
          var lotId = childTasks[0].LotId;
          var multiLot = false;
  
          var vendorLotId = childTasks[0].VendorLotId;
          var multiVendorLot = false;
  
          var materialId = childTasks[0].MaterialId;
          var multiMaterial = false;
  
          var packagingId = childTasks[0].ExpectedPackagedPackId;
          var multiPackaging = false;
  
          var linenumber = childTasks[0].OrderLineNumber;
          var multiLine = false;
  
          // Loop through all of the children tasks
          for (let childTask of childTasks) {
  
              totalExpectedAmount = totalExpectedAmount + childTask.ExpectedPackagedAmount;
  
              if (childTask.LotId !== lotId) {
                  multiLot = true;
              }
              if (childTask.VendorLotId !== vendorLotId) {
                  multiVendorLot = true;
              }
              if (childTask.MaterialId !== materialId) {
                  multiMaterial = true;
              }
              if (childTask.ExpectedPackagedPackId !== packagingId) {
                  multiPackaging = true;
              }
              if (childTask.OrderLineNumber !== linenumber) {
                  multiLine = true;
              }
          }
  
          if (multiLine) {
              $row.cells.linenumber.displayControl.text = childTasks[0].OrderLineNumber?.toString() + '**';
              $row.cells.linenumber.styles.setAttentionClass();
          }
          else {
              $row.cells.linenumber.displayControl.text = childTasks[0].OrderLineNumber?.toString();
          }
  
          if (multiMaterial) {
              $row.cells.material.displayControl.text = childTasks[0].Material?.LookupCode + '**';
              $row.cells.material.styles.setAttentionClass();
          }
          else {
              $row.cells.material.displayControl.text = childTasks[0].Material?.LookupCode;
  
          }
  
          if (multiPackaging) {
              $row.cells.packaging.displayControl.text = childTasks[0].ExpectedPackagedPack?.ShortName + '**';
              $row.cells.packaging.styles.setAttentionClass();
          }
          else {
              $row.cells.packaging.displayControl.text = childTasks[0].ExpectedPackagedPack?.ShortName;
  
          }
  
          if (multiLot) {
              $row.cells.lot.displayControl.text = childTasks[0].Lot?.LookupCode + '**';
              $row.cells.lot.styles.setAttentionClass();
          }
          else {
              $row.cells.lot.displayControl.text = childTasks[0].Lot?.LookupCode;
  
          }
  
          if (multiVendorLot) {
              $row.cells.vendorlot.displayControl.text = childTasks[0].Lot?.VendorLot?.LookupCode + '**';
              $row.cells.vendorlot.styles.setAttentionClass();
          }
          else {
              $row.cells.vendorlot.displayControl.text = childTasks[0].Lot?.VendorLot?.LookupCode;
  
          }
  
          $row.cells.picked.displayControl.text = '0';
          $row.cells.remaining.displayControl.text = totalExpectedAmount?.toString();
  
          $row.cells.quantity.displayControl.text = totalExpectedAmount?.toString();
          $row.cells.quantity.editControl.readOnly = true;
  
          $row.cells.source_licenseplate.editControl.value = $row.entity.ExpectedSourceLicensePlate?.Id;
          $row.cells.source_licenseplate.displayControl.text = $row.entity.ExpectedSourceLicensePlate?.LookupCode;
          $row.cells.source_licenseplate.editControl.readOnly = true;
  
          $row.cells.target_licenseplate.displayControl.text = $row.entity.ExpectedSourceLicensePlate?.LookupCode;
          $row.cells.target_licenseplate.editControl.value = $row.entity.ExpectedSourceLicensePlate?.Id;
          $row.cells.target_licenseplate.editControl.readOnly = true;
  
      }
  
  }
  
  // Set default target picking location from warehouse
  const defaultLocation = (await $datasources.Locations.ds_get_warehouse_by_warehouseId.get({ warehouseId: $row.entity.WarehouseId })).result;
  if ($utils.isDefined(defaultLocation)) {
      if (defaultLocation.DefaultPickDestinationLocationId) {
          $row.cells.target_location.editControl.value = defaultLocation.DefaultPickDestinationLocationId
          $row.cells.target_location.displayControl.text = `${defaultLocation.DefaultPickDestinationLocation?.Name}`;
      }
  }
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_sales_order_picking_gridComponentRowModel,
  $grid: FootPrintManager_sales_order_picking_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
  
  
  $row.cells.target_licenseplate.displayControl.text = $row.cells.target_licenseplate.editControl.value;
  $row.cells.target_location.displayControl.text = $row.cells.target_location.editControl.displayText;
  
  
  
  try {
  
      if ($row.entity.OperationCodeId == 8) {
          const materials = (await $datasources.Materials.ds_get_material_by_materialId.get({
              materialId: $row.entity.MaterialId
          })).result;
          if ($utils.isDefined(materials)) {
  
              var controllerTypeId = materials[0].ControllerTypeId;
              var isFixedWeight = materials[0].IsFixedWeight;
              var isFixedLength = materials[0].IsFixedLength;
              var isFixedVolume = materials[0].IsFixedVolume;
  
          } else {
              throw new Error('Unable to determine material properties.')
          }
  
          if ($row.cells.quantity.editControl.value > $row.entity.ExpectedPackagedAmount) {
              throw new Error('Quantity entered is greater than the remaining amount, overpicking not allowed.');
          }
  
  
          //Check if quantity entered is 0
          if ($row.cells.quantity.editControl.value == 0) {
              throw new Error('Quantity entered cannot be zero. If you need to cancel the task select the row then click on the cancel button.');
          }
  
  
  
          // Check if material is serial controlled
          if (controllerTypeId === 3 || controllerTypeId === 4 || isFixedWeight === false || isFixedLength === false || isFixedVolume === false) {
  
              const dialogResult = await $shell.FootPrintManager.openserialnumbers_selection_gridDialog({
                  packagingId: $row.entity.ActualPackagedPackId,
                  lotId: $row.entity.LotId,
                  licenseplateId: $row.cells.source_licenseplate.editControl.value,
                  packagedAmountRequest: $row.cells.quantity.editControl.value
              }, 'modal');
  
  
              var serialnumbersResult = dialogResult.SerialNumberIds;
              var confirmResult = dialogResult.confirm;
              if (confirmResult === false) {
                  throw new Error('Serial numbers were not created, please try again.')
              }
  
          }
          //Re-initialize variable
          var serialNumbers = [];
  
          if (confirmResult === true && $utils.isDefined(serialnumbersResult) && serialnumbersResult.length === $row.cells.quantity.editControl.value) {
  
              serialNumbers = serialnumbersResult;
  
          }
          if (confirmResult === true && (!$utils.isDefined(serialnumbersResult) || serialnumbersResult.length !== $row.cells.quantity.editControl.value)) {
  
              throw new Error('Error selecting serial numbers, please try again.')
  
          }
  
          // Check if target license plate exists, use 2 different APIs based on result
          const licenseplates = (await $datasources.SalesOrders.ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId.get({
              warehouseId: $row.entity.WarehouseId,
              lookupcode: $row.cells.target_licenseplate.editControl.value,
              shipmentId: $row.entity.ShipmentId
          })).result;
          if (!$utils.isDefined(licenseplates)) {
  
              await $flows.SalesOrders.pick_on_new_license_plate({
                  taskId: $row.entity.Id,
                  packagedAmount: $row.cells.quantity.editControl.value,
                  packagedPackId: $row.entity.ActualPackagedPackId,
                  sourceLicensePlateId: $row.cells.source_licenseplate.editControl.value,
                  targetLocationId: $row.cells.target_location.editControl.value,
                  targetLicensePlateLookupCode: $row.cells.target_licenseplate.editControl.value,
                  serialNumberIds: serialNumbers
              });
  
  
          } else {
              const licenseplateId = licenseplates[0].Id;
  
              await $flows.SalesOrders.pick_on_existing_license_plate({
                  targetLicensePlateId: licenseplateId,
                  sourceLicensePlateId: $row.cells.source_licenseplate.editControl.value,
                  packagedAmount: $row.cells.quantity.editControl.value,
                  packagedPackId: $row.entity.ActualPackagedPackId,
                  taskId: $row.entity.Id,
                  serialNumberIds: serialNumbers
              });
  
          }
  
      }
      // Full license plate pick task
      else if ($row.entity.OperationCodeId == 79) {
          await $flows.SalesOrders.pick_full_license_plate({
              taskId: $row.entity.Id,
              targetLocationId: $row.cells.target_location.editControl.value
          });
      }
  
      await $grid.refresh();
  
  
  
  } catch (error) {
  
  
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
  
      $shell.FootPrintManager.openErrorDialog('Selected task could not be picked.', `${errorMessage}`);
  
  
  
  }
  
  }
  on_quantity_changed(event = null) {
    return this.on_quantity_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_quantity_changedInternal(
    $row: FootPrintManager_sales_order_picking_gridComponentRowModel,
  $grid: FootPrintManager_sales_order_picking_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // set-row-remaining.flow.json
  const actualPackagedAmount = $row.cells.quantity.editControl.value;
  $row.cells.remaining.displayControl.text =
      `${$row.entity.ExpectedPackagedAmount} ${$row.entity.ExpectedPackagedPack?.ShortName}`;
  if ($row.entity.ExpectedPackagedAmount !== actualPackagedAmount &&
      actualPackagedAmount > 0) {
      $row.cells.remaining.styles.setAttentionClass();
  } else {
      $row.cells.remaining.styles.resetClasses();
  }
  
  
  
  var materialId = $row.entity.MaterialId;
  var packagingId = $row.entity.ActualPackagedPackId;
  
  
  // Default the decimal formatting
  const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
      materialId: materialId,
      packagingId: packagingId
  }));
  
  if ($utils.isDefined(decimalFormat)) {
      $row.cells.quantity.editControl.format = decimalFormat.format;
  
  }
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => SalesOrders_licenseplates_by_locationId_lotId_packagingId_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
  ],
  selector: 'FootPrintManager-sales_order_picking_grid',
  templateUrl: './FootPrintManager.sales_order_picking_grid.component.html'
})
export class FootPrintManager_sales_order_picking_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_sales_order_picking_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_sales_order_picking_gridComponentInParams = { orderId: null, shipmentId: null, waveId: null, orderStatusId: null, waveStatusId: null };


  //#region Variables
  vars: { entities?: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, LotId?: number, MaterialId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string }, ExpectedPackagedPack?: { Name?: string }, ActualPackagedPack?: { Name?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string } } }[], totalCount?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     operation: new GridHeaderModel(new HeaderStyles(null, null), 'operation', 'Operation', false, false, null, false),       linenumber: new GridHeaderModel(new HeaderStyles(null, null), 'linenumber', 'Line #', false, false, null, false),       material: new GridHeaderModel(new HeaderStyles(null, null), 'material', 'Material', false, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'vendorlot', 'Vendor lot', false, false, null, false),       picked: new GridHeaderModel(new HeaderStyles(null, null), 'picked', 'Picked', false, false, null, false),       remaining: new GridHeaderModel(new HeaderStyles(null, null), 'remaining', 'Remaining', false, false, null, false),       quantity: new GridHeaderModel(new HeaderStyles(null, null), 'quantity', 'Pick quantity', false, false, null, false),       source_location: new GridHeaderModel(new HeaderStyles(null, null), 'source_location', 'Source', false, false, null, false),       source_licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'source_licenseplate', 'Source LP', true, false, null, false),       target_location: new GridHeaderModel(new HeaderStyles(null, null), 'target_location', 'Target', true, false, null, false),       target_licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'target_licenseplate', 'Target LP', true, false, null, false),       expected_target_shipping_container: new GridHeaderModel(new HeaderStyles(null, null), 'expected_target_shipping_container', 'Target container', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_sales_order_picking_gridComponentRowModel[] = [];

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('shipmentId') set $inParams_shipmentId(value: any) {
    this.inParams['shipmentId'] = value;
  }
  get $inParams_shipmentId(): any {
    return this.inParams['shipmentId'] ;
  }
  @Input('waveId') set $inParams_waveId(value: any) {
    this.inParams['waveId'] = value;
  }
  get $inParams_waveId(): any {
    return this.inParams['waveId'] ;
  }
  @Input('orderStatusId') set $inParams_orderStatusId(value: any) {
    this.inParams['orderStatusId'] = value;
  }
  get $inParams_orderStatusId(): any {
    return this.inParams['orderStatusId'] ;
  }
  @Input('waveStatusId') set $inParams_waveStatusId(value: any) {
    this.inParams['waveStatusId'] = value;
  }
  get $inParams_waveStatusId(): any {
    return this.inParams['waveStatusId'] ;
  }

  topToolbar = {
      reallocate: new ToolModel(new ButtonModel('reallocate', new ButtonStyles(null, null), false, false, false, 'Reallocate', 'icon-ic_fluent_branch_20_regular', null)
    , false),
      auto_pick: new ToolModel(new ButtonModel('auto_pick', new ButtonStyles(null, null), false, false, false, 'Auto pick', 'icon-datex-Pick', null)
    , false),
      group_by_lp: new ToolModel(new ButtonModel('group_by_lp', new ButtonStyles(null, null), false, false, false, 'Group by LP', 'icon-datex-LicensePlate', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Picking tasks';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
      shipmentId:  $grid.inParams.shipmentId ,
      waveId:  $grid.inParams.waveId ,
    };
    try {
      const data = await this.datasources.SalesOrders.ds_sales_order_picking_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_sales_order_picking_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_sales_order_picking_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_sales_order_picking_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_sales_order_picking_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Disable Cancel button
  $grid.topToolbar.cancel.control.readOnly = true;
  
      // Check if the order status is provided and check to see if it is in a processing status
      if ($utils.isDefined($grid.inParams.orderStatusId) && $grid.inParams.orderStatusId === 2) {
  
          $grid.canEdit = true;
  
  
      } 
      // Check if the wave status is provided and make sure it is not in a completed or cancelled status
      else if ($utils.isDefined($grid.inParams.waveStatusId) && $grid.inParams.waveStatusId !== 5 && $grid.inParams.waveStatusId !== 4){
           $grid.canEdit = true;
      }
      else {
  
          $grid.canEdit = false;
  
          $grid.headers.quantity.required = false;
          $grid.headers.packaging.required = false;
          $grid.headers.lot.required = false;
          $grid.headers.vendorlot.required = false;
          $grid.headers.target_licenseplate.required = false;
          $grid.headers.target_location.required = false;
  
      }
  
  // Apply role based operations
  $grid.apply_operations();
  
  
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootPrintManager_sales_order_picking_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Task Cancellation Error', 'No tasks selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              taskId: row.entity.Id
          };
          const result = await $flows.SalesOrders.is_pick_task_cancelable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Order Line ${row.cells.linenumber.displayControl.text?.toString()} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Cancel pick task errors';
          const errorMessage = `Pick task(s) ${failures.map(c => c.cells.linenumber.displayControl.text?.toString()).join(',')} cannot be cancelled}`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Pick task(s) ${candidates.map(c => c.cells.linenumber.displayControl.text?.toString()).join(',')} - Once cancelled, the task(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some pick task(s) cannot be cancelled';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Cancel the following pick task(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              // Check if the order is also cancelable and prompt user if they also want to cancel the order.
              const flowParams = {
                  orderId: $grid.inParams.orderId,
                  shipmentId: $grid.inParams.shipmentId
              };
              const result = await $flows.SalesOrders.is_sales_order_cancelable_flow(flowParams);
  
              const can_cancel = result?.can_cancel ?? false;
              const one_remaining_pick_task = result?.one_remaining_pick_task ?? false;
  
              const dialogResult = await $shell.FootPrintManager.opensales_order_pick_task_cancellation_options_formDialog({
                  title: 'Select a reason code',
                  parentId: 6,
                  parentEntity: 'OperationType',
                  cancelOrderRequest: can_cancel,
                  autoCancelOrderRequest: one_remaining_pick_task
              });
              var userConfirmed = dialogResult.confirm;
              var reasonCodeId = dialogResult.reasonCodeId;
              var cancelOrder = dialogResult.cancelOrderConfirmed;
              var cancelOrderReasonCodeId = dialogResult.cancelOrderReasonCodeId;
  
              if (userConfirmed) {
  
                  for (const candidate of candidates) {
                      try {
                          const flowParams = {
                              taskId: candidate.entity.Id,
                              reasonCodeId: reasonCodeId
                          }
                          const result = await $flows.SalesOrders.cancel_pick_task_flow(flowParams);
                          const reason = result.reason;
                          if ($utils.isDefined(reason)) {
                              processFailures.push(candidate);
                              errorMsgList.push(`Order line ${candidate.cells.linenumber.displayControl.text?.toString()} - ${reason}`);
                          } else {
                              processSuccess.push(candidate);
                          }
  
                      } catch (error) {
                          processFailures.push(candidate);
                          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                          const errorDescription = `Order line ${candidate.cells.linenumber.displayControl.text?.toString()} - ${errorMessage}`;
                          errorMsgList.push(errorDescription);
                          errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                      }
                  }
  
                  // all succeeded
                  if (processSuccess.length === candidates.length) {
                      if (cancelOrder) {
  
                          const result = await $flows.SalesOrders.cancel_sales_order_shipment_and_order_lines_flow({
                              shipmentId: $grid.inParams.shipmentId,
                              reasonCodeId: cancelOrderReasonCodeId
                          });
                          const reason = result.reason;
  
                          if ($utils.isDefined(reason)) {
                              await $shell.FootPrintManager.openErrorDialog('Pick task(s) cancelled but order cannot be cancelled.', reason);
                              return;
                          }
                          else {
  
                              const title = 'Order Cancelled';
                              const message = `Pick tasks and order were successfully cancelled.`;
                              $shell.FootPrintManager.openToaster(title, message, EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
  
                          }
  
                      }
                      else {
                          const title = 'All pick task(s) cancelled';
                          const message = `Pick task(s) for order line(s) ${processSuccess.map(c => c.cells.linenumber.displayControl.text?.toString()).join(',')} cancelled`;
                          $shell.FootPrintManager.openToaster(title, message, EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
  
                      }
                  } else {
                      // all failures
                      if (processFailures.length === candidates.length) {
                          const title = 'All pick task(s) failed to cancel';
                          const message = `Pick task(s) ${processFailures.map(c => c.cells.linenumber.displayControl.text?.toString()).join(',')} could not be cancelled`;
                          await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      } else {
                          const title = 'Some pick task(s) could not be cancelled';
                          const success = `Pick task(s) ${processSuccess.map(c => c.ecells.linenumber.displayControl.text?.toString()).join(',')} were cancelled.`;
                          const errors = `The following pick task(s) could not be cancelled: ${processFailures.map(c => c.cells.linenumber.displayControl.text?.toString()).join(',')}`;
                          const message = `${success} \r\n\r\n${errors}`;
                          await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      }
                  }
                  await $grid.refresh();
              }
          }
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_sales_order_picking_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected and order status is created
  if (selectedRowsCount === 1) {
      $grid.topToolbar.cancel.control.readOnly = false;
      $grid.topToolbar.reallocate.control.readOnly = false;
      $grid.topToolbar.auto_pick.control.readOnly = false;
  
  }
  else if (selectedRowsCount > 1) {
      $grid.topToolbar.cancel.control.readOnly = false;
      $grid.topToolbar.reallocate.control.readOnly = true;
      $grid.topToolbar.auto_pick.control.readOnly = false;
  }
  else {
      $grid.topToolbar.cancel.control.readOnly = true;
      $grid.topToolbar.reallocate.control.readOnly = true;
      $grid.topToolbar.auto_pick.control.readOnly = true;
  
  }
  
  if (await $operations.FootPrintManager.Disable_SalesOrder_Editing.isAuthorized()) {
      $grid.topToolbar.auto_pick.hidden = true;
      $grid.topToolbar.cancel.hidden = true;
      $grid.topToolbar.group_by_lp.hidden = true;
      $grid.topToolbar.reallocate.hidden = true;
  }
  }
  on_reallocate_clicked(event = null) {
    return this.on_reallocate_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_reallocate_clickedInternal(
    $grid: FootPrintManager_sales_order_picking_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Reallocate Error', 'No task selected.');
      return;
  }
  
  // Loop through selected rows
  for (let row of selectedRows) {
      // Gather affected tasks
      let tasks: { id: number; warehouseId: number; projectId: number; materialId: number; packagingId: number; lotId: number; packagedAmount: number; }[] = [];
  
      if (row.entity.OperationCodeId === 79) {
          tasks.push(...(await $datasources.SalesOrders.ds_get_released_pick_tasks_by_chainhead.get({ chainhead: row.entity.Id })).result.map(r => ({
              id: r.Id,
              warehouseId: r.WarehouseId,
              projectId: r.ProjectId,
              materialId: r.MaterialId,
              packagingId: r.ActualPackagedPackId,
              lotId: $utils.isDefined(r.OrderLine.LotId) ? r.OrderLine.LotId : null,
              packagedAmount: r.ExpectedPackagedAmount
          })));
      } else {
          tasks.push({
              id: row.entity.Id,
              warehouseId: row.entity.WarehouseId,
              projectId: row.entity.Material.ProjectId,
              materialId: row.entity.MaterialId,
              packagingId: row.entity.ActualPackagedPackId,
              lotId: $utils.isDefined(row.entity.OrderLine.LotId) ? row.entity.OrderLine.LotId : null,
              packagedAmount: row.entity.ExpectedPackagedAmount
          })
      }
  
      for (let task of tasks) {
          // Prompt user for replacement inventory
          const wizardResult = await $shell.FootPrintManager.openinventory_by_location_selection_wizardDialog({
              warehouseId: task.warehouseId,
              projectId: task.projectId,
              materialId: task.materialId,
              packagingId: task.packagingId,
              lotId: task.lotId,
              packagedAmountRequest: task.packagedAmount
          });
          var inventorySelectionResults = wizardResult.inventory;
  
          // Reallocate pick task based on selection
          if ($utils.isDefined(inventorySelectionResults)) {
              try {
                  if (row.entity.OperationCodeId === 79) {
                      await cancel_existing_lp_grouping(row.entity.Id, tasks.map(t => t.id));
                  }
  
                  const result = await $flows.SalesOrders.reallocate_pick_task_flow({
                      taskId: task.id,
                      currentPackagedAmount: task.packagedAmount,
                      updatedPackagedAmount: inventorySelectionResults[0].packagedAmount,
                      lotId: inventorySelectionResults[0].lotId,
                      locationId: inventorySelectionResults[0].locationId,
                      vendorLotId: inventorySelectionResults[0].vendorLotId
                  });
                  const reason = result.reason;
  
                  if ($utils.isDefined(reason)) {
                      await $shell.FootPrintManager.openErrorDialog('Task cannot be reallocated', reason);
                      return;
                  }
              }
              catch (error) {
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = `${errorMessage}`;
                  await $shell.FootPrintManager.openErrorDialog('Task reallocation error', 'An error occurred during the reallocation of the task', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
              }
  
          }
      }
  
      if (row.entity.OperationCodeId === 79) {
          await $flows.SalesOrders.group_pick_tasks_by_license_plate_flow({ task_ids: tasks.map(t => t.id) });
      }
  }
  
  await $grid.refresh();
  
  
  
  
  /**********************************************
   * FUNCTIONS
  ***********************************************/
  async function cancel_existing_lp_grouping(lp_pick_id: number, child_task_ids: number[]) {
      // Cancel existing lp grouping
      await $flows.Utilities.crud_update_flow({
          entitySet: 'Tasks',
          id: lp_pick_id,
          entity: { StatusId: 3 }
      });
  
      for (let task_id of child_task_ids) {
          // Clear chain head
          await $flows.Utilities.crud_update_flow({
              entitySet: 'Tasks',
              id: task_id,
              entity: { ChainHead: 0 }
          });
      }
  }
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $grid: FootPrintManager_sales_order_picking_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (await $operations.FootPrintManager.Disable_SalesOrders_AutoPicking.isAuthorized()) {
      $grid.headers.quantity.hidden = true;
      $grid.headers.target_licenseplate.hidden = true;
      $grid.headers.target_location.hidden = true;
      $grid.headers.source_licenseplate.hidden = true;
      $grid.canEdit = true;
      $grid.topToolbar.auto_pick.hidden = true;
  }
  
  if (await $operations.FootPrintManager.Disable_SalesOrder_Editing.isAuthorized()) {
      $grid.topToolbar.auto_pick.hidden = true;
      $grid.topToolbar.cancel.hidden = true;
      $grid.topToolbar.group_by_lp.hidden = true;
      $grid.topToolbar.reallocate.hidden = true;
  }
  
  }
  on_group_by_lp_clicked(event = null) {
    return this.on_group_by_lp_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_group_by_lp_clickedInternal(
    $grid: FootPrintManager_sales_order_picking_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const rowCount = $grid.selectedRows.length;
  const pluralChar = rowCount === 1 ? '' : 's';
  
  let message = `Do you want to group ${rowCount > 0 ? `the ${rowCount} selected` : 'all released, ungrouped'} picking task${pluralChar} by license plate?`;
  let is_confirm = await $shell.FootPrintManager.openConfirmationDialog(
      `Group task${pluralChar} by license plate?`,
      message,
      `Group task${pluralChar}`, 'Cancel');
  
  if (!is_confirm) { return; }
  
  let task_ids: number[] = [];
  // If nothing selected, group all released picking tasks
  if (rowCount > 0) {
      task_ids = $grid.selectedRows.map(row => row.entity.Id);
  
  } else {
      // Get tasks by order or wave
      if ($utils.isDefined($grid.inParams.orderId)) {
          task_ids = (await $datasources.SalesOrders.ds_find_tasks.get({ orderId: $grid.inParams.orderId })).result
              .filter(t => t.OperationCodeId === 8 && (t.ChainHead ?? 0) === 0 && t.StatusId === 1)
              .map(t => t.Id);
      } else if ($utils.isDefined($grid.inParams.waveId)) {
          const pickSlips = (await $datasources.Waves.ds_pick_waves_details.get({ WaveId: $grid.inParams.waveId })).result;
  
          for (let pickSlip of pickSlips) {
              task_ids.push(...pickSlip.Tasks
                  .filter(t => t.OperationCodeId === 8 && (t.ChainHead ?? 0) === 0 && t.StatusId === 1)
                  .map(t => t.Id));
          }
      }
  }
  
  if (!$utils.isDefined(task_ids)) {
      await $shell.FootPrintManager.openInfoDialog('Grouping aborted', 'There are no picking tasks eligible to group');
      return;
  }
  
  let grouping_result = await $flows.SalesOrders.group_pick_tasks_by_license_plate_flow({ task_ids: task_ids });
  
  if ($utils.isDefined(grouping_result.reasons)) {
      $shell.FootPrintManager.openToaster(`Error grouping pick task${pluralChar}`, `Error: ${(await $flows.Utilities.grammar_format_string_array_flow({ values: grouping_result.reasons })).formattedValue}`, EToasterType.Error, { closeButton: true, timeOut: 7000, positionClass: EToasterPosition.bottomRight });
  } else {
      $shell.FootPrintManager.openToaster(`Successfully grouped task${pluralChar}`, `Successfully grouped picking task${pluralChar}`, EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
      $grid.refresh();
  }
  
  
  }
  on_auto_pick_clicked(event = null) {
    return this.on_auto_pick_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_pick_clickedInternal(
    $grid: FootPrintManager_sales_order_picking_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Check if order is pickable
  const result = await $flows.SalesOrders.is_sales_order_pickable_flow({
      orderId: $grid.inParams.orderId,
      shipmentId: $grid.inParams.shipmentId
  });
  
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      $shell.FootPrintManager.openToaster('Order cannot be picked', reason, EToasterType.Error, { closeButton: true, timeOut: 7000, positionClass: EToasterPosition.bottomRight });
      return;
  }
  
  // Get warehouseId
  var warehouseId = $grid.selectedRows[0]?.entity.WarehouseId;
  if (!$utils.isDefined(warehouseId)) {
  
      openErrorToaster('Order Auto Pick Error', 'Unable to determine preferred warehouse from the order selected');
      return;
  }
  
  
  // Get assigned or scheduled dock door from dock appointment if it exists
  const dockappointment = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  if ($utils.isDefined(dockappointment)) {
  
      const doorId = dockappointment[0]?.DockAppointment?.AssignedLocation?.Id ?? dockappointment[0]?.DockAppointment?.ScheduledLocation?.Id;
  
      if ($utils.isDefined(doorId)) {
          const dockDoor = (await $datasources.DockAppointments.ds_get_dock_door_by_locationId.get({ locationId: doorId })).result;
          if ($utils.isDefined(dockDoor)) {
              // Get child location of type inventory
              var locationId = dockDoor.ChildLocationContainers[0]?.Id
          }
  
      }
  }
  
  // Get user input for auto-picking
  const dialogResult = await $shell.FootPrintManager.openauto_pick_options_formDialog({
      warehouse_id: warehouseId,
      load_location_id: locationId
  });
  
  var userConfirmed = dialogResult.confirm;
  var autoLoadConfirmed = dialogResult.auto_load;
  var loadLocation = dialogResult.load_location;
  let license_plate_lookup = dialogResult.license_plate_lookup;
  
  if (!userConfirmed) { return; }
  
  try {
  
      let selectedRows = $grid.selectedRows;
      let task_ids: number[] = [];
      for (let selectedRow of selectedRows) {
          let task_id = selectedRow.entity.Id;
          task_ids.push(task_id);
      }
  
      const result = await $flows.SalesOrders.auto_pick_sales_order_flow({
          orderId: $grid.inParams.orderId,
          shipmentId: $grid.inParams.shipmentId,
          license_plate_lookup: license_plate_lookup,
          task_ids: task_ids
      });
      const reason = result.reason;
  
      if ($utils.isAllDefined(userConfirmed, autoLoadConfirmed) && autoLoadConfirmed === true) {
  
          const flowLoadParams = {
              orderId: $grid.inParams.orderId,
              shipmentId: $grid.inParams.shipmentId,
              loadLocation: $utils.isDefined(loadLocation) ? loadLocation : null
          }
          const resultLoad = await $flows.SalesOrders.auto_load_sales_order_flow(flowLoadParams);
          var reasonLoad = resultLoad.reason;
      }
  
      if ($utils.isDefined(reason)) {
          openErrorToaster('Order cannot be fully auto picked', reason);
          await $grid.refresh();
  
          return;
      }
      else if ($utils.isDefined(reasonLoad)) {
          openErrorToaster('Order cannot be fully auto loaded', reasonLoad);
          await $grid.refresh();
  
          return;
      }
      else {
  
          const loadmessage = (autoLoadConfirmed === true) ? ' and loaded' : ''
          const title = `Order picked ${loadmessage}`;
          $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
          await $grid.refresh();
  
      }
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Order ${errorMessage}`;
      await $shell.FootPrintManager.openErrorDialog('Auto pick order error', 'An error occurred during the auto picking of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      await $grid.refresh();
  }
  
  
  
  
  /************************************
   * FUNCTIONS
  *************************************/
  function openErrorToaster(title: string, message?: string) {
      $shell.FootPrintManager.openToaster(title, message, EToasterType.Error, { closeButton: true, timeOut: 7000, positionClass: EToasterPosition.bottomRight });
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
