import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Notifications.frontend.types'
import { $frontendTypes as $types} from './Notifications.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Notifications_auto_email_report_options_dd_singleComponent } from './Notifications.auto_email_report_options_dd_single.component'


interface INotifications_auto_email_rule_reports_gridComponentEntity {
  id?: string, type?: string, name?: string, options?: string, rule_id?: string}

interface INotifications_auto_email_rule_reports_gridComponentInParams {
  ruleId?: string, ruleContext: string, readOnly: boolean}

interface INotifications_auto_email_rule_reports_gridComponentOutParams {
  reports?: string[]}

class Notifications_auto_email_rule_reports_gridComponentRowModel extends GridRowModel {
  grid: Notifications_auto_email_rule_reports_gridComponent;
  entity: INotifications_auto_email_rule_reports_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    name_display: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    name: new GridCellModel(
      new CellStyles(null, null),
      new SelectBoxModel(
  this.formGroup.controls['name_display'] as DatexFormControl, 
  null, null,
  true,
  false,
  '', null)
,
      new SelectBoxModel(
        this.formGroup.controls['name_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      ),
  }

  get $fields_name_selector_inParams_ruleContext(): string {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.ruleContext;
    
    return expr;
  }
  cacheValueFor_$fields_name_selector_inParams_excludeReports: string[];
  get $fields_name_selector_inParams_excludeReports(): string[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.rows.map(r => r.cells.name.editControl.value);
    
    if(!isEqual(this.cacheValueFor_$fields_name_selector_inParams_excludeReports, expr)) {
      this.cacheValueFor_$fields_name_selector_inParams_excludeReports = expr;
    }
    return this.cacheValueFor_$fields_name_selector_inParams_excludeReports;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Notifications_ShellService,
private datasources: Notifications_DatasourceService,
private flows: Notifications_FlowService,
private reports: Notifications_ReportService,
private localization: Notifications_LocalizationService,
private operations: Notifications_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
    this.formGroup
      .controls['name_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_name_changed();
      });
  }

  async $initializeExisting(grid: Notifications_auto_email_rule_reports_gridComponent, entity: INotifications_auto_email_rule_reports_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Notifications_auto_email_rule_reports_gridComponent, entity?: INotifications_auto_email_rule_reports_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      ruleId:  $grid.inParams.ruleId ,
    };
    const data = await this.datasources.Notifications.ds_auto_email_rule_reports.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as INotifications_auto_email_rule_reports_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.name.displayControl as SelectBoxModel).reset($row.entity.name);
    (this.cells.name.editControl as SelectBoxModel).reset($row.entity.name);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: Notifications_auto_email_rule_reports_gridComponentRowModel,
  $grid: Notifications_auto_email_rule_reports_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  try {
  
      let result = await $flows.Notifications.update_auto_email_rule_attachment_flow({
          attachmentId: $row.entity.id,
          properties: {
              rule_id: $grid.inParams.ruleId,
              type: 'REPORT',
              name: $row.cells.name.editControl.value
          }
      });
  
      $row.refresh();
  }
  catch (error) {
      await $shell.Notifications.openErrorDialog('Error updating rule attachment', error.message);
      throw new Error(); // to prevent display mode
  }
  
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: Notifications_auto_email_rule_reports_gridComponentRowModel,
  $grid: Notifications_auto_email_rule_reports_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  try {
      if (!$utils.isDefined($row.cells.name.editControl.value)) {
          throw new Error('No report selected');
      }
  
      $row.cells.name.displayControl.value = $row.cells.name.editControl.value;
  
      await $grid.map_outputs();
  
      // let result = await $flows.Notifications.add_auto_email_rule_attachment_flow({
      //     ruleId: $grid.inParams.ruleId,
      //     properties: {
      //             rule_id: $grid.inParams.ruleId,
      //             type: 'REPORT',
      //             name: $row.cells.name.editControl.value
      //         }
      // });
  
      // $row.entity.id = result.attachmentId;
      // $row.refresh();
  }
  catch (error) {
      await $shell.Notifications.openErrorDialog('Error creating rule attachment', error.message);
      throw new Error(); // to prevent display mode
  }
  
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Notifications_auto_email_rule_reports_gridComponentRowModel,
  $grid: Notifications_auto_email_rule_reports_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefinedTrimmed($row.entity.options) && isValidJson($row.entity.options)) {
  
  }
  
  
  
  function isValidJson(jsonString: string): boolean {
      try {
          JSON.parse(jsonString);
          return true;
      } catch (error) {
          return false;
      }
  }
  }
  on_name_changed(event = null) {
    return this.on_name_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_name_changedInternal(
    $row: Notifications_auto_email_rule_reports_gridComponentRowModel,
  $grid: Notifications_auto_email_rule_reports_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $row.entity.options = null;
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Notifications_auto_email_report_options_dd_singleComponent),
  ],
  selector: 'Notifications-auto_email_rule_reports_grid',
  templateUrl: './Notifications.auto_email_rule_reports_grid.component.html'
})
export class Notifications_auto_email_rule_reports_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: INotifications_auto_email_rule_reports_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fixedWidth', ['default','set-width-table']);


  inParams: INotifications_auto_email_rule_reports_gridComponentInParams = { ruleId: null, ruleContext: null, readOnly: null };

  outParams: INotifications_auto_email_rule_reports_gridComponentOutParams = { reports: [] };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ reports?: string[] }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     name: new GridHeaderModel(new HeaderStyles(null, null), 'name', 'Name', true, false, 400, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Notifications_auto_email_rule_reports_gridComponentRowModel[] = [];

  @Input('ruleId') set $inParams_ruleId(value: any) {
    this.inParams['ruleId'] = value;
  }
  get $inParams_ruleId(): any {
    return this.inParams['ruleId'] ;
  }
  @Input('ruleContext') set $inParams_ruleContext(value: any) {
    this.inParams['ruleContext'] = value;
  }
  get $inParams_ruleContext(): any {
    return this.inParams['ruleContext'] ;
  }
  @Input('readOnly') set $inParams_readOnly(value: any) {
    this.inParams['readOnly'] = value;
  }
  get $inParams_readOnly(): any {
    return this.inParams['readOnly'] ;
  }

  topToolbar = {
      delete_selected_rows: new ToolModel(new ButtonModel('delete_selected_rows', new ButtonStyles(['destructive'], null), false, false, false, 'Remove', 'icon-ic_fluent_subtract_circle_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Notifications_ShellService,
    private datasources: Notifications_DatasourceService,
    private flows: Notifications_FlowService,
    private reports: Notifications_ReportService,
    private localization: Notifications_LocalizationService,
    private operations: Notifications_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Reports';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.ruleContext)) {
        this.$missingRequiredInParams.push('ruleContext');
      }
      if(isNil(this.inParams.readOnly)) {
        this.$missingRequiredInParams.push('readOnly');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 100;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ruleId:  $grid.inParams.ruleId ,
    };
    try {
      const data = await this.datasources.Notifications.ds_auto_email_rule_reports.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as INotifications_auto_email_rule_reports_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Notifications_auto_email_rule_reports_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: Notifications_auto_email_rule_reports_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: INotifications_auto_email_rule_reports_gridComponentEntity) {
    const row = new Notifications_auto_email_rule_reports_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Notifications_auto_email_rule_reports_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  
  }
  map_outputs(event = null) {
    return this.map_outputsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async map_outputsInternal(
    $grid: Notifications_auto_email_rule_reports_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $grid.outParams.reports = $grid.rows.map(row => row.cells.name.editControl.value);
  
  let result = (await $datasources.Notifications.ds_auto_email_rule_reports_dd.getList({ ruleContext: $grid.inParams.ruleContext, excludeReports: $grid.rows.map(r => r.cells.name.editControl.value)})).result;
  if (!$utils.isDefined(result) || result.length === 0) {
      $grid.bottomToolbar.addLine.hidden = true;
  } else {
      $grid.bottomToolbar.addLine.hidden = false;
  }
  
  $grid.events.outParamsChange.emit();
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: Notifications_auto_email_rule_reports_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $grid.canAdd = !$grid.inParams.readOnly;
  $grid.topToolbar.delete_selected_rows.hidden = $grid.inParams.readOnly;
  $grid.canEdit = false;
  
  
  await $grid.map_outputs();
  
  
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Notifications_auto_email_rule_reports_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.delete_selected_rows.control.readOnly = $grid.selectedRows.length === 0;
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: Notifications_auto_email_rule_reports_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  for (let i = $grid.rows.length - 1; i >= 0; i--) {
      if ($grid.rows[i].selected) {
          $grid.rows.splice(i, 1);
      }
  }
  
  await $grid.map_outputs();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
