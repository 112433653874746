import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { PackVerification_verification_locations_dd_singleComponent } from './PackVerification.verification_locations_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => PackVerification_verification_locations_dd_singleComponent),
  ],
  selector: 'FootPrintManager-select_pack_verification_location_form',
  templateUrl: './FootPrintManager.select_pack_verification_location_form.component.html'
})
export class FootPrintManager_select_pack_verification_location_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { shipping_container_id: number, warehouse_id: number } = { shipping_container_id: null, warehouse_id: null };
  //#region Inputs
  @Input('shipping_container_id') set $inParams_shipping_container_id(v: number) {
    this.inParams.shipping_container_id = v;
  }
  get $inParams_shipping_container_id(): number {
    return this.inParams.shipping_container_id;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    location: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), true, false, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['location'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Location', true, false),
  };

  fieldsets = {
  main: new FieldsetModel('Main', true, false, true, false),
};

  //#region fields inParams
  get $fields_location_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.inParams.warehouse_id;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    location: this.fields.location.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.shipping_container_id)) {
        this.$missingRequiredInParams.push('shipping_container_id');
      }
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Select location';
  
    const $form = this;
    const $utils = this.utils;



    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .location
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_changed();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_select_pack_verification_location_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (!$utils.isDefined($form.fields.location.control.value)) {
      return;
  }
  
  let isBlind = true;
  
  let startTasks = (await $datasources.PackVerification.ds_get_start_pack_verification_tasks_by_shippingContainerId.getList({ shippingContainerId: $form.inParams.shipping_container_id })).result;
  
  let failedStartTask = startTasks.find(task => task.Status.Id === 3 && task.Undone)
  
  if ($utils.isDefined(failedStartTask)) {
      isBlind = false;
  }
  
  let targetLocation = $form.fields.location.control.value;
  
  let locationValidation = (await $flows.PackVerification.is_pack_verification_location_valid_flow({
      shippingContainerId: $form.inParams.shipping_container_id,
      locationId: targetLocation
  }));
  
  if ($utils.isDefined(locationValidation.reasons)) {
      await $shell.FootPrintManager.openErrorDialog(locationValidation.fail_description ?? 'Location is not valid', (await $flows.Utilities.grammar_format_string_array_flow({ values: locationValidation.reasons })).formattedValue);
      return;
  }
  
  let startTaskId: number;
  
  if ($utils.isDefined(startTasks)) {
      startTasks.sort((a, b) => a.Id - b.Id); //Sort ascending
      startTaskId = startTasks.find(t => t.Status.Id === 1)?.Id;
  }
  
  
  
  await $shell.FootPrintManager.openpack_verification_wizardDialog({
      shippingContainerId: $form.inParams.shipping_container_id,
      startVerificationTaskId: startTaskId,
      targetLocationId: $form.fields.location.control.value,
      isBlind: isBlind
  });
  
  $form.close();
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_select_pack_verification_location_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  on_location_changed(event = null) {
    return this.on_location_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_changedInternal(
    $form: FootPrintManager_select_pack_verification_location_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.toolbar.confirm.control.readOnly = !$utils.isDefined($form.fields.location.control.value);
  }
  //#endregion private flows
}
