<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="create_dock_door" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_create_dock_door_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="appointment_types" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_manage_dock_appointment_types($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden && !filtersets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden && !filters.warehouse.removed" 
                                  class="field-container standard {{filters.warehouse.invalid ? 'invalid' : ''}} {{filters.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.warehouse.styles.style"
                                  [ngClass]="filters.warehouse.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouse.label + (filters.warehouse.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Locations-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouse.control.type"
                                  formControlName="warehouse"
                                  (displayTextChange)="filters.warehouse.control.displayText=$event"
                                  [placeholder]="filters.warehouse.control.placeholder"
                                  [styles]="filters.warehouse.control.styles"
                                  [tooltip]="filters.warehouse.control.tooltip"
                              >
                              </Locations-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouse.invalid">
                                <ng-container *ngFor="let error of filters.warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-active" *ngIf="!filters.active.hidden && !filters.active.removed" 
                                  class="field-container standard {{filters.active.invalid ? 'invalid' : ''}} {{filters.active.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.active.styles.style"
                                  [ngClass]="filters.active.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.active.label + (filters.active.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.active.label}}<span *ngIf="filters.active.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-slide-toggle data-cy="slideToggle" formControlName="active"
                                            color="primary"
                                            class="datex-toggle"
                                            [ngStyle]="filters.active.control.styles.style"
                                            [ngClass]="filters.active.control.styles.classes"
                                            [matTooltip]="filters.active.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.active.control.label}}</mat-slide-toggle>
                              <ng-container *ngIf="filters.active.invalid">
                                <ng-container *ngFor="let error of filters.active.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.dock_doors.hasTabToShow" class="tab" data-cy="tab-dock_doors">
              <h2 [className]="tabs.dock_doors.active? 'active': ''" (click)="tabs.dock_doors.activate()">{{tabs.dock_doors.title}}</h2>
            </div>
            <div *ngIf="tabs.dock_door_visualization.hasTabToShow" class="tab" data-cy="tab-dock_door_visualization">
              <h2 [className]="tabs.dock_door_visualization.active? 'active': ''" (click)="tabs.dock_door_visualization.activate()">{{tabs.dock_door_visualization.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-dock_doors_list *ngIf="tabs.dock_doors.active"
              #$tabs_dock_doors
              [warehouseIds]="$tabs_dock_doors_dock_doors_list_inParams_warehouseIds"
              [active]="$tabs_dock_doors_dock_doors_list_inParams_active"
              ($refreshEvent)="refresh(false, false, '$tabs_dock_doors')">
              </FootPrintManager-dock_doors_list>
              <FootPrintManager-dock_door_visualization *ngIf="tabs.dock_door_visualization.active"
              #$tabs_dock_door_visualization
              [warehouse_id]="$tabs_dock_door_visualization_dock_door_visualization_inParams_warehouse_id"
              ($refreshEvent)="refresh(false, false, '$tabs_dock_door_visualization')">
              </FootPrintManager-dock_door_visualization>
        </div>

      </ng-container>
    </div>
  </div>
</div>