import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Orderful_ShellService } from './Orderful.shell.service';
import { Orderful_OperationService } from './Orderful.operation.service';
import { Orderful_DatasourceService } from './Orderful.datasource.index';
import { Orderful_FlowService } from './Orderful.flow.index';
import { Orderful_ReportService } from './Orderful.report.index';
import { Orderful_LocalizationService } from './Orderful.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Orderful.frontend.types'
import { $frontendTypes as $types} from './Orderful.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


type EntityType = { 
    Id?: number, LookupCode?: string}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Orderful-orderful_runner',
  templateUrl: './Orderful.orderful_runner.component.html'
})
export class Orderful_orderful_runnerComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { title?: string } = { title: null };
  //#region Inputs
  @Input('title') set $inParams_title(v: string) {
    this.inParams.title = v;
  }
  get $inParams_title(): string {
    return this.inParams.title;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    runs: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order_class: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    material: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    packaging: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    stream: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    confirm: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    code_box: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    code_box_input: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      ingress: new ToolModel(new SplitButtonModel<{ all_in: ButtonModel, map_940: ButtonModel, map_943: ButtonModel, map_947_in: ButtonModel, query_poller: ButtonModel, map_orders: ButtonModel, submit_orders: ButtonModel, confirm_orders: ButtonModel, check_order_status: ButtonModel, insert_transaction: ButtonModel }>(
        'ingress',
        new ButtonStyles(null, null),
        false,
        false,
        'Ingress',
        'ms-Icon ms-Icon--PaddingBottom',
        null,
        [
          new ButtonModel('all_in', new ButtonStyles(null, null), false, false, false, 'All inbound', 'ms-Icon ms-Icon--BoxPlaySolid'),
          new ButtonModel('map_940', new ButtonStyles(null, null), false, false, false, '940 Map', 'ms-Icon ms-Icon--Nav2DMapView'),
          new ButtonModel('map_943', new ButtonStyles(null, null), false, false, false, '943 Map', 'ms-Icon ms-Icon--Nav2DMapView'),
          new ButtonModel('map_947_in', new ButtonStyles(null, null), false, false, false, '947 Map', 'ms-Icon ms-Icon--Nav2DMapView'),
          new ButtonModel('query_poller', new ButtonStyles(null, null), false, false, false, 'Query Poller', 'ms-Icon ms-Icon--CloudDownload'),
          new ButtonModel('map_orders', new ButtonStyles(null, null), false, false, false, 'Map Orders', 'ms-Icon ms-Icon--Play'),
          new ButtonModel('submit_orders', new ButtonStyles(null, null), false, false, false, 'Submit Orders', 'ms-Icon ms-Icon--CloudUpload'),
          new ButtonModel('confirm_orders', new ButtonStyles(null, null), false, false, false, 'Confirm Orders', 'ms-Icon ms-Icon--Accept'),
          new ButtonModel('check_order_status', new ButtonStyles(null, null), false, false, false, 'Check Orders', 'ms-Icon ms-Icon--CloudSearch'),
          new ButtonModel('insert_transaction', new ButtonStyles(null, null), false, false, false, 'Insert transaction', 'ms-Icon ms-Icon--Insert')
        ])
    , false),
      egress: new ToolModel(new SplitButtonModel<{ all_out: ButtonModel, map_945: ButtonModel, map_944: ButtonModel, map_846: ButtonModel, map_947: ButtonModel, generate_846: ButtonModel, generate_947: ButtonModel, query_footprint: ButtonModel, map_orders: ButtonModel, submit_orders: ButtonModel }>(
        'egress',
        new ButtonStyles(null, null),
        false,
        false,
        'Egress',
        'ms-Icon ms-Icon--PaddingTop',
        null,
        [
          new ButtonModel('all_out', new ButtonStyles(null, null), false, false, false, 'All outbound', 'ms-Icon ms-Icon--BoxPlaySolid'),
          new ButtonModel('map_945', new ButtonStyles(null, null), false, false, false, '945 Map', 'ms-Icon ms-Icon--Nav2DMapView'),
          new ButtonModel('map_944', new ButtonStyles(null, null), false, false, false, '944 Map', 'ms-Icon ms-Icon--Nav2DMapView'),
          new ButtonModel('map_846', new ButtonStyles(null, null), false, false, false, '846 Map', 'ms-Icon ms-Icon--Nav2DMapView'),
          new ButtonModel('map_947', new ButtonStyles(null, null), false, false, false, '947 Map', 'ms-Icon ms-Icon--Nav2DMapView'),
          new ButtonModel('generate_846', new ButtonStyles(null, null), false, false, false, 'Generate 846', 'ms-Icon ms-Icon--CloudDownload'),
          new ButtonModel('generate_947', new ButtonStyles(null, null), false, false, false, 'Generate 947', 'ms-Icon ms-Icon--CloudDownload'),
          new ButtonModel('query_footprint', new ButtonStyles(null, null), false, false, false, 'Query FootPrint', 'ms-Icon ms-Icon--CloudDownload'),
          new ButtonModel('map_orders', new ButtonStyles(null, null), false, false, false, 'Map Orders', 'ms-Icon ms-Icon--Play'),
          new ButtonModel('submit_orders', new ButtonStyles(null, null), false, false, false, 'Submit Orders', 'ms-Icon ms-Icon--CloudUpload')
        ])
    , false),
      test_map: new ToolModel(new SplitButtonModel<{ test_940: ButtonModel, test_945: ButtonModel, test_943: ButtonModel, test_944: ButtonModel }>(
        'test_map',
        new ButtonStyles(null, null),
        false,
        false,
        'Test Maps',
        'ms-Icon ms-Icon--TestBeaker',
        null,
        [
          new ButtonModel('test_940', new ButtonStyles(null, null), false, false, false, '940 Sales Order to FootPrint', 'ms-Icon ms-Icon--Nav2DMapView'),
          new ButtonModel('test_945', new ButtonStyles(null, null), false, false, false, '945 Shipping Confirmation', 'ms-Icon ms-Icon--Nav2DMapView'),
          new ButtonModel('test_943', new ButtonStyles(null, null), false, false, false, '943 Purchase Order to FootPrint', 'ms-Icon ms-Icon--Nav2DMapView'),
          new ButtonModel('test_944', new ButtonStyles(null, null), false, false, false, '944 Warehouse Receipt', 'ms-Icon ms-Icon--Nav2DMapView')
        ])
    , false)
  };

  fields = {
    runs: new FieldModel(new NumberBoxModel(this.formGroup.controls['runs'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'No. of runs to perform', false, false),
    project: new FieldModel(new TextBoxModel(this.formGroup.controls['project'] as DatexFormControl, null, false, false, 'Overrides the project', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    warehouse: new FieldModel(new TextBoxModel(this.formGroup.controls['warehouse'] as DatexFormControl, null, false, false, 'Overrides the warehouse', null)
, new ControlContainerStyles(null, null), 'Warehouse', false, false),
    order_class: new FieldModel(new TextBoxModel(this.formGroup.controls['order_class'] as DatexFormControl, null, false, false, 'Overrides the order class', null)
, new ControlContainerStyles(null, null), 'Order class', false, false),
    material: new FieldModel(new TextBoxModel(this.formGroup.controls['material'] as DatexFormControl, null, false, false, 'Overrides the material for all lines', null)
, new ControlContainerStyles(null, null), 'Material', false, false),
    packaging: new FieldModel(new TextBoxModel(this.formGroup.controls['packaging'] as DatexFormControl, null, false, false, 'Overrides the packaging for all lines', null)
, new ControlContainerStyles(null, null), 'Packaging', false, false),
    stream: new FieldModel(new TextBoxModel(this.formGroup.controls['stream'] as DatexFormControl, null, false, false, 'This is the \x22Stream\x22 field on the Orderful payload.', null)
, new ControlContainerStyles(null, null), 'Stream', false, false),
    confirm: new FieldModel(new CheckBoxModel(this.formGroup.controls['confirm'] as DatexFormControl, null, false, false, 'Send payload', null)
, new ControlContainerStyles(null, null), 'Submit to Orderful', false, false),
    code_box: new FieldModel( new CodeBoxModel(this.formGroup.controls['code_box'] as DatexFormControl, null, null, false, 'json', null)
, new ControlContainerStyles(null, null), '', false, false),
    code_box_input: new FieldModel( new CodeBoxModel(this.formGroup.controls['code_box_input'] as DatexFormControl, null, null, false, 'json', null)
, new ControlContainerStyles(null, null), '', false, false),
  };

  fieldsets = {
  code_box: new FieldsetModel('Code box', true, false, true, false),
  code_box_output: new FieldsetModel('Output', false, true, true, false),
  code_box_input: new FieldsetModel('Input', false, true, false, false),
};


  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    runs: this.fields.runs.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    order_class: this.fields.order_class.control.valueChanges
    ,
    material: this.fields.material.control.valueChanges
    ,
    packaging: this.fields.packaging.control.valueChanges
    ,
    stream: this.fields.stream.control.valueChanges
    ,
    confirm: this.fields.confirm.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Orderful_ShellService,
    private datasources: Orderful_DatasourceService,
    private flows: Orderful_FlowService,
    private reports: Orderful_ReportService,
    private localization: Orderful_LocalizationService,
    private operations: Orderful_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Runner';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
    };

    const data = await this.datasources.Orderful.ds_message_staging.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.runs.control as NumberBoxModel).reset(1);
    
    
    
    
    
    
    (this.fields.confirm.control as CheckBoxModel).reset(false);

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .runs
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .project
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .order_class
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .material
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .stream
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .confirm
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 02/23/2024
  
  $editor.toolbar.egress.hidden = true;
  $editor.toolbar.ingress.hidden = true;
  
  if ($utils.isDefined($editor.inParams.title)) {
      $editor.title = $editor.inParams.title;
  } else {
      $editor.title = "Utility to run Ordeful functions!";
  };
  
  if (await $operations.FootPrintApiManager.Enable_ApiManager_ManualRunner.isAuthorized()) {
      $editor.toolbar.egress.hidden = false;
      $editor.toolbar.ingress.hidden = false;
  } else {
      $shell.FootPrintApiManager.openErrorDialog("Not authorized!", "You are not authorized to run the Orderful components!")
  };
  }
  on_parse(event = null) {
    return this.on_parseInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_parseInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
    this.logger.log('Orderful', 'orderful_runner.on_parse');
  //O.Arias - 12/05/2023
  
  
  }
  on_940_map(event = null) {
    return this.on_940_mapInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_940_mapInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 12/05/2023
  
  let map = (await $flows.Orderful.orderful_940_map({ mapping_summary: true }))
  
  if ($utils.isDefined(map)) {
      if ($utils.isDefined(map.orders)) {
          $editor.fields.code_box.control.value = map.orders;
      };
  };
  }
  query_poller(event = null) {
    return this.query_pollerInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async query_pollerInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/08/2024
  
  let project: string;
  let warehouse: string;
  let packaging: string;
  let material: string;
  let order_class: string;
  let stream: string;
  
  project = $editor.fields.project.control.value;
  warehouse = $editor.fields.warehouse.control.value;
  material = $editor.fields.material.control.value;
  packaging = $editor.fields.packaging.control.value;
  order_class = $editor.fields.order_class.control.value;
  stream = $editor.fields.stream.control.value;
  
  let messages = (await $flows.Orderful.main_get_orders_from_poller({
      output_details: true
  }))?.messages;
  
  let json = {
      messages: messages
  };
  
  $editor.fields.code_box.control.value = json;
  }
  map_imported_orders(event = null) {
    return this.map_imported_ordersInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async map_imported_ordersInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/08/2024
  
  let project: string;
  let warehouse: string;
  let packaging: string;
  let material: string;
  let order_class: string;
  
  project = $editor.fields.project.control.value;
  warehouse = $editor.fields.warehouse.control.value;
  material = $editor.fields.material.control.value;
  packaging = $editor.fields.packaging.control.value;
  order_class = $editor.fields.order_class.control.value;
  
  let messages = (await $flows.Orderful.main_map_imported_orders({
      project: project,
      warehouse: warehouse,
      order_class: order_class,
      material: material,
      packaging: packaging
  }))?.messages;
  
  let json = {
      messages: messages
  };
  
  $editor.fields.code_box.control.value = json;
  }
  submit_orders_footprint(event = null) {
    return this.submit_orders_footprintInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async submit_orders_footprintInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/08/2024
  
  let project: string;
  let warehouse: string;
  let packaging: string;
  let material: string;
  let order_class: string;
  
  project = $editor.fields.project.control.value;
  warehouse = $editor.fields.warehouse.control.value;
  material = $editor.fields.material.control.value;
  packaging = $editor.fields.packaging.control.value;
  order_class = $editor.fields.order_class.control.value;
  
  let messages = (await $flows.Orderful.main_submit_orders_footprint({}))?.messages;
  
  let json = {
      messages: messages
  };
  
  $editor.fields.code_box.control.value = json;
  }
  confirm_orders(event = null) {
    return this.confirm_ordersInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async confirm_ordersInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/08/2024
  
  let project: string;
  let warehouse: string;
  let packaging: string;
  let material: string;
  let order_class: string;
  let stream: string;
  
  project = $editor.fields.project.control.value;
  warehouse = $editor.fields.warehouse.control.value;
  material = $editor.fields.material.control.value;
  packaging = $editor.fields.packaging.control.value;
  order_class = $editor.fields.order_class.control.value;
  stream = $editor.fields.stream.control.value;
  
  let messages = (await $flows.Orderful.main_confirm_orders({}))?.messages;
  
  let json = {
      messages: messages
  };
  
  $editor.fields.code_box.control.value = json;
  }
  check_order_status(event = null) {
    return this.check_order_statusInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async check_order_statusInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 02/05/2024
  
  let start_time = new Date();
  let step_time = new Date();
  let counter: number = 1;
  
  let integration_name: string = 'Orderful';
  let runs: number = $editor.fields.runs.control.value;
  if (!$utils.isDefined(runs)) { runs = 1 };
  if (runs <= 0) { runs = 1 };
  
  //Start the output parameter
  let out_messages = [];
  
  await log_add({
      "process": "check_order_status",
      "notes": `Begin [check_order_status]!`
  });
  
  add_message(`Begin [check_order_status]!`)
  
  try {
  
      while (counter <= runs) {
  
          counter = counter + 1;
  
          let confirmation = (await $flows.FootPrintApiManager.footprint_check_order_status({integration_name: integration_name}));
  
          if ($utils.isDefined(confirmation)) {
  
              out_messages = out_messages.concat(confirmation.messages);
  
              if (!confirmation.success || confirmation.count === 0) {
                  //Break if the flow did not report a successful execution
                  counter = 1000;
              };
  
          } else {
              throw ({ message: `Error calling the [footprint_check_order_status] flow! No response received!` })
          };
      };
  
  } catch (error) {
  
      // Get inner-most error message
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) {
          targetError = targetError.error;
      };
      if (!$utils.isDefined(targetError?.message)) {
          targetError = { "notes": `Uncaught exception! ${JSON.stringify(targetError)}` }
      };
  
      let errorMessage = `[check_order_status] Error! ${targetError.message} (${(((new Date()).getTime() - step_time.getTime()) / 1000)} sec)`
  
      add_message(errorMessage);
  
      await log_add({
          "process": "check_order_status",
          "notes": errorMessage
      });
  };
  
  let log_message = `Finished [check_order_status]! (Total ${(((new Date()).getTime() - start_time.getTime()) / 1000)} sec)`;
  
  add_message(log_message);
  
  await log_add({
      "process": "check_order_status",
      "notes": log_message
  });
  
  let json = {
      messages: out_messages
  };
  
  $editor.fields.code_box.control.value = json;
  
  /* Functions */
  function add_message(messsage: string) {
      if ($utils.isDefined(messsage)) {
          out_messages.push(messsage);
      };
  };
  
  const log_schema = (await $flows.Orderful.orderful_logs({})).payload;
  async function log_add(input: typeof log_schema[0]) {
      await $flows.Orderful.orderful_logs({ action: "Write", payload: input });
  };
  }
  query_footprint(event = null) {
    return this.query_footprintInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async query_footprintInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/08/2024
  
  let project: string;
  let warehouse: string;
  let packaging: string;
  let material: string;
  let order_class: string;
  let stream: string;
  
  project = $editor.fields.project.control.value;
  warehouse = $editor.fields.warehouse.control.value;
  material = $editor.fields.material.control.value;
  packaging = $editor.fields.packaging.control.value;
  order_class = $editor.fields.order_class.control.value;
  stream = $editor.fields.stream.control.value;
  
  let output = (await $flows.Orderful.main_get_shipments_from_footprint({
      project: project,
      warehouse: warehouse,
      order_class: order_class
  }));
  
  let json = {
      messages: output?.messages,
      shipments: output.shipments
  };
  
  $editor.fields.code_box.control.value = json;
  }
  map_exported_orders(event = null) {
    return this.map_exported_ordersInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async map_exported_ordersInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/08/2024
  
  let project: string;
  let warehouse: string;
  let packaging: string;
  let material: string;
  let order_class: string;
  let stream: string;
  
  project = $editor.fields.project.control.value;
  warehouse = $editor.fields.warehouse.control.value;
  material = $editor.fields.material.control.value;
  packaging = $editor.fields.packaging.control.value;
  order_class = $editor.fields.order_class.control.value;
  stream = $editor.fields.stream.control.value;
  
  let messages = (await $flows.Orderful.main_map_exported_orders({
      stream: stream,
      project: project,
      warehouse: warehouse,
      order_class: order_class,
      material: material,
      packaging: packaging
  }))?.messages;
  
  let json = {
      messages: messages
  };
  
  $editor.fields.code_box.control.value = json;
  }
  on_945_map(event = null) {
    return this.on_945_mapInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_945_mapInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/12/2024
  
  let map = (await $flows.Orderful.orderful_945_map({ mapping_summary: true }))
  
  if ($utils.isDefined(map)) {
      if ($utils.isDefined(map.orders)) {
          $editor.fields.code_box.control.value = map.orders;
      };
  };
  }
  submit_orders_orderful(event = null) {
    return this.submit_orders_orderfulInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async submit_orders_orderfulInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/08/2024
  
  let project: string;
  let warehouse: string;
  let packaging: string;
  let material: string;
  let order_class: string;
  let stream: string;
  
  project = $editor.fields.project.control.value;
  warehouse = $editor.fields.warehouse.control.value;
  material = $editor.fields.material.control.value;
  packaging = $editor.fields.packaging.control.value;
  order_class = $editor.fields.order_class.control.value;
  stream = $editor.fields.stream.control.value;
  
  let messages = (await $flows.Orderful.main_submit_orders_orderful({
      stream: stream
  }))?.messages;
  
  let json = {
      messages: messages
  };
  
  $editor.fields.code_box.control.value = json;
  }
  on_940_test(event = null) {
    return this.on_940_testInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_940_testInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/03/2024
  
  let transaction_type = '940_WAREHOUSE_SHIPPING_ORDER';
  let order = JSON.parse($editor.fields.code_box_input.control.value);
  
  try {
  
      if ($utils.isDefined(order)) {
  
          if (Array.isArray(order)) {
              order = order[0];
          }
  
          let result_payload: { orders: any[], map: string, transaction_type: string }[];
  
          //Here is where we apply the map and return the FootPrint JSON schema
          let apply_map_payload: any = (await $flows.Orderful.orderful_apply_order_map({
              order_payload: order,
              transaction_type: transaction_type,
              sender: "",
              receiver: "",
              stream: "",
              owner: "",
              project: "",
              warehouse: "",
              material: "",
              packaging: "",
              order_class: "",
              order_type: "Outbound"
          }));
  
          //To prevent breaking current implementations
          if ($utils.isDefined(apply_map_payload?.result_payload)) { result_payload = apply_map_payload.result_payload }
          if ($utils.isDefined(apply_map_payload?.orders)) { result_payload = [apply_map_payload] }
  
          if (!$utils.isDefined(result_payload)) {
              throw ("No orders resulted from applying the map [orderful_apply_map]!");
          }
  
          for (let mapped_orders of result_payload) {
  
              if ($utils.isDefined(mapped_orders)) {
  
                  $editor.fields.code_box.control.value = mapped_orders.orders;
  
              } else {
                  throw new Error(`The [orderful_apply_map] function did not return a payload!`);
              }
          }
      } else {
          throw new Error(`Could not determine the input payload!`);
      }
  
  } catch (error) {
      $editor.fields.code_box.control.value = { error: error, order: order }
  }
  
  if ($editor.fieldsets.code_box_input.expanded) {
      $editor.fieldsets.code_box_input.toggle();
  }
  
  if (!$editor.fieldsets.code_box_output.expanded) {
      $editor.fieldsets.code_box_output.toggle();
  }
  }
  on_945_test(event = null) {
    return this.on_945_testInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_945_testInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/03/2024
  
  let order_type = "Outbound";
  let transaction_type = 'GENERIC JSON ORDER EXPORT REQUEST';
  let order = JSON.parse($editor.fields.code_box_input.control.value);
  
  try {
  
      if ($utils.isDefined(order)) {
  
          if (Array.isArray(order)) {
              order = order[0];
          }
  
          let result_payload: { orders: any[], map: string, transaction_type: string }[];
  
          //Here is where we apply the map and return the FootPrint JSON schema
          let apply_map_payload: any = (await $flows.Orderful.orderful_apply_order_map({
              order_payload: order,
              transaction_type: transaction_type,
              sender: "",
              receiver: "",
              stream: "",
              owner: "",
              project: "",
              warehouse: "",
              material: "",
              packaging: "",
              order_class: "",
              order_type: "Outbound"
          }));
  
          //To prevent breaking current implementations
          if ($utils.isDefined(apply_map_payload?.result_payload)) { result_payload = apply_map_payload.result_payload }
          if ($utils.isDefined(apply_map_payload?.orders)) { result_payload = [apply_map_payload] }
  
          if (!$utils.isDefined(result_payload)) {
              throw ("No orders resulted from applying the map [orderful_apply_map]!");
          }
  
          for (let mapped_orders of result_payload) {
  
              if ($utils.isDefined(mapped_orders)) {
  
                  $editor.fields.code_box.control.value = mapped_orders.orders;
  
              } else {
                  throw new Error(`The [orderful_apply_map] function did not return a payload!`);
              }
          }
      } else {
          throw new Error(`Could not determine the input payload!`);
      }
  
  } catch (error) {
      $editor.fields.code_box.control.value = { error: error, order: order }
  }
  
  if ($editor.fieldsets.code_box_input.expanded) {
      $editor.fieldsets.code_box_input.toggle();
  }
  
  if (!$editor.fieldsets.code_box_output.expanded) {
      $editor.fieldsets.code_box_output.toggle();
  }
  }
  on_943_map(event = null) {
    return this.on_943_mapInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_943_mapInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 02/26/2024
  
  let map = (await $flows.Orderful.orderful_943_map({ mapping_summary: true }))
  
  if ($utils.isDefined(map)) {
      if ($utils.isDefined(map.orders)) {
          $editor.fields.code_box.control.value = map.orders;
      };
  };
  }
  on_944_map(event = null) {
    return this.on_944_mapInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_944_mapInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 02/26/2024
  
  let map = (await $flows.Orderful.orderful_944_map({ mapping_summary: true }))
  
  if ($utils.isDefined(map)) {
      if ($utils.isDefined(map.orders)) {
          $editor.fields.code_box.control.value = map.orders;
      };
  };
  }
  on_943_test(event = null) {
    return this.on_943_testInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_943_testInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/03/2024
  
  let transaction_type = '943_WAREHOUSE_STOCK_TRANSFER_SHIPMENT_ADVICE';
  let order = JSON.parse($editor.fields.code_box_input.control.value);
  
  try {
  
      if ($utils.isDefined(order)) {
  
          if (Array.isArray(order)) {
              order = order[0];
          }
  
          let result_payload: { orders: any[], map: string, transaction_type: string }[];
  
          //Here is where we apply the map and return the FootPrint JSON schema
          let apply_map_payload: any = (await $flows.Orderful.orderful_apply_order_map({
              order_payload: order,
              transaction_type: transaction_type,
              sender: "",
              receiver: "",
              stream: "",
              owner: "",
              project: "",
              warehouse: "",
              material: "",
              packaging: "",
              order_class: "",
              order_type: "Inbound"
          }));
  
          //To prevent breaking current implementations
          if ($utils.isDefined(apply_map_payload?.result_payload)) { result_payload = apply_map_payload.result_payload }
          if ($utils.isDefined(apply_map_payload?.orders)) { result_payload = [apply_map_payload] }
  
          if (!$utils.isDefined(result_payload)) {
              throw ("No orders resulted from applying the map [orderful_apply_map]!");
          }
  
          for (let mapped_orders of result_payload) {
  
              if ($utils.isDefined(mapped_orders)) {
  
                  $editor.fields.code_box.control.value = mapped_orders.orders;
  
              } else {
                  throw new Error(`The [orderful_apply_map] function did not return a payload!`);
              }
          }
      } else {
          throw new Error(`Could not determine the input payload!`);
      }
  
  } catch (error) {
      $editor.fields.code_box.control.value = { error: error, order: order }
  }
  
  if ($editor.fieldsets.code_box_input.expanded) {
      $editor.fieldsets.code_box_input.toggle();
  }
  
  if (!$editor.fieldsets.code_box_output.expanded) {
      $editor.fieldsets.code_box_output.toggle();
  }
  }
  on_944_test(event = null) {
    return this.on_944_testInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_944_testInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/03/2024
  
  let order_type = "Inbound";
  let transaction_type = 'GENERIC JSON ORDER EXPORT REQUEST';
  let order = JSON.parse($editor.fields.code_box_input.control.value);
  
  try {
  
      if ($utils.isDefined(order)) {
  
          if (Array.isArray(order)) {
              order = order[0];
          }
  
          let result_payload: { orders: any[], map: string, transaction_type: string }[];
  
          //Here is where we apply the map and return the FootPrint JSON schema
          let apply_map_payload: any = (await $flows.Orderful.orderful_apply_order_map({
              order_payload: order,
              transaction_type: transaction_type,
              sender: "",
              receiver: "",
              stream: "",
              owner: "",
              project: "",
              warehouse: "",
              material: "",
              packaging: "",
              order_class: "",
              order_type: "Inbound"
          }));
  
          //To prevent breaking current implementations
          if ($utils.isDefined(apply_map_payload?.result_payload)) { result_payload = apply_map_payload.result_payload }
          if ($utils.isDefined(apply_map_payload?.orders)) { result_payload = [apply_map_payload] }
  
          if (!$utils.isDefined(result_payload)) {
              throw ("No orders resulted from applying the map [orderful_apply_map]!");
          }
  
          for (let mapped_orders of result_payload) {
  
              if ($utils.isDefined(mapped_orders)) {
  
                  $editor.fields.code_box.control.value = mapped_orders.orders;
  
              } else {
                  throw new Error(`The [orderful_apply_map] function did not return a payload!`);
              }
          }
      } else {
          throw new Error(`Could not determine the input payload!`);
      }
  
  } catch (error) {
      $editor.fields.code_box.control.value = { error: error, order: order }
  }
  
  if ($editor.fieldsets.code_box_input.expanded) {
      $editor.fieldsets.code_box_input.toggle();
  }
  
  if (!$editor.fieldsets.code_box_output.expanded) {
      $editor.fieldsets.code_box_output.toggle();
  }
  }
  under_development(event = null) {
    return this.under_developmentInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async under_developmentInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/08/2024
  
  $shell.Orderful.openInfoDialog("Under development!","This component is still under development, thank you!");
  }
  insert_transaction(event = null) {
    return this.insert_transactionInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async insert_transactionInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/15/2024
  
  let insert_transaction: any = JSON.parse($editor.fields.code_box_input.control.value ?? null);
  
  if ($utils.isDefined(insert_transaction)) {
  
      let query_poller = (await $flows.Orderful.orderful_query_poller({
          output_details: true,
          insert_transaction: insert_transaction
      }));
  
      let json = {
          messages: query_poller?.messages,
          insert_transaction: insert_transaction
      };
  
      $editor.fields.code_box.control.value = json;
  } else {
      $editor.fields.code_box.control.value = { message: `No valid transaction was supplied in the input.` };
  }
  
  if (!$editor.fieldsets.code_box_output.expanded) {
      $editor.fieldsets.code_box_output.toggle()
  }
  if ($editor.fieldsets.code_box_input.expanded) {
      $editor.fieldsets.code_box_input.toggle()
  }
  }
  all_in(event = null) {
    return this.all_inInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async all_inInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/17/2024
  
  let messages = (await $flows.Orderful.all_in({}))?.messages;
  
  let json = {
      messages: messages
  };
  
  $editor.fields.code_box.control.value = json;
  }
  all_out(event = null) {
    return this.all_outInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async all_outInternal(
    $editor: Orderful_orderful_runnerComponent,
  
    $shell: Orderful_ShellService,
    $datasources: Orderful_DatasourceService,
    $flows: Orderful_FlowService,
    $reports: Orderful_ReportService,
    $settings: SettingsValuesService,
    $operations: Orderful_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Orderful_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/17/2024
  
  let messages = (await $flows.Orderful.all_out({}))?.messages;
  
  let json = {
      messages: messages
  };
  
  $editor.fields.code_box.control.value = json;
  }
  //#endregion private flows
  
}
