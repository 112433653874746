import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { BarcodeParsing_ShellService } from './BarcodeParsing.shell.service';
import { BarcodeParsing_OperationService } from './BarcodeParsing.operation.service';
import { BarcodeParsing_DatasourceService } from './BarcodeParsing.datasource.index';
import { BarcodeParsing_FlowService } from './BarcodeParsing.flow.index';
import { BarcodeParsing_ReportService } from './BarcodeParsing.report.index';
import { BarcodeParsing_LocalizationService } from './BarcodeParsing.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './BarcodeParsing.frontend.types'
import { $frontendTypes as $types} from './BarcodeParsing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'BarcodeParsing-home',
  templateUrl: './BarcodeParsing.home.component.html'
})
export class BarcodeParsing_homeComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    input_scan: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      parse_input: new ToolModel(new ButtonModel('parse_input', new ButtonStyles(null, null), false, false, false, 'Parse', 'ms-Icon ms-Icon--BranchFork', null)
    , false)
  };

  fields = {
    input_scan: new FieldModel(new TextBoxModel(this.formGroup.controls['input_scan'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Input scan', false, false),
    gtin: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'GTIN', false, false),
    serial_number: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Serial number', false, false),
    expiration_date: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Expiration date', false, false),
    batch_number: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Batch number', false, false),
  };

  fieldsets = {
  input: new FieldsetModel('Input', false, false, true, false),
  output: new FieldsetModel('Output', false, false, true, false),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    input_scan: this.fields.input_scan.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: BarcodeParsing_ShellService,
private datasources: BarcodeParsing_DatasourceService,
private flows: BarcodeParsing_FlowService,
private reports: BarcodeParsing_ReportService,
private localization: BarcodeParsing_LocalizationService,
private operations: BarcodeParsing_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Home';
  
    const $form = this;
    const $utils = this.utils;

    


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .input_scan
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_parse_clicked(event = null) {
    return this.on_parse_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_parse_clickedInternal(
    $form: BarcodeParsing_homeComponent,
  
    $shell: BarcodeParsing_ShellService,
    $datasources: BarcodeParsing_DatasourceService,
    $flows: BarcodeParsing_FlowService,
    $reports: BarcodeParsing_ReportService,
    $settings: SettingsValuesService,
    $operations: BarcodeParsing_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarcodeParsing_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarcodeParsing', 'home.on_parse_clicked');
  if ($utils.isDefinedTrimmed($form.fields.input_scan.control.value)) {
      let result = (await $flows.BarcodeParsing.parse_gs1_barcode_flow({ inputScan: $form.fields.input_scan.control.value }));
  
      if ($utils.isDefined(result?.reasons)) {
          throw new Error(result.reasons[0]);
      }
  
      $form.fields.gtin.control.text = result.extractedValues.gtin.toString();
      $form.fields.serial_number.control.text = result.extractedValues.serialNumber.toString();
      $form.fields.expiration_date.control.text = result.extractedValues.expirationDate.toString();
      $form.fields.batch_number.control.text = result.extractedValues.lotNumber.toString();
  }
  }
  //#endregion private flows
}
