import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Waves_wave_date_types_singleComponent } from './Waves.wave_date_types_single.component'


import { FootPrintManager_pick_waves_expanded_gridComponent } from './FootPrintManager.pick_waves_expanded_grid.component';

interface IFootPrintManager_pick_waves_gridComponentEntity {
  Id?: number, BatchPick?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, ReleaseDate?: string, StagingLocationId?: number, WarehouseId?: number, Priority?: { Id?: number, Description?: string, Name?: string, Value?: number }, Status?: { Id?: number, Name?: string }, Shipments?: { Id?: number, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { Id?: number, OrderClassId?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, OrderClass?: { Id?: number, Name?: string, OrderClassTypeId?: number, OrderTypeId?: number } } }[] }[], Warehouse?: { Id?: number, Name?: string }}

interface IFootPrintManager_pick_waves_gridComponentInParams {
  ownerId?: number[], projectId?: number[], warehouseId?: number[], statusIds?: number[]}


class FootPrintManager_pick_waves_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_pick_waves_gridComponent;
  entity: IFootPrintManager_pick_waves_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    is_batch_pick_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    Shipment_Wave_Id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    manual_allocations: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Shipment_Wave_Description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Shipment_Wave_Status_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Shipment_Wave_Priority_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    Shipment_Wave_CreatedSysDateTime: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null,  'datetime', 'l, LT', null)
,
null
      ),
    Shipment_Wave_CreatedSysUser: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    released_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null,  'datetime', 'l, LT', null)
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    is_batch_pick: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['is_batch_pick_display'] as DatexFormControl, null, true, false, '', null)
,
null
      ),
    staging_location: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }


  //#region rowExpand inParams


  get $rowExpand_FootPrintManager_pick_waves_expanded_grid_inParams_WaveId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.Id;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_pick_waves_gridComponent, entity: IFootPrintManager_pick_waves_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_pick_waves_gridComponent, entity?: IFootPrintManager_pick_waves_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      ownerIds:  $grid.inParams.ownerId ,
      projectIds:  $grid.inParams.projectId ,
      warehouseIds:  $grid.inParams.warehouseId ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      fromDate:  $grid.filters.from_date.control.value ,
      toDate:  $grid.filters.to_date.control.value ,
      dateType:  $grid.filters.date_type.control.value ,
    };
    const data = await this.datasources.Waves.ds_pick_waves_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_pick_waves_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.Shipment_Wave_Id.displayControl as TextModel).text = $row.entity.Id?.toString();
    (this.cells.manual_allocations.displayControl as TextModel).text = `None`;
    (this.cells.Shipment_Wave_Description.displayControl as TextModel).text = $row.entity.Description;
    (this.cells.Shipment_Wave_Status_Name.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.Shipment_Wave_Priority_Name.displayControl as TextModel).text = $row.entity.Priority?.Value.toString();
    (this.cells.Shipment_Wave_CreatedSysDateTime.displayControl as TextModel).text = $row.entity.CreatedSysDateTime?.toString();
    (this.cells.Shipment_Wave_CreatedSysUser.displayControl as TextModel).text = $row.entity.CreatedSysUser;
    (this.cells.released_on.displayControl as TextModel).text = $row.entity.ReleaseDate?.toString();
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.Warehouse.Name;
    (this.cells.is_batch_pick.displayControl as CheckBoxModel).reset($row.entity.BatchPick);
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'Shipment_Wave_Id' : {
        this.on_wave_id_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_wave_id_clicked(event = null) {
    return this.on_wave_id_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_wave_id_clickedInternal(
    $row: FootPrintManager_pick_waves_gridComponentRowModel,
  $grid: FootPrintManager_pick_waves_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let waveid = parseInt($row.cells.Shipment_Wave_Id.displayControl.text)
  
  
  $shell.FootPrintManager.opensingle_wave_hub({
      waveId: waveid,
      waveDescription: $row.cells.Shipment_Wave_Description.displayControl.text,
      waveStatus: $row.cells.Shipment_Wave_Status_Name.displayControl.text, 
      projectId: $row.entity?.Shipments[0]?.OrderLookups[0]?.Order?.Project.Id,
      ownerId: $row.entity?.Shipments[0]?.OrderLookups[0]?.Order?.Project.Owner.Id,
      warehouseIds: $row.entity.Warehouse.Id[0]
  })
  
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_pick_waves_gridComponentRowModel,
  $grid: FootPrintManager_pick_waves_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Waves_wave_date_types_singleComponent),
    forwardRef(() => FootPrintManager_pick_waves_expanded_gridComponent)
  ],
  selector: 'FootPrintManager-pick_waves_grid',
  templateUrl: './FootPrintManager.pick_waves_grid.component.html'
})
export class FootPrintManager_pick_waves_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_pick_waves_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_pick_waves_gridComponentInParams = { ownerId: [], projectId: [], warehouseId: [], statusIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Shipment_Wave_Id: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment_Wave_Id', 'Wave', false, false, null, false),       manual_allocations: new GridHeaderModel(new HeaderStyles(null, null), 'manual_allocations', 'Manual allocation', false, false, null, false),       Shipment_Wave_Description: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment_Wave_Description', 'Description', false, false, null, false),       Shipment_Wave_Status_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment_Wave_Status_Name', 'Status', false, false, null, false),       Shipment_Wave_Priority_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment_Wave_Priority_Name', 'Priority', false, false, null, false),       Shipment_Wave_CreatedSysDateTime: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment_Wave_CreatedSysDateTime', 'Created on', false, false, null, false),       Shipment_Wave_CreatedSysUser: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment_Wave_CreatedSysUser', 'Created by', false, false, null, false),       released_on: new GridHeaderModel(new HeaderStyles(null, null), 'released_on', 'Released on', false, false, null, false),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),       is_batch_pick: new GridHeaderModel(new HeaderStyles(null, null), 'is_batch_pick', 'Batch', false, false, null, false),       staging_location: new GridHeaderModel(new HeaderStyles(null, null), 'staging_location', 'Staging location', false, false, null, false),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'notes', 'Notes', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_pick_waves_gridComponentRowModel[] = [];

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }

  topToolbar = {
      process_wave: new ToolModel(new ButtonModel('process_wave', new ButtonStyles(null, null), false, false, false, 'Process', 'icon-ic_fluent_arrow_clockwise_20_regular', null)
    , false),
      release_wave: new ToolModel(new ButtonModel('release_wave', new ButtonStyles(null, null), false, false, false, 'Release', 'icon-ic_fluent_arrow_circle_up_20_regular', null)
    , false),
      separator7: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel_wave: new ToolModel(new ButtonModel('cancel_wave', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      print_wave: new ToolModel(new ButtonModel('print_wave', new ButtonStyles(null, null), false, false, false, 'Print', 'icon-ic_fluent_print_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    from_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    from_date: new FieldModel(new DateBoxModel(this.formGroup.controls['from_date'] as DatexFormControl, null, false, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'From date', false, false),
    to_date: new FieldModel(new DateBoxModel(this.formGroup.controls['to_date'] as DatexFormControl, null, false, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'To date', false, false),
    date_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['date_type'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Date type', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Pick waves';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 20;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerIds:  $grid.inParams.ownerId ,
      projectIds:  $grid.inParams.projectId ,
      warehouseIds:  $grid.inParams.warehouseId ,
      statusIds:  $grid.inParams.statusIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      fromDate:  $grid.filters.from_date.control.value ,
      toDate:  $grid.filters.to_date.control.value ,
      dateType:  $grid.filters.date_type.control.value ,
    };
    try {
      const data = await this.datasources.Waves.ds_pick_waves_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_pick_waves_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_pick_waves_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootPrintManager_pick_waves_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_pick_waves_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let waveResults = [];
  let waveIds = $grid.rows.map(row => row.entity.Id)
  
  if ($utils.isDefined(waveIds)) {
      waveResults = (await $datasources.Waves.get_manual_allocations.get({ WaveIds: waveIds })).result
  }
  
  let stagingLocations = (await $datasources.Locations.ds_get_locations_by_locationIds.get({ locationIds: $grid.rows.filter(row => $utils.isDefined(row.entity.StagingLocationId)).map(row => row.entity.StagingLocationId )})).result;
  
  for (let row of $grid.rows) {
      row.cells.Shipment_Wave_CreatedSysUser.displayControl.text = (await $flows.Utilities.format_username_flow({ inUserName: row.cells.Shipment_Wave_CreatedSysUser.displayControl.text })).outUserName
      row.cells.manual_allocations.displayControl.text = `None`
      for (let wave of waveResults)
          if (row.entity.Id === wave.Shipment.Wave.Id) {
              if (wave.TaskCount > 0) {
                  row.cells.manual_allocations.displayControl.text = `⚠ ${wave.TaskCount.toString()} manual tasks`;
                  row.cells.manual_allocations.styles.setAttentionClass();
              }
          }
  
      // Set staging location
      row.cells.staging_location.displayControl.text = stagingLocations.find(l => l.Id === row.entity.StagingLocationId)?.Name;
  }
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_pick_waves_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $grid: FootPrintManager_pick_waves_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let message: string; 
  let processedWaveIds: number[] = [];
  let failedWaveIds: {id: number, errors: string} [] = [];
  for (let row of $grid.selectedRows) {
      var result = (await $flows.SalesOrders.process_single_wave_flow({ waveId: row.entity.Id, createManualAllocationTasks: false }))
      var reason = result.reason;
      if ($utils.isDefined(reason)) {
          await $shell.Waves.openErrorDialog(`Unable to process wave ${row.entity.Id}`, reason)
          failedWaveIds.push({id: row.entity?.Id, errors: reason});
          return;
      }
      if (!$utils.isDefined(reason)) {
          processedWaveIds.push(row.entity.Id);
      }
  
      $grid.refresh();
  }
  message = '';
     if($utils.isDefined(processedWaveIds)){
      message += `Successfully processed ${processedWaveIds.length === 1 ? 'wave' : 'waves'} ${processedWaveIds.join(', ')}. `;
     }
     if($utils.isDefined(failedWaveIds)){
      message += `Failed to processed ${failedWaveIds.length === 1 ? 'wave' : 'waves'} ${failedWaveIds.map(c => `${c.id} (${c.errors})`).join(', ')}. `;
     }
  await $shell.Waves.openInfoDialog(`Process waves`, message)
  $grid.refresh();
  }
  on_release_clicked(event = null) {
    return this.on_release_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_release_clickedInternal(
    $grid: FootPrintManager_pick_waves_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let message: string; 
  let releasedWaveIds: number[] = [];
  let failedWaveIds: {id: number, errors: string} [] = [];
  for (let row of $grid.selectedRows) {
      var result = (await $flows.Waves.release_single_wave_flow({ waveId: row.entity.Id }))
      var reason = result.reason;
      if ($utils.isDefined(reason)) {
          await $shell.Waves.openErrorDialog(`Unable to release wave ${row.entity.Id}`, reason)
          failedWaveIds.push({id: row.entity?.Id, errors: reason});
          return;
      }
      if (!$utils.isDefined(reason)) {
          releasedWaveIds.push(row.entity.Id);
      }
  
      $grid.refresh();
  }
  message = '';
     if($utils.isDefined(releasedWaveIds)){
      message += `Successfully released ${releasedWaveIds.length === 1 ? 'wave' : 'waves'} ${releasedWaveIds.join(', ')}. `;
     }
     if($utils.isDefined(failedWaveIds)){
      message += `Failed to release ${failedWaveIds.length === 1 ? 'wave' : 'waves'} ${failedWaveIds.map(c => `${c.id} (${c.errors})`).join(', ')}. `;
     }
  await $shell.Waves.openInfoDialog(`Release waves`, message)
  $grid.refresh();
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootPrintManager_pick_waves_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let message: string;
  let canceledWaveIds: number[] = [];
  let failedWaveIds: { id: number, errors: string }[] = [];
  let candidates: number[] = [];
  
  for (let row of $grid.selectedRows) {
      candidates.push(row.entity.Id)
  }
  
  
  const confirmation = await $shell.Waves.openConfirmationDialog(`Cancel ${candidates.length === 1 ? 'wave' : 'waves'} ${candidates.join(', ')}?`, `Once cancelled, waves cannot be restored.`, 'Confirm', 'Cancel')
  
  if (confirmation) {
      for (let row of $grid.selectedRows) {
          var result = (await $flows.Waves.cancel_wave_flow({ waveId: row.entity.Id })).reasons[0]
          if ($utils.isDefined(result)) {
              await $shell.Waves.openErrorDialog(`Unable to cancel wave ${row.entity.Id}`, result)
              failedWaveIds.push({ id: row.entity?.Id, errors: result });
              return;
          }
          if (!$utils.isDefined(result)) {
              canceledWaveIds.push(row.entity.Id);
          }
      }
      message = '';
      if ($utils.isDefined(canceledWaveIds)) {
          message += `Successfully cancelled ${canceledWaveIds.length === 1 ? 'wave' : 'waves'} ${canceledWaveIds.join(', ')}. `;
      }
      if ($utils.isDefined(failedWaveIds)) {
          message += `Failed to cancel ${failedWaveIds.length === 1 ? 'wave' : 'waves'} ${failedWaveIds.map(c => `${c.id} (${c.errors})`).join(', ')}. `;
      }
      await $shell.Waves.openInfoDialog(`Cancel waves`, message)
      await $grid.refresh();
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_pick_waves_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  // Process
  $grid.topToolbar.process_wave.control.readOnly = (await checkAllProcessable(selectedRowsCount >= 0));
  
  // Release
  $grid.topToolbar.release_wave.control.readOnly = (await checkAllreleaseable(selectedRowsCount >= 0));
  
  // Print
  $grid.topToolbar.print_wave.control.readOnly = (await checkAllPrintable(selectedRowsCount != 1));
  
  // Cancel
  $grid.topToolbar.cancel_wave.control.readOnly = (await checkAllcancelable(selectedRowsCount >= 0));
  
  /**************************************************** 
   * FUNCTIONS
  *****************************************************/
  async function checkAllProcessable(allow: boolean) {
      for (let row of $grid.selectedRows) {
          if (row.entity.Status.Id === 1) //Created
          {
              allow = false;
          }
      }
      return allow;
  }
  async function checkAllreleaseable(allow: boolean) {
      for (let row of $grid.selectedRows) {
          if (row.entity.Status.Id === 2) //Processed
          {
              allow = false;
          } 
      }
      return allow;
  }
  async function checkAllPrintable(allow: boolean) {
      for (let row of $grid.selectedRows) {
          if (row.entity.Status.Id === 3 && allow === false) //Released waves
          {
              allow = false;
          } 
      }
      return allow;
  }
  async function checkAllcancelable(allow: boolean) {
      for (let row of $grid.selectedRows) {
          if (row.entity.Status.Id === 1 || row.entity.Status.Id === 2 || row.entity.Status.Id === 3) //Cancel waves in Processed, Created or Released.
          {
              allow = false;
          } 
      }
      return allow;
  }
  
  
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $grid: FootPrintManager_pick_waves_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  for (let row of $grid.selectedRows) {
      $shell.Waves.openpick_slip_by_wave_id_reportDialog({ waveId: row.entity.Id });
  }
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
