<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="material_catalogs" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_manage_catalogs_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden && !filtersets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-material_catalogs" *ngIf="!filters.material_catalogs.hidden && !filters.material_catalogs.removed" 
                                  class="field-container standard {{filters.material_catalogs.invalid ? 'invalid' : ''}} {{filters.material_catalogs.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.material_catalogs.styles.style"
                                  [ngClass]="filters.material_catalogs.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.material_catalogs.label + (filters.material_catalogs.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.material_catalogs.label}}<span *ngIf="filters.material_catalogs.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Catalogs-material_catalogs_dd_single 
                                  data-cy="selector"
                                  [type]="filters.material_catalogs.control.type"
                                  formControlName="material_catalogs"
                                  (displayTextChange)="filters.material_catalogs.control.displayText=$event"
                                  [placeholder]="filters.material_catalogs.control.placeholder"
                                  [styles]="filters.material_catalogs.control.styles"
                                  [tooltip]="filters.material_catalogs.control.tooltip"
                              >
                              </Catalogs-material_catalogs_dd_single>
                              <ng-container *ngIf="filters.material_catalogs.invalid">
                                <ng-container *ngFor="let error of filters.material_catalogs.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.source_materials.hasTabToShow" class="tab" data-cy="tab-source_materials">
              <h2 [className]="tabs.source_materials.active? 'active': ''" (click)="tabs.source_materials.activate()">{{tabs.source_materials.title}}</h2>
            </div>
            <div *ngIf="tabs.project_subscribers.hasTabToShow" class="tab" data-cy="tab-project_subscribers">
              <h2 [className]="tabs.project_subscribers.active? 'active': ''" (click)="tabs.project_subscribers.activate()">{{tabs.project_subscribers.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-material_catalog_source_materials_grid *ngIf="tabs.source_materials.active"
              #$tabs_source_materials
              [catalog_id]="$tabs_source_materials_material_catalog_source_materials_grid_inParams_catalog_id"
              ($refreshEvent)="refresh(false, false, '$tabs_source_materials')">
              </FootPrintManager-material_catalog_source_materials_grid>
              <FootPrintManager-material_catalogs_project_subscribers_grid *ngIf="tabs.project_subscribers.active"
              #$tabs_project_subscribers
              [catalog_id]="$tabs_project_subscribers_material_catalogs_project_subscribers_grid_inParams_catalog_id"
              ($refreshEvent)="refresh(false, false, '$tabs_project_subscribers')">
              </FootPrintManager-material_catalogs_project_subscribers_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>