<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="manifest_ready_shippingcontainer" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_manifest_ready_shipping_container_button_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator1" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="manifeststatus" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
              >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-shippingcontainerinfo"
                     *ngIf="!fieldsets.shippingcontainerinfo.hidden && !fieldsets.shippingcontainerinfo.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.shippingcontainerinfo.collapsible }">
                    <div *ngIf="!fieldsets.shippingcontainerinfo.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.shippingcontainerinfo.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.shippingcontainerinfo.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.shippingcontainerinfo.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.shippingcontainerinfo.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.shippingcontainerinfo.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.shippingcontainerinfo.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-shippingcontainercarrier" *ngIf="!fields.shippingcontainercarrier.hidden && !fields.shippingcontainercarrier.removed" 
                            class="field-container standard {{fields.shippingcontainercarrier.invalid ? 'invalid' : ''}} {{fields.shippingcontainercarrier.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.shippingcontainercarrier.styles.style"
                            [ngClass]="fields.shippingcontainercarrier.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.shippingcontainercarrier.label + (fields.shippingcontainercarrier.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.shippingcontainercarrier.label}}<span *ngIf="fields.shippingcontainercarrier.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="shippingcontainercarrier"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.shippingcontainercarrier.control.readOnly || fields.shippingcontainercarrier.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.shippingcontainercarrier.control.placeholder}}"
                                [ngStyle]="fields.shippingcontainercarrier.control.styles.style"
                                [ngClass]="fields.shippingcontainercarrier.control.styles.classes"
                                [matTooltip]="fields.shippingcontainercarrier.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.shippingcontainercarrier.invalid">
                          <ng-container *ngFor="let error of fields.shippingcontainercarrier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shippingcontainercarrierservice" *ngIf="!fields.shippingcontainercarrierservice.hidden && !fields.shippingcontainercarrierservice.removed" 
                            class="field-container standard {{fields.shippingcontainercarrierservice.invalid ? 'invalid' : ''}} {{fields.shippingcontainercarrierservice.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.shippingcontainercarrierservice.styles.style"
                            [ngClass]="fields.shippingcontainercarrierservice.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.shippingcontainercarrierservice.label + (fields.shippingcontainercarrierservice.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.shippingcontainercarrierservice.label}}<span *ngIf="fields.shippingcontainercarrierservice.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="shippingcontainercarrierservice"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.shippingcontainercarrierservice.control.readOnly || fields.shippingcontainercarrierservice.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.shippingcontainercarrierservice.control.placeholder}}"
                                [ngStyle]="fields.shippingcontainercarrierservice.control.styles.style"
                                [ngClass]="fields.shippingcontainercarrierservice.control.styles.classes"
                                [matTooltip]="fields.shippingcontainercarrierservice.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.shippingcontainercarrierservice.invalid">
                          <ng-container *ngFor="let error of fields.shippingcontainercarrierservice.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shippingcontainer_dimensions" *ngIf="!fields.shippingcontainer_dimensions.hidden && !fields.shippingcontainer_dimensions.removed" 
                            class="field-container standard {{fields.shippingcontainer_dimensions.invalid ? 'invalid' : ''}} {{fields.shippingcontainer_dimensions.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.shippingcontainer_dimensions.styles.style"
                            [ngClass]="fields.shippingcontainer_dimensions.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.shippingcontainer_dimensions.label + (fields.shippingcontainer_dimensions.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.shippingcontainer_dimensions.label}}<span *ngIf="fields.shippingcontainer_dimensions.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="shippingcontainer_dimensions"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.shippingcontainer_dimensions.control.readOnly || fields.shippingcontainer_dimensions.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.shippingcontainer_dimensions.control.placeholder}}"
                                [ngStyle]="fields.shippingcontainer_dimensions.control.styles.style"
                                [ngClass]="fields.shippingcontainer_dimensions.control.styles.classes"
                                [matTooltip]="fields.shippingcontainer_dimensions.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.shippingcontainer_dimensions.invalid">
                          <ng-container *ngFor="let error of fields.shippingcontainer_dimensions.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shippingcontainerweight" *ngIf="!fields.shippingcontainerweight.hidden && !fields.shippingcontainerweight.removed" 
                            class="field-container standard {{fields.shippingcontainerweight.invalid ? 'invalid' : ''}} {{fields.shippingcontainerweight.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.shippingcontainerweight.styles.style"
                            [ngClass]="fields.shippingcontainerweight.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.shippingcontainerweight.label + (fields.shippingcontainerweight.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.shippingcontainerweight.label}}<span *ngIf="fields.shippingcontainerweight.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="shippingcontainerweight"
                                matInput
                                numberBox
                                [format]="fields.shippingcontainerweight.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.shippingcontainerweight.control.readOnly || fields.shippingcontainerweight.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.shippingcontainerweight.control.placeholder}}"
                                [ngStyle]="fields.shippingcontainerweight.control.styles.style"
                                [ngClass]="fields.shippingcontainerweight.control.styles.classes"
                                [matTooltip]="fields.shippingcontainerweight.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.shippingcontainerweight.invalid">
                          <ng-container *ngFor="let error of fields.shippingcontainerweight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-container_type" *ngIf="!fields.container_type.hidden && !fields.container_type.removed" 
                            class="field-container standard {{fields.container_type.invalid ? 'invalid' : ''}} {{fields.container_type.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.container_type.styles.style"
                            [ngClass]="fields.container_type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.container_type.label + (fields.container_type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.container_type.label}}<span *ngIf="fields.container_type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="container_type"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.container_type.control.readOnly || fields.container_type.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.container_type.control.placeholder}}"
                                [ngStyle]="fields.container_type.control.styles.style"
                                [ngClass]="fields.container_type.control.styles.classes"
                                [matTooltip]="fields.container_type.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.container_type.invalid">
                          <ng-container *ngFor="let error of fields.container_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-address_info"
                     *ngIf="!fieldsets.address_info.hidden && !fieldsets.address_info.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.address_info.collapsible }">
                    <div *ngIf="!fieldsets.address_info.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.address_info.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.address_info.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.address_info.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.address_info.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.address_info.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.address_info.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-shippingcontainer_shipping_address" *ngIf="!fields.shippingcontainer_shipping_address.hidden && !fields.shippingcontainer_shipping_address.removed" 
                            class="field-container double {{fields.shippingcontainer_shipping_address.invalid ? 'invalid' : ''}} {{fields.shippingcontainer_shipping_address.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.shippingcontainer_shipping_address.styles.style"
                            [ngClass]="fields.shippingcontainer_shipping_address.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.shippingcontainer_shipping_address.label + (fields.shippingcontainer_shipping_address.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.shippingcontainer_shipping_address.label}}<span *ngIf="fields.shippingcontainer_shipping_address.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="shippingcontainer_shipping_address"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.shippingcontainer_shipping_address.control.readOnly || fields.shippingcontainer_shipping_address.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.shippingcontainer_shipping_address.control.placeholder}}"
                                [ngStyle]="fields.shippingcontainer_shipping_address.control.styles.style"
                                [ngClass]="fields.shippingcontainer_shipping_address.control.styles.classes"
                                [matTooltip]="fields.shippingcontainer_shipping_address.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.shippingcontainer_shipping_address.invalid">
                          <ng-container *ngFor="let error of fields.shippingcontainer_shipping_address.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-printing"
                     *ngIf="!fieldsets.printing.hidden && !fieldsets.printing.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.printing.collapsible }">
                    <div *ngIf="!fieldsets.printing.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.printing.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.printing.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.printing.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.printing.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.printing.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.printing.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-printer_packinglist" *ngIf="!fields.printer_packinglist.hidden && !fields.printer_packinglist.removed" 
                            class="field-container double {{fields.printer_packinglist.invalid ? 'invalid' : ''}} {{fields.printer_packinglist.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.printer_packinglist.styles.style"
                            [ngClass]="fields.printer_packinglist.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.printer_packinglist.label + (fields.printer_packinglist.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.printer_packinglist.label}}<span *ngIf="fields.printer_packinglist.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <PrintNode-printers_dd_single 
                            data-cy="selector"
                            [type]="fields.printer_packinglist.control.type"
                            formControlName="printer_packinglist"
                            (displayTextChange)="fields.printer_packinglist.control.displayText=$event"
                            [placeholder]="fields.printer_packinglist.control.placeholder"
                            [styles]="fields.printer_packinglist.control.styles"
                            [tooltip]="fields.printer_packinglist.control.tooltip"
                          [letterOnly]="$fields_printer_packinglist_selector_inParams_letterOnly"
                        >
                        </PrintNode-printers_dd_single>
                        <ng-container *ngIf="fields.printer_packinglist.invalid">
                          <ng-container *ngFor="let error of fields.printer_packinglist.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-label_size" *ngIf="!fields.label_size.hidden && !fields.label_size.removed" 
                            class="field-container standard {{fields.label_size.invalid ? 'invalid' : ''}} {{fields.label_size.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.label_size.styles.style"
                            [ngClass]="fields.label_size.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.label_size.label + (fields.label_size.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.label_size.label}}<span *ngIf="fields.label_size.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manifesting-label_size_dd_single 
                            data-cy="selector"
                            [type]="fields.label_size.control.type"
                            formControlName="label_size"
                            (displayTextChange)="fields.label_size.control.displayText=$event"
                            [placeholder]="fields.label_size.control.placeholder"
                            [styles]="fields.label_size.control.styles"
                            [tooltip]="fields.label_size.control.tooltip"
                        >
                        </Manifesting-label_size_dd_single>
                        <ng-container *ngIf="fields.label_size.invalid">
                          <ng-container *ngFor="let error of fields.label_size.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-results"
                     *ngIf="!fieldsets.results.hidden && !fieldsets.results.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.results.collapsible }">
                    <div *ngIf="!fieldsets.results.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.results.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.results.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.results.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.results.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.results.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.results.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-shippingcontainer_tracking" *ngIf="!fields.shippingcontainer_tracking.hidden && !fields.shippingcontainer_tracking.removed" 
                            class="field-container standard {{fields.shippingcontainer_tracking.invalid ? 'invalid' : ''}} {{fields.shippingcontainer_tracking.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.shippingcontainer_tracking.styles.style"
                            [ngClass]="fields.shippingcontainer_tracking.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.shippingcontainer_tracking.label + (fields.shippingcontainer_tracking.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.shippingcontainer_tracking.label}}<span *ngIf="fields.shippingcontainer_tracking.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="shippingcontainer_tracking"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.shippingcontainer_tracking.control.readOnly || fields.shippingcontainer_tracking.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.shippingcontainer_tracking.control.placeholder}}"
                                [ngStyle]="fields.shippingcontainer_tracking.control.styles.style"
                                [ngClass]="fields.shippingcontainer_tracking.control.styles.classes"
                                [matTooltip]="fields.shippingcontainer_tracking.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.shippingcontainer_tracking.invalid">
                          <ng-container *ngFor="let error of fields.shippingcontainer_tracking.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>


      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>