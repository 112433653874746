import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootPrintManager_inventory_by_lot_selection_gridComponentEntity {
  WarehouseId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { LastReceiveDate?: string, LookupCode?: string, MaterialId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Description?: string, LookupCode?: string, Name?: string } }, Packaging?: { ShortName?: string }}

interface IFootPrintManager_inventory_by_lot_selection_gridComponentInParams {
  warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number}

interface IFootPrintManager_inventory_by_lot_selection_gridComponentOutParams {
  inventory?: { lotId?: number, packagingId?: number, packagedAmount?: number }[]}

class FootPrintManager_inventory_by_lot_selection_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_inventory_by_lot_selection_gridComponent;
  entity: IFootPrintManager_inventory_by_lot_selection_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    quantity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    quantity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['quantity_edit'] as DatexFormControl, null, false, false, '', '', null)
      ),
    material_lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    on_hand: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    available: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    expiration_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    manufacture_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    last_received_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    material_description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    vat: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_inventory_by_lot_selection_gridComponent, entity: IFootPrintManager_inventory_by_lot_selection_gridComponentEntity) {
    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.WarehouseId,this.entity.LotId,this.entity.PackagedId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_inventory_by_lot_selection_gridComponent, entity?: IFootPrintManager_inventory_by_lot_selection_gridComponentEntity) {
    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.WarehouseId,this.entity.LotId,this.entity.PackagedId].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'WarehouseId',this.entity.WarehouseId);
    set($resultKey, 'LotId',this.entity.LotId);
    set($resultKey, 'PackagedId',this.entity.PackagedId);
    const inParams = {
      $keys:[$resultKey],
      warehouseId:  $grid.inParams.warehouseId ,
      materialId:  $grid.inParams.materialId ,
      packagingId:  $grid.inParams.packagingId ,
      lotId:  $grid.inParams.lotId ,
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.Inventory.ds_inventory_by_lot_selection_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootPrintManager_inventory_by_lot_selection_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.material_lookupcode.displayControl as TextModel).text = $row.entity.Lot?.Material?.LookupCode;
    (this.cells.on_hand.displayControl as TextModel).text = $row.entity.PackagedAmount?.toString();
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity.Lot?.VendorLot?.LookupCode;
    (this.cells.name.displayControl as TextModel).text = $row.entity.Lot?.Material?.Name;
    (this.cells.material_description.displayControl as TextModel).text = $row.entity.Lot?.Material?.Description;
    (this.cells.vat.displayControl as TextModel).text = $row.entity.Lot?.VendorLot?.Vat;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_inventory_by_lot_selection_gridComponentRowModel,
  $grid: FootPrintManager_inventory_by_lot_selection_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var lotId = $row.entity.LotId;
  var packagedId = $row.entity.PackagedId;
  var warehouseId = $grid.inParams.warehouseId;
  var materialId = $row.entity.Lot?.MaterialId;
  
  // concatenate uom short name with packaged amount 
  //$row.cells.packaged_amount.displayControl.text = $row.entity.PackagedAmount?.toString() + ' ' + $row.entity.Packaging?.ShortName
  
  // Apply date format
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.ExpirationDate) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
      var dateFormat = $settings.FootPrintManager.DateFormat;
      var expirationDate = $utils.date.format($row.entity.Lot?.VendorLot?.ExpirationDate, dateFormat);
      $row.cells.expiration_date.displayControl.text = expirationDate;
  }
  
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.ManufactureDate) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
      var dateFormat = $settings.FootPrintManager.DateFormat;
      var manufactureDate = $utils.date.format($row.entity.Lot?.VendorLot?.ManufactureDate, dateFormat);
      $row.cells.manufacture_date.displayControl.text = manufactureDate;
  }
  
  // Set the available cell
  const inventory = (await $flows.SalesOrders.get_sales_order_line_availability_flow({
      lotId: lotId,
      packagedId: packagedId,
      warehouseId: warehouseId,
      materialId: materialId
  }));
  if ($utils.isDefined(inventory?.availableByLot)) {
      $row.cells.available.displayControl.text = inventory.availableByLot.toString();
  }
  else {
      $row.cells.available.displayControl.text = '0'
  }
  
  
  // Load quantity cell from outParam
  
  if ($utils.isDefined($grid.outParams.inventory)) {
  
      const rowFound = $grid.outParams.inventory.filter(
          element => element.lotId === $row.entity.LotId
              && element.packagingId === $row.entity.PackagedId
  
      );
  
      if ($utils.isDefined(rowFound)) {
  
          $row.cells.quantity.editControl.value = rowFound[0].packagedAmount;
          $row.cells.quantity.displayControl.text = rowFound[0].packagedAmount?.toString();
  
      }
  
  }
  
  if ($utils.isDefined($grid.vars.selectedRows)) {
      const rowFound = $grid.vars.selectedRows.find(selectedRow =>
          selectedRow.entity.LotId === $row.entity.LotId
          && selectedRow.entity.PackagedId === $row.entity.PackagedId
      );
  
      if ($grid.rows.length === 0) {
          $grid.rows = $grid.rows.concat($grid.vars.selectedRows);
      }
  
      if ($utils.isDefined(rowFound)) {
          $grid.rows.splice($grid.rows.indexOf(rowFound), 1);
      }
  
      $grid.sort_grid();
  
  }
  
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  function isRowSelected(row: any) {
      return $utils.isDefined(row.cells.quantity.editControl.value) && row.cells.quantity.editControl.value > 0;
  }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_inventory_by_lot_selection_gridComponentRowModel,
  $grid: FootPrintManager_inventory_by_lot_selection_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.quantity.editControl.value > $row.entity.PackagedAmount){
      throw new Error ('Selected quantity cannot be greater than the on hand quantity.')
  }
  
  
  if ($utils.isDefined($grid.outParams.inventory)) {
  
      const rowFound = $grid.outParams.inventory.filter(
          element => element.lotId === $row.entity.LotId
              && element.packagingId === $row.entity.PackagedId
  
      );
  
      if ($utils.isDefined(rowFound)) {
  
          if ($utils.isDefined($row.cells.quantity.editControl.value) && $row.cells.quantity.editControl.value > 0) {
              rowFound[0].packagedAmount = $row.cells.quantity.editControl.value;
              $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
  
          } else {
  
              $grid.outParams.inventory.splice($grid.outParams.inventory.indexOf(rowFound[0]), 1);
              $row.cells.quantity.editControl.value = null;
              $row.cells.quantity.displayControl.text = null;
  
          }
  
      } else {
  
          $grid.outParams.inventory.push(
              {
                  packagedAmount: $row.cells.quantity.editControl.value,
                  lotId: $row.entity.LotId,
                  packagingId: $row.entity.PackagedId
              }
          )
  
          $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
      }
  
  } else {
  
      $grid.outParams.inventory.push(
          {
              packagedAmount: $row.cells.quantity.editControl.value,
              lotId: $row.entity.LotId,
              packagingId: $row.entity.PackagedId
          }
      )
  
      $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
  
  }
  
  addToSelectedRows($row);
  
  $grid.events.outParamsChange.emit();
  
  $grid.rows.sort((a, b) => isRowSelected(a) ? isRowSelected(b) ? 0 : -1 : isRowSelected(b) ? 1 : 0);
  
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  function isRowSelected(row: any) {
      return $utils.isDefined(row.cells.quantity.editControl.value) && row.cells.quantity.editControl.value > 0;
  }
  
  function addToSelectedRows(row: any) {
      if ($utils.isDefined($grid.vars.selectedRows)) {
  
          const rowFound = $grid.vars.selectedRows.filter(
              existingRow => existingRow.entity.LotId === $row.entity.LotId
                  && existingRow.entity.PackagedId === $row.entity.PackagedId
          );
  
          if ($utils.isDefined(rowFound)) {
  
              if (isRowSelected($row)) {
                  rowFound[0].packagedAmount = $row.cells.quantity.editControl.value;
                  $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
  
              } else {
  
                  $grid.vars.selectedRows.splice($grid.vars.selectedRows.indexOf(rowFound[0]), 1);
                  $row.cells.quantity.editControl.value = null;
                  $row.cells.quantity.displayControl.text = null;
  
              }
  
          } else {
  
              $grid.vars.selectedRows.push($row);
  
              $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
          }
  
      } else {
  
          $grid.vars.selectedRows.push($row);
  
          $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
      }
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-inventory_by_lot_selection_grid',
  templateUrl: './FootPrintManager.inventory_by_lot_selection_grid.component.html'
})
export class FootPrintManager_inventory_by_lot_selection_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_inventory_by_lot_selection_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_inventory_by_lot_selection_gridComponentInParams = { warehouseId: null, materialId: null, packagingId: null, lotId: null, projectId: null };

  outParams: IFootPrintManager_inventory_by_lot_selection_gridComponentOutParams = { inventory: [] };

  //#region Variables
  vars: { selectedRows?: any[] } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ inventory?: { lotId?: number, packagingId?: number, packagedAmount?: number }[] }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     quantity: new GridHeaderModel(new HeaderStyles(null, null), 'quantity', 'Quantity', false, false, null, false),       material_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'material_lookupcode', 'Material', false, false, null, false),       on_hand: new GridHeaderModel(new HeaderStyles(null, null), 'on_hand', 'On hand', false, false, null, false),       available: new GridHeaderModel(new HeaderStyles(null, null), 'available', 'Available', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'vendorlot', 'Vendor lot', false, false, null, false),       expiration_date: new GridHeaderModel(new HeaderStyles(null, null), 'expiration_date', 'Expiration date', false, false, null, false),       manufacture_date: new GridHeaderModel(new HeaderStyles(null, null), 'manufacture_date', 'Manufacture date', false, false, null, false),       last_received_date: new GridHeaderModel(new HeaderStyles(null, null), 'last_received_date', 'Last received date', false, false, null, false),       name: new GridHeaderModel(new HeaderStyles(null, null), 'name', 'Name', false, false, null, false),       material_description: new GridHeaderModel(new HeaderStyles(null, null), 'material_description', 'Description', false, false, null, false),       vat: new GridHeaderModel(new HeaderStyles(null, null), 'vat', 'Vat', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_inventory_by_lot_selection_gridComponentRowModel[] = [];

  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('materialId') set $inParams_materialId(value: any) {
    this.inParams['materialId'] = value;
  }
  get $inParams_materialId(): any {
    return this.inParams['materialId'] ;
  }
  @Input('packagingId') set $inParams_packagingId(value: any) {
    this.inParams['packagingId'] = value;
  }
  get $inParams_packagingId(): any {
    return this.inParams['packagingId'] ;
  }
  @Input('lotId') set $inParams_lotId(value: any) {
    this.inParams['lotId'] = value;
  }
  get $inParams_lotId(): any {
    return this.inParams['lotId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Inventory by lot';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
      if(isNil(this.inParams.projectId)) {
        this.$missingRequiredInParams.push('projectId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 15;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouseId:  $grid.inParams.warehouseId ,
      materialId:  $grid.inParams.materialId ,
      packagingId:  $grid.inParams.packagingId ,
      lotId:  $grid.inParams.lotId ,
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
      const data = await this.datasources.Inventory.ds_inventory_by_lot_selection_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootPrintManager_inventory_by_lot_selection_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_inventory_by_lot_selection_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push(row.$initializeExisting(this, entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_inventory_by_lot_selection_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.vars.selectedRows = [];
  }
  sort_grid(event = null) {
    return this.sort_gridInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async sort_gridInternal(
    $grid: FootPrintManager_inventory_by_lot_selection_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await delay(125);
  
  $grid.rows.sort((a, b) => isRowSelected(a) >= 0 ? isRowSelected(b) >= 0 ? isRowSelected(a) < isRowSelected(b) ? -1 : 1 : -1 : isRowSelected(b) >= 0 ? 1 : 0);
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  function isRowSelected(inputRow: any) {
      return $grid.vars.selectedRows.indexOf($grid.vars.selectedRows.find(selectedRow => 
          selectedRow.entity.LotId === inputRow.entity.LotId
          && selectedRow.entity.PackagedId === inputRow.entity.PackagedId
      ));
  }
  
  function delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
